.ant-layout.main-body{  position: fixed; left: 0; right: 0; bottom: 0; top:0px; overflow: auto; }
.ant-layout.main-body.perform-body{ bottom: 30px; padding:0; height:100%; position: relative; z-index:1; }

.ant-layout.main-body.perform-body .perform-body-tasks{ overflow: hidden; flex: 1; }

.perform-body-tasks .no-padding{ padding:0 !important; }

.perform-body-tasks .all-lt-task{ height:100%; }
.perform-body-tasks .all-lt-task .left_task .ant-row{ height:100%; }
.ant-layout.main-body.perform-body .perform-body-tasks .left_task{ box-shadow: 0 3px 6px 0 rgba(0,0,0,.1); height:100%; background:#fff; overflow: hidden; }

.ant-layout.main-body.perform-body .perform-body-tasks .right_task{ overflow: hidden; height: 100%; }
.ant-table-scroll table { min-width: auto;}

.number_box_field .ant-form-item-children{ display:flex; }

body{
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue, proxima-nova ,sans-serif;
}
body *{
    font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue, proxima-nova ,sans-serif;
}

a{ outline:none; }
a:focus, a:hover{ outline:none; text-decoration:none; }
ul{ padding:0; margin:0; list-style:none; }

.ant-btn{ border:none; }

.common-btn{  font-size: 14px !important; line-height: 1 !important; font-weight: 600 !important; color:#fff !important; background:#3235F5 !important; position: relative; z-index:1; }
.common-btn:after{ content: ''; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: rgba(255,255,255,0.0); z-index: -1; }
.common-btn:hover:after{ background: rgba(255,255,255,0.1); }

.flex-body{ flex:1; display: flex; flex-flow: column; overflow:auto; }
.flex-body .common-bar{ background:#FAD500; margin:0; padding:6px 0; text-align:center; display:flex; align-items:center; justify-content:center; position:relative; }
.flex-body .common-bar .text-block{ margin:0; padding:0 12px 0 0; }
.flex-body .common-bar p{ font-size:15px; line-height:1; color:#313131; font-weight:600; margin:0; padding:0; }
.flex-body .common-bar .btn{ display:inline-block; font-size:14px; line-height:1; font-weight:600; color:#333; background: rgba(255,255,255,0.5); margin:0 0 0 5px; padding:10px 30px; border-radius:3px; box-shadow:none; outline:none; }
.flex-body .common-bar .btn:hover{ background: rgba(255,255,255,0.8); }
 
.flex-body .common-layout{ display:flex; flex:1; position: relative; height:100%; }

.flex-body .common-bar .hide_btn{ position:absolute; right:10px; top:50%; transform:translateY(-50%); height:auto; margin:0; padding:5px; background:none; box-shadow:none; outline:none; border:none; width:auto; height:auto; border-radius:0; color:#000; cursor:pointer; }

.flex-body .common-bar.red{ background:#FF4D4F; }

/* Common section */
.right-modal{
    position: fixed;
    right: 0;
    top: 60px;
    bottom: 0;
    background: #FFF;
    box-shadow: 0px -5px 20px 0px rgba(0,0,0,0.1);
    z-index: 20;
    width:25%;
    animation: appear 500ms;
}
@keyframes appear{
    0%{
        transform: translateX(130%);
    }
    100%{
        transform: translateX(0%);
    }
}
.main-navigation {
    background: #FFF !important;
}
.form-holder > div{
    float: left;
    width: 100%;
    padding:10px 30px;
}
.form-holder > div label{
    margin-bottom: 10px;
    float: left;
    width: 100%;
    color: #999;
}
.form-holder .ant-btn-primary{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-radius: 0px;
    font-weight: 700;
    
    font-size: 16px;
}
.mt-5{
    margin-top: 11px;
}
.gutter-box .mt-5{ margin-top:0; }
.form-holder h5{
    padding: 30px;
    padding-bottom: 10px;
    font-size: 18px;
    position: relative;
}
.form-holder h5 .close-btn{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    cursor: pointer;
}

.login-form h1{ font-size: 25px; line-height: 1.2; color: #000; font-weight: 500; margin: 0; padding: 0 0 35px 0; }

/*============ Header Start ===========*/
.ant-layout-header{ position: relative; width:100%; height:55px; top:0; background:#fff; display:flex; align-items: center; border-bottom: solid 1px rgba(0,0,0,.1); z-index:999; padding:0 20px; }

.ant-layout-header .main-header{ width:100%; display:flex; align-items:center; justify-content:space-between; margin:0; padding:0; }
.ant-layout-header .logo-block{  margin: 0; padding: 0; display: flex; align-items: center;  }
.ant-layout-header .logo-block a{ font-size:13px; line-height:15px; font-weight:400; color:#B2B2B2; text-transform: uppercase; margin:0; padding:0; }

.ant-layout-header .form-block{ width:450px; margin:0 auto; padding:0; }
.ant-layout-header .form-block .ant-input-search{ margin:0; padding:0; }
.ant-layout-header .form-block .ant-input-search .ant-input{ background: #F9f9f9;
    border: none; border-radius: 10px;height: 41px; font-size:14px;  line-height: 15px; color:#000; box-shadow:none; padding-left: 30px; }

.ant-layout-header .form-block .ant-input-search .ant-input::-webkit-input-placeholder { color:#B2B2B2; opacity:1; }
.ant-layout-header .form-block .ant-input-search .ant-input::-moz-placeholder { color:#B2B2B2; opacity:1; }
.ant-layout-header .form-block .ant-input-search .ant-input:-ms-input-placeholder { color:#B2B2B2; opacity:1; }
.ant-layout-header .form-block .ant-input-search .ant-input:-moz-placeholder { color:#B2B2B2; opacity:1; }

.ant-layout-header .form-block .ant-input-search .ant-btn{ background:none; border:none; color:#000; box-shadow:none; outline:none; font-size: 14px; }
.ant-layout-header .form-block .ant-input-search .ant-btn .anticon-search{ font-size:16px; }

.ant-form-item-control.has-error .ant-input{ border:solid 1px red !important; }
.ant-form-item-control.has-error .ant-select-selection{ border:solid 1px red !important; }

.ant-form-item-control.has-error .ant-radio-inner{ border-color:red !important; }

.addvendor-modal .field-content{ padding:0 0 15px 0 !important; margin-top:0; }

.addvendor-modal.leave-modal .field-content{ padding:0 !important; margin-bottom:0; }
.addvendor-modal.leave-modal .content_field .bug-radio:last-child{ padding-bottom:0; }
.addvendor-modal.leave-modal .content_field .bug-radio:last-child .ant-radio-wrapper{ padding-bottom:0; }


.main-header .rt-block{ display:flex; align-items:center; margin:0; padding:0; }
.main-header .lt-block{ display:flex; align-items:center; margin:0; padding:0; }


.navigation-block{ margin:0; padding:0 0 0 15px; line-height:0; }
.navigation-block ul li{ display:inline-block; margin:0; padding:0; position: relative; }
.navigation-block ul li a{ display:inline-block; font-size:15px; line-height:1; font-weight:500; color: #000; margin:0; padding:19px 15px; position:relative; border-bottom:2px solid transparent; }
.navigation-block ul li a.active{ border-bottom:2px solid #000; }
.navigation-block ul li:hover a{ color:#000; }




.logout-menu{ display:flex; align-items:center; margin:0; padding:0; line-height:0; }
.help-menu{ margin:0 20px; padding:0; }
.help-menu .anticon-question-circle{ font-size:25px; }


.cmp-menu{ margin:0; padding:0; }
.cmp-menu .ant-dropdown-link{ margin:0; padding:0 5px 0 1px; display:flex; align-items:center; border:1px solid rgba(0,0,0,.1); height:30px; border-radius:30px; background:#fff; }
.cmp-menu .cmp-logo{ width:28px; height:28px; border:1px solid rgba(0,0,0,.1); border-radius:50%; display:flex; align-items:center; justify-content:center; background:#fff; overflow:hidden; margin:0 8px 0 0; padding:0; }
.cmp-menu .cmp-logo img{ width:100%; height:100%; object-fit:contain; }
.cmp-menu .cmp-text{ display:inline-block; max-width:200px; font-size:14px; line-height:1; font-weight:500; color: #000; margin:0 12px 0 0; padding:0; white-space:nowrap; text-overflow:ellipsis; overflow:hidden; }

.cmp-menu .ant-dropdown-link .anticon{ color:#000; font-size:14px !important; }


.cmp-dropdown-menu .title-drp .ant-dropdown-menu-item-group-title{ margin:0; max-width:275px; font-size:17px; }
.cmp-dropdown-menu .ant-dropdown-menu-item-group-list .ant-dropdown-menu-item:hover{ background:none; }
.cmp-dropdown-menu .title-drp .ant-dropdown-menu-item-group-list{ margin:0; }
.cmp-dropdown-menu .ant-dropdown-menu-item span{ display:block; font-size:16px; line-height:1; font-weight:500; color: #707070; margin:0; padding:0; }
.cmp-dropdown-menu .ant-dropdown-menu-item .wrks-btn{ display:block; width:100%; height:40px; font-size:15px; line-height:1; font-weight:600; color: #000; margin:0; padding:0; border:1px solid rgba(0,0,0,.1); box-shadow:none; outline:none; border-radius:4px; cursor: pointer; }
.cmp-dropdown-menu .ant-dropdown-menu-item .wrks-btn:hover{ box-shadow:0 2px 6px rgba(0,0,0,.2); }

.cmp-dropdown-menu .ant-dropdown-menu-item p{ display:block; font-size:14px; line-height:1.3; font-weight:400; color: #a5a5a5; margin:0; padding:0 0 5px 0; }

.ant-form-explain{ padding-top: 10px; }
.and-block{ float: left; width: 100%; background: rgba(0, 0, 0, 0.05); margin-top:0; padding: 10px 10px; position: relative; border-radius:5px; transition:all 200ms ease-in-out; border:1px solid transparent; }
.and-block:hover{ border:1px solid rgba(0,0,0,.1); }

.and-block .conditions-field{ padding-right:30px !important; }
.and-block .conditions-field .block{ position: relative; }
.and-block .conditions-field .block .ant-input{ height:37px; background:#fff; border:1px solid #D1D1D0; }
.and-block .conditions-field .block .ant-input:hover{ border: 1px solid #3235F5; }
.and-block .conditions-field .block .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }
.and-block .conditions-field .block .ant-select-selection{ height:37px; border:1px solid #D1D1D0; }
.and-block .conditions-field .block .ant-select-selection:hover{ border: 1px solid #3235F5; }
.and-block .conditions-field .block .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }
.and-block .conditions-field .block .ant-select-selection .ant-select-selection__rendered{ line-height:35px; }


.and-block .btn-delete{ position: absolute; width:18px; height:auto; right:-30px; top:0; bottom:0; padding:0; background:none; font-size:12px; color:#f5222d; transition:all 150ms ease-in-out; }
.and-block .btn-delete:hover{ color:#f5222d; }

.and-block.or-block{ margin-top:40px; position: relative; }


.add-condition-btn{ background:#f2f3f2; position: relative; z-index:1; text-align:center; }
.add-condition-btn .ant-btn{ background:none; border: 1px solid rgba(0,0,0,.5); color:#000; }
.trigger-drawer .ant-drawer-body{ padding:0px 20px 20px; position:relative; }
.trigger-drawer .ant-drawer-content-wrapper{ width:555px !important; }
.origin-block{ margin-bottom:0px; padding: 8px 0 10px 0; }
.origin-block .ant-select{ padding:0 2px; }
.origin-block .ant-select-selection{ border:1px solid #D1D1D0; }
.origin-block .ant-select-selection:hover{ border: 1px solid #3235F5; }
.origin-block .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }


.trigger-drawer label.heading-label{ display:block; font-size:18px; line-height:20px; font-weight:400; margin:0 0 15px 0; padding:0 0 12px; border-bottom:1px solid rgba(0,0,0,.1); }

.trigger-drawer .block{ margin:0; padding:0; }
.trigger-drawer .block .boxer{ margin-bottom:10px; }
.trigger-drawer .block label .ant-btn{ line-height:0; height:auto; }

.trigger-drawer .block .radio-field .ant-radio-group{ display:flex; }

.trigger-drawer .trigger-effect{ margin:0; padding:0 0 20px 0; }

.trigger-drawer .b_tag{ margin-top: 18px; background:#fafafa; padding: 10px 45px 10px 10px; position:relative; border:1px solid transparent; transition:all 200ms ease-in-out; border-radius:5px; }
.trigger-drawer .b_tag:hover{ border:1px solid rgba(0,0,0,.1); }
.trigger-drawer .b_tag p{ font-size: 13px; line-height: 1.4; }
.trigger-drawer .b_tag .form-box{ margin:0 0 12px 0; padding:0; display:flex; align-items:center; }
.trigger-drawer .b_tag .form-box label{ padding:0; width:85px; }
.trigger-drawer .b_tag .form-box.message-box{ align-items:flex-start; }
.trigger-drawer .b_tag .form-box textarea.ant-input{ min-height:135px; }
.trigger-drawer .b_tag .form-box:last-child{ margin-bottom:0; }

.trigger-drawer .b_tag .form-box.choose-form-box .ant-select{ flex:1; margin-bottom:0; }

.trigger-drawer .b_tag .ant-select-selection{ border:1px solid #D1D1D0; }
.trigger-drawer .b_tag .ant-select-selection:hover{ border: 1px solid #3235F5; }
.trigger-drawer .b_tag .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.trigger-drawer .b_tag .ant-select-enabled{ margin-bottom:10px; }
.trigger-drawer .b_tag .ant-select-enabled:last-child{ margin-bottom:0; }


.trigger-drawer .b_tag .delete-btn{ position: absolute; width: 18px; height: auto; right:13px; top:18px; padding: 0; background:none; border-radius: 50%; font-size: 12px; color:#f5222d; transition: all 150ms ease-in-out; }
.trigger-drawer .b_tag .delete-btn:hover{ color:#f5222d; }

.trigger-drawer .trigger-effect .effect-btn{ margin:15px 0 0 0; padding:0; text-align:center; }
.trigger-drawer .trigger-effect .add-effect-btn{ border:1px solid rgba(0,0,0,.3); color:#000; font-weight:600; transition:all 200ms ease-in-out; }
.trigger-drawer .trigger-effect .add-effect-btn:hover{ border:1px solid #3235F5; color:#3235F5; }

.trigger-drawer .ant-collapse-item{ border-bottom:none; margin-bottom:12px; }
.trigger-drawer .ant-collapse-borderless{ padding:0 0 18px 0; }
.trigger-drawer .ant-collapse-header{ font-size: 16px; line-height: 18px; font-weight:600; color: #111; border-bottom: 1px solid rgba(0,0,0,.1); padding-left:20px !important; }
.trigger-drawer .ant-collapse-header .ant-collapse-arrow{ left:0 !important; }

.trigger-drawer .ant-collapse-content-active{ border-bottom:1px solid rgba(0,0,0,.1); border-radius: 0 !important; }

.trigger-drawer .ant-collapse-content-box{ padding:15px 0 0; }
.trigger-drawer .add_fixed{ position: fixed; width:100%; bottom:0; z-index: 99; }
.trigger-drawer .trigger-add-btn{ display:block;  border-radius:0; font-weight:600; width: 555px; height:45px; font-size: 16px;
    margin-left: -20px; }

.trigger-drawer .field_add{ width:100% !important; }
.trigger-drawer .field_add .form-content{ padding:0; }
.trigger-drawer .field_add .form-content .rdw-editor-wrapper{ margin:0 0 0 -10px; flex:1; }
.trigger-drawer .rdw-editor-main{ min-height:210px; }


.webhook-sec{ margin:0; padding:0; }
.webhook-sec .block{ display:flex; align-items:center; margin:0 0 15px 0; padding:0; }
.webhook-sec .block:last-child{ margin-bottom:0; }
.webhook-sec .block .label-text{ width:155px; margin:0 5px 0 0; padding:0; }
.webhook-sec .block .label-text label{ display:block;  font-size: 14px; line-height: 1; color: #000; font-weight: 500; margin: 0; padding:0; }

.webhook-sec .block .form-field{ flex:1; margin:0; padding:0; }

.webhook-sec .block.data-block{ align-items:flex-start; }
.webhook-sec .block.data-block .label-text{ padding-top:10px; }

.webhook-sec .value-field{ margin:0; padding:15px 0 0 0; }
.webhook-sec .value-field .form-value{ margin:0; padding:0; }
.webhook-sec .value-field .form-value .input-box{ margin-bottom:10px; }
.webhook-sec .value-field .form-value .input-box:last-child{ margin-bottom:0; }

.webhook-sec .value-field .form-value .box{ margin:0; padding:0; }
.webhook-sec .value-field .form-value label{ display:block;  font-size: 13px; line-height: 1; color: #000; font-weight: 500; margin: 0; padding:0 0 5px 0; }
.webhook-sec .close-btn{ display:flex; align-items:center; justify-content:center; width:20px; height:20px; background:rgba(0,0,0,.1); border-radius:50%; box-shadow:none;; outline:none; margin:5px auto 0; padding:0; transition:all 200ms ease-in-out; }
.webhook-sec .close-btn .anticon{ line-height:0; color:#f5222d; width: 11px; font-size: 10px; }
.webhook-sec .close-btn:hover{ background:rgba(0,0,0,.2); }

.webhook-sec .block .form-field .ant-radio-group-solid .ant-radio-button-wrapper{ line-height:32px; }
.webhook-sec .block .form-field .ant-radio-group-solid .ant-radio-button-wrapper-checked{ background:#3235F5; box-shadow:none; }
.webhook-sec .block .form-field .ant-radio-group-solid .ant-radio-button-wrapper-checked:hover{ background:#1404D6; }

.webhook-sec .block .form-field .ant-input{ border:1px solid #D1D1D0; }
.webhook-sec .block .form-field .ant-input:hover{ border: 1px solid #3235F5; }
.webhook-sec .block .form-field .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }


.trigger-drawer .tigger-element .form-box{ display:block; }
.trigger-drawer .tigger-element .form-box label{ display:block; font-size:14px; color:#000; font-weight:500; margin:0; padding:0 0 9px 0; }

.trigger-drawer .tigger-element .group-form-box{ display:flex; margin:0 -8px; }
.trigger-drawer .tigger-element .group-form-box .ant-input-affix-wrapper{ padding:0 8px; }
.trigger-drawer .tigger-element .group-form-box .ant-input{ padding-left:12px; margin:0 8px; }
.trigger-drawer .tigger-element .group-form-box .ant-input-affix-wrapper .ant-input-prefix{ left:20px; }

.trigger-drawer .tigger-element .group-form-box .ant-input::-webkit-input-placeholder { color: rgba(0, 0, 0, 0.65); }
.trigger-drawer .tigger-element .group-form-box .ant-input::-moz-placeholder { color: rgba(0, 0, 0, 0.65); }
.trigger-drawer .tigger-element .group-form-box .ant-input:-ms-input-placeholder { color: rgba(0, 0, 0, 0.65); }
.trigger-drawer .tigger-element .group-form-box .ant-input:-moz-placeholder { color: rgba(0, 0, 0, 0.65); }

.atm-stage-field{ margin:0; padding:10px 0 0 0; }
.atm-stage-field .attribute-block{ margin-bottom:15px; }
.atm-stage-field .attribute-block .label-txt{ display: block; font-size: 14px; line-height: 1; color: #000; font-weight: 500; margin: 0; padding:0 0 9px 0; }
.atm-stage-field .attribute-block.checkbox-field{ padding:10px 0; }
.atm-stage-field .attribute-block .ant-checkbox-wrapper{ font-size:14px; }
.atm-stage-field .attribute-block .ant-checkbox-wrapper .ant-checkbox-inner{ width:25px; height:25px; }


/*============ Header End ===========*/
.disabled{opacity:0.7;}
.no-right-padding{ padding-right: 0px !important;}
.no-left-padding { padding-left: 0px !important; }


/*  login  */
.logo-h { display: flex; align-items: center; justify-content: center; width: 100%; margin-bottom: 50px; }
.logo-h img{ height: 40px; }

.form-h{ display:flex; background: #FFF; padding:0; margin:0; height:100vh; }

@keyframes scaleIn{
    0%{
        transform: scale(0.8);
    }
    100%{
        transform: scale(1);
    }
}

.form-h .lt-block{ width:500px; height:100%; overflow:auto; padding:30px; padding-right:60px; margin:0; }

.form-h .login-form .signup-sec{ float: left; margin:0; padding:0; display:flex; align-items:center; }
.form-h .login-form .signup-sec span{ display:inline-block; font-size: 14px; line-height: 16px; font-weight: 500; color: #000; letter-spacing: 0; margin: 0; padding:0 8px 0 0; }
.form-h .login-form .signup-sec a{ display:inline-block; font-size: 14px; line-height:1; color:#3235F5; font-weight:400; margin:0; padding:0; }
.form-h .login-form .signup-sec a:hover{ color:#1404D6; }

.form-h .login-form .forgot-sec{ float:right; }

.form-h h2{ font-size:22px; line-height:24px; font-weight:600; color:#fff; text-transform:capitalize; margin:0; padding:0 0 25px 0; }
.form-h h6{ font-size:12px; line-height:18px; font-weight:400; color:#fff; text-transform:capitalize; margin:0; padding:20px 0 0 0; }
.form-h h6 a{ color:#999; font-weight:400; transition:all 600ms ease-in-out; }
.form-h h6 a:hover{ color:#333 }

.form-h .login-form { margin:0; padding:0; border-radius: 5px; }
.form-h .login-form h3{ font-size:14px; line-height:30px; font-weight:600; color:#080705; text-align:left; margin:0; padding:0 0 35px 0; }

.form-h .login-form .ant-form-item{ margin:0 0 15px 0; padding:0; }
.tandc{line-height: 1.5; margin-top: 20px; text-align: left; font-size: 12px }
.form-h .login-form .ant-form-item .ant-form-item-children{ margin:0; padding:0;  transition:all 600ms ease-in-out; }
.form-h .login-form .ant-form-item .ant-form-item-children label{ font-size:16px; line-height:16px; font-weight:600; color:#080705; letter-spacing:0; margin:0; padding:0; transition:all 600ms ease-in-out; }
.form-h .login-form .ant-form-item .ant-form-item-children label a{ color:#3235F5; }
.form-h .login-form .ant-form-item .ant-form-item-children label a:hover{ color:#1404D6; }

.form-h .login-form .ant-form-item .ant-form-item-children .ant-input, .form-h .login-form .ant-form-item .ant-select { font-size:17px; line-height:1; font-weight:400; color:#080705;  margin:0; background:#FFF; height:45px; border:1px solid #D1D1D0; border-radius:5px; box-shadow:none; position: relative; padding:0; }


.form-h .login-form .ant-form-item .ant-form-item-children .ant-input{ padding:0 15px; } 
.form-h .login-form .ant-form-item .ant-form-item-children .ant-input:hover{ border: 1px solid #3235F5; }
.form-h .login-form .ant-form-item .ant-form-item-children .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }
.form-h.name_sec .login-form .ant-form-item .ant-form-item-children .ant-input{ font-size:16px; }

.form-h .login-heading{ display:block; font-size:16px; line-height:18px; font-weight:600; color: #444; margin:0; padding:0 0 35px 0; }

.form-h .login-form .login-form-forgot{ float:right; font-size:14px; line-height:1; color:#3235F5; font-weight:400; margin-bottom:0; }
.form-h .login-form .login-form-forgot:hover{ color:#1404D6; }

.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button{ width:100%; position: relative; left: 0%; transform: translateX(0%); font-size:14px; line-height:18px; font-weight:700; color:#fff; text-transform: uppercase; background:#1775ee; padding:0; height:49px; border-radius:5px; border:none; margin-top: 20px; }
/* .form-h .login-form .ant-form-item .ant-form-item-children .login-form-button:hover{ background:#0CB863; }
.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button:focus{ box-shadow:none; outline:none; } */

.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button:disabled{ background:#808080; opacity:.6; }

.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button.save-btn{ width:172px; margin-right:18px; }
.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button.reset-btn{ width:172px;     background: none; color: #333; border: 1px solid rgba(0,0,0,.1); }
.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button.reset-btn:hover{ color:#1775EE; }

.form-h .login-form .ant-form-item .ant-form-item-children .google-oauth{ width:100%; font-size:14px; line-height:18px; font-weight:600; color:#444; margin:0; padding:0; height:48px; border-radius:40px; border: 1px solid #EEE; display: flex; justify-content: center; align-items: center; position: relative; background: rgba(255,255,255,0.2); }
.form-h .login-form .ant-form-item .ant-form-item-children .google-oauth span{ position: absolute; top:50%; left: 26px; transform: translateY(-50%); }
.form-h .login-form .ant-form-item .ant-form-item-children .google-oauth:hover{ background:rgba(255,255,255,0.3); }

.or-box{ margin:20px 0 0 0; padding:0; position:relative; text-align:center; line-height:1; }
.or-box:before{ content:''; position:absolute; top:50%; transform:translateY(-50%); left:0; right:0; height:1px; background:rgba(0,0,0,.2); }
.or-box span{ display:inline-block; font-size:14px; line-height:1; color:#212121; font-weight:600; margin:0; padding:0 35px; background:#fff; position: relative; z-index:1; }

.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button.btn-wth{ background:none !important; border:1px solid #3235F5; color:#000 !important; }
.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button.btn-wth span{ display:inline-block; vertical-align: middle; }

.form-h .login-form .ant-form-item .ant-form-item-children .btn-wth .icon-img{ position: relative; top:-1px; margin-right:15px; }
.form-h .login-form .ant-form-item .ant-form-item-children .btn-wth .icon-img img{ width:23px; height:auto; }


.form-begining .ant-select-selection { height: 100%; border:none; display: flex; align-items: center; outline: none !important; box-shadow: none !important;}
.form-begining .ant-select-selection:hover{ border: 1px solid #3235F5; }
.form-begining .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.logo-img{ display:inline-block; margin:0 0 48px 0; padding:0; z-index:9; }
.logo-img a{ display:inline-block; }
.logo-img img{ width:auto; height:40px; }

.login-block{ margin:0; padding:0; }
.login-block .tip-side{ margin:0 45px 0 0; padding:0; position: relative; z-index:1; }
.login-block .tip-side .logo-h{ display:inline-block; position: absolute; top:30px; left:30px; margin:0; padding:0; z-index:9; }
.login-block .tip-side .text-block{ width:385px; margin:0 ; padding:0; background:#fff; }
.login-block .tip-side .text-block h2{ font-size:16px; line-height:18px; font-weight:700; color: #000; margin:0 0 20px 0; padding: 5px 0 0px 0; }
.login-block .tip-side .text-block p{ font-size: 15px; line-height: 1.5; font-weight: 400; color: #333; margin: 0; padding:0; }

.login-block .login-sec{ margin:0; padding:0; }
.login-block .login-sec .lg_form{ width:100%; margin:0; padding:0; }
.login-block .login-form{ padding:0; margin:0;  max-width: 100%; }
.login-block .login-form .login-btn{ padding-top:22px; }
.login-block .login-form .login-btn .login-form-button{ margin-top:0 !important; }

.form-h .rt-block{ flex:1; padding:0; margin:0; background:#74DADA; position: relative; }
.form-h .rt-block .desc{ position: absolute; max-width:500px; left:0; right:0; top:50%; transform:translateY(-50%); margin:0 auto; }
.form-h .rt-block .desc h2{  font-size:36px; line-height:1.4; color:#080705; font-weight:700; padding:0 0 18px 0; margin:0; }
.form-h .rt-block .desc p{ font-size:18px; line-height:1.4; color:#080705; font-weight:600; padding:0; margin:0; }
.form-h .rt-block .desc .logo{ margin:0 0 20px 0; padding:0; }
.form-h .rt-block .desc .logo img{ width:200px; height:auto; }

.form-h .login-form .ant-form-item .ant-form-item-required{ font-size:16px; line-height:16px; font-weight:600; color:#080705; letter-spacing:0; margin:0; padding:0; transition:all 600ms ease-in-out; }
.form-h .login-form .ant-form-item .ant-form-item-required:before{ display:none; }
.form-h .login-form .ant-form-item .ant-form-item-required:after{ display:none; }

.form-h .login-form .email-info{ margin:0 0 30px 0; padding:0; position: relative; }
.form-h .login-form .email-info label{ display:block; font-size:16px; line-height:16px; font-weight:600; color:#080705; letter-spacing:0; margin:0; padding:0 0 10px 0; }

.form-h .login-form .email-info p{ font-size:20px; line-height:16px; font-weight:600; color:#080705; letter-spacing:0; margin:0; padding: 0 135px 0 0; word-break: break-all; }

.form-h .login-form .email-info .wrn-btn{ position:absolute; right:0; bottom:0; font-size:14px; line-height:16px; font-weight:500; color:#3235F5; letter-spacing:0; margin:0; padding:8px 15px; border:1px solid #3235F5; outline:none; background:none; border-radius:3px; cursor: pointer; }
.form-h .login-form .email-info .wrn-btn:hover{ background:#3235F5; color:#fff; }

.form-h.login-page{ align-items:center; justify-content:center; position: relative; }
.form-h.login-page .logo-img{ position: absolute; left:30px; top:30px; }
.form-h.login-page .lt-block{ height:auto; padding-left:15px; padding-right:15px; overflow:inherit; }

@media screen and ( max-height: 500px ){
    .form-h.login-page{ height:auto; align-items: inherit; }

}

/*==== Sign-Up Page ====*/
.signup-block .signup-form, .login-form .singup-login{ margin:0; }
.signup-block{ margin:0; padding:0; background:#fff; }
.signup-block .feature-sec{ width:35%; margin:0; padding:0; position: relative; background:url(./assets/images/login-bg-img.png)  no-repeat left top; background-size:cover; z-index:1; }
.signup-block .feature-sec:after{ content: ""; position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: rgba(0,0,0,.4); z-index: -1; }
.signup-block .feature-sec .logo-h{ display: inline-block; position: absolute; top: 30px; left: 30px; margin: 0; padding: 0; z-index: 9; }

.signup-block .content-desc{ position: relative; width:450px; height:100%; right:0; top:0; bottom:0; border-radius:0; margin:0; padding:35px; z-index:9; }
.signup-block .content-desc h2{ font-size: 25px; line-height:27px; font-weight:600; color: #000; margin: 0 0 20px 0; padding:0; }
.signup-block .content-desc ul li{ font-size:15px; line-height:20px; font-weight:600; color: #333; margin: 0 0 15px 0; padding:0 0 0 25px; position: relative; }
.signup-block .content-desc ul li:last-child{ margin-bottom:0; }
.signup-block .content-desc ul li .icon-tick{display:inline-block; position: absolute; width:13px; left:0; top:1px; margin:0; padding:0; }
.signup-block .content-desc ul li .icon-tick img{ width:100%; height:auto; }
.signup-block .content-desc p{ font-size: 17px; line-height: 1.7; color: #555; font-weight: 400; margin: 0; padding: 0 0 45px 0;}
.signup-block .content-desc h6{ font-size: 13px; line-height:1.4; color:#333; font-weight:600; margin: 0; padding: 0; }
.signup-block .content-desc h6 span{ display:block; }

.signup-block .signup-form{ margin:0; padding:0; position: relative; }
.signup-block .signup-form .block_all{ margin:0; padding:0; }
.signup-block .signup-form .logo-h{ display:none; opacity:0; }


.signup-block .signup-form .login-form .has-error .ant-input{ border:1px solid red !important; }


.signup-block .signup-form .singup-man{ position: absolute; width:60%; left:-30%; bottom:0; z-index:99; }
.signup-block .signup-form .singup-man img{ width:100%; height:auto; }

.signup-block .signup-form .ant-checkbox-inner{ border-color:#D1D1D0; }
.signup-block .signup-form .ant-checkbox-inner:hover{ border-color:#3235F5; }

.signup-block .signup-form .ant-checkbox-checked .ant-checkbox-inner{ background-color:#3235F5; }

.character-list{ margin:0; padding:12px 0 0 0; }
.character-list .list-field{ display:flex; align-items:center; font-size:14px; line-height:1; font-weight:600; margin:0 0 12px 0; padding:0; }

.character-list .list-field.active{ color:#4AD295; }
.character-list .list-field .icon-img{ display:inline-block; width:21px; vertical-align:middle; margin:0 10px 0 0; padding:0; }
.character-list .list-field p{ flex:1; line-height:1.2; margin:0; padding:0; }
.character-list .list-field .icon-img img{ width:100%; height:auto; }
.character-list .list-field .icon-img .normal{ display:block; }
.character-list .list-field.active .icon-img .normal{ display:none; }
.character-list .list-field .icon-img .active{ display:none; }
.character-list .list-field.active .icon-img .active{ display:block; }

.form-h .login-form .ant-form-item .ant-form-item-children .ant-input.preloaded{ border:none; padding:0; text-decoration: underline; }
.form-h .login-form .ant-form-item .ant-form-item-children .ant-input.preloaded:focus{ border: 1px solid #0093FF; box-shadow: 0px 0px 1px 1px #b9d4e8; padding:0 15px;     text-decoration:none; }

.form-h .login-form .ant-form-item .ant-form-item-children .ant-input-group-addon{ border:none; background:none; font-size:16px; color:#080705; font-weight:400; }

.form-h.forgot-form{ justify-content:center; padding-top:40px; }
.form-h.forgot-form .forgot-box{ width:565px; }
.form-h.forgot-form .login-form{ padding:10px 35px 35px; }

.forgot-box h1{  font-size:25px; line-height: 1.4; color: #080705; font-weight: 700; margin: 0; padding: 0 0 35px 0; }
.forgot-box .login-heading{ font-size:18px; line-height: 1.4; color: #080705; font-weight:600; }

/* @media ( max-height: 500px ){
    .signup-block{ height:auto; padding-top:45px; }

} */

.welcome-box .progress-bar{ margin:0 0 25px 0; padding:0; display:flex; }
.welcome-box .progress-bar li{ width:36px; height:6px; background:#EBEBEB; margin:0 7px 0 0; padding:0; border-radius:7px; }
.welcome-box .progress-bar li.active{ background:#3235F5; }


.welcome-box h1{
    font-size: 4rem;
    margin: 0;
}
.welcome-box h4{
    font-size: 32px;
    line-height:1.1;
    font-weight:700;
    color:#000;
    margin:0;
    padding:0 0 15px 0; 
}


.signup-block .signup-form .login-form .singup-login .signup-sec{ padding-top:0; }
.signup-sec p { line-height: 24px;}


.form-h .login-form .ant-form-item .ant-form-item-children .field{ display:flex; align-items:center; justify-content:space-between; }
.form-h .login-form .ant-form-item .ant-form-item-children .field p{ margin:0; padding:0; line-height:1; }

.wrong_btn{ height:auto; border:none; font-size:13px; line-height:1; font-weight:600; margin:0; padding:14px 18px 12px; cursor: pointer; outline:none; box-shadow:none; }
.wrong_btn:hover{ background:#c6c6c6; }


.app-signup{ margin:0; padding:35px 0; display:flex; justify-content:center; position: relative; z-index:1; overflow:hidden;  }
.app-signup:before{ content:''; position: absolute; left:0; bottom:0; width: 0; height: 0; border-style: solid; border-width:45rem 0 0 90rem; border-color: transparent transparent transparent #8DD8D9; z-index:-1; }
.app-signup:after{ content:''; position: absolute; right:0; bottom:0; width: 0; height: 0; border-style: solid; border-width: 0 0 30rem 40rem; border-color: transparent transparent #FC7753 transparent; z-index:-2; }


.app-signup .element-block{ margin:0; padding:0; }
.app-signup .element-block .top-block{ display:flex; align-items:center; justify-content:space-between; margin:0 0 35px; padding:0 20px 0; position: relative; }
.app-signup .element-block .logo-img{ margin:0; padding:0; }

.app-signup .element-block .plus-icon{ width:18px; height:18px; margin:0; padding:0; position: absolute; left:0; right:0; top:50%; transform:translateY(-50%); margin:0 auto; }
.app-signup .element-block .plus-icon:before{ content:''; position: absolute; top:0; bottom:0; width:3px; left:50%; transform:translateX(-50%); background:#8DD8D9; }
.app-signup .element-block .plus-icon:after{ content:''; position: absolute; top:50%; transform:translateY(-50%); left:0; right:0; height:3px; background:#FC7753; }

.app-signup .element-block .company-logo{ margin:0; padding:0; }


.app-signup .signup-page{ display:block; width:475px; height:auto; background:#fff; border:1px solid rgba(0,0,0,.1); border-radius:10px; box-shadow:0 3px 15px rgba(0,0,0,.1); margin:0; padding:30px 30px 0; }



/*============ Side-Bar Start ===========*/
.ant-layout-sider{ background:#FFF; margin:0; padding:20px 0; } 
.ant-layout-sider .upper-block{ margin:0; padding:0; }
.ant-layout-sider .upper-block ul li{ font-size:14px; line-height:16px; color:#fff; font-weight:600; margin:0; padding:0; }
.ant-layout-sider .upper-block ul li a{ display:block; color:rgba(255,255,255,0.75); margin:0; padding: 13px 25px 14px; transition:all 600ms ease-in-out; font-size: 18px; }
.ant-layout-sider .upper-block ul li a:hover{ background:#688097; }
.ant-layout-sider .upper-block ul li.active a{ background:#688097; }

.ant-layout-sider .lower-block{ margin:0; padding:20px 20px 0 25px; text-align: left; }
.ant-layout-sider .lower-block button{ display:inline-block; font-size:14px; line-height:14px; color:#91A2B4; font-weight:400; text-transform:uppercase; margin:0; padding:10px 0px; background: none; width: 100%; text-align: left; border:none; transition:all 200ms ease-in-out; cursor: pointer; }
.ant-layout-sider .lower-block button:hover{ color:#fff; }
.ant-layout-sider .lower-block button:focus,.ant-layout-sider .lower-block button:active{
    box-shadow: none; outline: none;
}

.ant-layout-sider.main-navigation { width: 80px !important; min-width: 80px !important; max-width: 80px !important; display: flex; flex-flow: column; align-items: center; padding: 0px !important; background:#FFF !important; z-index: 999999}
.ant-layout-sider.content-navigation { background: #F9F9F9; width: 250px !important; min-width: 250px !important; max-width: 250px !important; padding:13px 0px; transition:all 500ms ease-in-out; }

.workflows-list { left:-300px;  position: fixed; top: 0; bottom: 0; transition:all 500ms ease-in-out;     box-shadow: 8px 0px 40px 0px rgba(0,0,0,0.1); background: #FFF !important; border-right: solid 1px #ccc; z-index:9; }
.workflows-list.visible { position: fixed; top: 0; bottom: 0; left: 80px; z-index: 222222; transition:all 500ms ease-in-out; }
 
.account_workspaces .top_box{ border:1px solid rgba(0,0,0,.1); padding:10px; background:#f9f9f9; }
.account_workspaces .top_box p{ color:#333; }
.account_workspaces .top_box .ant-btn{ display:inline-block; border:1px solid rgba(0,0,0,.1); padding:7px 15px; border-radius:4px; height:auto; box-shadow:none; outline:none; background:#3235F5; }
.account_workspaces .top_box .ant-btn:hover{ background:#1404D6; }

.main-navigation .ant-layout-sider-children { width: 100%; display: flex; flex-flow: column; align-items: center; justify-content:space-between; overflow: auto; overflow-x:hidden; }


.main-navigation .ant-layout-sider-children a, .main-navigation .ant-layout-sider-children .workflows-menu{ padding:20px 17px; display: flex; align-items: center; justify-content: center; width: 100%; flex-flow: column; height:auto; border:none; box-shadow:none; }
.main-navigation .ant-layout-sider-children .workflows-menu:hover{ background:#F9F9F9; }
.main-navigation .ant-layout-sider-children a *{ opacity: 1; }
.main-navigation .ant-layout-sider-children a i, .main-navigation .ant-layout-sider-children .workflows-menu i{ font-size: 25px; line-height:0; }
.main-navigation .ant-layout-sider-children a p, .main-navigation .ant-layout-sider-children .workflows-menu p{ font-size: 11px; margin-top: 10px; font-weight:600; color:#4c5880; margin-bottom: 0; padding-bottom: 0; }
.main-navigation .ant-layout-sider-children span{ text-align: center; }

.ant-layout-sider.content-navigation.invisible { margin-left: -250px; position: absolute; left:80px; top: 0; bottom: 0; z-index: 99999; }

#a ~ #b {
    background: #ccc
}

.main-navigation .ant-layout-sider-children .icon-img{ margin:0; padding:0; }
.main-navigation .ant-layout-sider-children .icon-img img{ width:24px; height:auto; }


.ant-layout-sider.content-navigation.invisible:hover, .ant-layout-sider-children:hover .ant-layout-sider.content-navigation.invisible{ margin-left:0; }


.main-navigation .humberger-menu{ position:absolute; width:40px; height:35px; right:-39px; top:0; background:#fff; box-shadow: 5px 0 15px -7px rgba(0,0,0,0.2); display: flex; align-items: center; flex-flow: column; justify-content: center; border-radius: 0 10px 10px 0; z-index:999; display:none; }
.main-navigation .humberger-menu .bar{ display:block; width:20px; height:2px; background:#333; margin:0 auto 3px; padding:0; }
.main-navigation .humberger-menu .bar:last-child{ margin-bottom:0; }

.main-navigation .ant-layout-sider-children a.active { background: #F9F9F9; }
.main-navigation .ant-layout-sider-children a.active * { opacity: 1; }

.ant-layout-sider .lower-block .add-field{ margin:0px 0 0 0; padding:0;  position: relative; }
.ant-layout-sider .lower-block .add-field .ant-input{ width:100%; height:34px; background:rgba(255,255,255,0.2); border-radius:3px; padding: 0 37px 0 10px; border:none; box-shadow: none; color: rgba(255,255,255,0.85) }
.ant-layout-sider .lower-block .add-field .ant-input:focus{ box-shadow:none; outline:none; border:none; background: #FFF; color: #000; }
.ant-layout-sider .lower-block .add-field .ant-input:hover{ box-shadow:none; outline:none; border:none; }

.ant-layout-sider .lower-block .add-field .ant-btn{ position: absolute; width:32px; height:100%; right:0; top:0; background:rgb(41, 151, 109); border: none; border-radius: 0 3px 3px 0; text-align: center; padding: 0; color: #FFF; }
.ant-layout-sider .lower-block .add-field .ant-btn:hover{ color:#000; }

.workflow-side-nav{ height:100%; margin:0; padding:0; }


.workflows-h { margin-top: 0px; overflow: hidden; padding-bottom:30px; padding-left: 15px; padding-right:15px; }

.dropdown-box-list{ box-shadow: 0px 1px 5px 2px rgb(0 0 0 / 10%); padding:15px; }

/* .empty-found{  position: absolute; left: 0; width: 100%; top: 50%; transform: translateY(-50%); text-align:center; } */
.empty-found{ text-align:center; padding:65px 0; }
.empty-found .no-text{ font-size:14px; line-height:16px; color:#555; font-weight:600; margin:0; padding:0; }
.empty-found .empty-list{ text-align: center; background: #001DFF; color: #fff; padding: 11px 20px; height: auto; display: inline-block; width: auto; border-radius: 3px; }
.empty-found .empty-list:hover{ background:#0A1EBA; color:#fff; }
.empty-found .empty-list:focus{ background:#0A1EBA; color:#fff; }

 .workflow-center-block .workflows-list-block{ max-width:900px; margin:0 auto; }

.workflows-h li{ display:block; transition: linear 0.1; border-radius: 10px; }
.workflows-h:hover{ overflow: auto; }
.workflows-h li:hover{ background:#fff; }
.workflows-h ul{ padding-left: 10px; padding-right: 10px; }
.workflows-h li a { padding: 10px 0 10px 10px; display:flex; align-items:flex-start; width: 213px; color: #000; font-size: 14px; margin-top: 5px; border:solid 1px transparent; color: #555; font-weight: 700; position: relative; width: 100%; }
.workflows-h li a.active{ background: #FFF; border-radius: 10px; border:solid 1px #EEE; box-shadow: 0px 1px 3px 0px rgba(0,0,0,.03); color: #000; }
.workflows-h li a .btn{ position: absolute; right:4px; top:50%; transform:translateY(-50%); margin:0; padding:0; min-width:135px; height:36px; background:#001DFF; border:none; box-shadow:none; outline:none; display:flex; align-items:center; justify-content:center; border-radius:3px; font-size:14px; line-height:1; font-weight:500; color:#fff; }

.workflows-h li .btn-text{ padding:10px 0 0 0; display:flex; align-items:flex-start; width: 213px; color: #000; font-size: 14px; border:solid 1px transparent; color: #555; font-weight: 700; position: relative; width: 100%; text-align:left; box-shadow:none; outline:none; border:none; background:none; cursor: pointer; }

.workflows-list-block{ height:100%; display:flex; flex-flow:column; }
.workflows-list-block .top-block{ margin:0; padding:0 0 30px 0; }

.workflows-list-block .field-top{ display:flex; align-items:center; justify-content:space-between; margin:0 0 30px 0; padding:0; }
.workflows-list-block .field-top h2{ margin-bottom:0; }
.workflow-aria .field-top .action-h{ margin:0; padding:0; }
.workflow-aria .field-top .action-h:hover{ box-shadow:none; background:none; }
.workflow-aria .field-top .action-h button{ background:#3235F5;  color:#fff; padding:11px 15px; height: auto; width: auto; border-radius: 45px; position: relative; overflow:hidden; z-index:1; }
.workflow-aria .field-top .action-h button:after{ content:''; position:absolute; top:0; bottom:0; left:0; right:0; background:rgba(255,255,255,0.0); z-index:-1; }
.workflow-aria .field-top .action-h button:hover:after{ background:rgba(255,255,255,0.1); }

.workflow-aria .field-top .action-h .link-btn{ font-size:14px; line-height:1; font-weight:500; color:#3235F5; margin:0; padding:5px 0; }
.workflow-aria .field-top .action-h .link-btn:hover{ opacity:.8; }

.workflows-list-block .workflow-side-bar{ flex:1; }
.workflows-list-block .workflow-side-bar .workflows-h{ position: relative; overflow:auto; }
.workflows-list-block .workflow-side-bar{ overflow: hidden; }
.workflows-list-block .workflow-side-bar:hover{ overflow:auto; }
.workflows-list-block .workflow-side-bar .nav-item{ margin:0; padding:0; }

.workflows-list-block .workflow-side-bar h2{ font-size:16px; color: #212121; opacity: 1; padding-bottom: 0; margin: 0 0 10px 0; }

.content-navigation h2{ padding:0px 15px; opacity: 0.3; }
.content-navigation h2{  font-size:19px; line-height:1.4; padding:0px 20px; opacity: 0.3; }
.action-h{ margin:12px 0 0 0; padding:5px 0; border-radius: 5px; overflow: hidden; }
.action-h:hover{ background:rgba(255,255,255,.8); box-shadow:0 1px 5px rgba(0,0,0,.1); }
.action-h button{ display:block; width: 100%; background: none; font-size:12px; color: #3235F5; border:none; font-weight: 700; border-radius:0; text-align:center; padding:0 10px; box-shadow:none; }

.action-h button:hover{ color:#1404D6; background:none; }
.action-h button:focus{ color:#1404D6; background:none; }

.empty-found .action-h:hover{ box-shadow:none; border:none; background:none; }


.workflow-search-field{ margin:0; padding:0; }
.workflow-search-field .ant-input{ font-size:16px; font-weight:500; border:none !important; border-bottom:1px solid #D1D1D0 !important; border-radius:0; padding-left:0; outline:none; box-shadow:none; }
.workflow-search-field .ant-input:hover{ border-bottom: 1px solid #3235F5 !important; }
.workflow-search-field .ant-input:focus{ border-bottom: 1px solid #3235F5 !important; box-shadow:none; }

.favorite-field{ margin:0 0 30px 0; padding:0 0 40px 0; border-bottom: 1px solid rgba(0,0,0,.1); }

.favorite-field .workflows-h{ position: relative; top:0; padding-bottom:0; }


.report-list-item{ display:flex; flex-flow:column; height:100%; }
.report-list-item .reports-list{ flex:1; }
.workflows-h.updates-list li{ border-bottom:1px solid rgba(0,0,0,.05); border-radius:0; white-space: normal; padding-top:0; }
.workflows-h.updates-list li:hover{ background:#e6f7ff; }
.workflows-h.updates-list li a{ margin-top:0; }
.workflows-h.updates-list li a.active{ border:none; border-left: 1px solid rgba(0,0,0,.05); border-right: 1px solid rgba(0,0,0,.05); border-radius:0; box-shadow: 0px 2px 3px 0px rgba(0,0,0,.05); }
.workflows-h.updates-list .text{ position: relative; }
.workflows-h.updates-list .text .ant-badge{ position: absolute; top:0; right:5px; padding-left:0; }
.workflows-h.updates-list .text .ant-badge p{ padding-left:0; }
.workflows-h.updates-list .text .ant-badge-count{ background:#3235F5; }

.workflows-h.updates-list li:last-child{ border-bottom:none; }


.submenu-box{ padding:15px; background:#fff; width:235px; box-shadow: 0px 1px 5px 2px rgb(0 0 0 / 10%); }
.submenu-box li{ display:block; border:1px solid rgba(0,0,0,.1); padding:0; margin-bottom:10px; }
.submenu-box li:hover{ background:none; }
.submenu-box li a{ margin:0; padding:10px; }
.submenu-box li:hover a{ color:#000; }
.submenu-box li:last-child{ margin-bottom:0; }

.submenu-box li .workflow_name{ color:#555; white-space: normal; }
.submenu-box li:hover .workflow_name{ color:#000; }

.submenu-box.prm-submenu li{ border:none; border-bottom:1px solid rgba(0,0,0,.1); border-radius:0; margin-bottom:0; }
.submenu-box.prm-submenu li a{ padding-left:0; white-space: normal; }

/* .navigation-block .submenu .workflows-h.perform-submenu li:hover{ background:none; } */

.workflows-h.submenu-box .btn-block{ margin:0; padding:0; }
.workflows-h.submenu-box .btn-block a{ display:block; font-size:14px; line-height:1; color:#000; font-weight:500; margin:0; padding:12px; text-align:center; border-radius:5px; }
.workflows-h.submenu-box .btn-block a:hover{ background:rgba(0,0,0,.1); }

.submenu-box.prm-submenu .btn-block{ margin-top:10px; }

.content-navigation .workflows-h li:hover{ background:#F2F2F2; }


/*============ Side-Bar End ===========*/

/*============ Main-Content Start ===========*/
.ant-layout-content{ margin:0; z-index: 23230; box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.1); border-left: solid 1px rgba(0,0,0,0.1) }
.ant-layout-content .top-block{ display:flex; justify-content: space-between; margin:0 auto; padding:35px 0 32px 0; max-width:870px; }

.ant-layout-content .top-block .lt-block{ display:flex; margin:0; padding:0; }
.ant-layout-content .top-block .lt-block h3{ font-size:20px; line-height:20px; color:#070202; font-weight:400; text-transform:capitalize; margin:0; padding:0 30px 0 0; display:flex; align-items:center; }

.ant-layout-content .top-block .lt-block .ant-btn{ height:41px; border:1px solid #C9C9C9; border-radius:0; padding:0 10px 0 15px; box-shadow:none; }
.ant-layout-content .top-block .lt-block .ant-btn span{ font-size:13px; line-height:15px; color:#170C0C; font-weight:400; text-transform:capitalize; margin:0; padding:0 25px 0 0; }

.ant-layout-content .top-block .rt-block{ margin:0; padding:0; }
.ant-layout-content .top-block .rt-block a{ display:inline-block; font-size:12px; line-height:14px; color:#fff; font-weight:700; text-transform:uppercase; margin:0; padding:11px 32px; background:#4ABF27; border-radius:3px; transition:all 600ms ease-in-out; }
.ant-layout-content .top-block .rt-block a:hover{ background:#0093FF; }

.ant-layout-content .bottom-block{ margin:0 auto; padding:0; max-width: 870px; }
.ant-layout-content .bottom-block .box{ margin:0 0 30px; padding:20px; background:#fff; box-shadow:0 1px 3px 0 rgba(0,0,0,.2); min-height:235px; position: relative; }
.ant-layout-content .bottom-block .box.active:after{ content:""; position: absolute; width:100%; height:5px; background:#0093FF; left:0; top:0; }
.ant-layout-content .bottom-block .box p{ font-size:20px; line-height:30px; color:#170C0C; font-weight:300; margin:0; padding:0 0 10px 0; cursor:pointer; user-select: none; -webkit-user-select: none; -moz-user-select: none; }
.ant-layout-content .bottom-block .box h6{ font-size:13px; line-height:15px; color:#B2B2B2; font-weight:400; margin:0; padding:0; }
.ant-layout-content .bottom-block .box ul{ position: absolute; width:100%; left:20px; bottom:20px  }
.ant-layout-content .bottom-block .box ul li{ display:inline-block; margin:0; padding:0 10px 0 0; }
.ant-layout-content .bottom-block .box ul li span{ display:inline-block; width:20px; height:20px; background:#A5D7F4; border-radius:50%; text-align:center; padding:0; overflow: hidden; }
.ant-layout-content .bottom-block .box ul li span img{ width:15px; }
.ant-row.main-content-wrapper{ height: 100%; margin: 0 !important;}
.overflow-col{ padding: 0px !important; height: 100%; overflow: auto; position: relative; }
.task-attr-holder .gutter-box{ height:100%; padding-bottom:53px; border-left:1px solid rgba(0,0,0,.1); }
.overflow-col .gutter-box .right-side{ height:100%; padding:20px; overflow: auto; }

.empty-text, .no-content{ font-size:14px; color:#999; font-weight:600; margin:0; padding:10px 0; text-align:center; }

.move-dropdown{ border-right:none; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important; }

/*============ Main-Content End ===========*/


/*_____________ Inner Page Start ______________*/

/*========== Body-header Start =========*/
.body-header{ margin:0;}
.heading-field{ margin:0; padding:5px 0px; border-bottom: solid 1px rgba(0,0,0,.1); display: flex; align-items: center; background: #FFF; }
.heading-field h5{  font-size:16px; line-height:1.4; color:#170C0C; font-weight:400; margin:0; padding:0 10px; background: rgba(255,255,255,1); width: 100%; padding-left: 20px; max-height: 60px; }
.checklist-header { display: flex; align-items:flex-start; justify-content: space-between; padding:20px;}
.heading-field.checklist-header h5 { background: none; padding: 0; font-weight: 400;}

.checklist-header .close-btn{ width:30px; height: 30px; border:none; box-shadow:none; outline:none; border-radius:50%; color:#fff; cursor: pointer; background:none; line-height:1; }
.checklist-header .close-btn:hover{ background:rgba(255,255,255,.1); }
.checklist-header .close-btn .anticon{ font-size:19px; }


 .task-editor-body .heading-field{ display:flex; justify-content:space-between; } 
.task-editor-body .heading-field .lt-side{ display:flex; align-items:center; margin:0; padding:0; }
.task-editor-body .heading-field .lt-side .un-tag{ font-size:9px; line-height:12px; color:#111; letter-spacing: 0.16px; font-weight:600; text-transform:uppercase; margin:0 0 0 10px; padding:6px 10px; background:rgba(204,204,204,.2);font-weight: 700;
    letter-spacing: 1px;
    color: #999; }

.task-editor-body .heading-field .rt-side{ margin:0; padding:0 5px 0 0; }
.task-editor-body .heading-field .rt-side .cross-btn{ width:35px; height:32px; background:none; border:none; border-radius:3px; box-shadow:none; outline:none; margin-left:12px; display: inline-block; vertical-align: middle; padding:0; transition:all 200ms ease-in-out; cursor: pointer; }
.task-editor-body .heading-field .rt-side .cross-btn .anticon-close{ font-size: 16px; position: relative; top: 1px; transition:all 200ms ease-in-out; }
.task-editor-body .heading-field .rt-side .cross-btn:hover .anticon-close{ color:#000; }

.task-editor-body .heading-field .rt-side .btn{ font-size:14px; font-weight:600; border:none; box-shadow:none; outline:none; padding:0 25px; }
.task-editor-body .heading-field .rt-side .btn:disabled{ background:#ccc!important; color:#B7B7B7; opacity:.8; }

.task-editor-body .heading-field .rt-side .trigger-btn{ color:#000; border:none; box-shadow:none; outline:none; }
.task-editor-body .heading-field .rt-side .trigger-btn .icon-img{ display:inline-block; margin:0 5px 0 0; padding:0; position:relative; top:5px; }
.task-editor-body .heading-field .rt-side .trigger-btn .n_img{ display:block; transition:all 250ms ease-in-out; }
.task-editor-body .heading-field .rt-side .trigger-btn:hover .n_img{ display:none; }
.task-editor-body .heading-field .rt-side .trigger-btn .h_img{ display:none; transition:all 250ms ease-in-out; }
.task-editor-body .heading-field .rt-side .trigger-btn:hover .h_img{ display:block; }

.task-editor-body .heading-field .rt-side .isue-btn{ font-size:15px; line-height:1; font-weight:600; margin:0; width:105px; }

.task-editor-body .field-table td,.task-editor-body .field-table th { padding:5px 10px; border:1px solid #333; }

.ant-modal.publish-modal .ant-modal-footer { text-align:center; overflow: hidden; margin-top:0; }
.publish-modal .ant-modal-footer .pub-btn{ width:100%; height:auto; margin:0; padding:13px 0; border-radius:0; border:none; box-shadow:none; background:#3235F5; }
.publish-modal .ant-modal-footer .pub-btn:hover{ opacity:.8; }
.publish-modal .ant-modal-footer .pub-btn.active-btn{ background:#FC7753;  color:#fff; }

.menu-button{ border: none; box-shadow: none; margin-right: 10px;}

.checklist-header .btn-block .perform-btn{ font-weight:600; color:#000; padding-top:3px; }

.ant-layout.main-body.perform-body .heading-field{ display:block; border:none; text-align:center; padding:5px 15px 7px; border-bottom:1px solid rgba(0,0,0,.2); }
.ant-layout.main-body.perform-body .heading-field .up-block{ margin:0; padding:0; position: relative; }
    
.heading-field .up-block .tag_field{ margin:0; padding:0; line-height:0; }
.heading-field .up-block .tag_field .ant-tag{ display:inline-block; font-size: 13px; line-height: 14px; color: #fff; font-weight: 500; text-transform: capitalize; height: auto; margin:0 5px 0 0; padding:4px 10px 3px; background: none; cursor: auto; }
.heading-field .up-block .tag_field .ant-tag:hover{ opacity:1; }

.ant-layout.main-body.perform-body .heading-field .drop-down{ display:inline-block; margin:0; padding:0; }
.ant-layout.main-body.perform-body .heading-field .drop-down .dropdown-content{ display:flex; align-items:center; width:auto; color:#000;  font-size:20px; line-height:1; font-weight:700; margin:0; padding:0; cursor: pointer; position: relative; max-width:500px; }

.dropdown-perform{ position: absolute; width:225px; left:50%; top:82%; transform: translateX(-50%); z-index: 999; margin:0; padding:0; }
.dropdown-perform .perform-dropdown-block{ background:#fff; border-radius:5px; box-shadow:0 2px 10px 0 rgba(0,0,0,.2); padding:7px 15px 0; }

.dropdown-perform:after{ content: ""; position: absolute; width:12px; height:12px; background: #fff; transform: rotate(45deg); top: -6px; left: 0; right: 0; margin: 0 auto; z-index: -1; }
.dropdown-perform .search-form{ margin:0; padding:0 0 10px; border-bottom:1px solid rgba(0,0,0,.1); }
.dropdown-perform .search-form .ant-input{ background: none; width:100%; height: 50px; box-shadow: none; outline: none; height: 40px; border: 1px solid rgba(0,0,0,.2); }

.dropdown-perform .list_item{ margin:0; padding:0; text-align:left; }
.dropdown-perform .list_item li{ margin:0; padding:0; }
.dropdown-perform .list_item li .ant-btn{ display:block; width:100%; height:auto; box-shadow:none; outline:none; border:none; border-radius:0; font-size:14px; line-height:1; color:#333; font-weight:600;  margin:0; padding:8px 0; text-align:left; }
.dropdown-perform .list_item li .ant-btn:hover{ color:#2F8FFA; }
.dropdown-perform .list_item li .ant-btn.delete_btn:hover{ color:#f5222d; }

.dropdown-perform .details-box{ margin:0; padding:0; text-align:left; max-height:400px; overflow:auto; overflow-x:hidden; }
.dropdown-perform .details-box p{ font-size:13px; line-height:1.3; color:#7d7d7d; font-weight:400;  margin:0; padding:15px 0; max-width:100% !important; text-align:center; }

.dropdown-perform .details-box ul{ margin:0; padding:0; }
.dropdown-perform .details-box ul li{ font-size: 13px; line-height: 15px; font-weight:600; color: #000; margin: 0 0 5px 0; padding:0; border-radius: 4px; overflow: hidden; position: relative; z-index:1; }
.dropdown-perform .details-box ul li a{ display:block; color:#000; padding:8px 15px; }
.dropdown-perform .details-box ul li a:hover{ background:#f9f9f9; }

.dropdown-perform .details-box .persent{ position: absolute; width:100%; left:0; top:0; bottom:0; background: #DDFCE4; padding:0; z-index:-1; border: 1px solid rgba(0,0,0,.1); border-radius:5px; transition:all 200ms ease-in-out; }
.dropdown-perform .details-box .persent:hover{ background: #94d0a2; }

.dropdown-perform .overlay{ position: fixed; width:100%; left:0; top:0; bottom:0; background:rgba(0,0,0,.5); }

.ant-layout.main-body.perform-body .heading-field p{ max-width:300px; line-height:1; margin:0 auto; color:#000; opacity:.7; padding-top:5px; }

.ant-layout.main-body.perform-body .heading-field .perform-back{ position: absolute; top:50%; left:0; transform: translateY(-50%); }
.perform-body .heading-field .perform-back .btn-bk{ display:inline-block;  font-size:14px; line-height:1; color:#000; font-weight:500; border-radius:50%; border:none; padding:0; width:40px; height:40px; background:none; transition:all 0ms ease-in-out; }
.perform-body .heading-field .perform-back .btn-bk .anticon{ font-size:16px; line-height:0; }
.perform-body .heading-field .perform-back .btn-bk:hover{ background:rgba(0,0,0,.1); }

.ant-layout.main-body.perform-body .heading-field .flow_button{ position: absolute; top:50%; transform:translateY(-50%); right:0; text-align:right; }
.ant-layout.main-body.perform-body .heading-field .flow_button .btn-bk{ font-size: 16px; line-height: 18px; width: 125px; width: 125px; height: 30px; font-weight: 700; letter-spacing: 0px; background:rgba(0,0,0,0.25); box-shadow:none; outline:none; }

.ant-layout.main-body.perform-body .heading-field .flow_button .btn-bk.unfollow-btn{ display:none; }
.ant-layout.main-body.perform-body .heading-field .flow_button:hover .btn-bk.unfollow-btn{ display:inline-block; }
.ant-layout.main-body.perform-body .heading-field .flow_button .btn-bk.follow-btn{ background:none !important; }
.ant-layout.main-body.perform-body .heading-field .flow_button:hover .btn-bk.follow-btn{ display:none; }

.ant-layout.main-body.perform-body .heading-field .flow_button .btn-bk:hover{ background:rgba(0,0,0,0.50); }
.ant-layout.main-body.perform-body .heading-field .flow_button .btn-bk.unfollow-btn {  box-shadow: none; transition: none; -webkit-transition: none;}
.ant-layout.main-body.perform-body .heading-field .flow_button .btn-bk.unfollow-btn:hover { background:#D63333; }
.ant-layout.main-body.perform-body .heading-field .flow_button .btn-bk:hover .unfollowing { display: block; }
.perform-body .heading-field .flow_button .user_img{ margin: 0 0 15px 0; padding:0; border-radius: 3px; }
.perform-body .heading-field .flow_button .user_img ul li{ display:inline-block; margin:0; padding:0 2px; }
.perform-body .heading-field .flow_button .user_img ul li:last-child{ padding-right:0; }




.emptyTask { top: 50%; position: relative; transform: translateY(-50%);}
    
.ant-layout.main-body.perform-body .heading-field .bottom-task{ margin:0; padding:8px 0 5px 0; }
.ant-layout.main-body.perform-body .heading-field .bottom-task ul{ margin:0; padding:0; }
.ant-layout.main-body.perform-body .heading-field .bottom-task ul li{ display:inline-block; margin:0; padding:0 15px; }
    
.ant-layout.main-body.perform-body .heading-field .bottom-task ul li a{ font-size:14px; line-height:16px; color:rgba(255,255,255,.6); font-weight:600; }
.ant-layout.main-body.perform-body .heading-field .bottom-task ul li a:hover{ color:#fff; }
.ant-layout.main-body.perform-body .heading-field .bottom-task ul li.active a{ color:#fff; font-weight:700; }

.start-btn{ position: absolute; width:100%; left:0; bottom:0; font-size: 14px; font-weight:600; padding: 15px 35px; height: auto; border-radius:0; }

.body-header .btn-block{ margin:0; padding:0; display:flex; align-items:center; }
.body-header .btn-block a{ display:inline-block; font-size:12px; line-height:14px; color:#fff; font-weight:700; text-transform:uppercase; margin:0 18px 0 0; border-radius:3px; }

.body-header .btn-block .badge-btn{ margin:0; padding:0 12px 0 0; }
.body-header .btn-block .badge-btn .act-btn{ border-radius:50%; min-width:27px; height:27px; border:none; box-shadow:none; outline:none; margin:0; padding:0; line-height:1; }
.body-header .btn-block .badge-btn .act-btn span{ display:inline-block; font-size:13px; line-height:1; color:#000; font-weight:600; margin:0; padding:0; }

.body-header .btn-block .notification-btn{ margin:0; padding:0 10px 0 0; position: relative; }
.body-header .btn-block .notification-btn .n-btn{ display:inline-block; width:35px; height:35px; background:none; border:none; border-radius:0; box-shadow:none; margin:0; padding:0; border-radius:50%; transition:all 500ms ease-in-out; }
.body-header .btn-block .notification-btn .n-btn:hover{ background:rgba(255,255,255,.1); box-shadow:0 2px 3px 0 rgba(0,0,0,.1); }
.body-header .btn-block .notification-btn img{ width:16px; height:auto; }

.notification-btn .ant-badge-count{ font-size:13px; font-weight:600; }

.body-header .btn-block .edit_flow_btn{ font-weight:600; color:#fff; background:none; border:1px solid rgba(255,255,255,.5); transition:none; padding-top:2px; }
.body-header .btn-block .edit_flow_btn:hover{ background:#fff; color:#000; }


.check_listtab{ margin:0; padding:0 20px; }
/* .check_listtab ul{ margin:0; padding:0; }
.check_listtab ul .tablist{ display:inline-block; font-size: 13px; line-height: 15px; color:#fff; font-weight:600; text-transform:uppercase; margin:0; border-radius:5px 5px 0 0; transition:all 200ms ease-in-out; cursor: pointer; padding:10px 15px 8px; }
.check_listtab ul .tablist:hover{ background:rgba(0,0,0,0.1);} */

.checklist-tab-item{ flex:1; }
.checklist-tab-item .ant-tabs{ height:100%; }
.checklist-tab-item .ant-tabs-content{ height:100%; padding-bottom:38px; }
.checklist-tab-item .ant-tabs-tabpane{ height:100%; }
.checklist-tab-item .main-dashboard.report-base-layout{ height:100%; padding:15px 25px; }
.checklist-tab-item .main-dashboard.report-base-layout .ant-col-lg-offset-1{ width:100%; margin-left:0; }

.checklist-tab-item .ant-tabs-bar{ margin:0; padding:0 20px; display: flex; align-items:center; flex-flow: row-reverse;  justify-content: flex-end; line-height:0; }
.checklist-tab-item .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container{ height:auto; }

.checklist-tab-item .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{ font-size: 13px; color:#fff; font-weight:600; text-transform:uppercase; max-width:275px; height:38px; background:rgba(255,255,255,.5); border:none; border-radius: 5px 5px 0 0; }

.checklist-tab-item .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab span{ display:inline-block; vertical-align:middle; max-width:175px; text-overflow:ellipsis; overflow:hidden; text-transform:capitalize; line-height:1; }
.checklist-tab-item .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .anticon{ position:relative; top:0.3px; margin-right:5px; }
.checklist-tab-item .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab.ant-tabs-tab-active{ background:#f0f2f5; }

.checklist-tab-item .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x{ color:#fff; position:relative; top:0.8px; right:-3px; }
.checklist-tab-item .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-close-x{ color:#000; }
.checklist-tab-item .ant-tabs-tab-arrow-show{ color:#fff; }
.checklist-tab-item .ant-tabs-tab-arrow-show .anticon{ font-size:15px; }
.checklist-tab-item .ant-tabs-tab-arrow-show.ant-tabs-tab-btn-disabled{ opacity:.5; }

.checklist-tab-item .ant-tabs.ant-tabs-card .ant-tabs-extra-content{ line-height:inherit; }
.checklist-tab-item .ant-tabs.ant-tabs-card .ant-tabs-extra-content .ant-tabs-new-tab{ width:25px; height:25px; background:#fff; border-radius:7px; margin-left:7px; border:none; font-size: 13px; }

.preform-loader{ position:fixed; top:0; }

.notification-modal{ position: absolute; width:500px; right:0; top: 45px; z-index: 999; background: #fff; border-radius: 5px; box-shadow: 0 2px 10px 0 rgba(0,0,0,.1); padding:0; }
.notification-modal:after{ content: ""; position: absolute; width: 12px; height: 12px; background: #fff; -webkit-transform: rotate(45deg); transform: rotate(45deg); top: -6px; right:22px; margin:0; z-index: -1; }

.notification-modal .data-box{ margin:0; padding:0; border:1px solid rgba(0,0,0,.2); border-bottom:none; }
.notification-modal .data-box a{ display:block; margin:0; padding: 15px 15px 20px; background:none; text-transform:inherit; border-bottom: 1px solid rgba(0,0,0,.2); border-radius: 0; }
.notification-modal .data-box a:hover{ background:rgba(167,173,187,.1); }

.notification-modal .data-box h3{ font-size:19px; line-height:21px; color:#111; font-weight:600; margin:0; padding:0 0 3px 0; }
.notification-modal .data-box h4{ font-size:14px; line-height:16px; color:#333; font-weight:600; margin:0; padding:0 0 10px 0; }
.notification-modal .data-box .date-time{ display:block; font-size:13px; line-height:17px; color:#a1a1a1; font-weight:400; margin:0; padding:0 0 15px 18px; position: relative; }
.notification-modal .data-box .date-time .icon{ display:inline-block; position: absolute; width:12px; left:0; top:0; margin:0; padding:0; opacity:.4; }
.notification-modal .data-box .date-time .icon img{ width:100%; height:auto; }
.notification-modal .data-box .button-sec{ margin:0; padding:0; }
.notification-modal .data-box .button-sec .ant-btn{ margin:0 8px 0 0; }

.prf_notification{ margin:0; padding:20px 0; max-height:550px; overflow:auto; overflow-x:hidden; }
.prf_notification .top_block{ margin:0 0 15px 0; padding:0 45px 15px; border-bottom:1px solid rgba(0,0,0,.1); }
.prf_notification h2{ font-size:16px; line-height:1.4; color:#080705; font-weight:600; margin:0; padding:0 0 15px 0; }

.prf_notification .slider_field{ margin:0; padding:0; position:relative; }
.prf_notification .slider_field .slick-dots{ display:none !important; }
.prf_notification .next_prev .next, .prf_notification .next_prev .prev{ position:absolute; top:50%; left:-33px; transform:translateY(-50%); width: 21px; height: 21px; border-radius: 50%; background:#fff; border:1px solid rgba(0,0,0,.2); display: flex; padding: 0; align-items: center; justify-content: center; transition:all 200ms ease-in-out; }
.prf_notification .next_prev .next:hover, .prf_notification .next_prev .prev:hover{ border:1px solid #444; }

.prf_notification .next_prev .anticon{ font-size:11px; transition:all 200ms ease-in-out; }
.prf_notification .next_prev:hover .anticon{ color:#000; }

.prf_notification .next_prev .prev{ left:auto; right:-33px; }

.prf_notification .block{ margin:0; padding:0; }
.prf_notification .block a{ display:flex; margin:0; padding:15px 25px; background:none; text-transform: inherit; }
.prf_notification .block a:hover{ background:none; }
.prf_notification .block .img-block{ width:50px; height:50px; border-radius:50%; margin:0 12px 0 0; padding:0; overflow:hidden; }
.prf_notification .block .img-block .profile{ display:block; }
.prf_notification .block .img-block img{ width:100%; height:100%; object-fit:cover; }

.prf_notification .block .content-text{ flex:1; width:100%; margin:0; padding:0; }
.prf_notification .block .up-block{ margin:0; padding:0; }
.prf_notification .block h3{ font-size:15px; line-height:17px; color:#262626; font-weight:600; margin:0; padding:0 0 4px 0; }
.prf_notification .block h4{ font-size:13px; line-height:15px; color:#5a5a5a; font-weight:600; margin:0; padding:0 0 10px 0; display:flex; align-items:center; }

.prf_notification .block h4 .profile{ padding-left:12px; }

.prf_notification .block .name_text{ display:inline-block; font-weight:400; }

.prf_notification .block .lower-block{ margin:0; padding:0; display:flex; align-items:center; justify-content:space-between; }
.prf_notification .block .date-time{ display:block; font-size:13px; line-height:17px; color:#a1a1a1; font-weight:400; margin:0; padding:0 0 0 18px; position: relative; }
.prf_notification .block .date-time .icon{ display:inline-block; position: absolute; width:12px; left:0; top:-1px; margin:0; padding:0; opacity:.4; }
.prf_notification .block .date-time .icon img{ width:100%; height:auto; }
.prf_notification .block .ant-btn{ display:inline-block;  font-size:13px; line-height:15px; font-weight:600; margin:0; padding:8px 10px; height:auto; background:#3235F5; }
.prf_notification .block .ant-btn:hover{ background:#1404D6; }

.prf_notification .notification-block{ margin:0; padding:0; }
.notification-block h2{ padding-left:15px; }
.notification-block .block{ border-bottom:1px solid rgba(0,0,0,.1); }
.notification-block .block:last-child{ border-bottom:none;}
.notification-block .block a{ padding:15px; display:flex; }

.notification-block .block .content-text{ flex:1; }

.notification-block .block.read{ background:#F6F6F7; transition:all 200ms ease-in-out; }
.notification-block .block.read:hover{ background:#F6F6F7; }
.notification-block .block.unread{ background: #FFF; transition:all 200ms ease-in-out; }
.notification-block .block.unread:hover{ background:#F6F6F7; }

.notification-close{ position: absolute; top:7px; right:10px; display:none; }
.notification-close .n_btn{ display:flex; align-items:center; justify-content:center; width:28px; height:28px; border:none; box-shadow:none; outline:none; border-radius:50%; color:#000; padding:0; transition:all 200ms ease-in-out; }
.notification-close .n_btn:hover{ background:rgba(0,0,0,.2); }


.perform-field-table { width: 100%; display:block; white-space:nowrap; overflow:auto; margin-bottom:10px; }
.perform-field-table td, .perform-field-table th { padding: 2px 10px; line-height:1.5; border:1px solid rgba(0,0,0,.5); max-width: 500px; white-space: normal; }


.perform-field-table tbody td .ant-btn{ margin:0; padding:5px 10px; font-size: 14px; font-weight:400; border-radius:3px; background:none; border:none; color:#000; }
.perform-field-table tbody td .ant-btn.delete-btn{ margin-right:0; color:red; }



.content_field_element .ant-input{ border:1px solid #D1D1D0; }
.content_field_element .ant-input:hover{ border: 1px solid #3235F5 !important; }
.content_field_element .ant-input:focus{ border: 1px solid #3235F5 !important; box-shadow: 0px 0px 2px 2px #75DDDD; }


.content_field_element .ant-input-number-input:hover{ border: 1px solid #3235F5; }
.content_field_element .ant-input-number-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.content_field_element .ant-time-picker-input:hover{ border: 1px solid #3235F5; }
.content_field_element .ant-time-picker-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.content_field_element .ant-input-number-input:disabled:hover{ border: 1px solid transparent; }


.content_field .ant-input-number{ width:100%; }

.name-sec .field_look .content_field_element .input_number_box.input_number_box_prfm{ height:35px; border-radius:4px; }
.name-sec .field_look .content_field_element .input_number_box.input_number_box_prfm .ant-input-number-input{ height:35px; }
.content_field_element .ant-input-number-input.input_number_box_prfm:hover{ border-color:transparent; }

.ant-input-number.input_number_box_prfm:hover{ border-color:transparent; }

.content_field_element .ant-btn{ height:auto;  font-size:14px; line-height:1; font-weight:600; color:#333; margin:15px 0 0 0; padding:10px 15px; border:1px solid rgba(0,0,0,.2); border-radius:5px; }

.content_field_element .ant-btn.add-rd-btn{ border:1px solid transparent; background:none !important; box-shadow:none; transition:all 0ms ease-in-out; padding:5px 0; color:#000 !important; }

.content_field_element .ant-upload .ant-upload-hint{ padding:0 15px; }

.content_field .perform-field-table{ margin-bottom:10px !important; }

.tab-block{ margin:0; padding:0; }
.tab-block .ant-tabs-top-bar{ margin:0; padding:0 68px; }
.tab-block .ant-tabs-top-bar .ant-tabs-tab{ font-size:13px; line-height:15px; color:#B2B2B2; font-weight:400; text-transform:uppercase; margin:0; padding:0 55px 18px; }
.tab-block .ant-tabs-top-bar .ant-tabs-tab.ant-tabs-tab-active{ color:#000; }
.workflow-tabs { height:100%; overflow: hidden; position: relative; }
.tab-block .ant-tabs-content{ padding-top: 30px; }

.workflow-tabs > .ant-tabs { position: absolute; top: 0; bottom: 0; left: 0; right: 0; }
.workflow-tabs > .ant-tabs-content { position: absolute; top: 50px; bottom: 0; left: 0; right: 0; }
.workflow-tabs > .ant-tabs-bar{ height: 50px; }
.workflow-tabs > .ant-tabs-nav{ margin-top: 14px; }
.workflow-tabs .tab-content { position: absolute; top:55px; bottom: 0; left: 0; right: 0; overflow: auto; padding-top:20px; padding-bottom: 30px; }

.report_table .ant-table-wrapper{  position: relative; top:0; bottom: 0; left: 0; right: 0; overflow: auto; padding-top:0; padding-bottom: 30px; }

.tab-fullscreen { position: fixed; top: 30px; bottom: 0; left: 80px; right: 0; z-index: 29382938; background: #FFF; border-left: solid 1px rgba(0,0,0,0.1);  }
.report_table .report-head{ padding:10px 15px; display:none; align-items:center; justify-content:space-between; background:#15507C; margin-top:-30px; }
.tab-fullscreen .report_table .report-head { display: flex; }
.report_table .report-head .sub-heading{ display:block; font-size:18px; color: #FFF; font-weight:600; }
.report_table .report-head .perform-btn{ font-weight:700; color:#000; }

.report_table .report_field{ position: relative; width:100%; left:0; top:0; padding:0 15px 20px; display:flex; align-items:center; justify-content:space-between; }
.tab-fullscreen .report_table .report_field{ padding:20px 15px 20px; }

.tab-fullscreen .report_table .report_field{ padding-top:20px; }

.report_table .report_field .right-sel{ display:flex; align-items:center; margin:0; padding:0; }

/* .report_field .left-filter .ant-dropdown-trigger{ border: 1px solid rgba(0,0,0,.1); padding: 9px 35px 9px 15px; border-radius: 3px; color: #000; } */
.report_table .report_field .right-sel .fullscreen-btn{ margin:0 0 0 20px; padding:0; }
.report_table .report_field .right-sel .fullscreen-btn .ant-btn{ display: flex; align-items:center; height:auto; border:none; box-shadow:none; outline:none; padding: 5px 10px; border: 1px solid rgb(0,0,0,.1); background: #f4f4f4; transition:all 200ms ease-in-out; }
.report_table .report_field .right-sel .fullscreen-btn .ant-btn:hover{ background:#1890FF; border:1px solid #1890FF; }

.report_table .report_field .lt-field{ display:flex; align-items:center; }
.report_field .lt-field .select-box{ width:260px; margin:0; padding:0; position: relative; } 
.select-dropdown{ width:100%; font-size:14px; line-height:19px; color:#BFC1D0; font-weight:400; margin:0; padding:7px 10px; border:1px solid rgba(0,0,0,.1); border-radius:4px; height:35px; cursor: pointer; }
.select-dropdown .down-arrow{ float:right; }
.select-box .dropdown-option{ position: absolute; left:0; width:100%; max-height:250px; border:1px solid rgba(0,0,0,.1); margin:0; padding:15px 0; box-shadow: 0 2px 100px 0 rgba(0,0,0,.1); background:#fff; z-index:9; border-radius:4px; transition:all 100ms ease-in-out; overflow:auto; visibility: hidden; opacity:0; }
.select-box:hover .dropdown-option{ visibility:visible; opacity:1; }
.select-box .dropdown-option .checkbox-all{ margin:0; padding:0 12px 5px; }
.checkbox-all .ant-checkbox-wrapper{ float: inherit !important; }

.select-box .dropdown-option ul{ display:block; }
.select-box .dropdown-option ul li{ font-size:14px; line-height:16px; font-weight:600; color:#6E767A; margin:0; padding:5px 12px; transition:all 100ms ease-in-out; cursor: pointer; }
.select-box .dropdown-option ul li:hover{ background:#E6F7FF; }
.select-box .dropdown-option ul li .check-icon{ display:inline-block; float:right; font-size: 10px; visibility:hidden; }
.select-box .dropdown-option ul li:hover .check-icon{ visibility:visible; }

.fullscreen-btn .ant-btn .anticon-fullscreen{ font-size:18px; transition:all 200ms ease-in-out; }
.fullscreen-btn .ant-btn:hover .anticon-fullscreen{ color:#fff; }
.fullscreen-btn .ant-btn span{ font-size: 13px; font-weight:600; transition:all 200ms ease-in-out; }
.fullscreen-btn .ant-btn:hover span{ color:#fff; }

.report_field .left-filter .ant-btn-group{ display:flex; align-items:center; }

.tooltip-btn { background: none !important; height:auto; }

.back-task-btn{ margin:0; padding:0; display:none; text-align:center; }
.back-task-btn .back-task{ display:block; width:100%; font-size: 14px; line-height: 16px; color: #333; border-radius:0; border:none; border-bottom:1px solid rgba(0,0,0,.2); padding:7px 10px 7px; height: auto; background:#fff; }
.back-task-btn .back-task .anticon-left{ line-height:0; position:relative; top:2px; }

.report_field .right-sel label{ float: left; font-size:12px; line-height:14px; font-weight:600; margin:0; padding:0 15px 0 0; }
.report_table table .ant-table-thead th div{ white-space: nowrap; }
.report_table table tbody.ant-table-tbody tr td{ white-space: nowrap; max-width: 400px; overflow: hidden; text-overflow: ellipsis; padding: 1px 3px; font-weight: 500; }
.report_table table tbody.ant-table-tbody tr td.stage-td { padding:0px; position: relative; }
.report_table table tbody.ant-table-tbody tr td.stage-td > div { background-color: rgb(214, 163, 255); height: 100%; position: relative; top: 0; bottom: 0; left: 0; right: 0; display: flex; align-items: center; color:#000; padding:10px 5px; line-height:1; }
.report_table table tbody.ant-table-tbody tr td span{ display:block; width: 100%; padding:0 6px; border-radius: 3px; border:solid 1px transparent; overflow:hidden; text-overflow:ellipsis; }
.report_table table tbody.ant-table-tbody tr td .img-upload-file span{ display:none; }
.report_table table tbody.ant-table-tbody tr td .img-upload-file {padding:4px 15px}
.report_table table tbody.ant-table-tbody tr td .img-upload-file span.img-text{ display:inline-block; }
/* .report_table table tbody.ant-table-tbody tr td span:hover{ background: #FFF; border:solid 1px rgba(0,0,0,0.2); } */

.report_table table tbody.ant-table-tbody tr td span.add-data-btn { color: #CCC; font-style: italic; font-weight: 400; }

.ant-table-body { overflow: auto !important; }

.report_table table tbody.ant-table-tbody tr td .ant-tag { width:100%; text-align: center; border-radius: 0px; }
.report_table table tbody.ant-table-tbody tr td .stage_content .color_fill{ right:5px !important; }

.report_table table tbody.ant-table-tbody tr td .stg-td p{ display:inline-block; margin-bottom:0; vertical-align:middle; }
.report_table table tbody.ant-table-tbody tr td .stg-td span{ display:inline-block; width:auto; vertical-align:middle; }
.report_table table tbody.ant-table-tbody tr td .stg-td span.stage_content{ margin:0 3px; }


.td-editor { position: fixed; z-index: 239283923; padding: 10px; border:solid 1px rgba(0,0,0,0.2); background: rgba(255,255,255,1); box-shadow: 0px 1px 30px 0px rgba(0,0,0,.1); border-radius: 5px;}
.td-editor input { width:100%; }
.td-editor input:hover{ border: 1px solid #3235F5; }
.td-editor input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.td-editor .ant-btn{ margin-left: 5px; margin-top: 5px; }
.td-editor .ant-btn.ant-btn-primary{ margin-left: 0px; background:#3235F5; }
.td-editor .checkbox-tag .ant-checkbox-wrapper{ display:block; width:100%; margin:0 0 10px 0!important; }
.td-editor .upload-file{ margin:0; padding:0; }
.td-editor .upload-file .block{ min-height:80px; background:#f4f4f4; margin:0 0 30px 0; padding: 12px 15px 0px; position: relative; }
.td-editor .upload-file .block .up-box{ display:flex; align-items:center; justify-content:space-between; margin:0; padding:0; }
.td-editor .upload-file .block .up-box .lt-side{ max-width:200px; white-space:normal; }
.td-editor .upload-file .block .heading-text{ display:block; font-size:13px; line-height:15px; color:#000; font-weight:600; margin:0; padding:0;     white-space: nowrap; overflow: hidden;text-overflow: ellipsis; }

.td-editor .upload-file .block .close-btn{ margin:0; padding:0; cursor: pointer; transition:all 120ms ease-in-out; }
.td-editor .upload-file .block .close-btn:hover .anticon{ color:red; }

.td-editor .upload-file .block .download-btn{ position: absolute; width:100%; left:0; bottom:-15px; text-align:center; margin:0; padding:0; }
.td-editor .upload-file .block .download-btn .btn-dwl{ border-radius:20px; margin:0; }

.td-editor .upload-file .choose-fiel{ width: 125px; height:auto; margin: 0 0 5px 0; }
.td-editor .upload-file .choose-fiel .choose-label{ font-size: 13px; line-height: 36px; cursor: pointer; }
.td-editor .upload-file .choose-fiel .choose-label:hover{ border:1px solid rgba(0,0,0,.5); }

.td-editor .roadio-sec{ display:block; }
.td-editor .roadio-sec .radio-field{ margin-bottom:10px; }

.td-editor .input-field{ display:flex; align-items:center; margin-bottom:10px; }
.td-editor .input-field span{ margin-right:10px; }
.td-editor .input-field .ant-input-number{ flex:1; }

.body-part{ margin:0; padding:0 0 0 68px; }
.body-part.perform-block{ padding:0; }

.body-part .dashbord-block{ margin:0; padding:0 68px 0 0; }
.body-part .dashbord-block .boxer{ margin:0; padding:0; background:rgba(152, 152, 152, 0.06); min-height:148px; display: flex; align-items: center; justify-content: center; border-radius: 30px; }
.body-part .dashbord-block .boxer h2{ font-size:65px; line-height:67px; color:#272121; font-weight:400; margin:0; padding:0; text-align:center; }
.body-part .dashbord-block .boxer h2 span{ display:block; font-size:13px; line-height:15px; padding-top:22px;  }
.body-part .dashbord-block .boxer.last-boxer{ justify-content: flex-start; padding:0 20px; }
.body-part .dashbord-block .boxer .text-panel{ padding:0 72px 0 0; }
.body-part .dashbord-block .boxer .text-panel h5{ font-size:13px; line-height:15px; color:#272121; font-weight:400; margin:0; padding:0 0 20px 0; }
.body-part .dashbord-block .boxer .text-panel h3{ font-size:23px; line-height:30px; color:#272121; font-weight:400; margin:0; padding:0; }
.body-part .dashbord-block .boxer .text-panel h3 span{ display:block; }
.body-part .dashbord-block .boxer .text-panel h6{ font-size:12px; line-height:14px; color:#272121; font-weight:400; margin:0; padding:0; }
.body-part .dashbord-block .boxer .text-panel h6 span{ display:block; padding-top:8px; }


.perform-drawer-element{ margin:0; padding:0; }
.perform-drawer-element .top-block{ margin:0; padding:0 0 15px 0; border-bottom:1px solid rgba(0,0,0,.1); }
.perform-drawer-element .title-field{ margin:0 0 15px 0; padding:0; position: relative; } 
.perform-drawer-element .title-field h2{ font-size:25px; line-height:15px; color:#272121; font-weight:500; margin:0; padding:0; }
.perform-drawer-element .title-field h2 span{ display:inline-block; font-size:15px; line-height:1; color:#fff; font-weight:400; margin:0 6px 0 0; padding:5px 8px; vertical-align:middle; }

.perform-drawer-element .title-field a{ display:inline-block; margin:0; padding:0; position:absolute; top:0; right:0; }
.perform-drawer-element .title-field a img{ width:18px; height:auto; }

.perform-drawer-element .top-block ul li{ margin:0 0 16px 0; padding:0; display:flex; align-items:center; }
.perform-drawer-element .top-block .label-text{ width:120px; font-weight:500; margin:0; padding:0; }
.perform-drawer-element .top-block ul li p{ font-size:16px; font-weight:500; margin:0; padding:0; }
.perform-drawer-element .top-block .ant-avatar{ margin-right:10px; }
.perform-drawer-element .top-block .ant-tag{ border:none; font-size:14px; background:#FFD8AF; padding:5px 12px; color:#000; }

.perform-stage{ margin:0; padding:15px 0; }
.perform-stage ul li{ margin:0; padding:0 0 25px 20px; border-left:2px solid #d9d9d9; position:relative; }
.perform-stage ul li:last-child{ padding-bottom:0; }
.perform-stage ul li .dotted-box{ position:absolute; width:18px; height:18px; background:#22D3BE; border-radius:50%; left:-10px; top:0; }
.perform-stage ul li:last-child{ border-left:2px solid transparent; }

.perform-stage h3{ font-size:21px; line-height:1; color:#000; font-weight:500; margin:0; padding:0 0 8px 0;  }
.perform-stage .ant-collapse{ margin:0; padding:0; border:none; }
.perform-stage .ant-collapse .ant-collapse-item{ margin:0 0 10px 0; padding:0; border: 1px solid #d9d9d9; }
.perform-stage .ant-collapse .ant-collapse-item:last-child{ margin-bottom:0; }
.perform-stage .ant-collapse .ant-collapse-header{ font-size:16px; line-height:15px; color:#272121; font-weight:500;  }


.base-drawer .ant-drawer-wrapper-body{ display:flex; flex-flow:column; }
.base-drawer .ant-drawer-wrapper-body .ant-drawer-body{ flex:1; overflow:auto; }

.base-drawer .ant-drawer-title{ font-size:20px; line-height:1.3; color:#000; font-weight:500; display:flex; align-items:center; }
.base-drawer .close-btn{ width:30px; height:30px; background:none; border-radius:50%; box-shadow:none; outline:none; box-shadow:none; margin:0 0 0 auto; padding:2px 0 0 0; color:#000; border:none; }
.base-drawer .close-btn:hover{ background:rgba(0,0,0,.1); }
.base-drawer .close-btn .anticon{ font-size:14px; line-height:0; }

.drawer-element{ margin:0; padding:0; }
.drawer-element .ant-card{ margin-bottom:30px; }
.drawer-element .ant-card-body h2{ font-size:20px; line-height:1.3; color:#000; font-weight:500; margin:0; padding:0 0 25px 0; }
.drawer-element .ant-card-body h4{ font-size:16px; line-height:1; color:#000; font-weight:600; margin:0; padding:0 0 9px 0; }
.drawer-element .ant-card-body ul li{ font-size:15px; line-height:1.3; color:#606060; font-weight:400; margin:0; padding:0 0 10px 0; }

.drawer-element .ant-card-body .block{ margin:0; padding:0 0 20px 0; }

.drawer-element .ant-card-body .date-text{ font-size:15px; line-height:1.3; color:#606060; font-weight:600; margin:0; padding:0; }

.drawer-element .ant-card-body ol{ list-style:none; display:flex; flex-flow:wrap; margin:0; padding:0; }
.drawer-element .ant-card-body ol li { width:50%; }

.drawer-element .ant-card-body .btn-field{ margin:0; padding:0; display:flex; justify-content:space-between; }
.drawer-element .ant-card-body .btn-field .common-btn{ width:48%; height:42px; margin:0; }
.drawer-element .ant-card-body .btn-field .common-btn.reject-btn{ border:1px solid #ff4d4f !important; color:#ff4d4f !important; background:none !important; }

.certificates_modal{ width:100% !important; max-width:850px; }

/*========== Body-header End =========*/

/*========== Previous-Checks Start =========*/
.body-part .previous-block{ margin:0; padding:0; }
.body-part .previous-block .up-secion{ margin:0; padding:10px 15px 10px 20px; display:flex; justify-content:space-between; }

.body-part .previous-block .up-secion .left-side{ margin:0; padding:0; display:flex; align-items:center; }

.lt-form-block{ margin:0 15px 0 0; padding:0 0 0 3px; }
.lt-form-block .ant-input{ height:38px; }

.body-part .previous-block .up-secion .left-side .ant-btn{ height: 41px; border: 1px solid #C9C9C9; border-radius: 0; padding: 0 10px 0 15px; -webkit-box-shadow: none; box-shadow: none; }
.body-part .previous-block .up-secion .left-side .ant-btn span{ font-size: 13px; line-height: 15px; color: #170C0C; font-weight: 400; text-transform: capitalize; margin: 0; padding: 0 25px 0 0; }
.body-part .previous-block .up-secion .left-side .perform-select{ flex:1; padding:0 3px; }
.left-side .perform-select .ant-select-selection{ width:200px; height:38px; border:1px solid #D1D1D0; }
.left-side .perform-select .ant-select-selection:hover{ border: 1px solid #3235F5; }
.left-side .perform-select .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }
.left-side .perform-select .ant-select-selection__rendered{ line-height:37px; color:#bfbfbf; }

.form-begining .ant-select-selection__rendered { width: 100%; }

.previous-block .up-secion .right-side{ margin:0; padding:0; display:flex; align-items:center; }
.previous-block .up-secion .right-side .ant-btn{ height:38px; font-weight:600; padding:0 15px; box-shadow:none; outline:none; color:#3235F5; }
.previous-block .up-secion .right-side .ant-btn.btn-clear{  background:#C20514; margin-left:20px; color:#fff !important; padding-top:2px; }
.multiple-box span{ display:block; padding-bottom:10px; }

/*== Click btn ==*/
.previous-block .up-secion .ant-radio-group{ margin-right:12px; background:none; border-radius:0; padding:0; overflow:hidden; border:1px solid rgba(0,0,0,.1); }
.previous-block .up-secion .ant-radio-button-wrapper{ background:none; border-radius:0; width:35px; height:auto; box-shadow:none !important; text-align: center; padding:0; border-top-width: 1px !important; outline:none; border:none; background:#fff; }
.previous-block .up-secion .ant-radio-button-wrapper .anticon-menu{ font-size:15px; color:#000; }
.previous-block .up-secion .ant-switch-checked{ background:#fff; border-radius:0; width:100px; height:35px; box-shadow:none; }
.previous-block .up-secion .ant-radio-button-wrapper-checked{ border: none; background: #333; }
.previous-block .up-secion .ant-radio-button-wrapper-checked .anticon-menu{ color:#fff; position:relative; top:1px; }

.previous-block .right-side .form-block{ margin:0 0 0 15px; padding:0; position: relative; }
.previous-block .right-side .form-block .ant-input{ width:100%; height:38px; border:1px solid #D1D1D0; }
.previous-block .right-side .form-block .ant-input:hover{ border: 1px solid #3235F5; }
.previous-block .right-side .form-block .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }
.previous-block .right-side .form-block .ant-btn{ background:#3235F5; color:#fff; height:38px; padding: 0 18px; }
.previous-block .right-side .form-block .ant-btn:hover{ background:#1404D6; }
.filter_stage .ant-select-selection--single{ height:38px; border:1px solid #D1D1D0; }
.filter_stage .ant-select-selection--single:hover{ border: 1px solid #3235F5; }
.filter_stage .ant-select-selection--single:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }
.filter_stage .ant-select-selection__rendered{ line-height:37px; }

.previous-block .up-secion .list_box span .anticon-menu{ transform:rotate(90deg); position:relative; top:1px; }

.ant-dropdown-field{ display:flex; align-items:center; width: 100%; height: 38px; border: 1px solid #D1D1D0; border-radius:4px; margin:0; padding: 4px 15px; background:#fff; font-size:14px; line-height:1; color: rgba(0, 0, 0, 0.65); font-weight:400; }
.ant-dropdown-field:hover{ color:#000; }
.ant-dropdown-field .anticon{ margin-left:12px; }

.dropdown-iep .title-item{ font-size:10px; line-height:1; color:#AEAEAE; font-weight:400;  }
.dropdown-iep .ant-dropdown-menu-item .ant-btn{ background:none; border:none; box-shadow:none; outline:none; height:auto; margin:0; padding:0; }
.dropdown-iep .ant-dropdown-menu-item .ant-btn:hover{ color:#000; }

.csv-modal{ padding-bottom:30px; }
.csv-modal .ant-steps{ max-width:450px; margin:0 auto; }
.base-content{ margin:0; padding:30px; }
.base-content p{ font-size:15px; line-height:1.3; color:#333; font-weight:400; margin:0; padding:0 0 30px 0; text-align:center; }
.base-content .doc-box{ margin:0 auto; padding:0; max-width:350px; }
.base-content .ant-upload-drag-icon{ padding-bottom:0; }
.base-content .ant-upload-drag-icon .anticon{ font-size:35px !important; }


.base-content .table-element{ width:100%; }
.base-content .table-element thead tr th{ border-bottom:1px solid rgba(0,0,0,.1); padding:10px 5px; }
.base-content .table-element tbody tr td{ padding:7px 5px;}
.base-content .table-element tbody tr:nth-child(odd) td{ background:#f4f4f4; }


.base-content .table-element h3{ font-size:15px; line-height:1; color:#333; font-weight:600; margin:0; padding:0; }
.base-content .table-element .sub-text{ display:block; font-size:13px; line-height:1; color:#9A9A9A; font-weight:400; margin:0; padding:0; }


.base-content .desc-table{ width:100%; max-width:100%; margin:0 auto; }
.base-content .desc-table tbody tr td{ width:40; padding:7px 20px; vertical-align:top; }
.base-content .desc-table tbody tr td:first-child{ width:40%; }
.base-content .desc-table tbody tr td p{ text-align:left; padding:0; }
.base-content .desc-table h5{ font-size:15px; line-height:1; color:#333; font-weight:600; margin:0; padding:0; }

.base-content .input-box label:after{ display:none; }


.sort-field{ margin:0; padding:0; }
.sort-field ol{ list-style:none; margin:0; padding:0 0 15px 0; }
.sort-field ol li{ display:flex; justify-content:space-between; margin:0; padding:0; }
.sort-field ol li h3{ display:block; font-size:16px; line-height:1; color:#333; font-weight:500; margin:0; padding:0; }


.sort-list{ margin:0 0 5px 0; padding:10px; display:flex; align-items:center; justify-content:space-between; position: relative; background:#ECECEC; border-radius:5px; position: relative; z-index:9999; }
.sort-list .field_drag_handle{ bottom:auto; top:50%; transform:translateY(-50%); left:0; opacity:1; padding:5px; }

.sort-list .text{ margin:0; padding:0 0 0 30px; }
.sort-list h3{ display:block; font-size:14px; line-height:1; color:#333; font-weight:500; margin:0; padding:0; }

.base-content.hst-content{ padding:0; }

.hst-content .ant-table-thead > tr > th, .hst-content .ant-table-tbody > tr > td{ padding:5px 5px; }


.base-csv-modal .main-cta{ display:flex; padding:0; }
.base-csv-modal .main-cta .common-btn{ flex: 1 1; height: 100%; border-radius: 0 0 4px 0; }
.base-csv-modal .main-cta .common-btn:disabled{ background: #ccc !important; opacity:.7; color:#5c5c5c !important; }
.base-csv-modal .main-cta .common-btn.back-btn{ background: #ccc !important; color: #000 !important; margin: 0; padding: 0; border-radius: 0 0 0 4px; }

.previous-block .bottom-section{ margin:0; padding:0 0 25px 10px; white-space: nowrap; overflow:auto; min-height:200px; }
.previous-block .bottom-section .thumb-box{ display: inline-block; width:280px; margin:0; padding:0 7px; vertical-align:top; }
.previous-block .bottom-section .panel-box{ margin:0; padding:0; border:1px solid rgba(0,0,0,.1); box-shadow:0 3px 6px 0 rgba(0,0,0,.1); border-radius:22px; overflow: hidden; }
.previous-block .bottom-section .panel-box .details-bar{ display:flex; align-items:center; justify-content:space-between; padding:10px 15px 5px; margin:0; border-bottom:1px solid rgba(0,0,0,.1); }
.previous-block .bottom-section .panel-box .details-bar .lt-side{ width:155px; white-space:normal; }

.details-bar .lt-side .ant-tag{ font-size: 12px; font-weight: 500; margin-bottom:8px; }

.previous-block .bottom-section .panel-box .details-bar .rt-side span{ display:inline-block; width:37px; height:37px; border-radius:50%; background:#FCEEB3; text-align:center; font-size:12px; line-height:36px; color:#170C0C; font-weight:400; overflow: hidden; }
.previous-block .bottom-section .ant-pagination { text-align:center; }

.previous-block .bottom-section table{ width:auto; background:#fff; border-radius: 4px; overflow:hidden; }


.clm-btn{ display:inline-block; font-size:14px; line-height:1; color: rgba(0, 0, 0, 0.65); font-weight:400; margin:0; padding:10.5px 15px; border:1px solid rgba(0,0,0,.1); border-radius:3px; background:#fff; margin-left:12px; }

.clm-btn .anticon-down{ margin-left:12px; }



.clm-drawer{ z-index:0; }
.clm-drawer .ant-drawer-body{ padding:0; }
.dropdown-fld{ background: #fff; padding:15px 0; }
.dropdown-fld ul li{ margin:0 0 0px 0; padding:0; }
.dropdown-fld ul li:last-child{ padding-bottom:0; }
.dropdown-fld ul li::marker{ display:none; }

.sortable-list{ margin:0; padding:5px 0 5px 15px; position: relative;  }
.sortable-list:hover{ background:rgba(0,0,0,.1) }
.sortable-list .field_drag_handle{ left:-5px;}
.sortable-list:hover .field_drag_handle{ opacity:1; }

.sortable-list .ant-checkbox-wrapper{ display:flex; align-items:flex-start; margin:0 0 0 12px; }
.sortable-list .ant-checkbox-wrapper .ant-checkbox{ position: relative; top:2px; }


.perform-empty{ text-align:center; }
.emptyContainer{ text-align:center; padding-top:30px; }
.emptyContainer img{  margin:0 auto 8px;; }
.emptyContainer p{ font-size:16px; text-align:center; margin:0; padding:0 0 15px 0; }
.setting-empty .paragraph{ display:inline-block; line-height:1.4; max-width:650px; margin:0 auto; padding:0; }


/* .tab-block .body-part .previous-block .bottom-section .thumb-box:nth-child(4){ padding-right:0; } */
.previous-block .bottom-section .thumb-box h2{ font-size:17px; line-height:19px; color:#696969; font-weight:600; margin:0; padding:0 0 6px 0; }
.previous-block .bottom-section .thumb-box h6{ font-size:11px; line-height:15px; color:#696969; font-weight:400; margin:0; padding:0; }
.previous-block .bottom-section .thumb-box h6 span{ display:block; }
.previous-block .bottom-section .thumb-box h6.time-text{ background:url(./assets/images/noun_clock.svg) no-repeat left center; background-size:14px; padding: 6px 0 6px 20px; }

.workflow-content { display:flex; flex-flow: column; position: relative; height:100%; width:100%; }

/* .workflow-content.workflow-fullscreen{ position: absolute; left:80px; right:0; top:0; bottom:0; } */

.workflow-content .settings-tab-element{ padding:0; }
.workflow-content .settings-tab-element .ant-tabs-nav-wrap{ padding-top:15px; }
.workflow-content .settings-tab-element .ant-tabs-left-bar .ant-tabs-tab{ margin-bottom:0; padding-top:15px; padding-bottom:15px; }
.workflow-content .settings-tab-element .ant-tabs-left-content{ height:100%; overflow:auto; }

.workflow-content .settings-tab-element .ant-tabs-tabpane{ padding-top:30px; padding-right:30px; }


.previous-block .bottom-section .thumb-box .box{ background:#fff; min-height:auto; margin:0; padding:15px 0 0; }
.previous-block .bottom-section .thumb-box .box ul{ margin:0 0 15px 0; padding:0; overflow: auto; overflow-x: hidden; }
.previous-block .bottom-section .thumb-box .box ul li{ font-size:13px; line-height:20px; color:#170C0C; font-weight:400; margin:0 0 3px; padding:7px 15px; white-space: initial; display:flex; align-items: center; position: relative; cursor:pointer; transition:all 200ms ease-in-out; }
.previous-block .bottom-section .thumb-box .box ul li:hover{ background:#e6f7ff; }
.previous-block .bottom-section .thumb-box .box ul li:last-child{ margin-bottom:0; }
/* .tab-block .body-part .previous-block .bottom-section .thumb-box .box ul li:hover{ background:rgba(0,0,0,.03); } */

.previous-block .bottom-section .thumb-box .box ul li:last-child{ margin:0; }
.previous-block .bottom-section .thumb-box .box ul li span.tick-icon{ float: left; position: relative; left:0; top:0; display: flex; justify-content: center; align-items: center; width:22px; height:22px; background:#DCF2E0; border:1px solid #5CCE65; margin-right:12px; }
.previous-block .bottom-section .thumb-box .box ul li span.cross-icon{ float: left; position: relative; left:0; top:0; display: flex; justify-content: center; align-items: center; width:23px; height:23px; margin-right:12px; background: rgba(0,0,0,.04); border:none; }

.previous-block .bottom-section .thumb-box .box ul li span.comment{ font-size: 12px; line-height: 14px; color: #ADA9A9; font-weight: 400; text-transform: capitalize; margin:13px 0 0 0; padding: 0 0 0 20px; background:url(./assets/images/black-icon.png) no-repeat left center; display:block; cursor: pointer; }
.previous-block .bottom-section .thumb-box .box ul li span.list-text{ float: left; width:85%; position: relative; }
.previous-block .bottom-section .thumb-box .box ul li b{ float:right; width:45px; font-size:11px; padding-left:3px; text-align:center; }

.body-part .thumb-box .box .task_sec{ text-transform: uppercase; background:#f4f4f4; font-size: 11px !important; color:#7e7e7e !important; padding: 8px 15px 6px !important; }
/* .tab-block .body-part .thumb-box .box .task_sec:hover{ background: #f5f5f5; } */

.previous-block .bottom-section .thumb-box .box a{ display:block; font-size:11px; line-height:13px; color:#696969; font-weight:700; text-transform:uppercase; margin:0; padding: 12px 0 15px; border-top:1px solid #F2F2F2; text-align:center; transition:all 100ms ease-in-out; }
.previous-block .bottom-section .thumb-box .box a:hover{ background:rgba(0,0,0,.03); }

/*========== Previous-Checks End =========*/

/*========== Tasks-Block Start =========*/
.content-overflow{ height: 100%; overflow:auto; box-shadow:none; }
.content-overflow.perform-overflow{ overflow:inherit; }
.content-overflow .rt-sec{ height:100%; }

.content-overflow .rt-sec .top_head{ margin:0; padding:10px 20px; border-bottom:1px solid rgba(0,0,0,.1); }
.content-overflow .rt-sec .top_head .text{ margin:0; padding:0 0 10px 0; }
.content-overflow .rt-sec .top_head h4{  font-size:16px; line-height:1.4; color:#707070; font-weight:600; margin:0; padding: 0 48px 0 0; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }


.content-overflow .top_head .use-btn{ display:flex; align-items:center; margin:0; padding:0; }
.content-overflow .top_head .use-btn .user-name{ display:flex; align-items:center; margin:0; padding:4px; border:1px solid rgba(0,0,0,.1); border-radius:30px; transition:all 200ms ease-in-out; }
.content-overflow .top_head .use-btn .user-name .profile_pic.round{ font-size:12px; line-height:1; }
.content-overflow .top_head .use-btn .user-name .ant-btn{ display:flex; align-items:center; margin:0; padding:0; height:auto; border:none; outline:none; box-shadow:none; }

.content-overflow .top_head .use-btn .user-name .user-icon{ margin:0 0 0 4px; padding:0; line-height:0; display:none; }
.content-overflow .top_head .use-btn .user-name .user-icon img{ width:13px; height:auto; }

.content-overflow .top_head .use-btn .user-name .assing-icon-show .user-icon{ display:block; }

.content-overflow .top_head .use-btn .user-name .ant-input{ margin-left:3px; font-size: 13px; line-height:1; font-weight: 500; border:1px solid transparent; outline:none; box-shadow:none;     height: auto; padding: 1px 3px; max-width:110px; }
.content-overflow .top_head .use-btn .user-name .ant-input:focus{ border:1px solid rgba(0,0,0,.1); }
.content-overflow .top_head .use-btn .user-name .ant-input::-webkit-input-placeholder { color:#444; }
.content-overflow .top_head .use-btn .user-name .ant-input::-moz-placeholder { color:#444; }
.content-overflow .top_head .use-btn .user-name .ant-input:-ms-input-placeholder { color:#444; }
.content-overflow .top_head .use-btn .user-name .ant-input:-moz-placeholder { color:#444; }


.content-overflow .top_head .use-btn .user-name:hover{ border:1px solid rgba(0,0,0,.5); }

/* .content-overflow .top_head .use-btn .user-name .profile_name{ margin-left: 7px; font-size: 14px; font-weight: 500; } */

.content-overflow .top_head .use-btn .user-name .close-icon{ margin:0px 0 0 5px; padding:0; line-height:0; cursor: pointer; width:15px; height:15px; text-align:center; }
.content-overflow .top_head .use-btn .user-name .close-icon .anticon{ font-size:13px; line-height:0; color:#C72D29; }
.content-overflow .top_head .use-btn .user-name .close-icon:hover .anticon{ opacity:.7; }

.content-overflow .top_head .date-btn{ display:flex; align-items:center; margin:0 0 0 10px !important; padding: 11px 18px 10px; border-radius:30px; background:rgb(3, 133, 255); cursor:pointer; transition:all 150ms ease-in-out; }
.content-overflow .top_head .date-btn:hover{ background: #0042cf; }

.content-overflow .top_head .date-btn.normal {
    background:rgb(56, 173, 21);
}

.content-overflow .top_head .date-btn.normal:hover {
    background:rgb(32, 121, 24);
}

.content-overflow .top_head .date-btn.warn {
    background:rgb(226, 159, 14);
}

.content-overflow .top_head .date-btn.warn:hover {
    background:rgb(206, 117, 1);
}

.content-overflow .top_head .date-btn.danger{ background:#FF4D4F; }
.content-overflow .top_head .date-btn.danger:hover{ background:rgb(190, 3, 0); }

.content-overflow .top_head .date-btn.unassigned{ background:#fff; border:1px solid rgba(0,0,0,.1); }
.content-overflow .top_head .date-btn.unassigned:hover{ border:1px solid rgba(0,0,0,.5); }
.content-overflow .top_head .date-btn.unassigned .anticon.anticon-calendar{ color:#444; }

.content-overflow .top_head .date-btn { margin:-2px 10px 0 0; padding:0; line-height:0; }
.content-overflow .top_head .date-btn .anticon{ font-size:14px; background:none; top:14px; }
.content-overflow .top_head .date-btn .anticon.anticon-calendar{ left:10px; right: auto; color:#fff; }
.content-overflow .top_head .date-btn span{ display:inline-block; font-size:14px; line-height:1; color:#fff; font-weight:600; margin:0; padding:0;  }
.content-overflow .top_head .date-btn .anticon.anticon-close-circle{ top:15px; right:7px; }

.content-overflow .top_head .date-btn .ant-calendar-picker-input.ant-input{ background:none; border:none; font-size:13px; line-height:1; color:#fff; font-weight:600; margin:0; padding:0; width: 145px; height:30px; padding-left:30px; outline:none; box-shadow:none; }

.content-overflow .top_head .date-btn .ant-calendar-picker-input.ant-input::-webkit-input-placeholder { color:#fff; }
.content-overflow .top_head .date-btn .ant-calendar-picker-input.ant-input::-moz-placeholder { color:#fff; }
.content-overflow .top_head .date-btn .ant-calendar-picker-input.ant-input:-ms-input-placeholder { color:#fff; }
.content-overflow .top_head .date-btn .ant-calendar-picker-input.ant-input:-moz-placeholder { color:#fff; }

.content-overflow .top_head .date-btn.unassigned .ant-calendar-picker-input.ant-input::-webkit-input-placeholder { color:#444; }
.content-overflow .top_head .date-btn.unassigned .ant-calendar-picker-input.ant-input::-moz-placeholder { color:#444; }
.content-overflow .top_head .date-btn.unassigned .ant-calendar-picker-input.ant-input:-ms-input-placeholder { color:#444; }
.content-overflow .top_head .date-btn.unassigned .ant-calendar-picker-input.ant-input:-moz-placeholder { color:#444; }


.content-overflow .rt-sec .right-task-perform{ height:100%; }
.content-overflow .rt-sec .right-task-perform .name-sec{ padding-top:10px; padding-bottom:5px; }
/* .content-overflow .rt-sec .right-task-perform .name-sec li{ background:#ccc; margin-bottom:2px; } */
.content-overflow .rt-sec .right-task-perform .name-sec .field_look{ padding-top:15px; padding-bottom:15px; }

.content-overflow .preform-mark-btn{ width:100%; margin:0 auto; padding:20px; }
.content-overflow .preform-mark-btn .field_add_btn.active{ margin-bottom:0 !important; float:inherit; }
.content-overflow .preform-mark-btn.emptyTask{ position: relative; top:0; transform:translateY(0); }
.content-overflow .preform-mark-btn.emptyTask .button-holder .field_add_btn.dr_btn{ float:inherit; }

.content-overflow.content-overflow-task{ background:#F9F9F9; }
.content-overflow.content-overflow-task .field-contents{ top:0 !important; }

.content-overflow.content-right-overflow{ border-left:1px solid rgba(0,0,0,.1); }

.content-overflow .rt-sec .right-task-perform .loading-text{ text-align:center; height:100%; padding-top:65px; }

.right-task-perform .field_look .ant-radio-group{ display:flex; flex-flow:column; }
.right-task-perform .field_look .ant-radio-group .ant-radio-wrapper{ margin-right:0; margin-bottom:10px; }
.right-task-perform .field_look .ant-radio-group .ant-radio-wrapper:last-child{ margin-bottom:0; }

.right-task-perform .field_look .ant-form-item-control{ line-height:1; }
.right-task-perform .field_look .ant-checkbox-group{ display:flex; flex-flow:column; }
.right-task-perform .field_look .ant-checkbox-group .ant-checkbox-wrapper{ margin-left:0; margin-bottom:8px; }


.perform-modal .field_look{ width:100%; height:auto; margin-left:0; padding:0; }

.name-sec .field-content{ width:100%; margin:0; padding:15px 20px !important; }
.name-sec .field-content label{ display:block; font-size: 14px; line-height: 1; font-weight: 600; color: #000; padding-bottom: 10px; }
.name-sec .field-content p{ margin:0 !important; margin-bottom:10px !important; padding:0; }
.name-sec .field-content iframe{ width:100%; height:190px;  }
.field-contents .field-content iframe{ width:100%;  }

.field-contents .field-content{ padding:0 35px; }

.field-contents .fields_holder .field-content{ padding-right:45px; }

.content-overflow .rt-sec .task-support{ margin:0; padding:15px 0; border-bottom: 1px solid rgba(0,0,0,.1); background:#f9f9f9;  }
.content-overflow .rt-sec .support-text{ display:block; font-size:12px; line-height:1; color:#333; font-weight:600; margin:0; padding:0 0 10px 0; }
.content-overflow .rt-sec.collapse-rt{ padding-bottom:0; }

.center-loader, .no-task-data{ position: absolute; top:50%; transform:translateY(-50%); left:0; width:100%; text-align:center; }


.assigned_field{ display:flex; align-items:center; margin:0; padding:0; }
.assigned_field p{ margin:0 0 0 15px; }

.assigned_field p span{ font-weight:600; }

.content_field.link_field_content > label{ padding-left:35px; }

.caption_field .content_field{ padding-left:30px; }
.caption_field .content_field label{ padding-left:0; }

.caption_field .content_field .img-lin-text{ display:inline-block; vertical-align:middle; max-width:400px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-left:10px; color:#000; }

.edit-drawer{ z-index:inherit; }

.content-overflow .rt-sec .right-task-perform .name-sec li:last-child .field_look{ padding-bottom:0; }

.type-field{ margin-top:25px !important; }
.type-field .type-row{ margin:0 -15px; padding:0; display:flex; }
.type-field .type-row .item{ width:50%; margin:0; padding:0 15px; }
.type-field .type-row .item-bx{ margin:0; padding:30px; border:1px solid rgba(0,0,0,.1); border-radius:15px; text-align:center; cursor: pointer; position: relative; }
.type-field .type-row .item-bx.active{ border:1px solid rgba(0,0,0,.5); }
.type-field .type-row .item-bx .check-icon{ position:absolute; top:0; left:0; margin:0; padding:15px; display:none; }
.type-field .type-row .item-bx .check-icon .anticon{ font-size:20px; color:#00DE3C; }
.type-field .type-row .item-bx.active .check-icon{ display:block; }

.type-field .type-row .item-bx .icon-img{ margin:0 0 15px 0; padding:0; }
.type-field .type-row .item-bx .anticon{ font-size:22px; }
.type-field .type-row .item-bx span{ display:block; font-size:16px; line-height:1; color:#333; font-weight:500; margin:0; padding:0; }



/*========== Settings Start =========*/
.body-content{ margin:0; padding:25px 0 0 0; flex:1; overflow: auto; }

.body-content .ant-tabs-tab{ font-weight:600; }
.body-content .ant-tabs-tab:hover{ color:#3235F5; }
.body-content .ant-tabs-content{ padding:0 30px; }

/* Artifical Scrollbar */
.scrollbar { position: fixed; left: 80px; right: 0; bottom: 0; overflow-x: auto; overflow-y: visible; }
.scrollbar .scrollbar-content { float: left;  height: 15px;}


.task-support .task-pf{ border: solid 1px rgba(0,0,0,0.1); background:#fff; margin-bottom:8px; }
.task-support .task-pf:hover{ background:#fff; }
.task-support .task-pf .task-progress { left:10px; }
.task-support .task-pf .task-name{ padding-left:30px; padding-right:0px; }
.task-support .task-pf .task-name .list-text{ font-size: 12px; padding: 15px 0; }

.task-pf.current{ border-bottom:none; }

.task-editor-body{ position: absolute; left:0; right:0; top:0; bottom:0; background:#FFF; border-left:1px solid #EEE; overflow:hidden;}
.task-holder{z-index: 2;box-shadow: 0px 1px 20px 0px rgba(0,0,0,0.1); background:#FFF; padding-bottom: 60px !important;}
.tasks-block{ margin:0; padding:0; }
.tasks-block .up-block{ margin:0; padding:0 0 15px 0; display:flex; align-items:center; justify-content:space-between; font-size: 18px; font-weight: 500; }
.tasks-block .up-block .version-text{ margin:0; padding:0; display: flex; align-items: center; }
.tasks-block .up-block .version-text h2{  font-size:25px; line-height:1.4; color:#080705; font-weight:600; margin:0; padding:0; }
.tasks-block .up-block .version-text .ant-btn{ height: 41px; border: 1px solid #C9C9C9; border-radius: 0; padding: 0 10px 0 15px; -webkit-box-shadow: none; box-shadow: none; margin-left:0 !important; }
.tasks-block .up-block .version-text .ant-btn span{ font-size: 13px; line-height: 15px; color: #170C0C; font-weight: 400; text-transform: capitalize; margin: 0; padding: 0 25px 0 0; }

.tasks-block .up-block .edit-btn{ margin:0 0 0 18px; padding:0; }
.tasks-block .up-block .edit-btn a{ display:inline-block; font-size:12px; line-height:14px; color:#fff; font-weight:700; margin:0; padding:10px 20px; background:#FC7753; border:none; border-radius:3px; }
.tasks-block .up-block .edit-btn a:hover{ background:#ec6945; }
 
.tasks-block .down-block{ margin:0; padding:0; }
.tasks-block .down-block .block{ padding:0; background:#fff; border-bottom: solid 1px #F4F4F4; transition:all 200ms ease-in-out; }
.tasks-block .down-block .block:hover{ background: rgba(236,235,235,.1); }
.tasks-block .down-block .block .desc-box{ margin:0; padding:0; }
.tasks-block .down-block .block .desc-box .text-block{ margin:0; padding:0; position: relative; }
.tasks-block .down-block .block .desc-box .text-block:after{ display:none; content:""; position: absolute; width:20px; height:20px; background:#FCFCFC; border:1px solid #B5B5B5; left:0; top: 50%; transform: translateY(-50%) }

.tasks-block .down-block .block .desc-box a{ display:block; padding:10px 35px 10px; }
.tasks-block .down-block .block .desc-box h2{ font-size:15px; line-height:22px; color:#170C0C; font-weight:600; margin:0;}
.tasks-block .down-block .block.section-task .desc-box h2{ font-size:12px; line-height:17px; font-weight:600; }
.tasks-block .down-block .block .desc-box p{ font-size:13px; line-height:20px; color:#676767; font-weight:400; margin:0; padding:0; }
.tasks-block .down-block .block .desc-box ul li{ font-size:14px; line-height:16px; color:#676767; font-weight:400; margin:0; padding:0 0 22px 0; }
.tasks-block .down-block .block .desc-box ul li span{ font-weight:700; color:#000; }
.tasks-block .down-block .block .desc-box ul li:last-child{ padding-bottom:0; }

.tasks-block .down-block .block.section-task{ background: #FBFBFB; border-bottom: 1px solid rgba(0,0,0,.03); }

.tasks-block .down-block .block.section-task .desc-box a{ padding-left:20px; cursor:auto; }

.task-attr-holder { position: relative; height:100%; }
.task-attr-holder .right-task{ position: relative; height:100%; }
.task-attr-holder .right-task .comment-post-text{ position: relative; width:100%; bottom:0; left:0; padding:10px 15px 15px; box-shadow:0px -1px 3px 1px rgb(0 0 0 / 10%) }

.cmt-form-block{ display:flex; margin:0; padding:0; }

.task-attr-holder .right-task .comment-post-text .form-field{ flex:1; position: relative; border:1px solid #D1D1D0; padding-right:55px; background:#fff; }
.task-attr-holder .right-task .comment-post-text .form-field .ant-input{ border:1px solid transparent; border-radius:0; padding-right: 60px; }
.task-attr-holder .right-task .comment-post-text .form-field .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; border-right:1px solid transparent; }
.task-attr-holder .right-task .comment-post-text .form-field .ant-btn{ position: absolute; width:55px; height:100%; right:0; top:0; bottom:0; color:#000; font-weight:600; border-left: 1px solid rgba(0,0,0,.1); border-radius: 0; padding:0; background:none; }
.task-attr-holder .right-task .comment-post-text .form-field .ant-btn:disabled{ opacity:.3; }
.task-attr-holder .right-task .comment-post-text .form-field .ant-btn .send-icon{ width:22px; height:auto; }

.task-attr-holder .right-task .ant-tabs-bar{ background:#fff; margin-bottom:0; }
.task-attr-holder .right-task .ant-tabs-nav{ width:100%; }
.task-attr-holder .right-task .ant-tabs-nav>div{ display:flex; justify-content:space-between; }
.task-attr-holder .right-task .ant-tabs-nav .ant-tabs-tab{ width:25%; font-size: 13px; line-height: 15px; color:#B2B2B2; font-weight:600; margin-right:0; text-align:center; padding-left:0; padding-right:0; }
.task-attr-holder .right-task .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active{ color:#000; }
.task-attr-holder .right-task .ant-tabs-ink-bar{ background:#000; }
.task-attr-holder .right-task-perform .block{ margin:0; padding:0; }
.task-attr-holder .right-task .user-tab{ height:100%; overflow:auto; }
.task-attr-holder .right-task .user-tab .ant-tabs .ant-tabs-nav-container{ padding:0; }
.task-attr-holder .right-task .user-tab .ant-tabs-tab-prev, .task-attr-holder .right-task .user-tab .ant-tabs-tab-next{ display:none; }


.comment-post-text .emoji-picker-react{ position:absolute; left:0; bottom:100%; z-index:9; box-shadow:0 2px 8px rgba(0,0,0,.1); }
.comment-post-text .btn-box{ margin:0; padding:0; }
.comment-post-text .btn-box .ant-btn{ background:none; box-shadow:none; outline:none; border:none; font-size:19px; margin-right:6px; color:rgba(0,0,0,.65); width:42px; height:100%; }
.comment-post-text .btn-box .ant-btn .anticon{ line-height:0; }
.comment-post-text .btn-box .ant-btn:last-child{ margin-right:0; }
.comment-post-text .btn-box .ant-btn:after{ display:none; }
.comment-post-text .btn-box .ant-btn:hover{ background:#fff; }

.files-upload{ margin:0 -5px; padding:0; display:flex; flex-flow:wrap; }
.files-upload .upload-item{ width:50%; margin:0; padding:0 5px 10px; }


.task-attr-holder .right-task .user-tab .ant-collapse-borderless{ height:100%; background:none; }

.task-attr-holder .right-task .user-tab .ant-collapse-item{ border-bottom:none; background:#fff; margin-bottom: 10px; border-radius: 0; }
.task-attr-holder .right-task .user-tab .ant-collapse-header{  font-size: 13px; line-height:1.4; font-weight:700; color: #111; border-bottom: 1px solid rgba(0,0,0,.1); }
.task-attr-holder .right-task .user-tab .ant-collapse-content{ padding-top:10px; }
.task-attr-holder .right-task .user-tab .ant-collapse-content.ant-collapse-content-active{ height:100%; overflow:auto; }

.task-attr-holder .right-task .user-tab .ant-collapse-item.ant-collapse-item-active{ height:100%; padding-bottom:52px; }
.task-attr-holder .right-task .user-tab .ant-collapse-content-box{ padding:15px 25px; }

.task-attr-holder .right-task .user-tab .ant-collapse-content-box .more_btn{ margin:0; padding:10px 0 0 0; text-align:center; }
.task-attr-holder .right-task .user-tab .ant-collapse-content-box .more_btn .ant-btn{ display:inline-block; width:145px; height:auto; font-size:14px; line-height:16px; color:#333; font-weight:600; background:none; box-shadow:none; border:1px solid rgba(0,0,0,.2); border-radius:25px; margin:0 auto; padding:14px 0; text-align:center; transition:all 150ms ease-in-out; }
.task-attr-holder .right-task .user-tab .ant-collapse-content-box .more_btn .ant-btn:hover{ border:1px solid #1890FF; color:#1890FF; }

.task-attr-holder .right-task .user-tab .center-loader{ z-index:-1; }

.task-attr-holder .right-task .comment-block{ flex:1; margin:0; padding:15px; overflow:auto; overflow-x:hidden; }


.task-attr-holder .right-task .block li{ display:flex; margin:0; padding:0 0 18px 0; }
.task-attr-holder .right-task .block li.comment-profile{ align-items:flex-start; }
.task-attr-holder .right-task .block li:last-child{ padding-bottom:0; border-bottom:none; }
.task-attr-holder .right-task .block .user-img{ width:40px; height:40px; border-radius:50%; margin:0 10px 0 0; padding:0; background:#999; display:flex; align-items:center; justify-content:center; }
.task-attr-holder .right-task .block .user-text{ flex:1; margin:0 0 0 12px; padding:0 0 18px 0; border-bottom:1px solid rgba(0,0,0,.1); position:relative; }
.task-attr-holder .right-task .block .user-text .name-text{ display:block; font-size:14px; line-height:15px; color:#000; font-weight:600; margin:0; padding:0 0 3px 0; }
.task-attr-holder .right-task .block .user-text .time-text{ display:block; font-size:12px; line-height:12px; color:#999; font-weight:400; margin:0; padding:1px 0 7px; }
.task-attr-holder .right-task .block .user-text .comment-content p{ display:block; font-size:15px; line-height:1.3; color:#111; font-weight:400; margin:0; padding:0; }

.task-attr-holder .right-task .block li:last-child .user-text{ border-bottom:none; }

.dropdown-field-reactions{ margin:0; padding:0; position: absolute; top:0; right:0; }
.dropdown-field-reactions .ant-dropdown-link{ display:inline-block; font-size:14px; line-height:1; color:#000; border:none; margin:0; padding:0; border:none; height:auto; min-height:inherit;}
.dropdown-field-reactions .ant-dropdown-link img{ width:20px; height:auto; }

.react-dropdown{ position: absolute; top:-12px; right:2px; margin:0; padding:0; z-index:1; visibility:hidden; opacity:0; }
.task-attr-holder .right-task .block li.comment-profile:hover .react-dropdown{ visibility:visible; opacity:1; }
.task-attr-holder .right-task .block li.comment-profile:hover .dropdown-field-reactions{ visibility:hidden; opacity:0; }

.react-dropdown ul{ display:inline-block; margin:0; padding:0 8px;  border-radius:30px; box-shadow: 0px 1px 5px 2px rgba(0,0,0,0.10); background:#fff; }
.right-task .block .react-dropdown ul li{ display:inline-block; margin:0; padding:8px 5px; justify-content:center; align-items:center; transition:all 200ms ease-in-out; }
.react-dropdown ul li .ant-btn{ display:inline-block; border:none; box-shadow:none; outline:none; margin:0; padding:0; background:none; height:auto; }
.react-dropdown ul li img{ width:20px; height:auto; transition:all 200ms ease-in-out;  }
.react-dropdown ul li:hover img{ transform:scale(1.2); }
.react-dropdown ul li .emoji-dlt-btn .anticon{ font-size:18px; position:relative; top:2px; color:#898989; }
.react-dropdown ul li:hover .emoji-dlt-btn .anticon{ transform:scale(1); color:red; }

.right-task .block .react-dropdown ul li.emoji-dlt-li{ border-left:1px solid rgba(0,0,0,.1); padding-left:8px; margin-left:8px; line-height:1; }

.comment-attachments{ margin:0 -5px; padding:0; display:flex; flex-flow:wrap; }
.comment-attachments .upload-item{ width:50%; padding:10px 5px 0; }

.comment-reactions{ margin:0 -4px; padding:15px 0 0 0; display:flex; align-items:center; flex-flow:wrap; }
.comment-reactions .reactions-item{ margin:0; padding:0 4px; }
.comment-reactions .reactions-box{ display:flex; align-items:center; margin:0 0 0 0; padding:0 8px; height:25px; border:1px solid rgba(0,0,0,.1); border-radius:25px; background:#fff; }
.comment-reactions .reactions-box img{ width:15px; height:auto; }
.comment-reactions .reactions-box span{ display:inline-block; font-size:12px; line-height:1; color:#000; font-weight:500; margin:0; padding:0 0 0 9px; }

.task-attr-holder .right-task .active-block{ margin:0 0 18px 0; padding:0; border-radius:10px; display:flex; }
.task-attr-holder .right-task .block .checked-icon{ width:38px; margin:0; padding:0; position: relative; display:flex; align-items:center; }
.task-attr-holder .right-task .block .checked-icon .anticon{ font-size:16px; }
.task-attr-holder .right-task .block .checked-icon img{ width:19px; height:auto; }
.task-attr-holder .right-task .block .checked-icon:after{ content:""; position:absolute; width:8px; height:8px; background:#000; border-radius:50%; right:-5px; bottom:-12px; }

.task-attr-holder .right-task .block.checked .checked-icon{ border-right:1px solid #4CAF50; }
.task-attr-holder .right-task .block.checked .checked-icon .anticon{ color:#4CAF50; }
.task-attr-holder .right-task .block.checked .checked-icon:after{ background:#4CAF50; }

.task-attr-holder .right-task .block.change .checked-icon{ margin:0; padding:0; border-right:1px solid #595BCE; }
.task-attr-holder .right-task .block.change .checked-icon .anticon{ color:#595BCE; }
.task-attr-holder .right-task .block.change .checked-icon:after{ background:#595BCE; }

.task-attr-holder .right-task .block.upload .checked-icon{ margin:0; padding:0; border-right:1px solid #F0AD4E; }
.task-attr-holder .right-task .block.upload .checked-icon .anticon{ color:#F0AD4E; }
.task-attr-holder .right-task .block.upload .checked-icon:after{ background:#F0AD4E; }

.task-attr-holder .right-task .block.prompt .checked-icon{ margin:0; padding:0; border-right:1px solid #E46F70; }
.task-attr-holder .right-task .block.prompt .checked-icon:after{ background:#E46F70; }

.task-attr-holder .right-task .block.reopened .checked-icon{ margin:0; padding:0; border-right:1px solid #E46F70; }
.task-attr-holder .right-task .block.reopened .checked-icon:after{ background:#E46F70; }

.task-attr-holder .right-task .block.addUser .checked-icon{ border-right:1px solid #0099FF; }
.task-attr-holder .right-task .block.addUser .checked-icon .anticon{ color:#0099FF; }
.task-attr-holder .right-task .block.addUser .checked-icon:after{ background:#0099FF; }

.task-attr-holder .right-task .block.stageMove .checked-icon{ border-right:1px solid #001ea6; }
.task-attr-holder .right-task .block.stageMove .checked-icon .anticon{ color:#001ea6; }
.task-attr-holder .right-task .block.stageMove .checked-icon:after{ background:#001ea6; }

.task-attr-holder .right-task .block.create_perform .checked-icon{ border-right:1px solid #fc7303; }
.task-attr-holder .right-task .block.create_perform .checked-icon .anticon{ color:#fc7303; }
.task-attr-holder .right-task .block.create_perform .checked-icon:after{ background:#fc7303; }

.task-attr-holder .right-task .block.email_trigger .checked-icon{ border-right:1px solid #0099FF; }
.task-attr-holder .right-task .block.email_trigger .checked-icon .anticon{ color:#0099FF; }
.task-attr-holder .right-task .block.email_trigger .checked-icon:after{ background:#0099FF; }

.task-attr-holder .right-task .block.userDelete .checked-icon{ border-right:1px solid #FF0000; }
.task-attr-holder .right-task .block.userDelete .checked-icon .anticon{ color:#FF0000; }
.task-attr-holder .right-task .block.userDelete .checked-icon:after{ background:#FF0000; }

.task-attr-holder .right-task .block.webhook-trigger .checked-icon{ border-right:1px solid #000; }
.task-attr-holder .right-task .block.webhook-trigger .checked-icon .anticon{ color:#000; }
.task-attr-holder .right-task .block.webhook-trigger .checked-icon:after{ background:#000; }


.task_clickable_area { position: absolute; top: 0; bottom: 0; left: 0; right: 0; cursor: pointer; z-index:2; }

.task-attr-holder .right-task .block.created .checked-icon{ border-right: 1px solid #E46F70; }
.task-attr-holder .right-task .block.created .checked-icon .anticon{ color:#E46F70; }
.task-attr-holder .right-task .block.created .checked-icon:after{ background:#E46F70; }

.task-attr-holder .right-task .block.reopened .checked-icon{ border-right: 1px solid #E46F70; }
.task-attr-holder .right-task .block.reopened .checked-icon .anticon{ color:#E46F70; }
/* .task-attr-holder .right-task .block.reopened .checked-icon img{ width:4px; margin-left: 5px; margin-top: -9px; } */
.task-attr-holder .right-task .block.reopened .checked-icon:after{ background:#595BCE; }

.task-attr-holder .right-task .active-block .timestamp-h { font-size: 11px; opacity: 0.7; padding-left: 35px; }

.task-attr-holder .right-task .block .checked{ margin:0; padding:5px 15px; width:90%; min-height:31px; box-sizing:border-box; position: relative; }
.task-attr-holder .right-task .block .checked p{ font-size:14px; line-height:1.4; color:#000;  margin:0; padding:0; padding-left: 35px; }

.task-attr-holder .right-task .block .checked .wr-text{ text-decoration:underline; cursor:pointer; }

.perform-activity-label { position: relative; }

.webhook-modal .ant-modal-body table{ width:100%; border:1px solid rgba(0,0,0,.1); }
.webhook-modal .ant-modal-body table tr{ transition:all 250ms ease-in-out; }
.webhook-modal .ant-modal-body table tr:hover{ background:#E8F7FE; }
.webhook-modal .ant-modal-body table tr td{ padding:7px 12px; }
.webhook-modal .ant-modal-body table tr td.label{ width:30%; }
.webhook-modal .ant-modal-body table tr td.value{ width:70%; }

.task-attr-holder .right-task .block.change .checked .df{ margin:0; }
.task-attr-holder .right-task .block.change .checked .df .profile{ display:inline-block; }
.task-attr-holder .right-task .block.change .checked .df .profile_pic.round{ display:inline-block; vertical-align: middle; position:relative; top:-1px; }
.task-attr-holder .right-task .block.change .checked .df .profile_name{ display:inline-block; }
.task-attr-holder .right-task .block.change .checked .df .text{ padding-left:5px; }
.task-attr-holder .right-task .block.change .checked span{ display:inline; }

.stage_content{ display:inline-block; text-align: center; margin:0; padding:2px 5px; position:relative; }
.stage_content .color_fill{ width:9px; height:9px; right:7px; }

.checked blockquote{ display: block; font-size:14px; line-height:16px; color:#454545; font-weight:600; margin:10px 0 0; padding:0 0 0 18px; background:url(./assets/images/activity-comment-icon.svg) no-repeat left 3px; background-size:13px; }


.task-attr-holder .right-task .ant-tabs .ant-tabs-tabpane{ padding:15px 15px !important; }

.task-attr-holder .attribute-delete{ position: absolute; width:100%; left:0; bottom:54px; background:#fdefef; }
.task-attr-holder .attribute-delete .delete-btn{ display:block; width:100%; height:auto; font-size:13px; font-weight:600; margin:0; padding:15px 0; border-radius:0; background:none; border-top:1px solid #f9f9f9; }
.task-attr-holder .attribute-delete .delete-btn:hover{ color:#ff4d4f; }

.trigger-moadal ul li{ display:flex; align-items:center; justify-content:space-between; padding: 0 0 15px 0; border-bottom: 1px solid rgba(0,0,0,.1); margin: 0 0 15px 0; position: relative; }
.trigger-moadal ul li:last-child{ border-bottom:none; }
.trigger-moadal ul li .text-block{ width:85%; }
.trigger-moadal ul li .edit-btn{ position: absolute; width: 35px; right:0; top:0; background:none; border: none; box-shadow: none; }
.trigger-moadal ul li .edit-btn .ant-btn{ width:100%; height:100%; padding:0; background:none; border: 1px solid rgba(0,0,0,.1); border-radius: 0; margin: 0 0 2px 0; }
.trigger-moadal ul li .edit-btn .ant-btn.d_btn{ color:#F7444D; margin-bottom:0; }

.trigger-list .ant-modal-body{ padding:10px 24px; }
.trigger-moadal ul.trigger-list li{ display:block; margin:0; padding: 15px; cursor:pointer; transition:all 200ms ease-in-out; }
.trigger-moadal ul.trigger-list li:hover{ background:#f9f9f9; }
.trigger-moadal ul.trigger-list li .icon-box{ display:inline-block; width:25px; height:25px; background:#fff; border-radius:50%; box-shadow:0 1px 3px rgba(0,0,0,.2); text-align:center; margin:0 10px 0 0; }
.trigger-moadal ul.trigger-list li .icon-box .anticon{ position: relative; top: 2px; }
.trigger-moadal ul.trigger-list li p{ margin-bottom:0; }


.trigger-moadal .no-data{ margin:0; padding:30px 0; text-align:center; }
.trigger-moadal .no-data img{ width:225px; height:auto; margin-bottom:35px; }
.trigger-moadal .no-data p{ font-size:15px; line-height:1.4; color:#333; margin:0; padding:0; }

.trigger-list .trigger-add-btn{ position: absolute; right:30px; top:13px; }
.trigger-list .trigger-add-btn .attr-add-btn{ border:none; box-shadow:none; outline:none; font-weight:600; }

.g_bg{ display:inline-block; font-size:13px; color:#000; border:1px solid rgba(46, 204, 113, .2); background:rgba(46, 204, 113, .2); padding: 0 5px; margin-right: 3px; }

.y_bg{ display:inline-block; font-size:13px; color:#000; border:1px solid rgba(254, 241, 96, .7); background:rgba(254, 241, 96, .2); padding: 0 5px; margin-right: 3px; }

.attr-btn{ background:none; width: 100%; border-radius: 5px; color: #000; font-weight:600; position: relative; font-size: 14px; }
.attr-btn .tig-btn{ background: #f4f4f4; border: none; width: 100%; text-align: left; padding: 5px; padding-left: 10px; box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.01); outline: none; }
.attr-btn button:focus{ background: #EEE; }
.attr-btn .attr-add-btn { display: inline-block; width:auto; font-size: 14px; color: #111; font-weight: 700; margin:15px 0 0 0; padding: 6px; background: #fff; border: 1px solid #111; border-radius: 3px; }

.ant-btn.main-cta { position: absolute; bottom: 0; left: 0; right: 0; width: 100%; height: 50px; border-top-left-radius: 0px; border-top-right-radius: 0px; font-weight: 700; }
.ant-btn.main-cta:disabled{ background: #808080 !important; opacity:.5; }

.attribute-block{ margin-bottom: 30px; }
.attribute-block .ant-switch-checked{ background:#3235F5; }

.attribute-block .ant-select-selection{ border:1px solid #D1D1D0; }
.attribute-block .ant-select-selection:hover{ border: 1px solid #3235F5; }
.attribute-block .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.attribute-block .ant-input{ border:1px solid #D1D1D0; }
.attribute-block .ant-input:hover{ border: 1px solid #3235F5; }
.attribute-block .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.attribute-block .add-btn{ margin:0; padding:5px 0 0 0; }
.attribute-block .add-btn .ant-btn{  font-weight:600; }

.attribute-block .add-btn a{ display: inline-block; font-size: 14px; line-height: 14px; color: #111; font-weight: 700; margin: 0; padding:6px; background: #fff; border: 1px solid #111; border-radius: 3px; }

.attribute-block .ant-btn-danger{ font-size: 14px; font-weight:500; color:#000; background:none; box-shadow:none; outline:none; border:none; padding:7px 0; }
.attribute-block .ant-btn-danger .anticon{ color:red; }
.attribute-block .ant-btn-danger:hover{ color: red; }
.attribute-block .ant-btn-danger:disabled{ color: gray; }
.attribute-block .ant-btn-danger:disabled .anticon{ color: gray; }

.attribute-block .days-field{ width:100%; margin:0; padding:0; display:flex; }
.attribute-block .days-field label{ display:block; font-size:14px; font-weight:600; color:#333; padding-bottom:5px; }
.attribute-block .days-select{ width:100%; }
.attribute-block .days-tag{ width:55%; padding:0 0 0 5px; box-sizing:border-box; }
.attribute-block .days-tag ul{ margin:0; padding:0; display:flex; flex-flow: wrap; }

.attribute-block .days-tag .days-input{ padding:0; margin:0 0 8px 0; }
.attribute-block .days-tag .tag-block{ margin:0; padding:0; }
.attribute-block .days-tag .tag-block .ant-tag{ margin-bottom:5px; }

.attribute-block .ant-checkbox-wrapper{ display:block; margin:0 0 18px 0; font-size: 15px; line-height:1; font-weight:600; }
.attribute-block .ant-checkbox-wrapper:last-child{ margin-bottom:0; }
.attribute-block .ant-checkbox-wrapper .ant-checkbox-inner{ width:30px; height:30px; border: 1px solid rgba(0,0,0,.5); border-radius:5px; }
.attribute-block .ant-checkbox-checked .ant-checkbox-inner{ border-color: #3235F5 !important; background:#3235F5; }
.attribute-block .ant-checkbox-inner::after{ left:33%; width: 8.71428571px; height: 12.14285714px; }
.attribute-block .ant-checkbox-wrapper span{ padding-left:12px; padding-right:0; }
.attribute-block .ant-checkbox-wrapper span.ant-checkbox{ padding-left:0; }
.attribute-block .ant-checkbox-checked:after{ display:none; }


.attribute-block .ant-checkbox-wrapper .ant-btn{ margin:0; font-size:13px; line-height:1; color:#3235F5; font-weight:600; box-shadow:none; outline:none; border:none; border-radius:0; height:auto; padding:5px 0; }
.attribute-block .ant-checkbox-wrapper .ant-btn:focus{ box-shadow:none; outline:none; }
.attribute-block .ant-checkbox-wrapper .ant-btn span{  padding-left:0; }
.attribute-block .ant-checkbox-wrapper .ant-btn:hover{ color:#1404D6; }

.attribute-block .ant-radio-wrapper{ vertical-align:text-top; }
.attribute-block .ant-radio-wrapper .ant-btn{ width: auto; font-size:13px; line-height:1; color:#3235F5; font-weight:600; box-shadow:none; outline:none; border:none; border-radius:0; height:auto; margin:0; padding:0; margin-left:24px; }
.attribute-block .ant-radio-wrapper .ant-btn:focus{ box-shadow:none; outline:none; }
.attribute-block .ant-radio-wrapper .ant-btn span{  padding-left:0; }


.attribute-block .ant-checkbox-wrapper.ant-checkbox-wrapper-checked{ display:flex; align-items:center; } 

.attribute-block .bg-color-list{ display:flex; margin:0; padding:0; }
.attribute-block .bg-color-list li{ width:25px; height:25px; border-radius:50%; margin:0 6px 0 0; padding:0; text-align:center; }
.attribute-block .bg-color-list li:last-child{ margin-right:0; }
.attribute-block .bg-color-list li.active{ background:url(./assets/images/color-tick.png) no-repeat center; background-size:12px; }

.attribute-block .label-field{ margin:0 0 5px 0; padding:1px 9px; position: relative; display:flex; align-items:center; justify-content:space-between; background:#efefef; }
.attribute-block .label-field:hover{ background:rgba(0,0,0,.1); }

.group-select-box{ display:flex; margin:0; padding:0; }
.group-select-box .ant-btn{ font-size: 14px; line-height:1; font-weight: 500; margin:0 0 0 7px; padding:0 15px; background:none; box-shadow:none; outline:none; }

.right-side .attribute-block h3{ float:inherit; line-height:1.4; }
.right-side .attribute-block a{ display:inline-block; font-size:15px; color:#000; border:1px solid rgba(0,0,0,.1); margin:0; padding:7px 12px; border-radius:3px; }
.right-side .attribute-block a .anticon{ padding-left:35px; }
.right-side .attribute-block a[disabled]{ color:gray; }

.right-side .attribute-block a.hyper-text{ border:none; border-bottom:1px solid transparent; margin:0; padding:0; border-radius:0; line-height:1; font-weight:500; }
.right-side .attribute-block a.hyper-text:hover{ border:none; }

.right-side .attribute-block .p-text{ display:block; margin:0; padding:15px 0 0 0; }


.task-attr-holder-stage .attribute-block .ant-checkbox-wrapper{ display:flex; align-items:center; }
.task-attr-holder-stage .attribute-block .ant-checkbox-wrapper span{ flex:1; }
.task-attr-holder-stage .attribute-block .ant-checkbox-wrapper span.ant-checkbox{ flex:inherit; }

.task-attr-holder-stage .attribute-block .check-label-fill{ display:flex; align-items:center; margin:8px 0 0 0; padding:0; }
.task-attr-holder-stage .attribute-block .check-label-fill .ant-switch-inner{ padding-left:0; }


.task-attr-holder-stage .attribute-block .ant-checkbox-wrapper .ant-btn span{ padding:0; }

.task-attr-holder-stage .attribute-block .ant-checkbox-wrapper .ant-checkbox-inner{ background-color:rgba(24,144,255,.1); }
/* .task-attr-holder-stage .attribute-block .ant-checkbox-wrapper:hover .ant-checkbox-inner{ background-color:rgba(24,144,255,.7); } */
.task-attr-holder-stage .ant-checkbox-inner:after{ transform: rotate(45deg) scale(1) translate(-50%, -50%); opacity:1; }


.stage-condition-editor .and-block { float:inherit; }
.stage-condition-editor .and-block .conditions-field{ float: inherit; }

.current-dropdown { border-right:none; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important; }
.current-dropdown .ant-menu-item{ color:#000 !important; }
.current-dropdown .ant-menu-item:hover{ color:#40a9ff !important; }
/* .current-dropdown .ant-menu-item.ant-menu-item-active:hover{ color:#000 !important; } */

.stage-condition-editor .ant-modal-header{ padding-top:55px !important; }
.stage-condition-editor .modal_heading{ position: absolute; width:100%; top:12px; left:0; text-align:center; margin:0; padding:0 30px; }

.stage-condition-editor.add-condition-modal .ant-modal-header{ padding-top:20px !important; }

/*=== Button Type ===*/
.attribute-block .btn-type{ margin:0; padding:0; }
.attribute-block .btn-type .ant-radio-button-wrapper:hover{ color: rgba(0, 0, 0, 0.65); background:#f1f1f1; }
.attribute-block .btn-type .ant-radio-button-wrapper-checked{ border:1px solid transparent; box-shadow:none; }

.stage-radio-block{ margin:0; padding:0; }
.stage-radio-block h5{ font-size:13px; line-height:1; color:#080705; font-weight:500; margin:0 0 15px 0; padding:0; }
.stage-radio-block h5 .color-dotted{ display:inline-block; width:7px; height:7px; border-radius:50%; background:#00DE3C; margin:0 4px 0 0; padding:0; position: relative; top: -1px; }

.stage-radio-block .approve-radio{ margin:0; padding:0; }
.stage-radio-block .approve-radio .ant-radio-wrapper{ display:block; font-size: 14px; line-height: 1; color: #000; font-weight: 500; margin: 0 0 15px 0; }
.stage-radio-block .approve-radio .ant-radio-wrapper:last-child{ margin-bottom:0; }
.stage-radio-block .approve-radio .ant-radio{ vertical-align:middle; }
.stage-radio-block .approve-radio .ant-radio-inner{ width:25px; height:25px; border: 1px solid rgba(0,0,0,.5); background: #EBF3FF; }
.stage-radio-block .approve-radio .ant-radio-wrapper-checked .ant-radio-inner{ background:#09B1A5; }
.stage-radio-block .approve-radio .ant-radio-inner:after{ width:11px; height:11px; left:0; right:0; margin:0 auto; display: inline-block; top: 50%; transform: translateY(-50%); opacity:1; background:#fff; border-radius:50%; }

.stage-radio-block h5 .color-dotted.danger{ background:#FF0A0A; }

.list-aprj{ margin:0; padding:0; }
.aprj-box{ display:flex; align-items: center; margin:0 0 15px 0; padding:0; }
.aprj-box .cdn-btn{ font-size: 13px; line-height: 1; color: #3235F5; font-weight: 600; box-shadow: none; outline: none; border: none; border-radius: 0; height: auto; margin:0; padding:5px 10px; }

.attribute-block.user-attribute .add-btn{ border:1px solid rgba(0,0,0,.1); border-radius:50%; width:30px; height:30px; margin:0; padding:0; display:flex; align-items:center; justify-content:center; margin-top:5px; }
.attribute-block.user-attribute .add-btn .anticon{ line-height:0; }
.attribute-block.user-attribute .add-btn:hover{ box-shadow:0 3px 8px rgba(0,0,0,.2); }

.attribute-block .add-cnd{ font-size: 13px; line-height: 1; color: #3235F5; font-weight: 600; box-shadow: none; outline: none; border: none; border-radius: 0; height: auto; margin: 0; padding:7px 10px; cursor: pointer; }



/*=== Button Type ===*/

.wrkf-element{ margin:0; padding:15px 0 0 0; }
.wrkf-element .attribute-block{ position: relative; margin-bottom:25px; }
.wrkf-element .attribute-block .label-txt{ padding-bottom:12px; }
/* .wrkf-element .attribute-block .label-txt span, .webhook-sec span{ opacity:.6; font-weight: 400; font-size: 10px;
    text-transform: uppercase;
    padding: 2px 6px;
    background: rgba(0,0,0,0.1);
    border-radius: 2px; margin-left: 2px; margin-top: -1px; } */

.webhook-sec span.sub-label{ display:inline-block; opacity:.6; font-weight: 400; font-size: 11px; padding: 2px 6px; border-radius: 2px; text-transform: uppercase; background:rgba(0,0,0,.1); }
.wrkf-element .attribute-block .label-txt span.sub-label{ opacity:.6; font-weight: 400; font-size: 11px; padding: 2px 6px; border-radius: 2px; margin-left:3px; background:rgba(0,0,0,.1); text-transform: uppercase; }


.wrkf-element .attribute-block .choose-item{ position: absolute; top:-4px; right:0; }
.wrkf-element .attribute-block .choose-item .ant-radio-wrapper{ margin-right:18px; }
.wrkf-element .attribute-block .choose-item .ant-radio-wrapper:last-child{ margin-right:0; }
.wrkf-element .attribute-block .choose-item span.ant-radio + *{ padding-right:0; }

.wrkf-element .attribute-block .up-field{ margin-bottom:10px; }
.wrkf-element .attribute-block .ant-calendar-picker, .wrkf-element .attribute-block .ant-time-picker { width:100%; }

.wrkf-element .attribute-block .choose-field input[type="file"]:disabled{ cursor:not-allowed; }

.wrkf-element .attribute-block .ant-checkbox-wrapper .ant-checkbox-inner{ width:17px; height:17px; border-radius:2px; }
.wrkf-element .attribute-block .ant-checkbox-inner::after{ width: 5.71428571px; height: 9.14285714px; left: 25%; }
.wrkf-element .attribute-block .ant-checkbox-wrapper span{ font-weight:500; }

.wrkf-element .d-flex{ display:flex; margin:0 -8px; }

.wrkf-element .d-flex .up-field{ width:50%; margin:0; padding:0 8px; }
.wrkf-element .d-flex .bottom-field{ width:50%; padding:0 8px; }

.wrkf-element .table-field{ margin:0 0 15px 0; padding:0 0 6px 0; white-space:nowrap; overflow:auto; }
.wrkf-element .table-field table{ width:100%; }
.wrkf-element .table-field table thead tr th{ border:1px solid rgba(0,0,0,.1); padding:5px 8px; font-size:13px; color:#212121; }
.wrkf-element .table-field table tbody tr td{ border:1px solid rgba(0,0,0,.1); padding:5px 8px; font-size:13px; color:#212121; }

.wrkf-element .attribute-block .map-btn{ margin-left:12px; }

.chs-top{ margin:0; padding:0; display:flex; align-items:center; justify-content:space-between; }
.chs-top span{ display:inline-block; max-width:250px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; margin-right:15px; }
.chs-top .remove-btn{ color:red; }




/*========== Tasks-Section End =========*/

/*========== Useer-Section Start =========*/
.admin-section{ margin:0; padding:0; }
.admin-section .thumb-nail{ margin:0 0 35px; padding:0; }
.admin-section .thumb-nail h6{ font-size:13px; line-height:20px; color:#0F0202; font-weight:400; text-transform:uppercase; margin:0; padding:0 0 7px 0; }
.admin-section .thumb-nail .box{ margin:0; padding:0 10px; background:#fff;min-height:87px; display:flex; align-items:center; border-radius: 10px; border:solid 1px #DDD;}
.admin-section .thumb-nail .box .img-block{ margin:0 15px 0 0; padding:0; width:50px; height:50px; border-radius:50%; overflow: hidden; }
.admin-section .thumb-nail .box .img-block img{ width:100%; height:100%; object-fit:cover; }

.admin-section .thumb-nail .box .email-address{ margin:0; padding:0; }
.admin-section .thumb-nail .box .email-address h2{ font-size:16px; line-height:18px; color:#0F0202; font-weight:600; margin:0; padding:0 0 5px 0; }
.admin-section .thumb-nail .box .email-address h3{ font-size:14px; line-height:16px; color:#868585; font-weight:400; margin:0; padding:0; word-break: break-all; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.admin-section .thumb-nail .box .email-address h5{ font-size:11px; line-height:13px; color:#CECECE; font-weight:400; font-style: italic; margin:0; padding:8px 0 0 0; }
.admin-section .thumb-nail .box .email-address span{ display:block; font-size:11px; }


.admin-section .thumb-nail .box{ margin-bottom:15px; position: relative; }
.admin-section .thumb-nail .box .ad-user-btn{ position: absolute;width: 42px; top:0; bottom:0; right:3px; display: flex; flex-flow: column; height: 100%; border-left: 1px solid rgba(0,0,0,.1); }
.admin-section .thumb-nail .box .ad-user-btn .ant-btn{ position: absolute; top:0; height:50%; background:none; border-radius:0; box-shadow:none; transition:all 100ms ease-in-out; border-top-right-radius: 10px; }
.admin-section .thumb-nail .box .ad-user-btn .ant-btn:hover{ background:rgba(0,0,0,.03); }

.admin-section .thumb-nail .box .ad-user-btn .edit-btn i{ color:#333; }
.admin-section .thumb-nail .box .ad-user-btn .ant-btn-danger{ top:auto; bottom:0; border-top:1px solid rgba(0,0,0,.1); border-bottom-right-radius: 10px; border-top-right-radius: 0px; }
.admin-section .thumb-nail .box .ad-user-btn .ant-btn-danger i{ color:#f5222d; }

/*== Modal Satrt ==*/
/* .ant-modal{ width:500px !important; } */
.ant-modal .ant-modal-content{ padding:35px 35px; margin:0; box-shadow: none; }
.ant-modal.modal-fix .ant-modal-content{ padding:20px; }
.ant-modal .ant-modal-content .ant-modal-header{ margin:0; padding:0; border: none; }
.ant-modal .ant-modal-title{ font-size:14px; line-height:27px; font-weight:700; color:#1A0D0D; margin:0; padding:0;  }

.ant-modal .ant-modal-body{ border:none; }
.ant-modal.modal-fix .ant-modal-body .ant-select{ width:50%; }

.ant-modal .ant-modal-body .box{ margin:0; padding:0; }
.ant-modal .ant-modal-body label{ display:block; font-size:13px; line-height:15px; font-weight:600; color:#1A0D0D; margin:0; padding:0 0 9px 0; }
.ant-modal .ant-modal-body label.condition-label{ font-weight:400; }

.ant-modal .ant-modal-body .ant-input{ background:none; height:37px; box-shadow:none; outline:none; margin:0; }
.ant-modal .ant-modal-body .ant-input.ant-input-textarea{ height:65px; }
.ant-modal .ant-modal-body .box .ant-input:focus{ border:1px solid #707070; box-shadow:none; outline:none; }
.ant-modal .ant-modal-body .box ul{ display:flex; }
.ant-modal .ant-modal-body .box ul li{ font-size:13px; line-height:15px; font-weight:400; color:#1A0D0D; display:flex; flex:1; justify-content:center; align-items:center; min-height:50px; border:1px solid #DDDDDD; margin:0 10px 0 0; padding:0; }

.ant-modal .ant-modal-body .box ul li.active{ border:1px solid #63CF6D; background:#f9f9f9; }
.ant-modal .ant-modal-body .box ul li:last-child{ margin-right:0; }

.ant-modal.rolemodal .ant-modal-body table label{ padding-bottom:0; }

.ant-modal.rolemodal .select-box{ margin:0; padding:0; }
.ant-modal.rolemodal .select-box .block{ border:1px solid #ccc; margin:0 0 25px 0; padding:25px 25px 30px; }
.ant-modal.rolemodal .select-box .select-form{ margin:0; padding:0; }

.ant-modal.rolemodal .select-box .select-form .designation-sel{ margin-bottom:18px !important; }
.ant-modal.rolemodal .select-box .select-form:last-child .designation-sel{ margin-bottom:0 !important; }

.ant-modal.rolemodal .select-box .add-btn{ margin:25px 0 7px; padding:0; text-align:center; }
.ant-modal.rolemodal .select-box .add-btn .btn{ width:30px; height:30px; border-radius:50%; border:1px solid rgba(0,0,0,.2); text-align:center; box-shadow:none; outline:none; padding:1px 0 0 0; transition:all 150ms ease-in-out; }
.ant-modal.rolemodal .select-box .add-btn .btn:hover{ border:1px solid rgba(0,0,0,.7); }
.ant-modal.rolemodal .select-box .add-btn .btn .anticon{ font-size:14px; line-height:0; }

.ant-modal.rolemodal .ant-table-thead > tr > th, .ant-modal.rolemodal  .ant-table-tbody > tr > td{ padding: 8px 15px;  }
.ant-modal.rolemodal  .ant-table-tbody > tr > td{ white-space:nowrap; }
.ant-modal.rolemodal  .ant-table-tbody > tr > td:last-child{ border-right:none; }

.ant-modal.rolemodal .ant-modal-body .ant-form{ margin-bottom:30px; }
.ant-modal.rolemodal .ant-modal-body .ant-form .ant-form-item-label{ padding-bottom:0; }

.perform-performer{ margin:0; padding:0 0 40px 0; }
.perform-performer b{ font-size:16px; }
.perform-performer .sel-user{ display:block; }
.perform-performer .no-found{ font-size:14px; line-height:15px; font-weight:600; color:#1A0D0D; margin:0;     padding: 15px 0; text-align: center; }

.ant-modal.rolemodal.adduser-modal .ant-modal-body{ padding-bottom:55px; }
.ant-modal.rolemodal .ant-modal-body .box{ padding-bottom:30px; }


.ant-modal.rolemodal .ant-modal-body .box .ant-col-24{ padding-bottom:18px; }


.adduser-modal .ant-steps{ margin-bottom:30px; }
.adduser-modal .ant-steps .ant-steps-item-process .ant-steps-icon{ color:#fff; }


.ant-modal.rolemodal .user-item{ margin:0; padding:0; margin-bottom: 20px; }
.ant-modal.rolemodal .user-bottom{ margin:0; padding:0; }

.ant-modal.rolemodal .user-item .add_empty{ padding-top:0; padding-bottom:18px; }

.ant-modal.rolemodal .user-item .search-block{ margin-bottom:25px; }

.ant-modal.rolemodal .user-string{ float: left; width: 25%; margin-bottom: 20px; margin:0 0 5px 0; padding:0 10px; position: relative; text-align:center; cursor: pointer; }
.ant-modal.rolemodal .user-string .user-avatar{ width:64px; height:64px; line-height:64px; border-radius:20px; background:#999; margin:0 auto 10px; padding:0; font-size:22px; color:#fff; font-weight:400; }

.ant-modal.rolemodal .user-string .user-name{ color:#000; font-size:12px; line-height:12px; font-weight:600; margin:0; padding:0 0 2px 0; }
.ant-modal.rolemodal .user-string .user-email{ color:#8e8d8d; font-size:11px; line-height:15px; font-weight:400;     white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }

.ant-modal.rolemodal .user-string.selected .user-avatar{ position: relative; }
.ant-modal.rolemodal .user-string.selected .user-avatar:after{ content:""; position: absolute; width:17px; height:17px; border-radius:50%; right:5px; bottom:7px; background:url(./assets/images/adduser-tick.svg) no-repeat center #5CCE65; background-size:8px; }

.ant-modal.rolemodal .user-string.selected .profile_pic.round{ position: relative; }
.ant-modal.rolemodal .user-string.selected .profile_pic.round:after{ content:""; position: absolute; width:17px; height:17px; border-radius:50%; right:2px; bottom:-2px; background:url(./assets/images/adduser-tick.svg) no-repeat center #5CCE65; background-size:8px; }

.ant-modal.rolemodal .next-btn{ position: absolute; width:100%; left:0; bottom:0; font-size: 14px; font-weight:600; padding: 15px 35px; height: auto; border-radius:0; }

.ant-modal.rolemodal .back-next{ position: absolute; width:100%; left:0; bottom:0; display:flex; }
.ant-modal.rolemodal .back-next .ant-btn{ display:inline-block; width:50%; font-size: 14px; font-weight:600; text-transform:uppercase; padding: 15px 35px; height: auto; border-radius:0; }
.ant-modal.rolemodal .back-next .ant-btn.bk-btn{ background:#ccc; color:#1890FF; }

.ant-modal.rolemodal .back-next .ant-btn.save-btn{ width:100%; }

.ant-modal.rolemodal .ant-modal-body .designation-sel{ margin-bottom:52px; }
.ant-modal.rolemodal.select-role-modal .ant-modal-body .designation-sel{ margin-bottom:0; }

.ant-modal.rolemodal.select-role-modal .ant-modal-body .designations-list{ margin-bottom:15px; }

.ant-modal .ant-modal-body .check-box{ margin:15px 0 0 0; padding:0; }
.ant-modal .ant-modal-body .check-box [type=checkbox]{ display:none; opacity:0; }
.ant-modal .ant-modal-body .check-box [type=checkbox] + label{ position: relative; padding:0 0 0 25px;  }
.ant-modal .ant-modal-body .check-box [type=checkbox] + label:before{ content:""; position: absolute; width:20px; height:20px; border:1px solid #DDDDDD; left: 0; top: 0; bottom: 0; margin: auto 0; }
.ant-modal .ant-modal-body .check-box [type=checkbox]:checked + label:after{ content:""; position: absolute; width:20px; height:20px; border:1px solid #DDDDDD; left: 0; top: 0; bottom: 0; margin: auto 0; background:url(./assets/images/check-symbol.svg) no-repeat center; background-size:11px; }
.ant-modal .ant-modal-body .btn-box{ margin:45px 0 0 0; padding:0; text-align:center; }
.ant-modal .ant-modal-body .btn-box .btn{ display:inline-block; font-size:12px; line-height:14px; font-weight:700; color:#4EB52F; text-transform:uppercase; margin:0; padding:10px 35px; border:1px solid #4AC32D; border-radius:3px; height:auto; transition:all 600ms ease-in-out; }
.ant-modal .ant-modal-body .btn-box .btn:hover{ background:#4AC32D; border:1px solid transparent; color:#fff; }

.ant-modal .ant-modal-body .create-profile .ant-select-selection--single{ height:50px; border-radius:0; }
.ant-modal .ant-modal-body .create-profile .ant-select-selection--single .ant-select-selection__rendered{ line-height: 43px; }
.ant-modal .ant-modal-body .create-profile .ant-select-selection--single:hover{ border: 1px solid #DDDDDD; }
.ant-modal .ant-modal-body .create-profile .ant-select-selection--single:focus{ border: 1px solid #707070; box-shadow:none; outline:none; }
.ant-modal .ant-modal-body .create-profile .ant-select-selection--single .ant-select-selection__rendered{ line-height:50px; }

.ant-modal .ant-modal-footer{ margin:20px 0 0 0; padding:0; border:none; }

.ant-modal-close{ top:42px; right:30px; }
.ant-modal-close-x{ width:30px; height:30px; }
.ant-modal-close-x .anticon{ font-size:14px; vertical-align:top; }

.addrecord_modal .ant-modal-body{ padding-bottom:55px; position: relative; }
.addrecord_modal .ant-modal-body .name-sec li{ padding-bottom:25px; }
.addrecord_modal .ant-modal-body .name-sec li .ant-radio-wrapper{ font-weight:400; }
.addrecord_modal .ant-modal-body .name-sec li .ant-checkbox-wrapper{ font-weight:400; }
.addrecord_modal .ant-modal-body .field_look{ padding:0; }
.addrecord_modal .ant-modal-body .field_look .ant-form-item-label{ padding-bottom:0; }
 
.addrecord_modal .ant-modal-body .field_look .ant-calendar-picker{ width:100%; }
.addrecord_modal .ant-modal-body .field_look .ant-time-picker{ width:100%; }

.addrecord_modal .modal-btn{ position: absolute; width:100%; height:50px; border-top-left-radius: 0px; border-top-right-radius: 0px; left:0; bottom:0; margin:0; padding:0; }
.addrecord_modal .modal-btn .ant-btn{ display:block; width:100%; height:auto;  font-size: 14px; font-weight:600; background:#3235F5; margin:0; padding: 15px 35px;     border-radius: 0 0 3px 3px; }
.addrecord_modal .modal-btn .ant-btn:hover{ background:#1404D6; }
 
.addrecord_modal .ant-modal-body .name-sec li .field-content{ padding:0 !important; overflow:inherit; }
.addrecord_modal .ant-modal-body .name-sec li .ant-form-item{ margin-top:0; margin-bottom:0; }

.create-profile{ padding-bottom:25px; }
.create-modal .create-profile .ant-radio-group{ display:flex; padding-bottom:16px; }
.ant-modal.create-modal .ant-modal-body label{ padding:0 25px 9px 0; }
.ant-modal.create-modal .ant-modal-body .create-profile .ant-select-selection{ height:37px; border-radius:4px; border:1px solid #D1D1D0; }
.ant-modal.create-modal .ant-modal-body .create-profile .ant-select-selection:hover{ border:1px solid #3235F5; }
.ant-modal.create-modal .ant-modal-body .create-profile .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }
.ant-modal.create-modal .ant-modal-body .create-profile .ant-select-selection .ant-select-selection__rendered{ line-height:37px; }

.pdf-block{ display:flex; align-items:center; justify-content:space-between; margin:0; padding:0; }
.pdf-block .group-box { flex:1; display:flex; align-items:center; margin:0; padding:0; }
.pdf-block .pdf-text{ display:inline-block; white-space: nowrap; font-size:14px; line-height:1; color:#000; margin:0; padding:0 0 0 12px; text-overflow: ellipsis; max-width:315px; overflow: hidden; vertical-align: middle; }
.pdf-block .dwl_btn{ border:1px solid #3235F5; background:none; height:36px; font-size: 14px; line-height:0; font-weight:600; color:#3235F5; transition:all 200ms ease-in-out; }

.ant-radio-inner{ border:1px solid #D1D1D0; }
.ant-radio-wrapper:hover .ant-radio-inner{ border:1px solid #3235F5; }
.ant-radio-inner:after{ background:#75DDDD; }
.ant-radio-checked .ant-radio-inner{ border:1px solid #3235F5; }

.workspace-modal .ant-modal-footer .ant-btn{ width:50%; margin:0; background:#EA4335; border-radius:0;  font-size:14px; line-height:1; font-weight:500; color:#fff; height:50px; }
.workspace-modal .ant-modal-footer .ant-btn:hover{ background:#fb3929; }
.workspace-modal .ant-modal-footer .ant-btn.ant-btn-primary{ background:#3235F5; }
.workspace-modal .ant-modal-footer .ant-btn.ant-btn-primary:hover{ background:#1404D6; }

.intd-form .name-sec li{ margin-bottom:25px; }
.intd-form .name-sec li .addon_box{ height:auto; display:flex; align-items:center; justify-content:center; padding-top:0; padding-bottom:0; }

.intd-form .name-sec li .ant-radio-group{ display:flex; flex-flow:column; }
.intd-form .name-sec li .ant-radio-group .ant-radio-wrapper{ margin-bottom:10px; }
.intd-form .name-sec li .ant-radio-group .ant-radio-wrapper:last-child{ margin-bottom:0; }

.intd-form .name-sec li .ant-checkbox-group{ display:flex; flex-flow:column; }
.intd-form .name-sec li .ant-checkbox-group .ant-checkbox-wrapper{ margin:0 0 10px; }
.intd-form .name-sec li .ant-checkbox-group .ant-checkbox-wrapper:last-child{ margin-bottom:0; }
.intd-form .name-sec li .field-content a{ display:block; }
.intd-form .name-sec li .field-content a:disabled{ background: #f9f9f9; cursor:no-drop; }

.intd-form .name-sec li .content_field_element .ant-input-number-input{ color:#333; }

.mrk-as-text{ margin:0; padding:0; }
.mrk-as-text ul{ margin:0; padding:12px 0 0 0; }
.mrk-as-text ul li{ margin:5px 0 0 0; padding:0; }

.mrk-as-text .usr-text{ display:inline-block; margin:0; padding:0 5px 0 0; vertical-align:middle; }
.mrk-as-text p{ display:inline-block; margin:0; padding:0; vertical-align:middle; }
.mrk-as-text p span{ display:inline-block; vertical-align: middle; line-height:1; }


/*========== Useer-Section End =========*/

/*========== Setting-Section Start =========*/
.settings-section{ margin:0; padding:0; }
.settings-section .field-setting{ margin:0 0 35px; padding:0; }
.settings-section .field-setting h2{ font-size:16px; line-height:1.4; color:#000; font-weight:500; margin:0; padding:0 0 10px 0; }
.settings-section .field-setting h2 .ant-btn{ height:auto; }

.settings-section .field-setting .ant-btn{ height: 41px; border:none; border-radius: 0; padding: 0 10px 0 15px; -webkit-box-shadow: none; box-shadow: none; margin-left:0 !important; }
.settings-section .field-setting .ant-btn span{ font-size: 13px; line-height: 15px; color: #fff; font-weight: 400; text-transform: capitalize; margin: 0; padding: 0 15px; }
.settings-section .field-setting .ant-btn.customizebtn-edit{ height:32px; padding:0 15px; border-radius:4px; }


.settings-section .field-setting .email-id{ display:flex; align-items: center; width:75%; margin:0; padding:0; background:#fff; }
.settings-section .field-setting .email-id .ant-select-selection--multiple .ant-select-selection__choice{ font-size:13px; line-height:15px; color:#000; font-weight:400; margin:0 5px; padding: 7px 35px 7px 15px; background:#EDEDED; border-radius:20px; height:auto; border:none; }
.settings-section .field-setting .email-id .ant-input{ width:100px; height:28px; border:1px solid #D9D9D9; border-radius:5px; box-shadow:none; }
.settings-section .field-setting .email-id .ant-select-selection--multiple{ min-height: 64px; border:1px solid #D9D9D9; border-radius:0; padding:0 10px; display:flex; align-items:center; }
.settings-section .field-setting .email-id .ant-select-selection--multiple .ant-select-selection__rendered > ul > li { margin-top:0; margin-right:10px; }

.settings-section .field-setting .color-list{ margin:0; padding:0; }
.settings-section .field-setting .color-list ul{ display: flex; }
.settings-section .field-setting .color-list ul li{ margin:0; padding:0; width:42px; height:40px; background:#6C93FF; }
.settings-section .field-setting .color-list ul li:nth-child(2){ background:#6ADB61; }
.settings-section .field-setting .color-list ul li:nth-child(3){ background:#FF9021; }
.settings-section .field-setting .color-list ul li:nth-child(4){ background:#FF21C3; }
.settings-section .field-setting .color-list ul li:nth-child(5){ background:#7191B1; }
.settings-section .field-setting .color-list ul li:nth-child(6){ background:#FC0808; }
.settings-section .field-setting .color-list ul li:nth-child(7){ background:#0FD6C7; }
.settings-section .field-setting .color-list ul li.active{ width:55px; height:52px; border:3px solid #fff; box-shadow:0 1px 3px 0 rgba(0,0,0,.1); position: relative; top:-6px; background:url(./assets/images/color-tick.png) no-repeat center; }

.settings-section .field-setting .nft_btn{ border-radius:4px; margin-right:25px; }
.settings-section .field-setting .nft_btn span{ font-weight:600; font-size:14px; }

.settings-section .field-setting .nft_btn.nft_default{ border:1px solid rgba(0,0,0,.1); margin-right:0; }
.settings-section .field-setting .nft_btn.nft_default span{ color:#333; }

.settings-section .field-setting .box{ width:250px; margin:0; padding:0; }
.settings-section .field-setting label{ display:block; font-weight: 500; padding-bottom: 3px; }
.settings-section .field-setting .ant-input{ border:1px solid #D1D1D0; }
.settings-section .field-setting .ant-input:hover{ border: 1px solid #3235F5; }
.settings-section .field-setting .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.settings-section .field-setting .ant-select-selection{ border:1px solid #D1D1D0; }
.settings-section .field-setting .ant-select-selection:hover{ border: 1px solid #3235F5; }
.settings-section .field-setting .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.icon-list{ margin:0; padding:0; }
.icon-list .ant-dropdown-trigger{ display:inline-block; font-size:20px; color: #fff; margin: 0; padding:7px 10px; border-radius:3px; }
.icon-list .ant-dropdown-trigger span{ margin:0 35px 0 0; padding:0; }

.dropdown-icon-list{ max-width:250px; display: flex; align-items:center; flex-flow: wrap; }
.dropdown-icon-list .ant-dropdown-menu-item{ width:20%; text-align:center; padding: 3px; }
.dropdown-icon-list .ant-dropdown-menu-item:hover{ background:none; }
.dropdown-icon-list .ant-dropdown-menu-item .anticon{ font-size:18px; line-height:0; margin:0; }
.dropdown-icon-list .ant-dropdown-menu-item .item{ border:1px solid rgba(0,0,0,.1); padding:8px 0; display:flex; align-items:center; justify-content:center; }
.dropdown-icon-list .ant-dropdown-menu-item:hover .item{ background:#E6F7FF; }

.settings-section.empty-sec{ text-align:center; }

.ntf_all{ margin:0; padding:0 0 45px 0; }
.ntf_all .item_block{ width:585px; margin:0 auto; padding:20px; border-bottom: 1px solid rgba(0,0,0,.1); display:flex; align-items:center; justify-content:space-between; }
.ntf_all .item_block h3{ font-size:17px; line-height:19px; font-weight:400; color: #444; margin: 0; padding:0; }
.ntf_all .item_block .heading-side{ margin:0; padding:0; max-width:385px; }

.ntf_all .item_block .ed_button{ margin:0; padding:0; }
.ntf_all .item_block .ed_button .ant-btn{ height:auto; padding:0 15px; }

.ntf_all .item_block .ed_button .ant-btn.d_btn{ padding-right:0; border-left:1px solid rgba(0,0,0,.2); }
.ntf_all .item_block .ed_button .ant-btn.d_btn:hover{ color:red; }

.addvendor-modal .ant-modal-body .content_field label span{ font-weight:400; }

.settings-section .perform_custom{ margin:0; padding:0; display:flex; align-items:center; }
.settings-section .perform_custom .heading-text h2{ padding:0; }
.settings-section .perform_custom .switch-block{ margin:0; padding:0 0 0 85px; }
.settings-section .perform_custom .switch-block .ant-switch-checked{ background:#3235F5; }

.settings-section .perform_custom .switch-block .customizebtn-edit{ display:inline-block; vertical-align: middle; margin-left: 12px; color:#3235F5; font-weight:500; border:none; box-shadow:none; outline:none; cursor: pointer; }
.settings-section .perform_custom .switch-block .customizebtn-edit:hover{ opacity:.8; }

.settings-section .perform_custom .switch-block a{ display:inline-block; vertical-align: middle; margin-left: 12px; color:#3235F5; font-weight:500; border:none; box-shadow:none; outline:none; cursor: pointer; }
.settings-section .perform_custom .switch-block a:hover{ opacity:.8; }

.settings-section .block{ width:765px; margin:0 0 18px 0; padding:25px 30px; display:flex; align-items:center; justify-content:space-between; border:1px solid rgba(0,0,0,.1); border-radius: 5px; box-shadow: 0 1px 5px 0 rgba(0,0,0,.05); }
.settings-section .block .left-sec{ width:100%; display:flex; align-items:center; margin:0; padding:0; }
.settings-section .block .left-sec .logo-icon{ width:45px; margin:0 15px 0 0; padding:0; }
.settings-section .block .left-sec .logo-icon img{ width:100%; height:auto; }

.settings-section .block .left-sec .desc{ width:80%; margin:0; padding:0; display:flex; align-items:center; justify-content:space-between; }
.settings-section .block .left-sec .desc .heading-text{ display:flex; align-items:center; margin:0; padding:0; }
.settings-section .block .left-sec .desc h2{  font-size:20px; line-height:1.4; font-weight:600; color:#333; margin:0 25px 0 0; padding:0; }
.settings-section .block .left-sec .desc p{ font-size:16px; line-height:1.4; font-weight:400; margin:0; padding:6px 0 0; }
.settings-section .block .add_button .ant-btn{ font-size:15px; font-weight:600; }
.settings-section .block .left-sec .desc .lt-text{ max-width:500px; }

.settings-section.logo_inton .block .left-sec .desc{ display:block; }

.tigger-drawer .block{ padding-bottom:15px; }
.tigger-drawer .block label{ display:block; padding:0 0 5px 0; }
.tigger-drawer .block .b_tag{ margin-top:5px; }

/*========== Setting-Section End =========*/

/*__________ Dropdown Field ___________*/
.previous-block .up-secion .right-side .dropdown-field{ margin:0; padding:0; width:175px; }
.dropdown-field .ant-dropdown-link{ display:block; border:1px solid rgba(0,0,0,.1); height:38px; border-radius:4px; line-height:36px; margin:0; padding:0 15px; position:relative; color:#474747; }
.dropdown-field .ant-dropdown-link .anticon-down{ position: absolute; top: 50%; transform: translateY(-50%); right:10px; }

.dropdown-list{ width:175px; background:#fff; box-shadow:0 2px 8px rgba(0,0,0,0.15); margin:0; padding:0; }
.dropdown-list .search-block{ margin:0; padding:0; }
.dropdown-list .search-block .ant-input{ border-radius:0; border-left:none; border-right:none; }

.dropdown-list .field-text{ margin:0; padding:0; }
.dropdown-list .field-text ul{ margin:0; padding:0; }
.dropdown-list .field-text ul li{ font-size:13px; line-height:1; color:#515659; font-weight:600; margin:0; padding:12px 18px 10px 25px; border-bottom:1px solid rgba(0,0,0,.1); position:relative; }
.dropdown-list .field-text ul li:hover{ background:#E7F7FE; }
.dropdown-list .field-text ul li:last-child{ border-bottom:none; }
.dropdown-list .field-text ul li .field_drag_handle{ opacity:1; width:25px; }

.dropdown-list .field-text ul li .hide-icon{ position: absolute; right: 0; top: 0; bottom: 0; width:25px; height:100%; display: flex; align-items: center; justify-content: center; z-index: 2323; background:none; border:none; border-radius:0; box-shadow:none; outline:none; color:#B5BDC2; }
.dropdown-list .field-text ul li .hide-icon span{ line-height:0; }
.dropdown-list .field-text ul li .hide-icon .anticon-eye-invisible{ line-height:0; font-size:13px; }
.dropdown-list .field-text ul li .hide-icon.active{ color:#333; }

/*========== Previous-Checks End =========*/

/*========== Task-Editor Start =========*/
.back-btn{ margin:0; padding:25px 68px 0; }
.back-btn a{ display:inline-block; font-size:12px; line-height:14px; color:#1A0D0D; font-weight:600; margin:0; padding:0 0 0 25px; position: relative; }
.back-btn a:after{ content:"<"; position: absolute; left:0; top:0; }

.task-lf { border-bottom: solid 1px rgba(255,255,255,0.2); padding:0; cursor: pointer; }

.task-lf .task-input-box{ padding:5px 5px 5px 35px; position: relative; }
.task-lf .task-input-box:hover .field_drag_handle{ opacity:1; }

.task-lf .task-type-section{ display: flex; align-items: center; padding: 5px 20px 5px; background:rgba(255,255,255,.3); height: 40px; font-size: 12px; line-height: 17px; color:#fff; font-weight:600; text-transform: uppercase; position: relative; cursor:pointer; }
.task-lf .task-type-section:hover,.task-lf .task-type-section.active{ color:#000; background:#fff; }
.task-lf.active .task-type-section{ color:#1C507A; background:#fff; }

.task-lf .task-type-section:hover .field_drag_handle{ opacity:1; }    
.task-lf .task-type-section .ant-input{  font-size: 12px; line-height: 17px; color: #fff; font-weight:600; text-transform: uppercase; background:none; border:none; box-shadow:none; } 
.task-lf .task-type-section p{ margin:0; padding:0; }
.task-lf .task-type-section .closed_stage{ position: absolute; right:25px; top: 50%; transform: translateY(-50%); display:flex; align-items:center; }
.task-lf .task-type-section .closed_stage .closed_icon{ margin:0 12px 0 0; padding:0 }
.task-lf .task-type-section .closed_stage .closed_icon img{ width:14px; height:auto; }

.task-lf .task-type-section .closed_stage .color_fill{ position: relative; right:0; top:0; transform: translateY(0); }
.task-lf:hover {background: rgba(0,0,0,0.1);}
.task-lf.selected{ background: rgba(0,0,0,0.3); color: #000; }

.task-input-box input[type="text"],.task-input-box .task-name-h { border:none; height: 50px; cursor: pointer; transition: none; -webkit-transition: none; background: none; color: #FFF; font-weight:600; font-size: 15px; outline:none; box-shadow:none; }
.task-lf.selected .task-input-box input[type="text"]{ font-weight:600; color: #FFF; outline:none; box-shadow:none; border:none; }
.task-lf.selected .task-input-box input[type="text"]:focus{ border:none; outline: none; box-shadow: none; cursor: text; color: #FFF; outline:none; box-shadow:none; border:none; }

.task-lf .another_add{ margin:0; padding:5px 35px; }   
.task-lf .add-task-input{ width:100%; height:35px; border:none; border-radius:3px; box-shadow:none; outline:none; font-size: 14px; font-weight: 500; color: #000; background:none; }
.task-lf .add-task-input:hover { background:rgba(0,0,0,0.3)}
.task-lf .add-task-input:focus{ background:#fff; }

.task-lf .add-task-input::-webkit-input-placeholder { color:rgba(255,255,255,0.5); font-weight:600; }
.task-lf .add-task-input::-moz-placeholder { color:rgba(255,255,255,0.5); font-weight:600; }
.task-lf .add-task-input:-ms-input-placeholder { color:rgba(255,255,255,0.5); font-weight:600; }
.task-lf .add-task-input:-moz-placeholder { color:rgba(255,255,255,0.5); font-weight:600; }

.task-lf .add-task-input:focus::-webkit-input-placeholder { color:#333; }
.task-lf .add-task-input:focus::-moz-placeholder { color:#333; }
.task-lf .add-task-input:focus:-ms-input-placeholder { color:#333; }
.task-lf .add-task-input:focus:-moz-placeholder { color:#333; }

.stg-label{ margin:0; padding:0; }
.stg-label h5{ font-size:14px; line-height:1; color:#fff; font-weight:600; text-transform:uppercase; margin:0; padding:10px 20px; }

.task-input-box .task-name-h { margin-bottom: 0px; display: flex; align-items: center; padding-left: 10px; min-height: 50px; max-height: auto;}
.task-input-box .right-side .mandatory-block .ant-radio-button-wrapper{ font-size:11px; line-height:35px;color:#A8A8A8; font-weight:600; text-transform:uppercase; background:#fff; border-radius:0; width:50px; height:35px; box-shadow:none; text-align: center; padding:0; }
.task-input-box .right-side .mandatory-block .ant-switch-checked{ background:#fff; border-radius:0; width:100px; height:35px; box-shadow:none; }

.task-input-box .upper-text{ display:flex; align-items:center; }
.task-input-box p{ flex:1; max-width:100%;  font-size: 15px; line-height:1; color: #FFF; font-weight:600; margin:0; padding:20px 0; cursor:pointer; }

.right-side h3{ float: left; margin-bottom: 10px; width: 100%; }

.right-side .mandatory-block .ant-radio-button-wrapper-checked{ transform: scale(1.1); border:1px solid #C9C9C9;  color: #FFF; background: #333; border: none; box-shadow: none; border-radius: 4px; top: -1px; }

.right-side .another-field{ margin:0; padding:0; }
.right-side .another-field h6{ font-size:14px; line-height:16px; color:#676767; font-weight:400; margin:0 0 45px 0; padding:0; }
.right-side .another-field h6 span{ display:inline-block; width:25px; height:25px; border-radius:0%; margin-left:20px; }
.right-side .another-field h6 span img{ width:100%; height:100%; }
.change-baser { float: left; width: 100%; position: relative; background: #f4f4f4; padding: 7px 10px 10px; border-radius: 5px; margin-bottom: 10px;}
.change-baser .close-icon{ position: absolute;  right: 10px; top: 50%; transform: translateY(-50%); width: 10px; }
.change-baser h4{ margin: 0; }

.right-side .another-field .add-another{ margin:0; padding:0 0 35px 0; }
.right-side .another-field .add-another h3{ font-size:14px; line-height:16px; color:#676767; font-weight:400; margin:0; padding:0 0 12px 0; }
.right-side .another-field .add-another .change-baser{ margin:0 0 20px 0; padding:0 15px; background:#E5E5E5; min-height:32px; display:flex; justify-content: space-between; align-items:center; }
.right-side .another-field .add-another .change-baser .text-field{ margin:0; padding:0; }
.right-side .another-field .add-another .change-baser .text-field h4{ font-size:12px; line-height:14px; color:#170C0C; font-weight:400; margin:0; padding:0; }
.right-side .another-field .add-another .change-baser .close-icon{ width:6px; margin:0; padding:0; }
.right-side .another-field .add-another .change-baser .close-icon img{ width:100%; height:auto; }

.right-side .another-field .add-another .add-btn{ margin:0; padding:0; }
.right-side .another-field .add-another .add-btn a{ display:inline-block; font-size:12px; line-height:14px; color:#9C9797; font-weight:400; margin:0; padding:0; }
.right-side .delete-btn{ margin:0; padding:0; }
.right-side .delete-btn a{ display:inline-block; font-size:14px; line-height:16px; color:#D63333; font-weight:400; margin:0; padding:0; }

.task-holder-all{ overflow:inherit; padding-bottom:0 !important; }
.task-holder-all .all-task-if{ height:100%; overflow:auto; padding-bottom: 122px; }

.up-fill{ display:flex; align-items:center; justify-content:space-between; margin:0 0 15px 0; padding:0; }
.up-fill h1{ margin:0; padding:0; }
.up-fill .rt-side{ margin:0; padding:0; }
.up-fill .rt-side .edit_button{ background:none; border:none; border-radius:0; box-shadow:none; outline:none; margin:0; padding:8px; }
.up-fill .rt-side .edit_button .anticon-edit{ font-size:18px; }

.add-task{ margin-bottom:0; position: absolute; left:0; right:0; bottom:35px; text-align:center; padding:0px 0; }
.add-task .add-field{ margin:0 auto; padding:0 5px; width:100%; height:68px; display:flex; align-items:center; justify-content:center; background: #fff !important; border-radius:0px; overflow:hidden; color:#5a34a9 !important; cursor: pointer; border:none; box-shadow:none; outline:none; }
.add-task .add-field a{ display:inline-block; font-family: 'Poppins', sans-serif; font-size:14px; line-height:1; color:#fff; font-weight:500; margin:0; position: relative; display: flex; align-items: center; }
.add-task .add-field a p{ font-family: 'Poppins', sans-serif; margin: 0; margin-left:10px; font-size: 14px; }
.add-task .add-field a{ display:inline-block; font-size:14px; line-height:1; color:#fff; font-weight:500; margin:0; position: relative; display: flex; align-items: center; }
.add-task .add-field a span{ font-size:25px; margin-top:0; }
.add-task .add-field .tooltip-btn{ color:#fff !important; }


.add-task .add-field.add-section{ height:auto; background:none; margin:12px 0; }
.add-task .add-field.add-section a{ display:inline-block;  margin:0; padding:2px 12px; background: rgba(255,255,255,.1); border-radius: 5px; color: #fff; transition:all 100ms ease-in-out; }
.add-task .add-field.add-section a:hover{ background: rgba(255,255,255,.2); }

.add-task .add-field.add-section a p{ float:right; font-size: 11px; line-height:24px; font-weight:700; margin-left:10px; }
.add-task .add-field.add-section a span{ font-size:19px; margin-top: 0px; }

li.optionWithId { position: relative; }
li.optionWithId span { display: block;  color: #CCC; font-style: italic }
li.optionWithId:hover span { display: block}
.ant-select-selection-selected-value > span { color: #CCC; font-style: italic; margin-left: 10px;}

.ints-form-field{ margin:0; padding:30px 48px; }
.ints-form-field h4{ font-size:22px; line-height: 1; color: #170C0C; font-weight: 700; margin: 0; padding: 0 0 20px 0; }
.ints-form-field .input-field{ margin:0; padding:0 0 22px 0; }
.ints-form-field .input-field label{ display:block; font-size: 14px; line-height: 1; font-weight: 600; color: #000; margin:0; padding:0 0 10px 0; }
.ints-form-field .input-field .ant-input{ width:100%; height:40px; font-size: 16px; border: solid 1px rgba(0,0,0,0.2); border-radius:0; }
.ints-form-field p{ font-size:14px; line-height: 1.3; color: #212121; font-weight:400; margin: 0; padding:0; }



/*========== Task-Editor End =========*/
.trigger-button{ display:block; margin:0; padding:0; text-align:center; position: relative; z-index:1; }
.trigger-button.or-trigger { position: absolute; width: 100%;; bottom:-42px; }
.button-add{ display:inline-block; margin:0 !important; padding:25px 0 25px 0; text-align:center; position:relative; }
.button-add:before{ content:""; position: absolute; width:1px; height:15px; left:0; right:0; margin:0 auto; top:5px; background:rgba(0,0,0,.3); }
.button-add:after{ content:""; position: absolute; width:1px; height:15px; left:0; right:0; margin:0 auto; bottom:5px; background:rgba(0,0,0,.3); }

.button-add .btn{ display:inline-block; width: 55px; font-size:12px; line-height:14px; font-weight:600; color:#000; margin:0; padding:3px 0; border:1px solid rgba(0,0,0,.1); cursor: pointer; }

.button-add .btn.and-btn{ border-right:none; border-radius: 15px 0 0 15px; }

.button-add .btn.or-btn{ border-radius:0 15px 15px 0; }

.button-add .btn.active{ background:#3235F5; border:1px solid transparent; color:#fff; }

.trigger-radio .ant-radio-button-wrapper{ padding:0 12px !important; }

.and-block .conditions-field:last-child .button-add{ padding-bottom:0; }
.and-block .conditions-field:last-child .button-add:after{ display:none; } 

.trigger-button.or-trigger .button-add{ position: relative; }
.trigger-button.or-trigger .button-add:after{ display:block !important; content:""; position: absolute; width:1px; height:13px; left:0; right:0; margin:0 auto; bottom:-17px; background:rgba(0,0,0,.3); }

/*========== Task-Perform Page Start =========*/

.task-page{ background:#F0F2F5; }
.task-perfirm{ margin:0; padding:40px 25px 65px; }

.task-perfirm .all-task{ margin:0; padding:0; }
.task-perfirm .all-task .lt-enviornment{ width:65%; margin:0; padding:0 60px 0 35px; }

.task-perfirm .task-comment{ margin:0 0 30px; padding: 28px 20px 35px; background:#fff; box-shadow: 0 1px 7px 0 rgba(0,0,0,.1); }
.task-perfirm .task-comment .desc-box{ margin:0; padding:0 50px; position: relative; }
.task-perfirm .task-comment .desc-box:after{ content: ""; position: absolute; width: 35px; height: 35px; background: #FCFCFC; border: 1px solid #B5B5B5; left: 0; top: 5px; }
.task-perfirm .task-comment .desc-box .text-block{ margin:0; padding:0 0 25px; }
.task-perfirm .task-comment .desc-box .text-block h2{ font-size: 20px; line-height: 22px; color: #170C0C; font-weight: 700; margin: 0; padding: 0 0 10px 0; }
.task-perfirm .task-comment .desc-box .text-block p{ font-size: 13px; line-height: 20px; color: #676767; font-weight: 400; margin: 0; padding: 0; }

.task-perfirm .task-comment .desc-box .bottom-field{ margin:0; padding:0; }
.task-perfirm .task-comment .desc-box .bottom-field h6{ font-size:12px; line-height:14px; color:#9F9F9F; font-weight: 400; margin:0 0 20px 0; padding: 0 0 15px 0; border-bottom:1px solid #9F9F9F; }

.task-perfirm .task-comment .desc-box .bottom-field ul.do-that{ margin:0; padding:0 0 15px 0; }
.task-perfirm .task-comment .desc-box .bottom-field ul.do-that li{ font-size:15px; line-height:18px; color:#170C0C; font-weight:700; margin:0; padding: 0 0 15px 30px; position: relative; }
.task-perfirm .task-comment .desc-box .bottom-field ul.do-that li:after{ content: ""; position: absolute; width: 18px; height:18px; background: #FCFCFC; border: 1px solid #B5B5B5; left: 0; top:1px; }
.task-perfirm .task-comment .desc-box .bottom-field ul.do-that li.active{ color:#989898; font-style:italic; text-decoration:line-through; }
.task-perfirm .task-comment .desc-box .bottom-field ul.do-that li.active:after{ background:#CEFFE1; border:1px solid #06984F; }
.task-perfirm .task-comment .desc-box .bottom-field ul.do-that li.active:before{ content: ""; position: absolute; width: 18px; height:18px; background:url(./assets/images/tick.png) no-repeat center; background-size: 11px; left:1px; top:1px; z-index:1; }

.task-perfirm .task-comment .desc-box .bottom-field .form-field{ margin:0; padding:0 0 30px 0; position: relative; }
.task-perfirm .task-comment .desc-box .bottom-field .form-field .ant-input{ font-size:15px; line-height:17px; color:#B7B7B7; font-weight:600; height:42px; border:1px solid #C7C7C7; border-radius:0; }
.task-perfirm .task-comment .desc-box .bottom-field .form-field .ant-input:focus{ box-shadow:none; outline:none; }

.task-perfirm .task-comment .desc-box .bottom-field .form-field .ant-btn{ position: absolute; right:0; top:0;  font-size:13px; line-height:15px; color:#e2dfdf; font-weight:700; text-transform:uppercase; background:#275FA0; border-radius:0; border: none; outline: none; box-shadow: none; padding:0 22px; height: 42px; }

.task-perfirm .task-comment .desc-box .bottom-field .task-controlar{ margin:0; padding:0; }
.task-perfirm .task-comment .desc-box .bottom-field .task-controlar .ant-btn{ font-size:11px; line-height:13px; color:#275FA0; font-weight:400; text-transform:uppercase; margin:0 45px 0 0; padding:0; border:none; background:none; transition:all 600ms ease-in-out; }

.task-perfirm .task-comment .desc-box.active-box .text-block h2{ color: #989898; font-style: italic; text-decoration: line-through; }
.task-perfirm .task-comment .desc-box.active-box:after{ background: #CEFFE1; border: 1px solid #06984F; }
.task-perfirm .task-comment .desc-box.active-box:before{ content: ""; position: absolute; width: 35px; height: 35px; background:url(./assets/images/task-tick2.svg) no-repeat center; background-size: auto auto; background-size: 22px; left: 1px; top: 5px; z-index: 1; }

.task-perfirm .right-task{ position: fixed; width:35%; top:0; bottom:70px; right:0; background:#FBFBFB; margin:0; padding:100px 30px 0; overflow:auto; overflow-x:hidden; }
.task-perfirm .right-task .btn-block{ margin:0; padding:0 0 15px 0; text-align:right; }
.task-perfirm .right-task .btn-block .ant-btn{ font-size:12px; line-height:14px; color:#fff; font-weight:700; text-transform:uppercase; margin:0; padding:0 30px 0; height:38px; background:#E4E4E4; border:none; border-radius:20px; }

.task-perfirm .right-task .select-box{ margin:0; padding:0 0 25px 0; }
.task-perfirm .right-task .select-box .ant-select{ width:100%; height:45px; margin:0; padding:0; }
.task-perfirm .right-task .select-box .ant-select .ant-select-selection{ width:100%; height:45px; border:1px solid #707070; border-radius:0; box-shadow:none; }
.task-perfirm .right-task .select-box .ant-select .ant-select-selection .ant-select-selection__rendered{ line-height:43px; }
.task-perfirm .right-task .select-box .ant-select .ant-select-selection .ant-select-selection__placeholder{ font-size:12px; color:#191919; font-weight:600; text-transform:uppercase; }
.task-perfirm .right-task .select-box .ant-select .ant-select-selection .ant-select-selection-selected-value{ text-transform:uppercase; color:#191919; }


.task-perfirm .right-task .user-list{ margin:0; padding:0; }
.task-perfirm .right-task .user-list .block{ margin:0 0 15px 0; padding:0; display:flex; border:1px solid #F0F0F0; border-radius:5px; overflow: hidden; position: relative; }
.task-perfirm .right-task .user-list .block .checked-icon{ position: absolute; width:62px; height:100%; top:0; bottom:0; background:#27BF50; display: flex; align-items: center; justify-content: center; }
.task-perfirm .right-task .user-list .block .checked-icon img{ width:22px; height:auto; }
.task-perfirm .right-task .user-list .block .checked-icon.reopened{ background:#D0B750; }
.task-perfirm .right-task .user-list .block .checked-icon.re-opened img{ width:5px; }
.task-perfirm .right-task .user-list .block .checked-icon.unchecked{ background:#D86D2B; }
.task-perfirm .right-task .user-list .block .checked-icon.performed{ background:#5594F7; }

.task-perfirm .right-task .user-list .block .checked{ margin:0 0 0 62px; padding:10px 15px; width:300px; display:flex; flex-flow:column; justify-content:center; }
.task-perfirm .right-task .user-list .block .checked h6{ font-size:13px; line-height:17px; color:#290404; font-weight:700; margin:0; padding:0 0 6px ; }
.task-perfirm .right-task .user-list .block .checked p{ font-size:11px; line-height:16px; color:#A3A3A3; font-weight:400; text-transform:uppercase; margin:0; padding:0 45px 0 0; }
.task-perfirm .right-task .user-list .block .checked p span{ color:#695F5F; }

.task-push{ position: fixed; bottom:0; width:100%; height:70px; margin:0; padding:0 25px; display:flex; justify-content: space-between; align-items:center; background:#fff; z-index:1;  box-shadow:0 2px 8px 0 rgba(0,0,0,.1); }
.task-push .left-text{ margin:0; padding:0; }
.task-push .left-text h5{ font-size:18px; line-height:20px; color:#000; font-weight:400; margin:0; padding:0 0 5px 0; }
.task-push .left-text p{ font-size:13px; line-height:15px; color:#919191; font-weight:300; margin:0; padding:0; }

.task-push .rt-side{ margin:0; padding:0; }
.task-push .rt-side .ant-btn{ font-size:12px; line-height:14px; color:#000; font-weight:700; margin:0; padding:0; background:#fff; border:1px solid #555555; border-radius:0; width:100px; height:40px; text-align:center;  }

.task-push .rt-side .ant-btn.active{ border:1px solid #9F8705; color:#93821E;  margin-right:15px; padding-right: 30px; background:url(./assets/images/push-icon.svg) no-repeat 78% center #F0E5D0; background-size: 9px; }


.task-pf { padding:0; border-bottom: solid 1px rgba(0,0,0,0.1); position: relative; }
/* .task-pf:hover { background: rgba(0,0,0,0.01); } */
.task-pf .task-name { color:#999; padding-left:45px; padding-right:95px; font-size: 16px; user-select: none; -webkit-user-select: none; position: relative; z-index: 2; }
.task-progress { position: absolute; left: 20px; top: 50%; width:22px; height:22px;transform: translateY(-50%); transition:all 600ms ease-in-out; border-radius:3px; overflow: hidden; }

.task-pf .task-name .list-text{ display:block; color:#111; font-weight:600; font-size: 14px; padding:20px 0; }

.task-pf .select-text{ display:block;  font-size:12px; line-height:1.4; color:#484848; font-weight:700; text-transform: uppercase; padding:5px 20px; background:#f4f4f4; }
.task-pf .select-text span{  }

.task-pf h5{ display: block; cursor: pointer; color: #111; font-weight:600; font-size: 14px; margin:0; padding:20px; }

.task-pf .task-output-box{ cursor: pointer; padding:0 15px; }

.task-pf .task-stick{ position: absolute; width:4px; top:0; bottom:0; right:0; }



.task-pf .assign_field{ position: absolute; top: 50%; right: 0; }
.task-pf .assign_task{ position: absolute; top:50%; transform:translateY(-50%); right:15px; display: flex; align-items:center; z-index:2; }
.task-pf .task_btn{ margin:0 0 0 5px; padding:0; position:relative; }
.task-pf .task_btn .btn{ width:25px; height:25px; border:none; box-shadow:none; background:none; border:1px solid rgba(0,0,0,.3); border-radius:50%; text-align:center; padding:0; }
.task-pf .task_btn .btn:hover{ border:1px solid #1890FF; }
.task-pf .task_btn .btn img{ width:52%; height:auto; opacity: .7; }

.task-pf .assign-user-modal{ position:absolute; width:275px; top:30px; right:12px; background:#fff; box-shadow: 0 2px 100px 0 rgba(0,0,0,.1); padding:20px; border-radius:4px; z-index:99; }
.task-pf .assign-user-modal:after{ content: ""; position: absolute; width: 12px; height: 12px; background: #fff; transform: rotate(45deg); top: -6px; right:8px; margin: 0; }

.task-pf .assign-user-modal .search-field{ margin:0; padding:0; }
.task-pf .assign-user-modal .search-field .ant-btn{ margin-top:10px; }

.content_field .bug-radio{ padding-bottom:12px; }
.content_field .bug-radio .ant-radio-inner{ border:1px solid #D1D1D0; }
.content_field .bug-radio .ant-radio-wrapper:hover .ant-radio-inner{ border:1px solid #3235F5; }
.content_field .bug-radio .ant-radio-checked .ant-radio-inner{ border:1px solid #3235F5; }
.content_field .bug-radio .ant-radio-checked .ant-radio-inner:after{ background:#75DDDD; }

.task-support .assign-user-modal{ top:25px; }

/*=========== Perform-Report Start ============*/
.perform-report{ width: 850px; margin:0 auto; padding:0; background:#fff; }
.perform-report .head-block{ margin:0; padding:15px 30px; border-bottom:1px solid rgba(0,0,0,.1); }
.perform-report .head-block .lt-block{ float: left; margin:0; padding:4px 0 0 0; }
.head-block .switch-box{ float: left; margin:0; padding:0 30px 0 0; }
.head-block .switch-box .sw-side{ float: left; margin:0; padding:0; }

.head-block .switch-box .text-side{ float: left; margin:0; padding:0 0 0 15px; }
.head-block .switch-box .text-side span{ display:inline-block; font-size:12px; line-height:14px; color:#000; font-weight:400; margin:0; padding:5px 0 0 0; }

.perform-report .head-block .rt-block{ float:right; margin:0; padding:0; }

.perform-report .report-field{ margin:0; padding:45px; }
.report-field .top-head{ margin:0; padding:0 0 25px 0; border-bottom:1px solid rgba(0,0,0,.3); }

.perform-users .head-block{ padding:0; }
.perform-users .upper-block{ display:flex; align-items:center; justify-content:space-between; margin:0; padding:0 0 15px 0; border-bottom:1px solid rgba(0,0,0,.1); }
.perform-users .upper-block h1{ margin:0; padding:0; }

.perform-users .down-block{ margin:0; padding:0; }
.perform-users .box{ margin:0 0 7px 0; padding:7px; background: #fff; display: flex; align-items: center; position: relative; border: solid 1px #DDD; border-radius:0; overflow: hidden; box-shadow:0 2px 6px rgba(0,0,0,.1); }
.perform-users .box .img-block{ margin: 0 15px 0 0; padding: 0; width:60px; height:60px; border-radius:16px; overflow: hidden; background:#ccc; }
.perform-users .box .user-name{ flex:1; margin:0 35px 0 0; padding: 0; }
.perform-users .box .user-name h5{ font-size: 16px; line-height: 18px; color: #0F0202; font-weight:600; margin: 0; padding:0; }
.perform-users .box .user-name .email-text{ font-size: 14px; line-height: 16px; color: #868585; font-weight: 400; margin: 0; padding: 0; }
.perform-users .box .d-btn{ position: absolute; width: 42px; top: 0; bottom: 0; right:0; height: 100%; border-left: 1px solid rgba(0,0,0,.1); display: flex; align-items: center; transition:all 150ms ease-in-out; }
.perform-users .box .d-btn:hover{ background:#f9f9f9; }
.perform-users .box .d-btn .ant-btn-danger{ height:100%; background:none; border:none; border-radius:0; box-shadow:none; color:#f5222d; }

.name-sec .field_look .content_field_element { padding-left:0; }
.name-sec .field_look .content_field_element #file_preview{ margin:0; padding:0; }
.name-sec .field_look .content_field_element #file_preview label{ display:block; font-size: 14px; font-weight:600; color:#000; padding-bottom:10px; }

.addon_box{ display:flex; align-items:center; justify-content:center; vertical-align:baseline; background:#FAFAFA; border: 1px solid #d9d9d9; border-right:none; line-height:1; margin:0; padding:0 15px 0; }
.name-sec .field_look .content_field_element .input_number_box{ border-radius:0 4px 4px 0; }

.field_look .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after{ border-color:#fff; }


.perform-block-drawer .field_look{ padding:5px 0; margin-left:0; }
.perform-block-drawer .preform-mark-btn .button-holder{ border-top:none; }
.perform-block-drawer .name-sec li .p_m{ margin:0 !important; }
.perform-block-drawer .name-sec .field-content{ width:100%; }

.item-radio .perform-radio label span{ color:#333; }
.perform-radio .ant-radio-disabled .ant-radio-inner{ background-color: #fff; border-color: rgba(0,0,0,.2) !important; }
.perform-radio .ant-radio-inner{ border-color:#D1D1D0; }

.perform-radio .ant-radio-checked .ant-radio-inner{ border-color:#3235F5; }
.perform-radio .ant-radio-checked .ant-radio-inner:after{ background:#75DDDD; }
.perform-radio .ant-radio-wrapper:hover .ant-radio-inner{ border-color:#3235F5; }

.field_preview .ant-select-disabled{ color:#333; }
.field_preview .ant-radio-disabled + span{ color:#333; }
.field_preview .ant-checkbox-disabled + span{ color:#333; }

.field_preview .content_field .field-table{ display:block; width:100%; white-space:nowrap; overflow:auto; }

.performperformer-madal-member{ width:800px !important; }
.performperformer-madal-member .members-field.workflow-members{ padding:0; }

#file_preview .choose-field{ margin:0; padding:0; }
#file_preview .choose-field input[type=file] { cursor: pointer; width:140px; height:37px; overflow: hidden; box-shadow:none; outline:none; padding:0 2px; }
  
#file_preview .choose-field input[type=file]:before { display: inline-block; content: 'Choose file'; width:100%; height:100%;  font-size:14px; line-height: 37px; color:#333; font-weight:600; background:#fff; border:1px solid #D1D1D0; padding: 0 10px; text-align: center; border-radius:5px; transition:all 200ms ease-in-out; }

#file_preview .choose-field input[type=file]:hover:before{ border: 1px solid #3235F5; }
#file_preview .choose-field input[type=file]:focus:before{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }
  
#file_preview .choose-field input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
  }
#file_preview .choose-field input[type=file]::-moz-file-upload-button {
    visibility: hidden;
  }

.img-upload-file{ border: 1px solid rgba(0,0,0,.1); position: relative; display: flex; align-items: center; justify-content: space-between; padding:8px 15px; background:#fff; } 

.img-upload-file .img-text{ flex:1; font-size:13px; font-weight:500; margin:0; padding:0 !important; max-width:400px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.img-upload-file .button-btn{ margin:0; padding:0; }
.img-upload-file .button-btn .anticon-delete{ font-size:15px; transition:all 200ms ease-in-out; cursor: pointer; color:red; }
.img-upload-file .button-btn .anticon-delete:hover{ color:red; }

.img-upload-file .button-btn .ant-btn-circle{ background:none; height:auto; margin:0; padding:0; border:none; box-shadow:none; outline:none; color:#000; font-size:15px; }


.top-head .name-block{ float: left; margin:0; padding:0; } 
.top-head .up-sec{  margin:0; padding:0 0 35px 0; }
.top-head .up-sec h2{ font-size:25px; line-height:27px; color:#000; font-weight:600; margin:0; padding:0 0 7px 0; }
.top-head .up-sec p{ display:block; font-size:15px; line-height:17px; color:#999; font-weight:400; margin:0; padding:0; } 

.report-field .top-head .complete-sec{ margin:0; padding:0; } 
.top-head .complete-sec h4{ font-size:45px; line-height:47px; color:#000; font-weight:600; margin:0; padding:0; }

.top-head .details-sec{ float:right; margin:0; padding:0; }
table.table-text{ margin:0; padding:0; }

table.table-text tr{ margin:0; padding:0; vertical-align: top; }
table.table-text tr td{ margin:0; padding:0 0 15px 0; }
table.table-text .lt{ font-size:13px; line-height:15px; color:#999; font-weight:600; padding-right:30px; }

table.table-text .rt{ font-size:14px; line-height:16px; color:#111; font-weight:600; }
table.table-text .rt span{ display:block; }
table.table-text .rt ul li{ display:block; margin:0; padding:3px 0; }

.report-field .user-text{ margin:0; padding:30px 0 35px 0; }
.report-field .user-text h5{ font-size:20px; line-height:22px; color:#999; font-weight:400; margin:0; padding:0 0 15px 0; }
.report-field .user-text .mail-id{ margin:0; padding:0 0 10px 0; }
.report-field .user-text .mail-id ul{ margin:0; padding:0; }
.report-field .user-text .mail-id ul li{ float: left; font-size:14px; line-height:16px; color:#000; font-weight:400; margin:0; padding:0 5px 0 0; }


.report-field .lower-field{ margin:0; padding:0; }

.report-field .lower-field .block{ margin:0; padding:0 0 30px 0; }
.report-field .lower-field .block p{ font-size:14px; line-height:1.3; color:#000; font-weight:400; margin:0; padding:0 0 30px 0; }

.lower-field .block .capacity-block{ margin:0; padding:0 0 30px 0; }
.capacity-block h6{ font-size:18px; line-height:20px; color:#000; font-weight:700; margin:0; padding:0 0 10px 0; }
.capacity-block .progress-bar{ margin:0; padding:0; }
.capacity-block .progress-bar .percent-progress{ float: left; min-width:90%; margin:0; padding:0; }
.capacity-block .progress-bar .percent-box{ height:13px; border-radius:30px; margin:5px 0 0 0; padding:0; position: relative; }
.capacity-block .progress-bar .percent-text{ float: left; min-width:10%; margin:0; padding:0; text-align:center; }
.capacity-block .progress-bar .percent-content{ font-size:20px; line-height:22px; color:#000; font-weight:400; margin:0; padding:0 0 0 0; position: absolute; right: -60px; top: -4px; }

.lower-field .block .contact-text{ margin:0; padding:0; }
.lower-field .block .contact-text .p_m{ margin:0 !important; }
.lower-field .block .contact-text .field-content{ padding:0; margin:0; } 
 
.lower-field .block .contact-text .item-value{ margin:0; padding:0; }
.lower-field .block .contact-text .label-value{ margin:0; padding:0; }
.lower-field .block .contact-text .label-value label{ display:inline-block !important; font-size:15px; line-height:1; font-weight:600; color:#6f6f6f; padding:0 18px 0 0;  vertical-align: middle; }
.lower-field .block .contact-text .label-value p{ display:inline-block; padding:0; font-size: 16px; line-height: 1; }

.lower-field .block .contact-text .label-value p .no-data{ font-size:15px; line-height:1; font-style:italic; opacity:.2; }

.lower-field .block .contact-text .item-value .field-main-content{ margin:0 0 25px 0; }

.lower-field .block .contact-text .item-value .field-content{ padding:0 0 25px!important; }
.lower-field .block .contact-text .item-value .field-content p{ padding-bottom:18px; }
.lower-field .block .contact-text .item-value .field-content p:last-child{ padding-bottom:0; }
.lower-field .block .contact-text .item-value .field_preview label.table-label{ display:block; padding-bottom:8px; } 

.lower-field .block .contact-text .item-value .field-content .link-content{ margin:0; }

.lower-field .block .contact-text .label-value label.upload-label{ font-size:14px; font-weight:400; padding-bottom:8px; }

.report-end{ margin:0; padding:0 45px 45px; display:none; }
.report-end .lt-side{ float: left; margin:0; padding:0; }
.report-end .lt-side .sub-heading{ display:inline-block; font-size:16px; line-height:18px; color:#000; font-weight:400; margin:0; padding:0; }

.report-end .rt-side{ float:right; margin:0; padding:0; }
.report-end .rt-side .url-text{ display:inline-block; font-size:16px; line-height:18px; color:#000; font-weight:400; margin:0; padding:0; }


.mv-stg{ margin:0; padding:0 0 35px 0; } 
.mv-stg h5{ font-size:18px; line-height:1.3; color:#797979; font-weight:500; margin:0; padding:0 0 25px 0; text-align:center; }
.mv-stg .stage-text{  font-size: 13px; line-height: 1.4; color: #000; font-weight: 600; margin: 0 0 30px 0; padding: 0; }
.mv-stg .bottom-field{ margin:0; padding:0; }
.mv-stg .bottom-field p, .mv-stg .bottom-field ul li{ font-size:15px; line-height:1.5; color:#000; font-weight:400; margin:0 0 15px 0; padding:0; }
.mv-stg .bottom-field ul li{ position: relative; padding-left:20px; }
.mv-stg .bottom-field ul li:before{ content:''; position:absolute; width:8px; height:8px; background:#000; border-radius:50%; left:0; top:7px; }
.mv-stg .bottom-field ul li a{ color:#000; font-weight:700; word-break: break-all; }

.tasks-block .cnt-btn{ font-weight: 600; color: #fff; background: none; border:none; box-shadow:none; outline:none; transition: none; min-width:140px; }
.tasks-block .cnt-btn.rva-btn{ margin-left:10px; border: 1px solid #ff4d4f; color: #ff4d4f; background:none !important; }
 
.ant-modal.base-modal .ant-modal-title{ font-size:16px; font-weight:400; }
.base-modal .ant-modal-body .ant-checkbox-inner{ width:25px; height:25px; }
.base-modal .ant-modal-body .ant-checkbox-inner:after{ left:30%; }


.base-modal .attribute-block .ant-radio-wrapper{ font-weight:400; }

.base-modal .choose-item{ margin-bottom:10px; }
.base-modal .choose-item .ant-radio-group{ display:flex; }


.base-modal .ant-modal-footer div .ant-btn{ width:100%; height:50px; font-size:14px; line-height:1; font-weight:600; color:#fff; box-shadow:none; outline:none; border:none; border-radius:0; margin:0; padding:0; }
.base-modal .ant-modal-footer div .ant-btn:first-child{ display:none; }

.mtb-modal .attribute-block{ max-width:353px; margin-left:auto; }

.field_look .ant-radio-checked.ant-radio-disabled .ant-radio-inner{ border: 1px solid gray !important; }
.field_look .ant-radio-checked.ant-radio-disabled .ant-radio-inner:after{ background:gray; }

.field_look .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner{ background:gray; }
.field_look .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after{ top:45%; }

/*=========== Perform-Report End ============*/


.progress-meter { position: absolute; bottom: 0; left: 0; right: 0; transition:all 600ms ease-in-out; }

.progress_check{ position: absolute; top:0; bottom: 0; left: 0; right: 0; transition:all 600ms ease-in-out; display:flex; align-items:center; justify-content:center; }
.progress_check .anticon{ color:#000; }



.task-pf.selected{ background: rgba(0,0,0,0.01); }

.task-pf.active{ background: rgba(0,0,0,0.01); }

.task-pf.selected .task-name{ color: #000; }

.task-progress .progress-meter{ display:flex; align-items:center; justify-content:center; }
.task-progress .progress-meter .anticon{ color:#fff; }
.task-progress.red .progress-meter{ background: #ea7c35; }
.task-progress.yellow .progress-meter{ background: #efdb73; }
.task-progress.green .progress-meter{ background: #9fd878; }




/*========== Task-Perform Page End =========*/

/* Tab style override */
.tab-block .ant-tabs-top-bar .ant-tabs-tab{
    color: rgba(255,255,255,0.6);
    font-weight: 500;
    
}
.tab-block .ant-tabs-top-bar .ant-tabs-tab.ant-tabs-tab-active{ color: #FFF !important; font-weight:600;  }
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar{ background: #0093FF; }

.workflow-tabs .ant-tabs-nav .ant-tabs-tab{ color: #FFF; opacity: 1; font-weight:600; }

.workflow-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active { background: #FFF; padding-top: 20px; border-top-left-radius: 5px; border-top-right-radius: 5px; }

.workflow-tabs .ant-tabs-ink-bar{ display: none !important; }

.workflow-tabs .ant-tabs-left .ant-tabs-nav .ant-tabs-tab{ color:#333; }
.workflow-tabs .ant-tabs-left .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active{ padding: 8px 24px; }
.workflow-tabs .ant-tabs-left .ant-tabs-ink-bar{ display:block !important; background:#28b170; }


.checklist-header h5 { display:inline-block; font-size: 22px; line-height:1.4; font-weight:500; color: #FFF; margin-bottom:0px; position: relative; }
.checklist-header .headind-side{ max-width:650px; margin:0; padding:0; position:relative; display:flex; }
.checklist-header .headind-side .expand-btn{ position: absolute; width:35px; left:-48px; top:2px; margin:0; padding:0; }
.checklist-header .headind-side .expand-btn .ant-btn{ height:auto; background:none; box-shadow:none; outline:none; border:none; padding:7px; transition:all 150ms ease-in-out; }
.checklist-header .headind-side .expand-btn .ant-btn .anticon-menu-fold,.anticon-menu-unfold{ color:rgba(255,255,255,1); font-size:15px; line-height: 15px; }
.checklist-header .headind-side .expand-btn .ant-btn:hover{ background:rgba(0,0,0,.3); }
.checklist-header .headind-side .expand-btn .ant-btn.m_btn{ display:none; }


.checklist-header .headind-side .text-box{ flex:1; margin:0; padding:0; }
.checklist-header .headind-side p{ font-size:14px; line-height:1.4; color:rgba(255,255,255,.8); font-weight:600; margin:0; padding:6px 0 0 0; max-width:520px; }

.checklist-header .headind-side .btn-sec{ position:absolute; top:0; right:-22px; margin:0; padding:0; }
.checklist-header .headind-side .btn-sec .heart-btn{ background:none; box-shadow:none; outline:none; color: rgba(255,255,255,.6); margin:0; padding:0; position: relative; top:0; }
.checklist-header .headind-side .btn-sec .heart-btn:hover{ color:#fff; }

.checklist-header .headind-side .btn-sec.active .heart-btn{ color:yellow; }
.checklist-header .headind-side .btn-sec .heart-btn .anticon{ font-size:15px; line-height:0; }
.checklist-header .headind-side .btn-sec .heart-btn .anticon.normal{ display:block; }
.checklist-header .headind-side .btn-sec .heart-btn .anticon.active{ display:none; }

.checklist-header .headind-side .btn-sec.active .heart-btn .anticon.normal{ display:none; }
.checklist-header .headind-side .btn-sec.active .heart-btn .anticon.active{ display:block; }



.workflow_dp { width: 30px; height: 30px; position: absolute; background: #333; margin-right: 10px; border-radius: 5px; left:5px; top: 50%; transform: translateY(-50%); line-height: 30px; text-align: center; color: #FFF; text-transform: uppercase; border-radius: 50%; font-size:10px; display:flex; align-items:center; justify-content:center; border:none; box-shadow:none; outline:none; }
.workflow_name{ padding-left: 40px;  font-size:14px; line-height:1.4; color:#000; font-weight:600; max-width:190px; }

.workflow_dp .anticon{ font-size:14px; line-height:0; margin:0; padding:0; }

.updates-list p{ font-size:12px; line-height:1.4; font-weight:400; padding:0; margin:0; padding-left: 8px; max-width:265px;  }

.setting-menu{ padding-left: 8px; }

.setting-menu .icon-img{ display:inline-block; width:22px; text-align:center; margin:0 8px 0 0; padding:0; vertical-align: middle; opacity:.7; }
.setting-menu .icon-img .anticon-gold{ font-size:22px; }
.setting-menu .icon-img .anticon-api{ font-size:20px; }
.setting-menu .icon-img .anticon-mail{ font-size:18px; }
.setting-menu .icon-img .anticon{ font-size:20px; color:#494949; }


.ant-tabs-ink-bar{background: #FFF; height: 2px; bottom: 2px}
.empty-text, .no-content { position: absolute; top:50%; left:0; right:0; width: auto; text-align: center; max-width:300px; margin:0 auto; transform:translateY(-50%); }

/* Status bar  */

.status-bar{ position: fixed; bottom: 0; left: 0; right: 0; height: 30px; background: #FFF; border-top: solid 1px rgba(0,0,0,.1); z-index: 299322; display: flex; align-items: center; font-size: 12px; justify-content: space-around; display:none; }

.right-modal{ position: fixed; top: 0; bottom: 0; right: 0; width: 300px; background: #FFF; border-radius: 0px; }
.modal-fields-holder{ float: left; width: 100%; min-height: 500px; position: relative; }

/* Menu at task editor */

.field-add-menu { width: 450px; display: flex; }

.menu-heading { padding: 20px 10px; float: left; border-bottom: solid 1px rgba(0,0,0,.08); width: 100%; font-weight: 700; margin-bottom: 20px;}
.field-add-menu .ant-dropdown-menu-item-group {
    width: 50%; display: inline-block;
    padding: 20px;
}
.field-add-menu .ant-dropdown-menu-item-group:nth-child(2){
    border-left: solid 1px #EEE;
}

.field-add-menu .ant-dropdown-menu-item-group-list .ant-dropdown-menu-item .icon-add{ display:inline-block; margin: 0 8px 0 0; padding:0; width:16px; text-align:center; position:relative; top:-1px; }

p.danger { font-size:14px; color:#FF0000; font-weight:600; }
p.danger .icon{ display:inline-block; margin:0 8px 0 0; padding:0; position: relative; top:-1px; }
p.danger .icon img{ width:17px; height:auto; }

p.primary { font-size:14px; color:#00B466; font-weight:600; }
p.primary .icon{ display:inline-block; margin:0 8px 0 0; padding:0; position: relative; top:-1px; }
p.primary .icon img{ width:17px; height:auto; }

.ant-dropdown-menu-item-group-title{  font-size:14px; line-height:1.4; font-weight:600; color: #000; margin-bottom: 10px; }
.link-content{ display: flex; justify-content: flex-start; border:solid 1px rgba(0,0,0,.1); border-radius: 5px; overflow: hidden; background: #FFF; cursor: pointer; user-select: none; padding:15px; }

.link-content:hover{ border-color: #999; }
.link-content .link-image{ width:165px; margin:0 15px 0 0; padding:0; }
.link-content .link-image img{ width: 100%; height:auto; max-height: 100px; object-fit:contain; }
.link-content .link-text{ flex:1; padding:0; }
.link-content .link-text p{ padding: 0px !important; margin: 0px !important; font-size: 14px; max-height:62px;  overflow: hidden; color: #585858; }
.link-text h2{ font-size: 15px; line-height:18px; margin: 0; font-weight: 700; margin-bottom: 5px; }
.no-item { width: 300px; position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); display: flex; flex-flow: column; align-items: center; text-align: center; }
.no-item .add-field-button{ display: inline-block; font-size: 12px; line-height: 14px; color:#309052; font-weight: 700;
    margin: 0; padding:8px 15px; background: #fff; border: 1px solid #309052; border-radius: 3px; }
.no-item .add-field-button:hover{ border: 1px solid transparent; background:#309052; color:#fff; }
/* .no-item .add-field-button i{ color:#309052; } */
.no-item .add-field-button:hover i{ color:#fff; }
.no-item p{ font-size: 14px; color: #999; font-weight: 500; margin: 0; padding: 10px 0; text-align: center; max-width: 315px; }

.edit-btn-h{ margin-left: 10px; }
.edit-btn-h .ant-btn-icon-only{ width:35px; height:35px; background:rgba(0,0,0,.1); border:none; box-shadow:none; border-radius:50%; }
.edit-btn-h .ant-btn-icon-only:hover{ background:#f4f4f4; }

.radio-btn-h .ant-col-24 { padding: 5px 0px; }
.radio-btn-h .users-h{ margin-top: 6px; margin-bottom: 6px; padding-left: 6px; }

.account-block .active{ background:none !important; margin-bottom:8px; }
.account-block a{ color: #d9d9d9; }
.account-block a .ant-badge img{ opacity:.5 !important; }
.bottom-accountInf-block { position: relative; bottom:20px; margin-top:30px; }

.user-profile{ width: 30px; height:30px; border-radius:50%; background: #EAEAEA url(./assets/images/user-icon.svg); background-size: cover !important; background-position: center !important; margin:0 auto; overflow: hidden }

.edit-drawer .ant-drawer-body{ padding:0; }
.edit-drawer .ant-drawer-body .up_draw{ padding:24px 24px 0; }
.edit-drawer .ant-drawer-body .up_draw .ant-select-selection{ border:1px solid #D1D1D0; }
.edit-drawer .ant-drawer-body .up_draw .ant-select-selection:hover{ border: 1px solid #3235F5; }
.edit-drawer .ant-drawer-body .up_draw .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }


.edit-drawer .ant-drawer-content-wrapper{ width:550px !important; }
.edit-drawer .no-item{ width:100%; }

.edit-drawer .ant-drawer-content-wrapper .field-contents{ overflow: inherit !important; min-height: inherit; height: 100%; position: relative; }

.edit-drawer .perform-draw{ float: inherit; }
.edit-drawer .field-main-content{ overflow: hidden; }

.ant-modal .ant-modal-close{ right: -48px; top:0 !important; } 


.content_field .ant-select-selection--single{ padding-left:0 !important; }


/*=========== Payments and Orders Start ==========*/
.payment-layout{ background:none; position:relative; }
.payment-block{ width:100%; margin:0; padding:0; }
.payment-block .heading-block{ margin:0; padding:30px 65px 15px; background:#000; }
.payment-block .heading-block h2{ font-size:25px; line-height:27px; color:#fff; font-weight:700; margin:0; padding:0 0 5px 0; }
.payment-block .heading-block p{ font-size:14px; line-height:20px; color:#fff; font-weight:400; margin:0; padding:0; }

.payment-block .tab-field{ margin:0; padding:0; }
.payment-block .tab-field .ant-tabs-top-bar{ margin:0; padding:0 100px; background:#000; }
.payment-block .tab-field .ant-tabs-top-bar .ant-tabs-tab{  font-size:13px; line-height:15px; color:#fff; font-weight:600; padding: 22px 33px; }
.payment-block .tab-field .ant-tabs-top-bar .ant-tabs-ink-bar-animated{ background:#FF9D00; }

.payment-block .tab-field .ant-tabs-tabpane{ padding:0 60px; }
.payment-block .tab-field .order-block{ margin:0; padding:45px 0; }
.payment-block .tab-field .order-block .block{ display:flex; align-items:center; justify-content:space-between; background:#C3F7C7; height:105px; margin:0 0 15px 0; padding:0 65px; border-radius:5px; }
.payment-block .tab-field .order-block .block .heading{ width:40%; margin:0; padding:0; }
.payment-block .tab-field .order-block .block .heading h3{ font-size:18px; line-height:20px; color:#336E00; font-weight:600; text-transform:capitalize; margin:0; padding:0; }

.payment-block .tab-field .order-block .block .status-text{ width:60%; display:flex; align-items:center; justify-content:space-between; margin:0; padding:0; }
.payment-block .tab-field .order-block .block .status-text .desc{ width:50%; min-height: 60px; margin:0; padding:0 35px; border-left:1px solid rgba(0,0,0,.1); display: flex; flex-flow: column; justify-content: center; }
.payment-block .tab-field .order-block .block .status-text .desc h6{ font-size:14px; line-height:16px; color:#2B1D1D; font-weight:600; margin:0; padding:0; }
.payment-block .tab-field .order-block .block .status-text .desc p{ font-size:13px; line-height:18px; color:#2B1D1D; font-weight:400; margin:0; padding:0; }
.payment-block .tab-field .order-block .block .status-text .desc span{ display:block; font-size:14px; line-height:16px; color:#C48000; font-weight:600; margin:0; padding:0; }

.payment-block .tab-field .order-block .block.light-red{ background:#F7DBC3; }
.payment-block .tab-field .order-block .block.light-red .heading h3{ color:#C48000; }

.payment-block .tab-field .order-block .block.light-blue{ background:#C3E9F7; }
.payment-block .tab-field .order-block .block.light-blue .heading h3{ color:#016388; }

.payment-block .tab-field .tab-content{ margin:0; padding:0; }
.payment-block .tab-field .tab-content .ant-row{ display:flex; align-items:center; width:100%; }
.payment-block .tab-field .tab-content .top-block .pricing-side{ margin:0; padding: 40px 125px 65px 0; border-right:1px solid rgba(0,0,0,.1); text-align: center; }

.payment-block .tab-field .tab-content .top-block .pricing-side .user-features{ margin:0; padding:0 0 50px 0; }
.payment-block .tab-field .tab-content .top-block .pricing-side .user-features .content{ display:block; font-size:25px; line-height:27px; color:#000; font-weight:400; margin:0; padding:0; }
.payment-block .tab-field .tab-content .top-block .pricing-side .user-features .price-part{ display:block; font-size:130px; line-height:110px; color:#000; font-weight:400; margin:25px 0; padding:0; }
.payment-block .tab-field .tab-content .top-block .pricing-side .user-features .price-part .dolar{ font-size:60px; line-height:72px; vertical-align:top; }

.payment-block .tab-field .tab-content .top-block .pricing-side .user-features .price-part .off-part{ display:inline-block; width:55px; background:#FFEEC9; font-size:16px; line-height:20px; vertical-align:top; }

.payment-block .tab-field .tab-content .top-block .pricing-side .desc{ margin:0; padding:0; }
.payment-block .tab-field .tab-content .top-block .pricing-side .desc p{ font-size:14px; line-height:16px; color:#9D9D9D; font-weight:400; text-transform:uppercase; margin:0; padding:0; }
.payment-block .tab-field .tab-content .top-block .pricing-side .desc p span{ display:block; color:#000; text-transform:none; padding:10px 0 0 0; }

.payment-block .tab-field .tab-content .top-block .form-block{ margin:0; padding:0 0 0 125px; }
.payment-block .tab-field .tab-content .top-block .form-block .box{ margin:0; padding:0 0 20px 0; }
.payment-block .tab-field .tab-content .top-block .form-block .box label{ display:block;  font-size:14px; line-height:16px; color:#100404; font-weight:600; margin:0; padding:0 0 5px 0; }
.payment-block .tab-field .tab-content .top-block .form-block .box .ant-input{ width:100%; height:38px; border:1px soliod #C1C1C1; border-radius:0; box-shadow:none; outline:none; }
.payment-block .tab-field .tab-content .top-block .form-block .box .ant-input:hover{ border:1px solid #C1C1C1; }
.payment-block .tab-field .tab-content .top-block .form-block .box .ant-input:focus{ border:1px solid #C1C1C1;}

.payment-block .tab-field .tab-content .top-block .form-block .box .text{ float: left; font-size:15px; line-height:17px; color:#100404; font-weight:600; margin:0; padding:0; }
.payment-block .tab-field .tab-content .top-block .form-block .box .amount{ float:right; font-size:20px; line-height:22px; color:#100404; font-weight:600; margin:0; padding:0; }
.payment-block .tab-field .tab-content .top-block .form-block .box .ant-btn{ display:block; width:100%; height:auto; font-size:13px; line-height:15px; color:#fff; text-transform:uppercase; font-weight:600; margin:0; padding:12px 0; background:#001DFF; border:1px solid transparent; box-shadow:none; border-radius:3px; transition:all 600ms ease-in-out; }
.payment-block .tab-field .tab-content .top-block .form-block .box .ant-btn:hover{ background:#0a1eba; }

.payment-block .tab-field .tab-content .bottom-block{ padding:45px 0 55px 0; }

.loading-text{ display:block; position:absolute; width:100%; top:50%; transform:translateY(-50%); left:0; text-align:center; }


.tab-field .tab-content .top-block{ display:inline-block; margin:0; padding:0; }
.tab-field .tab-content .payment-filter{ display:flex; align-items:center; margin:0; padding:45px 0; }
.tab-field .tab-content .label-text{ margin:0 25px 0 0; padding:0; }
.tab-field .tab-content .label-text label{ font-size:18px; line-height:1; color:#000; font-weight:700; margin:0; padding:0; }

.tab-field .tab-content .payment-filter .rt-field{ display:flex; }
.tab-field .tab-content .payment-setting{ margin:0 25px 0 0; padding:0; }
.tab-field .tab-content .payment-setting .ant-radio-button-wrapper{ height:50px; font-size: 15px; line-height: 50px; color: #000; }
.tab-field .tab-content .payment-setting .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{ background:#000; color:#fff; border-color:transparent; box-shadow:none; }

.tab-field .tab-content .dropdown-box{ margin:0; padding:0; }
.tab-field .tab-content .dropdown-box .ant-select-selection--single{ height:50px; font-size: 15px; line-height:1; color: #000; text-transform:uppercase; }
.tab-field .tab-content .dropdown-box .ant-select-selection__rendered{ line-height:49px; }
.tab-field .tab-content .dropdown-box .ant-select-arrow{ color:#000; right: 6px; }


.tab-field .tab-content .team-list{ margin:0; padding:0 30px 0 0; }
.tab-field .tab-content .team-list .block{ border:1px solid #CFCFCF; border-radius:20px; margin:0 0 25px 0; padding:25px 40px 25px 25px; display:flex; align-items:center; justify-content:space-between; position: relative; cursor:pointer; transition:all 220ms ease-in-out; }
.tab-field .tab-content .team-list .block:hover{ background:#f9f9f9; }
.tab-field .tab-content .team-list .block:last-child{ margin-bottom:0; }

.team-list .block .text-field{ width:50%; margin:0; padding:0; }
.team-list .block .text-field h4{ font-size:22px; line-height:1; color:#000; font-weight:600; margin:0; padding:0 0 5px 0; }
.team-list .block p{ font-size:12px; line-height:1.3; color:#000; font-weight:400; margin:0; padding:0; }

.team-list .block .price-content{ margin:0; padding:0; text-align:center; }
.team-list .block .price-content h4{ font-size:45px; line-height:35px; color:#000; font-weight:600; margin:0; padding:0 0 5px 0; }
.team-list .block .price-content .simbol-text{ font-size:20px; line-height:1;     display: inline-block; vertical-align: middle; margin: 0 5px 0 0; }

.team-list .block .check-icon{ position: absolute; right:-16px; top:50%; transform:translateY(-50%); }
.team-list .block .check-icon img{ width:32px; height:auto; }

.team-list .block .check-icon img.color-icon{ display:none; }
.team-list .block .check-icon img.gray-icon{ display:block; }

.team-list .block.active .check-icon img.color-icon{ display:block; }
.team-list .block.active .check-icon img.gray-icon{ display:none; }

.tab-field .tab-content .amount-sec{ margin:0; padding:0 0 0 30px; }
.tab-field .tab-content .amount-sec .up-text{ margin:0; padding:0; border-bottom:1px solid rgba(0,0,0,.1); }
.tab-field .tab-content .amount-sec .up-text h5{ font-size:18px; line-height:1; color:#000; font-weight:700; margin:0; padding:0 0 10px 0; }

.tab-field .tab-content .amount-pyable{ margin:0; padding:0; }
.tab-field .tab-content .amount-pyable table{ width:100%; margin:0; padding:0; border-collapse:inherit; }

.tab-field .tab-content .amount-pyable table tr th{ width:50%; font-size:18px; line-height:1; color:#000; font-weight:700; margin:0; padding:0 0 10px 0; border-bottom:1px solid rgba(0,0,0,.2); }

.tab-field .tab-content .amount-pyable table tbody{ border-bottom:1px solid rgba(0,0,0,.1); }
.tab-field .tab-content .amount-pyable table tr td{ width:50%; font-size:18px; line-height:1; color:#000; font-weight:400; margin:0; padding:15px 45px; border:none !important; }
.tab-field .tab-content .amount-pyable table tr td:last-child{ text-align:right; }


.tab-field .tab-content .amount-pyable tfoot tr td{ border-top:1px solid rgba(0,0,0,.1) !important; }

.tab-field .tab-content .amount-sec .button-block{ margin:0; padding:25px 0 0 0; }
.tab-field .tab-content .amount-sec .button-block .btn{ display:block; width:100%; font-size:16px; line-height:1; color:#fff; font-weight:700; margin:0; padding:18px 0; height:auto; border:none; border-radius:4px; box-shadow:none; outline:none; background:#37006E; transition:all 200ms ease-in-out; }
.tab-field .tab-content .amount-sec .button-block .btn:hover{ background:#671fae; }



.faq-section .heading-text{ margin:0; padding:0; text-align:center; }
.faq-section .heading-text span{ display:block; font-size:16px; line-height:18px; color:#000; font-weight:400; text-transform:uppercase; margin:0; padding:0; }
.faq-section .heading-text h2{ display:block; font-size:52px; line-height:54px; color:#000; font-weight:700; text-transform:uppercase; margin:0; padding:0 0 55px 0; }

.faq-section .accordion-box{ width:75%; margin:0 auto; padding:0; }
.faq-section .accordion-box .Collapsible{ margin:0; padding:0; }
.faq-section .accordion-box .Collapsible .Collapsible__trigger{ display:block; font-size:18px; line-height:20px; color:#4C4C4C; font-weight:600; margin:0; padding:20px 0; border-bottom:1px solid #B2B2B2; cursor: pointer; }
.faq-section .accordion-box .Collapsible .Collapsible__trigger.is-closed{ position: relative; }
.faq-section .accordion-box .Collapsible .Collapsible__trigger.is-closed:before{ content:""; position: absolute; width:30px; height:1px; background:#707070; right:0; top:0; bottom:0; margin:auto 0; }
.faq-section .accordion-box .Collapsible .Collapsible__trigger.is-closed:after{ content:""; position: absolute; width:30px; height:1px; background:#707070; right:0; top:0; bottom:0; margin:auto 0; transform: rotate(90deg); }

.faq-section .accordion-box .Collapsible .Collapsible__trigger.is-open{ position: relative; }
.faq-section .accordion-box .Collapsible .Collapsible__trigger.is-open:before{ content:""; position: absolute; width:30px; height:1px; background:#707070; right:0; top:0; bottom:0; margin:auto 0; }

.faq-section .accordion-box .Collapsible .Collapsible__contentOuter{ margin:0; padding:0; }
.faq-section .accordion-box .Collapsible .Collapsible__contentOuter p{ margin:0; padding:15px 0 20px; }

.faq-section .btn-block{ margin:0; padding:60px 0 0 0; text-align:center; }
.faq-section .btn-block .ant-btn{ display: inline-block; width:300px; height: auto; font-size: 13px; line-height:15px; color: #fff; text-transform: uppercase; font-weight:600; margin: 0 auto; padding: 14px 0; background:#001DFF; border: 1px solid transparent; box-shadow: none; border-radius: 3px; transition: all 500ms ease-in-out; }
.faq-section .btn-block .ant-btn:hover{ background:#0A1EBA; }


/*=========== Payments and Orders End ==========*/

/*=========== Work-Space Start ==========*/
.base-workspaces{ padding:0; align-items:center; background:#EAEAEA; overflow: auto; padding-bottom:48px; }
.base-workspaces .ant-layout-header{ position:sticky; top:0; }

.base-workspaces .login-form{ max-width:565px; padding: 50px 35px 35px; background: #fff; box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%); border: 1px solid rgba(0,0,0,.1); border-radius: 8px; margin-top:58px; }

.workspace-block{ width:950px; margin:58px auto 0; padding:0; }
.workspace-block .left-block{ margin:0; padding:0; position: relative; }

.workspace-block .left-block .lt-field{ margin:0; padding:0; }
.workspace-block .left-block .right-field{ margin:0; padding:0; }


.workspace-block .left-block .text-content{ margin:0; padding:30px; background:#3235F5; }
.workspace-block .left-block .text-content .icon-img{ margin:0; padding:0 0 35px 0; }
.workspace-block .left-block .text-content .icon-img img{ width:42px; height:auto; }

.workspace-block .left-block .text-content h3{ font-size:25px; line-height:1.2; font-weight:600; color:#fff; margin:0; padding:0 0 10px 0; }
.workspace-block .left-block .text-content p{ font-size:15px; line-height:1.4; font-weight:400; color:#fff; margin:0; padding:0 0 30px 0; }

.workspace-block .left-block .workspace-list{ margin:0; padding:0; }
.workspace-block .left-block .ws-button{ margin:0; padding:0; }
.workspace-block .left-block .ws-button .ws-btn{ display:block; width:200px;  font-size:14px; line-height:1; font-weight:700; color:#fff; margin:0 auto; padding:0 18px; border:none; background:#3235F5; height:49px; }
.workspace-block .left-block .ws-button .ws-btn:hover{ background:#5a5cf5; }

.workspace-block .left-block .ws-button .sub-text{ display:block; font-size:13px; line-height:1.2; font-weight:400; color:#929292; margin:0; padding:10px 0 0 0; }
.workspace-block .left-block .ws-button .sub-text a{ display:inline-block; transition:all 500ms ease-in-out; }
.workspace-block .left-block .ws-button .sub-text a:hover{ color:#001DFF; }

.workspace-block .box{ margin:0 0 25px 0; padding:0; background:#fff; box-shadow: 0 3px 6px 0 rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.1); border-radius:8px; }
.workspace-block .box .heading-block{ margin:0; padding:15px 30px 15px 15px; border-bottom:1px solid rgba(0,0,0,.1); position: relative; }    
.workspace-block .box .heading-block h2{ font-size:13px; line-height:15px; color:#1D1C1D; font-weight:400; text-transform:uppercase; margin:0; padding:0; }
.workspace-block .box .heading-block h2 span{ display:inline-block; line-height:0; margin:0 10px 0 0; padding:0; position:relative; top:-1px; }

.workspace-block .box .heading-block .number{ display:flex; align-items:center; justify-content:center; position: absolute; min-width:30px; height:30px; right:30px; top:-15px; border-radius:50%; background:#D30000; font-size:16px; line-height:1; color:#fff; font-weight:500; text-align:center; }

.workspace-block .box .bottom-block{ margin:0; padding:0; }
.workspace-block .box .list-block{ margin:0; padding:15px 30px 15px 15px; display:flex; align-items:center; justify-content:space-between; border-top:1px solid rgba(0,0,0,.1); transition:all 300ms ease-in-out; }

.workspace-block .box .list-block:first-child{ border-top:none; }

.workspace-block .box.work-box .list-block{ padding-top:0; padding-bottom:0; }
.workspace-block .box .list-block:hover{ background:#f9f9f9;  }

.workspace-block .box .bottom-block .wrk-list{ display:flex; align-items:center; justify-content:space-between; width:100%; padding: 12px 0 12px 0; transition:all 600ms ease-in-out; height:auto; background:none; box-shadow:none; outline:none; text-align:left; }

.workspace-block .box .bottom-block  .list-block:last-child a{ border-bottom:none; }


.workspace-block .box .list-block .lt-block{ margin:0; padding:0; display:flex; align-items:center; }
.workspace-block .box .list-block .lt-block .fetures{ width:58px; height:58px; background:#E8E8E8; border-radius:10px; margin:0; padding:0; overflow: hidden; }
.workspace-block .box .list-block .lt-block .text-block{ margin:0; padding:0 0 0 18px; }
.workspace-block .box .list-block .lt-block .text-block h3{ font-size:15px; line-height:17px; color:#030000; font-weight:600; margin:0; padding:0; }
.workspace-block .box .list-block .lt-block .text-block span{ font-size:12px; line-height:14px; color:#BDBDBD; font-weight:600; margin:0; padding:0; }

.workspace-block .box .list-block .btn-block{ margin:0; padding:0; }
.workspace-block .box .list-block .btn-block .ant-btn{ display:inline-block; font-size:14px; line-height:16px; color:#fff; font-weight:700; text-transform:uppercase; margin:0; padding:10px 25px; background:#0AB278; border-radius:6px; height:auto; border:none; box-shadow:none; transition:all 600ms ease-in-out; }
.workspace-block .box .list-block .btn-block .ant-btn:hover{ background:#06895B; }

.workspace-block .box .list-block .btn-block img{ width:15px; height:auto; }

.workspace-block .left-block .right-field .not-item{ display:block; position: relative; overflow: hidden; margin-bottom:25px; }
.workspace-block .left-block .right-field .not-item .blank_text{ position: absolute; width:100%; left:0; top:0; bottom:0; display: flex; align-items: center; justify-content: center; z-index: 1; font-size: 16px; font-weight: 500; color: #000; background: rgba(255,255,255,.4); border-radius:5px; }

.wsphoto-block > div { position: relative; width: 100%; height: 100%; text-align: center; display: flex; align-items: center; justify-content: center; color: #FFF; font-weight: 700; background-size: cover; background-position: center; }

.workspace-block .not-item .box.work-box{ margin:0; }
.workspace-block .not-item .bottom-block{ filter: blur(7px); opacity:.8; }
.workspace-block .not-item .box.work-box .list-block:hover{ background:transparent; }

.workspace-block.login-workspace{  width:100%; margin:0; }
.workspace-block.login-workspace .box{ box-shadow:none; border:none; }
.workspace-block.login-workspace .box .list-block{ margin:0; padding:0; }
.login-workspace .close_btn{ margin:0; padding:0; }
.login-workspace .close_btn .btn{ width:20px; height:20px; border-radius:50%; background:none; display:flex; align-items:center; justify-content:center; margin:0; padding:0; box-shadow:none; outline:none; border: 1px solid rgba(0,0,0,.1); transition:all 200ms ease-in-out; }
.login-workspace .close_btn .btn:hover{ background:rgba(0,0,0,.2); }
.login-workspace .close_btn .btn .anticon{ font-size:10px; line-height:0; color:#000; }
.login-workspace .box.choose-box{ position:relative; z-index:1; transition:all 200ms ease-in-out; cursor:pointer; }
.login-workspace .box.choose-box:after{ content:""; position: absolute; top:-5px; bottom:-5px; left:-5px; right:-5px; background:transparent; z-index:-1; border-radius:8px; transition:all 200ms ease-in-out; }
.login-workspace .box.choose-box:hover:after { background:#f9f9f9; }
.login-workspace .box.choose-box .fetures{ display:flex; align-items:center; justify-content:center; }
.login-workspace .box.choose-box .fetures .anticon{ font-size: 24px; color: #000; }

/*=========== Work-Space End ==========*/

/*=========== Proflie-Page Start ==========*/
.workspace-setting-profile{ overflow:auto; }
.workspace-setting-profile .back-setting-btn{ margin:0; padding:0; text-align:center; display:none; }
.workspace-setting-profile .back-setting-btn .back-btn{ display:block; width:100%; height:auto; color:#333; padding:9px 5px; border:none; border-bottom:1px solid rgba(0,0,0,.2); box-shadow:none; outline:none; border-radius:0; }

.profile-section{ margin:0; padding:60px; position: relative; }
.profile-section .upload-profile{ margin:0; padding:0 0 45px 0; }
.profile-section .upload-profile h3{ font-size:16px; line-height:18px; color:#431B1B; font-weight:700; margin:0; padding:0 0 15px 0; }
.profile-section .upload-profile .block{ display:flex; margin:0; padding:0; }
.profile-section .upload-profile .block .profile-side{ width:200px; margin:0; padding:0; }
.profile-section .upload-profile .block .profile-side .prifile-img{ width:175px; height:175px; background:#EBEBEB; border-radius:10px; margin:0 0 10px 0; padding:0; }

.profile-section .upload-profile .block .profile-side .upload-btn{ margin:0; padding:0; display:flex;     flex-flow: column; }
.profile-section .upload-profile .block .profile-side .upload-btn .img-block{ width:120px; height:125px; margin:0 0 15px 0; padding:0; border-radius:5px; background:#EBEBEB; overflow: hidden; position: relative; }
.profile-section .upload-profile .block .profile-side .upload-btn .img-block img{ width:100% !important; height:100%; object-fit:cover; }

.choose-fiel{ margin:0; padding:0; min-width:140px; height:37px; position: relative; }
.choose-fiel .choose-label{ display: inline-block; width:100%; height:100%;  font-size:14px; line-height: 37px; color:#333; font-weight:600; background:#fff; border: 1px solid rgba(0,0,0,.1); padding: 0 10px; text-align: center; border-radius:5px; transition:all 200ms ease-in-out; }
.choose-fiel .choose-label:hover{ border:1px solid #0065FC; }

.choose-fiel input[type=file] { position: absolute; width:100%; height:100%; left:0; top:0; cursor: pointer; overflow: hidden; box-shadow:none; outline:none; opacity:0; cursor: pointer; }
  

.profile-section .upload-profile .block .profile-side .upload-btn .ant-upload{ width:175px; height:155px; background:#EBEBEB; font-size:14px; line-height:16px; color:#431B1B; font-weight:600; margin:0; padding:4px 15px; border:none; }

.profile-section .upload-profile .block .check-side{ width:550px; margin:0; padding:0 0 0 15px; }
.profile-section .upload-profile .block .check-side .check-box{ margin:0; padding:0 0 15px 0; }
.profile-section .upload-profile .block .check-side .check-box .ant-checkbox + span{ font-size:14px; line-height:16px; color:#431B1B; font-weight:600; padding-left:15px; }

.profile-section .form-section{ max-width:500px; margin:0; padding:0; }
.profile-section .form-section .form-box{ margin:0; padding:0 0 25px 0; }
/* .profile-section .form-section .form-box label{ display:block; font-size:14px; line-height:17px; color:#431B1B; font-weight:700; margin:0; padding:0 0 10px 0; } */
.profile-section .form-section .form-box .ant-input{ height:43px; border:1px solid rgba(0,0,0,.2); color:#000;  box-shadow:none; border:1px solid #D1D1D0; }
.profile-section .form-section .form-box .ant-input:hover{ border: 1px solid #3235F5; }
.profile-section .form-section .form-box .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.profile-section .form-section .form-box .ant-select-selection{ height:43px; border:1px solid rgba(0,0,0,.2); box-shadow:none; }
.profile-section .form-section .form-box .ant-select-selection:hover{ border: 1px solid #3235F5; }
.profile-section .form-section .form-box .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.profile-section .form-section .form-box .ant-select-selection .ant-select-selection-selected-value{ line-height:48px; }
.profile-section .form-section .form-box .ant-select-selection--multiple .ant-select-selection__rendered > ul > li{ height:37px; line-height: 40px; }
 
.profile-section .form-section .form-box .designations-list{ margin:0 0 25px 0; padding:0; display:inline-block; }

.profile-section .btn-field{ position:absolute; top:0; right:0; padding:60px; }


.designations-list li{ width:100%; display:flex; align-items:center; font-size:14px; line-height:1; color:#444; font-weight:600; margin:0; padding:10px; transition:all 150ms ease-in-out; border-radius:2px; }
.designations-list li:hover{ background:#f9f9f9; }
.designations-list li .value-text{ display:inline-block; width:175px; margin:0; padding:0; }
.designations-list li .close-btn{ display:inline-block; font-size:13px; background:none; border:none; color:#333; transition:all 150ms ease-in-out; cursor:pointer; outline:none; margin-left:auto; }
.designations-list li .close-btn:hover{ color:#FF4D4F; }

/*=========== Proflie-Page End ==========*/

/*=========== Members-Page Start ==========*/
.members-field{ margin:0; padding:32px 60px; }
.members-field .upper-block{ display:flex; align-items:center; justify-content:space-between; flex-flow:row-reverse; margin:0; padding:0 0 30px 0; }
.members-field .upper-block .search-block{ width:50%; margin:0; padding:0; }
.members-field .upper-block .search-block .ant-input{ font-size:16px; line-height:18px; color:#000; font-weight:600; height:50px; background:#F8F8F8; border-radius:5px; border:1px solid transparent; box-shadow:none; padding-left: 20px; border:1px solid #D1D1D0; }
.members-field .upper-block .search-block .ant-input:hover{ border: 1px solid #3235F5; }
.members-field .upper-block .search-block .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }


.members-field .upper-block .member-btn{ display:flex; margin:0; padding:0; }
.members-field .upper-block .member-btn .ant-btn{ display:inline-block; width:130px; height:auto; font-size:15px; color: #3235F5; font-weight:600; margin:0 0 0 10px; padding:5px 0; background:#fff; border:1px solid #3235F5; transition:all 200ms ease-in-out; }
.members-field .upper-block .member-btn .ant-btn:hover{ color:#fff; background:#1404D6; }

.members-field .upper-block .member-btn .ant-btn.active{ color:#fff; background:#3235F5; transition:all 200ms ease-in-out; }
.members-field .upper-block .member-btn .ant-btn.active:hover{ background:#1404D6; }

.members-field .ant-table table{ width:100% !important; background:#fff; border-radius:4px; overflow: hidden; }


.members-field .lower-block{ margin:0; padding:0; }
.members-field .lower-block .block{ margin:0; padding: 0 0 60px 0; }
.members-field .lower-block .block .heading-text{ margin:0 0 35px 0; padding:0 0 15px 0; border-bottom:1px solid rgba(0,0,0,0.05); }
.members-field .lower-block .block .heading-text h4{ font-size:12px; font-weight: 700; line-height:18px; color:#333; font-weight:700; margin:0; padding:0; }

.members-field .lower-block .block .item-block{ margin:0; padding:0; }
.members-field .lower-block .block .item-block .item{ margin:0; padding:0; text-align:center; }
.members-field .lower-block .block .item-block .item .img-block{ width:110px; height:110px; border-radius:40px; background:#EAEAEA url(./assets/images/user-icon.svg); background-size: cover; background-repeat: no-repeat;  margin:0 auto 15px; padding:0; }
.members-field .lower-block .block .item-block .item .text-block{ margin:0; padding:0; }
.members-field .lower-block .block .item-block .item .text-block h5{ font-size:17px; line-height:19px; color:#431B1B; font-weight:700; margin:0; padding:0 0 5px 0; }
.members-field .lower-block .block .item-block .item .text-block span{ display:block; font-size:14px; line-height:16px; color:#BDAFAF; font-weight:400; margin:0; padding:0; }

.members-field .ant-table-thead > tr > th, .members-field .ant-table-tbody > tr > td{ padding:8px 15px; }
.members-field .ant-table-tbody > tr > td:last-child{ border-right:none; }
.members-field .ant-table-tbody > tr > td .btn{ color:#3235F5; font-weight:500; border:none; box-shadow:none; outline:none; background:none; cursor: pointer; }
.members-field .ant-table-tbody > tr > td .delete-members{ color:#808080; }
.members-field .ant-table-tbody > tr > td .delete-members:hover{ color:#FF4D4F; }
.members-field .ant-table-tbody > tr > td .adm-text{ display:inline-block; border-bottom:1px dotted rgba(0,0,0,.5); }

.ant-popover-buttons .ant-btn-primary.ant-btn-sm{ background:#FF4D4F; }

.members-field.workflow-members{ padding:32px 0px; }

.tltp-overlay .ant-tooltip-content{ width:600px; }
.tooltip-table tr td{ padding:10px 0; vertical-align:top; }
.tooltip-table tr td .ant-checkbox-wrapper{ display:flex; color:#fff; }
.tooltip-table .ant-checkbox-inner{ top:3px; background:#3235F5; border-color:#3235F5; }
.tooltip-table .ant-checkbox-disabled .ant-checkbox-inner{ background:gray; border-color:gray !important; }
.tooltip-table .ant-checkbox-disabled + span{ color:gray; }

.tooltip-table .item{ display:flex; }
.tooltip-table .rnd-box{ display:inline-block; width:17px; height:17px; border-radius:50%; background:gray; vertical-align:middle; margin:0 7px 0 0; padding:0; position: relative; top:2px; }
.tooltip-table .item.active .rnd-box { background: green; }
.tooltip-table .item.disabled { color:grey; }

.tooltip-table .item.green .rnd-box{ background:green; color:gray; }
.tooltip-table .item.gray .rnd-box{ background:gray; color:gray; }

.workflows-field{ height:100%; margin:0; padding:30px 60px; }
.workflows-field h2{  font-size:25px; line-height:1.4; color:#080705; font-weight:600; margin:0; padding:0 0 35px 0; }
.workflows-field table{ background:#fff; border-radius:4px; overflow:hidden; }
.workflows-field tbody tr td{ padding-top:6px; padding-bottom:6px; }
.workflows-field tbody tr td a{ font-weight:600; transition:all 200ms ease-in-out; }
/* .workflows-field tbody tr td a:hover{ color:red; } */

.workflows-field tbody tr td .ant-btn{ background:none; box-shadow:none; outline:none; margin:0; padding:0 8px; }

.workflows-field tbody tr td .ant-btn.delete_btn:hover{ color:#FA4C52; }

.workflows-field tbody tr td .btn{ border:none; background:none; box-shadow:none; outline:none; font-size:14px; line-height:1; font-weight:500; color: #1890ff; margin:0; padding:0; cursor: pointer; }
.workflows-field tbody tr td .btn.delete-btn{ color:#808080; }
.workflows-field tbody tr td .btn.delete-btn:hover{ color:red; }

.workflows-field tbody tr td .btn.deactivate-btn{ color:#f56464; }

.dsgn-box label:after{ display:none; }

.workflows-field .upper-block{ margin:0; padding:0 0 35px 0; display:flex; align-items:center; justify-content:space-between; }
.workflows-field .upper-block h2{ padding-bottom:0; }
.workflows-field .upper-block .btn{ display: inline-block; width:auto; height: auto; font-size: 15px; color: #fff; font-weight: 600; margin:0; padding:5px 15px; background:#3235F5; border: 1px solid #3235F5;  }


.detele-workflow-modal .ant-modal-footer div{ display:flex; }
.detele-workflow-modal .ant-modal-footer .ant-btn{ display: inline-block; width: 50%; font-size: 14px; color: #fff; font-weight:600; text-transform: uppercase; margin: 0; padding: 15px 35px; height: auto; border-radius: 0; background: #1890FF; }
.detele-workflow-modal .ant-modal-footer .ant-btn.ant-btn-danger{ background:#FA4C52; }

.checkbox-tsk{ margin:0; padding:0; }
.checkbox-tsk .ant-checkbox-wrapper{ font-size:16px; font-weight:500; }
.checkbox-tsk p{ padding-left:24px; }

.element-dsg-wrapper{ margin:0; padding:0; display:flex; align-items:center; flex-flow:row-reverse; justify-content:space-between; }
.element-dsg-wrapper .flow-diagram{ width:50%; margin:0; padding:0; }

.element-dsg-wrapper .text-flow{ width:50%; margin:0; padding:25px 0; display:flex; align-items:center; justify-content:center; border-left:1px solid rgba(0,0,0,.2); }
.element-dsg-wrapper .text-flow .box{ width:25%; margin:0; padding:0; text-align:center; }
.element-dsg-wrapper .text-flow .box h4{ font-size:48px; line-height:1; color:#000; font-weight:700; margin:0; padding:0 0 8px 0; }
.element-dsg-wrapper .text-flow .box span{ display:block; font-size:16px; line-height:1; color:#818181; font-weight:400; margin:0; padding:0; }

.detele-modal .ant-modal-footer div{ display:flex; }
.detele-modal .ant-modal-footer .ant-btn{ display: inline-block; width: 50%; font-size: 14px; color: #fff; font-weight:600; text-transform: uppercase; margin: 0; padding: 15px 35px; height: auto; border-radius: 0; background: #1890FF; }
.detele-modal .ant-modal-footer .ant-btn.ant-btn-danger{ background:#FA4C52; }

/*=========== Members-Page End ==========*/

.fields_holder.fst-holder{ padding: 20px 10px; margin:0; }
.field-contents { top: 35px !important; }
.fields_holder .fixed-add-btn{ background:#fff; box-shadow: 0 1px 35px 0 rgba(0,0,0,.1); }


.fst-holder .content_field .ant-input{ height:35px; border:1px solid #D1D1D0; }
.fst-holder .content_field .ant-input:hover{ border: 1px solid #3235F5; }
.fst-holder .content_field .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }


.user-content{ display:block; margin:0; padding:0 0 25px 0; }
.user-content .data-name{ display: block; padding-right:12px; border-bottom: 1px solid #ddd; padding-bottom: 8px; margin-bottom: 15px; font-size: 13px; }

.user-content .ant-avatar{ margin-right:10px; border-radius:20px; }
.user-content .ant-avatar:last-child{ margin-right:0; }

.ant-modal.rolemodal .perform-performer .user-string{ min-height:inherit; margin-bottom:18px; }

.field_add_btn { float: left; height:auto; font-size: 12px; line-height: 14px; color: #63BC17; font-weight: 700; margin:0 0 0 10px; padding: 7px 20px; background: #fff; border: 1px solid #58B100; border-radius: 3px; border-radius: 50px; width: 100% !important; height: 40px !important; box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1); }
  
  
  .field_add_btn.active{ background: #1890FF; color: #fff; width: 200px; height: 45px; margin: 0 auto !important; display: inline-block; 
    float: left; margin-bottom: 30px !important; position: relative; font-size:15px; font-weight:600; border-radius: 50px !important; width: 150px !important; height: 35px; font-weight: 700; }
  .field_add_btn.active:hover { background: #2e70db }

  .field_add_btn.active:disabled{ float: inherit; background:#F5F5F5 !important; color:gray !important; }

  /* .field_add_btn:hover{ border: 1px solid transparent; background:#58B100; color:#fff; } */
  /* .field_add_btn.active:hover{ border: 1px solid #58B100; background:none; color: #63BC17; } */
  
.preform-mark-btn .field_add_btn.dr_btn .done-btn{ display:block; } 
.preform-mark-btn .field_add_btn.dr_btn:hover .done-btn{ display:none; }

.preform-mark-btn .field_add_btn.dr_btn .re-open{ display:none; }
.preform-mark-btn .field_add_btn.dr_btn .re-open .anticon{ padding-right:4px; }
.preform-mark-btn .field_add_btn.dr_btn:hover .re-open{ display:block; }

/*=========== Main Dashboard Start ==========*/
.main-dashboard{ margin:0; padding:55px 0 60px 0; width:100%; background:none; box-shadow:none; }
.main-dashboard .ant-row{ width:100%; margin:0; padding:0; }

.main-dashboard .field-section{ margin:0; padding:0; }
.main-dashboard .field-section .block{ margin:0; padding:0; }

.field-section .block .ant-collapse{ margin:0; padding:0; border:none; background:none; }
.field-section .block .ant-collapse-item{ margin:0 0 45px 0; padding:0; border-bottom: 2px solid rgba(0,0,0,.1); }
.field-section .block .ant-collapse-item.ant-collapse-item-active{ border-bottom: 2px solid transparent;  }

.field-section .block .ant-collapse-header{ font-size:18px; line-height:20px; color:#151B25; font-weight:600;     padding: 12px 0 12px 25px; border-radius:0; }
.field-section .block .anticon-right{ left:0 !important; }
.field-section .block .ant-collapse-content{ margin:0; padding:0; background:none; border:none; border-top: 2px solid rgba(0,0,0,.1);  }
.field-section .block .ant-collapse-content-box{ margin:0; padding:0 0 20px; }
/* .field-section .block .ant-collapse-content-box p{ font-size:14px; line-height:16px; color:#A7AFB6; font-weight:600; margin:0; padding:0; text-align:center; } */

.field-section .block .item-all{ padding:20px 0 0 0; }
.field-section .block .item-all .perfm_box{ display:inline-block; text-align:center; }
.field-section .block .item-all .item{ display:inline-block; width:162px; margin:0 auto; padding:0 5px; vertical-align:top; transition:all 600ms ease-in-out; cursor: pointer; margin-bottom:20px; }

.field-section .item-all .item .box{ margin:0; padding:5px; text-align:center; transition:all 100ms ease-in-out; border-radius:5px; padding-bottom:15px; border-bottom-left-radius: 5px; border-bottom-right-radius:5px;  } 
.field-section .item-all .item:hover .box{ background:rgba(0,0,0,.05); }

.field-section .item-all .item .box .icon-box{ margin:0; padding:0 5px; width:100%; min-height:36px; background:#B7BFC6; border-radius:5px; text-align:center; position: relative; display:flex; align-items:center; justify-content:space-between; }
.field-section .item-all .item .box .icon-box .icon-img{ display:inline-block; margin:0; padding:10px 18px; text-align:left; visibility: hidden; opacity:1; background:none; border:none; box-shadow:none; }
.field-section .item-all .item:hover .box .icon-box .icon-img{ visibility:visible; opacity:1; }
.field-section .item-all .item .box .icon-box .icon-img img{ width:15px; height:auto; }
.field-section .item-all .item .box .icon-box .main-text{ display:inline-block; font-size:1rem; color:#ffff; }

.field-section .parsant-side{ margin:0; padding:0 0 0 5px; }
.field-section .img_plus{ margin:0; padding:0; display:flex; align-items:center; }
.field-section .img_plus .img_field{ width:25px; height:25px; background:#ccc; border-radius:50%; margin:0 5px 0 0; padding:0; overflow:hidden; }
.field-section .img_plus .img_field img{ width:100%; height:100%; object-fit:cover; }
.field-section .img_plus .text_plus .btn_plus{ display:flex; align-items:center; justify-content:center; width:25px; height:25px; background:rgba(0,0,0,.7); border-radius:50%; font-size:11px; line-height:13px; font-weight:600; color:#fff; margin:0; padding:0; }



.field-section .item-all .item .box .text-block{ margin:0; padding:5px 0 0; text-align:left; padding-left:5px; }
.field-section .item-all .item .box .text-block h4{ font-size:13px; line-height:17px; color:#2d2d2d; font-weight:700; margin:0; padding:0 0 4px 0; }
.field-section .item-all .item .box .text-block span{ display:block; font-size:11px; line-height:14px; color:#98A1AA; font-weight:400; margin:0; padding:0; }

.field-section .item-all .item .box .button-field{ margin:0; padding:0; }
.field-section .item-all .item .box .button-field .ant-btn{ margin: 0 5px; padding: 2px 5px; font-size: 12px; height:auto; }

.field-section .block .due-item .perfm_box{ width: 19.7%; }

.field-section .due-item .item .box .button-field{ margin:0; padding:10px 0 0 0; text-align:left; }
.field-section .due-item .item .box .button-field .ant-btn{ margin: 0 5px 0 0; padding: 2px 5px; font-size: 12px; height:auto; }


.field-section .block .top-filter{ display:flex; align-items:center; justify-content:space-between; margin:0; padding:0 0 35px 0; }
.field-section .block .top-filter .lt-block{ margin:0; padding:0; }

.field-section .block .top-filter h2{  font-size:16px; line-height:1.4; color:#151B25; font-weight:600; padding:0; border-radius:0; }

.field-section .block .top-filter .rt-block{ margin:0; padding:0; }
.field-section .block .top-filter .rt-block .ant-select-selection{ border:1px solid #D1D1D0; }
.field-section .block .top-filter .rt-block .ant-select-selection:hover{ border: 1px solid #3235F5; }
.field-section .block .top-filter .rt-block .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }


.field-section .block .top-filter .form-group{ display:flex; width:100%; margin:0; padding:0; }
.field-section .block .top-filter .form-group .ant-select{ width:250px; }
.field-section .block .top-filter .form-group .ant-select .ant-select-selection__placeholder{ color:#000; }
.field-section .block .top-filter .form-group .ant-select-selection{ height:48px; border:1px solid #D1D1D0; border-right:1px solid transparent; border-radius: 3px 0 0 3px; }
.field-section .block .top-filter .form-group .ant-select-selection:hover{ border: 1px solid #3235F5; }
.field-section .block .top-filter .form-group .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }


.field-section .block .top-filter .form-group .ant-select-selection__rendered{ line-height:46px; }

.field-section .block .top-filter .form-group .search-field{ flex:1; margin:0; padding:0; }
.field-section .block .top-filter .form-group .ant-input{ height:48px;  border-radius:0; border:1px solid #D1D1D0; }
.field-section .block .top-filter .form-group .ant-input:hover{ border: 1px solid #3235F5; }
.field-section .block .top-filter .form-group .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.field-section .block .top-filter .form-group .ant-btn{ width:70px; height:48px; font-size:20px; line-height:1; background:#3235F5; }
.field-section .block .top-filter .form-group .ant-btn:hover{ background:#1404D6; }


.search-field-filter{ flex:1; margin:0; padding:0; }
.search-field-filter .ant-input{ height:48px;  border-radius:0; border:1px solid #D1D1D0; }
.search-field-filter .ant-input:hover{ border: 1px solid #3235F5; }
.search-field-filter .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.search-field-filter .ant-btn{ width:70px; height:48px; font-size:20px; line-height:1; background:#3235F5; }
.search-field-filter .ant-btn:hover{ background:#1404D6; }




.field-section .block .table-view{ margin:0; padding:0; }
.field-section .block .table-view table{ width:100%; border-collapse: collapse; margin:0; padding:0; border: 1px solid #e8e8e8; }
.field-section .block .table-view table tbody tr{ transition:all 200ms ease-in-out; cursor:pointer; }
.field-section .block .table-view table tbody tr:hover{ background:#E7F7FE; }

/* .field-section .block .table-view table tr th, tr td{ border-right: 1px solid #e8e8e8; border-bottom: 1px solid #e8e8e8; } */
.field-section .block .table-view table tr th{ background:#FAFAFA; padding:16px; }
.field-section .block .table-view table tr td{ padding:5px 16px; }
.field-section .block .table-view table tr td:first-child{ background:#333; color:#fff; }

.profile_user{ display:flex; margin:0; padding:0; }
.profile_user .profile{ margin-right:7px; }
.profile_user .profile:last-child{ margin-right:0; }

.member-modal .ant-modal-footer div{ display:flex; }
.member-modal .ant-modal-footer .ant-btn{ display: inline-block; width: 50%; font-size: 14px; color:#1890FF; font-weight:600; text-transform: uppercase; margin:0; padding: 15px 35px; height: auto; border-radius: 0; background:#ccc;  }
.member-modal .ant-modal-footer .ant-btn.ant-btn-danger{ background:#40A9FF; color:#fff; }

.recent-loader{ position:fixed; top:50%; left:50%; transform:translate(-50%, -50%); }


/* .performperformer-madal{ width: 700px !important; } */
.performperformer-madal .ant-modal-body{ padding-bottom:55px; }
.performperformer-madal .ant-table-tbody > tr > td { border-right:none !important; }
.performperformer-madal .ant-table-tbody > tr > td .remove-btn{ background:#df5562; color:#fff; font-size:14px; line-height:1; font-weight:600; }

.performperformer-madal-member .performers-add-btn{ position: absolute; right:30px; top:11px; }

.recent-table-view{ margin:0; padding:0; }
.recent-table-view h2{ font-size:14px; line-height:1; color:#D1D1D1; font-weight:400; text-transform:uppercase; letter-spacing:0.60px; margin:0 0 10px 0; padding:0 0 10px 0; border-bottom:1px solid rgba(0,0,0,.1); }

.recent-table-view .table-field{ margin:0; padding:0; }

.recent-table-view .table-field ul{ margin:0; padding:0; }
.recent-table-view .table-field ul li{ margin:0 0 9px 0; padding:15px; border-radius:10px; cursor: pointer; }
.recent-table-view .table-field ul li:hover{ background:#F9F9F9; }
.recent-table-view .table-field ul li .ant-row{ display:flex; align-items:center; }
.recent-table-view .table-field ul li .box{ margin:0; padding:0; }
.recent-table-view .table-field .box h4{ font-size:18px; line-height:1; color:#000; font-weight:700; margin:0; padding:0 0 5px 0; }
.recent-table-view .table-field .box h6{ font-size:15px; line-height:1; color:#000; font-weight:500; margin:0; padding:0; }
.recent-table-view .table-field .box .theme-color-box{ display:inline-block; width:14px; height:14px; margin:0 8px 0 0; padding:0; vertical-align: bottom; }
.recent-table-view .table-field .box p{ font-size:14px; line-height:1; color:#2b2b2b; font-weight:400; margin:0; padding:0 0 8px 0; }
.recent-table-view .table-field .box .update-text{ font-size:14px; color:#7C7C7C; padding:0; }

.recent-table-view .table-field .profile_user{ justify-content:flex-end; }

.recent-table-view .btn-block{ margin:45px 0 100px; padding:0; text-align:center; }
.recent-table-view .btn-block .common-btn{ min-width:125px; height:42px; }
.recent-table-view .btn-block .common-btn:disabled{ background: #808080 !important; opacity:.5; }


.prm-tooltip .ant-tooltip-inner{ background:rgba(255,255,255,1); }
.prm-tooltip .ant-tooltip-content{ min-width:300px; }
.tooltip-field p{ color:#000; line-height:1.3; padding-bottom:8px; margin-bottom:0; }
.tooltip-field ul li{ color:#000; padding-bottom:8px; }


/*=========== Main Dashboard End ==========*/

/*=========== Branch Page Start ==========*/
.workspace-branch{  position:relative; margin:0; padding:0; }

.branch-head{ margin:0; padding:30px 60px; display:flex; align-items:center; justify-content:space-between; position: absolute; left: 0; right:0; top:0; z-index:9999; }
.branch-head .lt-block .ant-checkbox-wrapper{ font-size: 15px; font-weight: 500; color: #333; }

.branch-head .rt-block .ant-btn{ font-size:15px; font-weight:600; }


.branch-section{ margin:0; padding:95px 60px 60px; height:100vh; white-space:nowrap; overflow:auto; }

.branch-section .ant-table-body .ant-tag{ border-radius:0; font-weight:600; }

.cm-modal .form-box{ margin:0; padding:0 0 15px 0; }
.cm-modal .form-box .ant-form-item{ margin-bottom:0; }
.cm-modal .check_box .ant-form-item{ margin-bottom:0; }


.cm-modal .ant-modal-footer{ margin-top:0; }
.cm-modal .ant-modal-footer .ant-btn{ display: inline-block; width: 100%; font-size: 14px; font-weight:600; color:#3235F5; text-transform: uppercase; padding: 15px 35px; height: auto; border-radius: 0; background: #ccc; }
.cm-modal .ant-modal-footer .ant-btn.ant-btn-primary{ background:#3235F5; color:#fff; margin-left:0; position: relative; z-index:1; }
.cm-modal .ant-modal-footer .ant-btn.ant-btn-primary:after { content: ''; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: rgba(255,255,255,0.0); z-index: -1; }
.cm-modal .ant-modal-footer .ant-btn.ant-btn-primary:hover:after{ background: rgba(255,255,255,0.1); }


.cm-modal .ant-modal-footer .ant-btn.ant-btn-primary span{  }


.notify-modal{ width:700px !important; }
.notify-fild{ padding-bottom:35px; }
.notify-fild .ant-row h3{ font-size:18px; line-height:1; font-weight:400; color:#6f6f6f; margin:0; padding:0 0 25px 10px; }
.notify-fild .ant-row .gutter-box .sub-heading{ display:inline-block; font-size:20px; line-height:1; font-weight:600; color:#000; margin: 0; padding:0 0 20px 0; }
.notify-fild .ant-select-selection{ height: 37px; border:1px solid #D1D1D0; }
.notify-fild .ant-select-selection:hover{ border: 1px solid #3235F5; }
.notify-fild .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.notify-fild .ant-row .gutter-box .ant-select-selection__rendered{ line-height: 35px; }
.notify-fild .ant-row .gutter-box .user-label{ display:inline-block; font-size:14px; line-height:1; font-weight:600; color:#8C8C8C; margin: 0; padding:0 0 12px 0; }
.notify-fild .row-span{ padding:0; position: relative; top:28px; }
.notify-fild .row-span span{ display:block; font-size:18px; text-align:center; }


.notify-fild .ant-row .gutter-box .ant-select-selection--multiple{ min-height:48px; }
.notify-fild .ant-row .gutter-box .ant-select-selection--multiple .ant-select-selection__rendered{ position: relative; top:2px; }
.notify-fild .ant-row .gutter-box .ant-select-selection--multiple .ant-select-selection__rendered ul li{ height: 35px; line-height: 32px; border-radius: 20px; width:auto; }
.notify-fild .ant-row .degn-btn{ padding-top:15px; }
.notify-fild .ant-row .degn-btn .add-plus{ width:35px; height:35px; border:1px solid rgba(0,0,0,.1); border-radius:50%; line-height: 31px; padding:0; }
/* .notify-fild .ant-row .degn-btn .add-plus .anticon-plus{ position: relative; left: 1px; } */

/* .notify-fild .ant-row.dstn-row{ border-top:1px solid rgba(0,0,0,.1); padding-top:25px; margin-top: 20px; } */

/* .notify-fild .ant-row.user-row{ border-top:1px solid rgba(0,0,0,.1); } */
.notify-fild .ant-radio-group label{ display:inline-block; padding:0 18px 0 0; }

.notify-fild .radio-box{ width:60px; margin:0; padding:0; text-align:center; }
.notify-fild .radio-box .and-btn{ display:block; width:100%; font-size:12px; line-height:14px; font-weight:600; color:#ACACAC; border:1px solid rgba(0,0,0,.1); text-transform:uppercase; margin:0 0 20px 0; padding:4px 0; box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015); position: relative; z-index:1; }
.notify-fild .radio-box .and-btn:after{ content:""; position: absolute; width:2px; height:60%; left:0; right:0; top:-17px; margin:0 auto; background:#D9D9D9; }

.notify-fild .radio-box .add-btn{ display:inline-block; width: 35px; height: 35px; border: 1px solid rgba(0,0,0,.1); border-radius: 50%; line-height: 31px; margin:0 auto; padding: 0; position: relative; }
.notify-fild .radio-box .add-btn:after{ content:""; position: absolute; width:2px; height:42%; left:0; right:0; top:-18px; margin:0 auto; background:#D9D9D9; }

.notify-fild .row{ margin:0; padding:0 0 25px 0; }

.notify-fild .block{ border-bottom: 1px solid rgba(0,0,0,.1); margin:0 0 25px 0; padding:0 0 25px 0; }
.notify-fild .block h3{  font-size: 13px; line-height: 1.4; font-weight:600; color: #1A0D0D; margin: 0; padding: 0 0 18px 0; }
.notify-modal .notify-fild .block .ant-btn{ font-weight: 700; color:#fff; background:#2F8FFA; box-shadow:none; outline:none; border:none; }


.notify-fild .sub-heading{ display:block; font-size:14px; line-height:1; font-weight:400; color:#8C8C8C; margin:0; padding:0 0 20px 0; }
.notify-fild .gutter-box{ padding-bottom:20px; }

.notify-fild .block .delete_btn{ text-align: center; }
.notify-fild .block .delete_btn .ant-btn{ background:none; box-shadow:none; outline:none; color:#FA4C52; margin-top:30px; height: 25px; padding:2px 8px 0; }

.notify-fild .block .dstn-row .delete_btn .ant-btn{ margin-top:0; }


/* .notify-fild .block .ant-btn{ border:1px solid #1890FF; font-size:13px; font-weight:600; color:#1890FF; box-shadow:none; outline:none; position: relative; }
.notify-fild .block .ant-btn:focus{ box-shadow:none; outline:none; } */

/* .notify-fild .block .ant-btn:after{ content:""; position: absolute; width:1px; height:50%; left:0; right:0; top:-18px; margin:0 auto; background:#1890FF; } */

.notify-fild.fillter_form .btn-more{ margin:20px 0; padding:0; text-align:center; }
.notify-fild.fillter_form .btn-more .ant-btn{ color:#fff; }

.notify-fild.fillter_form .ant-row .gutter-box .ant-select-selection--multiple{ min-height:inherit; height:37px; }

.notification-inside .notify-fild .ant-row .gutter-box .ant-select-selection--multiple .ant-select-selection__rendered ul li{ width:auto; }

.cm-modal .add-button{ position: absolute; width:100%; left:0; bottom:0; }
.cm-modal .add-button .ntf_btn{ display:block; font-weight:700; width:100%; height:50px; text-transform:uppercase; border-radius:0; z-index:9; }

.cm-modal .ant-modal-body .nft-form-box{ padding-bottom:52px; }

.notification-list .ant-modal-body{ padding:0; }
.notification_all{ margin:0; padding:0; }
.notification_all .ntf_block{ margin:0; padding:24px; border-bottom:1px solid rgba(0,0,0,.1); user-select:none; -webkit-user-select:none; cursor: pointer; }
.notification_all .ntf_block.disabled {
    opacity: 0.25;
    background: #F9F9F9;
    cursor:not-allowed;
}
.notification_all .ntf_block:hover{ margin:0; padding:24px; background:#F9F9F9; }
.notification_all .ntf_block:last-child{ border-bottom:none; }

.notification_all .ntf_block .icon-img{ float:left; width:60px; height:60px; background:#C1C1C1; margin:0 15px 0 0; padding:0; border-radius: 7px; }
.notification_all .ntf_block .text-block{ float:left; max-width:372px; margin:0; padding:0; }
.notification_all .ntf_block .text-block h2{ font-size:18px; line-height:20px; font-weight:600; color:#444; margin:0; padding:0 0 5px 0; }
.notification_all .ntf_block .text-block .sub-text{ display:block; font-size:14px; line-height:1.4; font-weight:400; color:#1E1E1E; margin:0; padding:0; }



/*=========== Branch Page End ==========*/

/*=========== Appsumo Page Start ==========*/
.workspace-comoany-element{ margin:0; padding:0; }

.workspace-comoany-element .upper-block{ display:flex; align-items:center; margin:0 0 60px 0; padding:35px 60px; background:#75dddd; }

.workspace-comoany-element .upper-block .btn-block{ margin:0 0 0 auto; padding:0; }

.apps-element{ flex:1; margin:0 35px 0 0; padding:0; position:relative; }
.apps-element h1{  font-size: 25px; line-height: 1.4; color: #080705; font-weight: 600; margin: 0; padding: 0 0 35px 0; }

.apps-element .apps-list{ position: absolute; left:0; margin:0; padding:0; }
.apps-element .apps-list .box{ display:inline-block; vertical-align:top; width:165px; background:#fff; margin:0 15px 0 0; padding:15px; border-radius:10px; box-shadow:0 4px 30px rgba(0,0,0,.1); }
.apps-element .apps-list .box .label-text{ display:block; font-size:12px; line-height:1; font-weight:400; color:#A6A6A6; margin:0 0 5px 0; padding:0; }
.apps-element .apps-list .box .icon{ display:inline-block; margin:0 2px 0 0; padding:0; position: relative; top: -1px; }
.apps-element .apps-list .box .icon img{ width:auto; height:auto; max-height:11px; }
.apps-element .apps-list .box .icon.user-img img{ max-height:9px; }

.apps-element .apps-list .box .value-text{ display:block; font-size:25px; line-height:1; font-weight:400; color:#787878; margin:0; padding:0; }
.apps-element .apps-list .box .value-text i{ font-size:20px; }

.apps-element .apps-list .box:last-child{ margin-right:0; }


.workspace-comoany-element .table-field{ margin:0; padding:30px 60px; }
.workspace-comoany-element .table-field h2{  font-size: 16px; line-height: 1.4; color: #080705; font-weight: 600; margin: 0; padding: 0 0 30px 0; }
.workspace-comoany-element .table-field table{ background:#fff; border-radius:4px; overflow:hidden; }

/*=========== Appsumo Page Start ==========*/


/*=========== Email Settings Start ==========*/
.email-stng-from{ margin:0; padding:0; }
.email-stng-from .submit-btn .secondary-btn{ font-size: 14px; line-height: 1; font-weight: 600; color: #fff;  box-shadow:none; }

.email-stng-from .checkbox-item{ margin-bottom:5px; }
.email-stng-from .checkbox-item .ant-checkbox-wrapper:hover .ant-checkbox-inner{ border-color:#3235F5; }

.email-stng-from .checkbox-item .ant-checkbox-checked .ant-checkbox-inner{ background:#3235F5; border-color:#3235F5; }

/*=========== Email Settings End ==========*/


/*_____________ Inner Page End ______________*/


/*=========== Create-Modal Srtart ==========*/

/*=========== Create-Modal End ==========*/
.ant-modal .ant-modal-content{ padding:0; }

.ant-modal .ant-modal-content .ant-modal-header{ padding:20px 25px; border-bottom:1px solid rgba(0,0,0,.1); }

.ant-modal .ant-modal-title{  font-size:13px; line-height:1.4; color:#000; font-weight:600; margin:0; padding:0; }

.ant-modal .ant-modal-close{ top:12px; }
.ant-modal .ant-modal-close-x{ width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; border-radius: 50%; transition:all 600ms ease-in-out; }
.ant-modal .ant-modal-close-x:hover{ background: rgba(0,0,0,.1); }

.ant-modal .ant-modal-close-x .anticon{ font-size:22px; color:#fff; }

/* .ant-modal .ant-modal-body{ padding: 0 30px 40px; } */
.ant-modal .ant-modal-body .btn-box{ margin:25px 0 0 0; }
.ant-modal .ant-modal-body .btn-box .btn{ text-transform:capitalize; border:2px solid transparent; color:#fff; background:#0093FF; height:50px; font-size:14px; line-height:1; font-weight:700; border-radius: 0 0 3px 3px; }
.ant-modal .ant-modal-body .btn-box .btn:hover{ border:2px solid transparent; background:#0093FF; }

.ant-modal .ant-modal-body .ant-input{ border:1px solid #D1D1D0; }
.ant-modal .ant-modal-body .ant-input:hover{ border: 1px solid #3235F5 !important; }
.ant-modal .ant-modal-body .ant-input:focus{ border: 1px solid #3235F5 !important; box-shadow: 0px 0px 2px 2px #75DDDD; }
.ant-modal .ant-modal-body .box .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.ant-modal .ant-modal-body .ant-select-selection:hover{ border: 1px solid #3235F5 !important; }
.ant-modal .ant-modal-body .ant-select-selection:focus{ border: 1px solid #3235F5 !important; box-shadow: 0px 0px 2px 2px #75DDDD; }

.ant-modal.field_add .ant-modal-body .ant-input-number{ border:1px solid #D1D1D0; }
.ant-modal.field_add .ant-modal-body .ant-input-number:hover{ border: 1px solid #3235F5; }
.ant-modal.field_add .ant-modal-body .ant-input-number:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.transparent_overlay { position: fixed; top:0; bottom:0; left: 0; right:0;  z-index: 12;}

.task-editor .ant-modal-body{ padding:24px 30px 55px; }

/* .edit-modal .ant-modal-content{ overflow: hidden; } */
.edit-modal .submit-btn{ position: absolute; width:100%; left:0; margin:0; }
.edit-modal .submit-btn .ant-btn{ border: none; width: 100%; margin: 0; padding: 0; height: 48px; border-radius:0; }

.edit-modal .ant-form-item p{ line-height:1; margin:0; padding:0; }

.edit-modal .submit-btn .tst-cnc-btn{ text-align:center; background:#fff; }
.edit-modal .submit-btn .tst-cnc-btn .ant-btn{ width:200px; margin:0 auto; background:none; color:#3235F5; }

.member-modal-box .edit-modal{ max-width:800px; }
 

.workspace-default-pp {float: left; width: 100%; height: 100%; text-align: center; display: flex; justify-content: center; font-size: 100px; align-items: center; text-shadow: 0 1px 0 #cccccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbbbbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaaaaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15); -webkit-transition: .2s all linear; background: #7210c3; color: #fb007b; font-weight: 700; margin: 0; padding: 0;}

.csv-upload .csv-field{ margin:0; padding:0; }
.csv-upload .csv-field .desc-text{ margin:0; padding:0; }
.csv-upload .csv-field .desc-text h3{ font-size:16px; line-height:24px; color:#333; font-weight: 500; margin: 0; padding: 0 0 3px 0; }
.csv-upload .csv-field .desc-text p{ margin:0; padding:0 0 25px 0; }

.csv-upload .csv-field .upload-sec{ display:flex; align-items:center; margin:0; padding:0; }
.csv-upload .csv-field .upload-sec .csv-choose{ margin:0; padding:0; width:140px; height:37px; position: relative; }
.csv-upload .csv-field .upload-sec .csv-choose .choose-label{ display: inline-block; width:100%; height:100%;  font-size:13px; line-height: 37px; color:#fff; font-weight:600; background:#3235F5; border: 1px solid rgba(0,0,0,.1); padding: 0 10px; text-align: center; border-radius:5px; transition:all 200ms ease-in-out; }

.csv-upload .csv-field .upload-sec .csv-choose input[type=file] { cursor: pointer; position: absolute; width:100%; height:100%; left:0; top:0; overflow: hidden; box-shadow:none; outline:none; opacity:0; }
  
.csv-upload .csv-field .upload-sec .csv-choose input[type=file]:before { display: inline-block; content: 'Upload CSV'; width:100%; height:100%;  font-size:13px; line-height: 37px; color:#fff; font-weight:600; background:#001DFF; border: 1px solid rgba(0,0,0,.1); padding: 0 10px; text-align: center; border-radius:5px; transition:all 200ms ease-in-out; }

.csv-upload .csv-field .upload-sec .csv-choose input[type=file]:hover:before{ border:1px solid #0065FC; }
  
.csv-upload .csv-field .upload-sec .csv-choose input[type=file]::-webkit-file-upload-button { visibility: hidden; }
.csv-upload .csv-field .upload-sec .csv-choose input[type=file]::-moz-file-upload-button { visibility: hidden; }

.csv-upload .csv-field .upload-sec .download-btn{ margin:-4px 0 0 0; padding:0 0 0 25px; } 
.csv-upload .csv-field .upload-sec .download-btn .csv-btn{ display:inline-block; font-size:13px; color:#1890ff; font-weight:600; margin:0; padding:0; transition:all 300ms ease-in-out; }
.csv-upload .csv-field .upload-sec .download-btn .csv-btn:hover{ color:#226eb3; }

.main-loader{ position: absolute; z-index: 20938239239; top: 50%; left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%); }
.center-loader { width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.csv-upload .ant-modal-body{ position: relative; }
.csv-upload .ant-modal-body .submit-btn{ padding-bottom:30px; }
.csv-upload .ant-modal-body .ant-btn{ position: absolute; left:0; right:0; bottom:0; height:48px; border-radius:0; }

/*============ Workflow-Field Start ==============*/
.workflow-field{ position: absolute; width:100%; left:0; top:50%; transform:translateY(-50%); margin:0; padding:0; text-align:center; }
.workflow-field img{ width:230px; height:auto; margin:0 auto; }

.workflow-field p{ font-size: 14px; line-height:16px; color:#555; font-weight:600; margin:0; padding:15px 0 0 0; }

.perform-empty-perform{ margin:0; padding:35px 0 0 0; }
.perform-empty-perform img{ width:150px; height:auto; margin:0 auto; }

.perform-empty-perform p{ font-size: 14px; line-height:16px; color:#555; font-weight:600; margin:0; padding:15px 0 0 0; }


/*=========== Dashboard Modal Start =============*/
.dashboard-modal{ width:565px !important; }
.dashboard-modal .template-choose{ margin:0; padding:0; }

.dashboard-modal .card-box{ margin:0 0 20px 0; padding:15px; text-align:center; border: 1px solid rgba(0,0,0,.2);  border-radius: 15px; box-shadow: 0 1px 3px 0 rgba(0,0,0,.1); overflow: hidden; cursor: pointer; }
.dashboard-modal .card-box .icon-block{ width:55px; margin:0 auto; padding:0; }
.dashboard-modal .card-box .icon-block img{ width:100%; height:auto; }
.dashboard-modal .card-box .text-field{ margin:0; padding:18px 0 0 0; }
.dashboard-modal .card-box .text-field p{ font-size: 15px; line-height:1.4; color:#555; font-weight:600; margin:0; padding:0; user-select: none; }

.dashboard-modal .card-box.active{ position: relative; border-color: #5CCE65; }
.dashboard-modal .card-box.active:after{ content: ""; position: absolute; width:22px; height:22px; border-radius: 50%; right:5px; top:5px; background:url(./assets/images/adduser-tick.svg) no-repeat 5px center #5CCE65; background-size:10px; z-index:9; margin:0 auto; } 

.dashboard-modal .ant-modal-footer{ margin:0; }
.dashboard-modal .ant-modal-footer div{ display:flex; align-items:center; margin:0; padding:0; }
.dashboard-modal .ant-modal-footer div .ant-btn{ width:50%; height:50px; font-weight: 700; color:#2996FA; background:#ccc; border-radius:0; }
.dashboard-modal .ant-modal-footer div .ant-btn.ant-btn-primary{ color:#fff; background:#1890FF; margin-left:0; }

/*=========== Dashboard Modal End =============*/

/*=========== Product-Update Page Start =============*/
.updates-content{ margin:0; padding:45px 0; background:#F0F2F5; }
.updates-content .product-listing{ width:550px; margin:0 auto; padding:0; }

.product-listing .top-head{ display:flex; align-items:center; justify-content:space-between; margin:0; padding:0 0 30px 0; }
.product-listing .top-head h2{  font-size:22px; line-height:1.4; color:#000; font-weight:600; margin:0; padding:0; }
.product-listing .top-head .button-side{ margin:0; padding:0; }
.product-listing .top-head .button-side .sbc-btn{ font-weight:600; text-transform: uppercase; }

.product-listing .all-list{ margin:0; padding:0; }
.product-listing .all-list .item-list{ margin:0 0 18px; padding:25px; border:1px solid rgba(0,0,0,.1); background:#fff; box-shadow:0 3px 15px 0 rgba(0,0,0,.1); border-radius:7px; }
.product-listing .all-list .item-list .ant-tag{ text-transform:uppercase; margin-bottom:15px; }
.product-listing .all-list .item-list h3{  font-size:16px; line-height:1.4; color:#3c3c3c; font-weight:600; margin:0; padding:0 0 5px 0; }
.product-listing .all-list .item-list p{ margin:0; padding:0 0 12px 0; }
.product-listing .all-list .item-list .active{ display:inline-block; font-size:14px; line-height:16px; color:#333; font-weight:600; margin:0; padding:0; transition:all 400ms ease-in-out; }
.product-listing .all-list .item-list .active:hover{ color:#52C41A; }


/*=========== Product-Update Page End =============*/


/*=========== Verify-Email Page Start =============*/
.verify-page{ margin:0; padding:0; display:flex; align-items:center; position: relative; }
.verify-field{ max-width:580px; margin:0 auto; padding:100px 15px 0; text-align:center; }
.verify-field h1{ font-size:25px; line-height:27px; color:#000; font-weight:700; margin:0; padding:0 0 10px 0; }
.verify-field .sub-text{ display:block; font-size:20px; line-height:1; color:#000; font-weight:400; margin:0; padding:0 0 35px 0; }
.verify-field p{ font-size:17px; line-height:1.7; color:#555; font-weight:400; margin:0; padding:0 0 35px 0; }

.verify-field .top-block{ display:inline-block; margin:0; padding:0; }
.verify-field .select-box{ margin:0; padding:0; text-align:center; }
.verify-field .select-box label{ display:block; font-size:14px; line-height:1; color:#000; font-weight:500; margin:0; padding:0 0 9px 0; }
.verify-field .select-box .ant-select{ width:375px; }
.verify-field .select-box .ant-select-selection{ height:42px; }
.verify-field .select-box .ant-select-selection__rendered{ line-height:40px; font-weight:500; }


.verify-field .mid-block{ display:block; margin:55px 0; padding:0; position: relative; } 
/* .verify-field .mid-block .loader-center{ position:absolute; top:50%; left:0; transform:translateY(-50%); } */
.verify-field .mid-block .ant-row{ margin:0 !important; padding:0; } 
.verify-field .mid-block .ant-input{ height:50px; border:none; border-radius:10px; box-shadow:none; outline:none; margin:0; padding:0 0 0 7px; font-size:35px; letter-spacing: 65px; text-overflow: clip; width:465px; } 
.verify-field .mid-block .box .ant-input.has-error{
    border-color:red;
}
.verify-field .mid-block .box .ant-input:focus{ border:1px solid #0039F5; }
.verify-field .mid-block p{ padding:50px 0; }

.verify-field .mid-block .verify-line{ display:flex; margin:0; padding:0; }
.verify-field .mid-block .verify-line li{ width:66px; height:1px; background:#595959; margin:0 10px; }

.verify-field .lower-block{ margin:0; padding:0; }
.verify-field .lower-block ul li{ display:block; margin:0; padding:0 0 14px 0; }
.verify-field .lower-block ul li a{ display:inline-block; font-size:13px; line-height:15px; font-weight:600; margin:0; padding:0; }

.notverify-field h1 span{ display:inline-block; width:30px; margin:0 0 0 8px; padding:0; vertical-align:middle; }
.notverify-field h1 span img{ width:100%; height:auto; }
.notverify-field .lower-block ul li a{ background:transparent; padding:20px 35px; border-radius:50px;  transition:all 200ms ease-in-out; }
.notverify-field .lower-block ul li a:hover{ background:#2f8dea; color:#fff; }
.notverify-field .lower-block ul li.email-id a{ font-weight:600; background:#DFEFFF; transition:all 200ms ease-in-out; }
.notverify-field .lower-block ul li.email-id a:hover{ background:#2f8dea; color:#fff; }

.verify-field .bottom-block{ margin:0; padding:0; }
.verify-field .bottom-block .ant-btn{ background:none; border:none; box-shadow:none; outline:none; font-size:14px; line-height:1; color:#3235F5; font-weight:600; }
.verify-field .bottom-block .ant-btn:hover{ opacity:.8; }
.verify-field .bottom-block p{ font-size:14px; line-height:1; color:#404040; font-weight:500; margin:0; padding:15px 0 0 0; }
.verify-field .bottom-block p .link-btn{ padding:0; font-weight:500; }

.auth-modal-base{ margin:0; padding:0 0 45px 0; }
.auth-modal-base .dFlex{ display:flex; margin:0 -5px 30px; padding:0; align-items: center; }
.auth-modal-base .input-box{ margin:0; padding:0 5px; font-weight:500; }

.alert-warning{ background:#fcf8e3; border:1px solid #faebcc; border-radius: 4px; margin:0; padding:15px; }
.alert-warning p{ color:#000; margin:0; padding:0; }
.alert-warning .common-btn:disabled{ background: #ccc !important; opacity: .7; color: #7a7a7a !important; }


.verify-page .bottom-img{ position:fixed; left:0; bottom:0; }
.verify-page .bottom-img img{ width:175px; height:auto; }

/*=========== Verify-Email Page End =============*/

/*=========== Introduction Section Start =============*/
.introduction-sec{ margin:0; padding:65px 0; }
.introduction-sec .block{ width:500px; margin:0; padding:30px; border:1px solid rgba(0,0,0,.1); }
.introduction-sec .block h2{  font-size:25px; line-height:1.4; color:#080705; font-weight:600; margin:0; padding:0 0 5px 0; }
.introduction-sec .block .sub-text{ display:block; font-size:14px; line-height:16px; color:#adabab; font-weight:400; margin:0; padding:0 0 15px 0; }
.introduction-sec .block p{ display:block; font-size:14px; line-height:18px; color:#333; font-weight:400; margin:0; padding:0; }
.introduction-sec .block p span{ display:inline-block; font-size:16px; line-height:18px; font-weight:600; }

.introduction-sec .block .up-block{ margin:0 0 25px 0; padding:0 0 25px 0; border-bottom:1px solid rgba(0,0,0,.1); }

.introduction-sec .block h3{ font-size:20px; line-height:22px; color:#333; font-weight:600; margin:0; padding:0 0 15px 0; }
.introduction-sec .block ul li{ font-size:15px; line-height:17px; color:#333; font-weight:400; margin:0; padding:0 0 10px 0; }

.introduction-sec .block .lower-content{ margin:0; padding:0 0 18px 0; }

.introduction-sec .block .button-sec{ margin:0; padding:0; }
.introduction-sec .block .button-sec .ant-btn{ margin:0 10px 0 0; font-weight:600; }


.ntf_introduction{ padding:0; }
.ntf_introduction .block{ width:auto; border:none; padding:0; }


/*=========== Introduction Section End =============*/

/*=========== Account-Profile Start =============*/
.account_block{ margin:0; padding:0; }

.profile-upload{ width:620px; margin:0 0 40px; padding:0; display:flex; align-items:center; }
.profile-upload .upload-img{ width:85px; height:85px; border-radius:50%; background:#E3E3E3; margin:0; padding:0; position:relative; overflow:hidden; }
.profile-upload .upload-img img{ width:100%; height:100%; object-fit:cover; }

.profile-upload .choose-fiel{ cursor:pointer; margin:0 0 0 35px; padding:0; min-width:inherit; }
.profile-upload .choose-fiel .upload-btn{ border:1px solid rgba(0,0,0,.1); width:145px; height:38px; border-radius:5px; background:transparent; box-shadow:none; outline:none; font-size:14px; line-height:1; font-weight:500; margin:0; padding:0; cursor: pointer; cursor: pointer; }
.profile-upload .choose-fiel .upload-btn:hover{ border:1px solid rgba(0,0,0,.5); }

.profile-upload .choose-fiel .choose-label{ border-radius:50%; }

.profile-upload .upload-img .upload-btn{ width:35px; height:35px; background:#fff; border-radius:50%; border:1px solid rgba(0,0,0,.1); cursor: pointer; }
.profile-upload .upload-img .upload-btn:hover{ box-shadow:0 3px 15px rgba(0,0,0,.2); opacity:.9; border-color: #ccc; }


.profile-upload .name-ac{ margin:0 0 0 16px; padding:0; display:flex; }
.profile-upload .name-ac h2{ font-size:24px; line-height:1; font-weight:500; color:#080705; margin:0; padding:0 0 4px 0; }
.profile-upload .name-ac .email-text{ font-size:15px; line-height:1; font-weight:400; color:#333; margin:0; padding:0; }


.ac_profile{ width:620px; margin:0 auto; padding:0; }
.ac_profile .ant-tabs-bar{ margin:0; border:none; }
.ac_profile .ant-tabs-bar .ant-tabs-nav{ width:100%; margin:0; padding:0; }
.ac_profile .ant-tabs-bar .ant-tabs-tab{ margin:0; width:50%; text-align:center; font-size:15px; line-height:17px; font-weight:700; color:#9D8F8F; text-transform:uppercase; margin:0; padding:0 0 18px 0; }
.ac_profile .ant-tabs-bar .ant-tabs-ink-bar{ background:#454545; }

/* .ac_profile .ant-tabs-content{ background:#fff; border:1px solid rgba(0,0,0,.1); border-radius:10px; } */

.user_profile{ padding:60px;  }
.dp_loader { position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex; align-items: center; justify-content: center; background: rgba(255,255,255,0.5);}

.account-sec{ margin:0; padding:0; }
.account-sec h3{ font-size:22px; line-height:24px; font-weight:600; color:#2C0D0D; margin:0; padding:0 0 25px 0; }
.account-sec .top-block{ margin:0; padding:60px; border-bottom:1px solid rgba(0,0,0,.1); display:block; max-width:100%; }
.account-sec .top-block .ant-form{ max-width:500px; }

.account-sec .top-block.no-border{ border:none; }

.acn-stng{ max-width:700px; }

.profile-section .form-box{ margin-bottom:20px; }

.profile-section .form-box label{ display:block; font-size:14px; line-height:1; font-weight:400; color:#787878; margin:0; padding:0 0 9px 0; }
.profile-section.edit-profile-sec .form-box label{ font-size:15px; font-weight:600; color:#2C0D0D;}
.profile-section .form-box .ant-input{ width:100%; height:42px; border:1px solid #D1D1D0; border-radius:0; }
.profile-section .form-box .ant-input:hover{ border: 1px solid #3235F5; }
.profile-section .form-box .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.profile-section .form-box .ant-calendar-picker{ width:100%; }
.profile-section .form-box .ant-time-picker{ width:100%; }
.profile-section .form-box .ant-time-picker-input{ width:100%; height:42px; border:1px solid #D1D1D0; border-radius:0; }
.profile-section .form-box .ant-time-picker-input:hover{ border: 1px solid #3235F5; }
.profile-section .form-box .ant-time-picker-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.profile-section .form-box .ant-select{ width:100%; }
.profile-section .form-box .ant-select .ant-select-selection{ height:42px; border-radius:0; border:1px solid #D1D1D0; }
.profile-section .form-box .ant-select .ant-select-selection:hover{ border: 1px solid #3235F5; }
.profile-section .form-box .ant-select .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.profile-section .form-box .ant-select .ant-select-selection__rendered{ line-height:42px; }
.profile-section .form-box .profile-btn{ display:inline-block; height:auto; font-size:14px; line-height:16px; font-weight:700; color:#fff; margin:0; padding:12px 45px; background:#3235F5; border-radius:4px; box-shadow:none; border:none; }
.profile-section .form-box .profile-btn:hover{ background:#1404D6; }

.profile-section .form-box .profile-btn.cancel-btn{ background:#ccc !important; margin-right:16px; color:#000 !important; }

.profile-section .form-box p{ font-size:16px; line-height:1; color:#000; font-weight:500; margin:0; padding:0; }
.profile-section .form-box p.desc{ font-size:14px; font-weight:400; padding-bottom:10px; }

.account-sec .bottom-block{ margin:0; padding:60px; }
.account-sec .bottom-block h3{ padding:0 0 0 0; }
.account-sec .bottom-block p{ font-size:14px; line-height:1.4; font-weight:600; color:#AB9F9F; margin:0; padding:0 0 30px 0; }
.account-sec .bottom-block .btn{ display:inline-block; height:auto; font-size:14px; line-height:16px; font-weight:700; color:#fff; margin:0; padding:12px 45px; background:#BF0000; border-radius:4px; box-shadow:none; border:none; }

.account-sec .bottom-block .dFlex{ display:flex; align-items:center; justify-content:space-between; margin:0 0 35px 0; padding:0; }

.lock_key_alert h4{ font-size:20px; line-height:1.2; font-weight:600; color:#000; margin:0; padding:0 0 10px 0; }
.account-sec .bottom-block .lock_key_alert p{ color:#000; font-weight:400; padding-bottom:15px; }

.lock_key_alert .code-text{ margin:0; padding:0 0 25px 0; }
.lock_key_alert .code-text h5{ font-size:16px; line-height:1; font-weight:500; color:#000; margin:0; padding:0; }

.mtd-field{ margin:35px 0 0 0; padding:0; }
.mtd-field ul li{ display:flex; align-items:center; justify-content:space-between; margin:0 0 12px 0; padding:5px 15px; border:1px solid rgba(0,0,0,.1); border-radius:4px; background:#fff; }
.mtd-field .text{ flex:1; margin:0 30px 0 0; padding:0; }
.mtd-field .text h5{ font-size:15px; line-height:1; font-weight:500; color:#000; margin:0; padding:0; }

.mtd-field .btn-box{ margin:0; padding:0;  }
.mtd-field .btn-box .delete-btn{ border:none; box-shadow:none; background:transparent; width:30px; height:30px; border-radius:50%; margin:0; padding:0; }
.mtd-field .btn-box .delete-btn:after{ display:none; }
.mtd-field .btn-box .delete-btn .anticon{ line-height:0; margin-top:3px; }
.mtd-field .btn-box .delete-btn:hover .anticon{ color:#ff4d4f; }
.mtd-field .btn-box .delete-btn:focus .anticon{ color:#ff4d4f; }

.empty-block .img-icon{ width:250px; margin:0 auto; padding:0; }
.empty-block .img-icon img{ width:100%; height:auto; }

.empty-block .text-block{ margin:0; padding:22px 0 0 0; }
.empty-block .text-block p{ font-size: 16px; color:#747474; text-align: center; margin: 0; padding: 0 0 15px 0; }

.profile { display:flex; align-items: center; }
.profile_pic div.round { border-radius: 50%; }
.profile_name { margin-left: 10px; }
.profile_pic div { background-size: cover !important; background-position: center !important; text-align: center; display: flex; align-items: center; justify-content: center; }
.profile_pic.round { border-radius: 50%; justify-content: center; align-items: center; display: flex; }


.account-sec .switch-field{ margin:15px 0 60px 0; padding:0; display:flex; align-items:center; }
.account-sec .switch-field label{ display:inline-block; font-size:14px; line-height:1; font-weight:600; color:#6a6a6a; margin:0; padding:0 35px 0 0; }


.dvc-field{ margin:0; padding:0; }
.dvc-field .block{ margin:0 0 30px 0; padding:0; display:flex; }
.dvc-field .block:last-child{ margin-bottom:0; }
.dvc-field .block span{ display:inline-block; width:35px; height:35px; font-size:14px; line-height:35px; font-weight:500; color:#000; margin:0 15px 0 0; padding:0; text-align:center; border:1px solid rgba(0,0,0,.1); border-radius:50%; }
.dvc-field .content{ margin:0; padding:0; }
.dvc-field .content h4{ display:inline-block; font-size:18px; line-height:1; font-weight:600; color:#333; margin:0; padding:0 0 18px 0; }

.dvc-field .block .dFlex, .dvc-field .block a{ display:inline-block; font-size:14px; line-height:1; font-weight:600; color:#333; margin:0 30px 0 0; padding:0; }
.dvc-field .block a span{ width:auto; height:auto; margin:0; padding:0; border:none; border-radius:0; }
.dvc-field .block a:hover{ opacity:.5; }
.dvc-field .block a:last-child{ margin-right:0; }
.dvc-field .block a .anticon{ font-size:25px; line-height:1; vertical-align:middle; margin-right:10px; }

.dvc-field .block .dFlex span{ width:auto; height:auto; margin:0; padding:0; border:none; border-radius:0; }
.dvc-field .block .dFlex .anticon{ font-size:25px; line-height:1; vertical-align:middle; margin-right:10px; }


.qr-box{ margin:0; padding:0; display:flex; }
.qr-box .qr-img{ width:100%; margin:0; padding:0; }
.qr-box .qr-img img{ width:100%; height:auto; }

.qr-box .text{ flex:1; margin:0; padding:0; }
.qr-box .text h5{ display:inline-block; font-size:17px; line-height:1.3; font-weight:400; color:#000; margin:0; padding:0; max-width:275px; }

.qr-box h6{ font-size:16px; line-height:1.3; font-weight:400; color:red; margin:0; padding:0; }

.dvc-field .block.code-block span{ color:red; border-color:red; }
.dvc-field .block.code-block .qr-box{ display:block; }

.code-box{ display:inline-block; border:1px solid rgba(0,0,0,.2); margin:15px 0 0 0; padding:6px 25px; }


.form-element{ margin:0; padding:30px 0 35px 0; }
.loader-center{ margin:0 0 15px 0; padding:0; text-align:center; }

.form-element .main-cta{ margin:0; padding:0; display:flex; }
.form-element .main-cta .common-btn{ flex:1; height:100%; border-radius:0 0 4px 0; }
.form-element .main-cta .common-btn.back-btn{ background:#ccc !important; color:#000 !important; margin:0; padding:0; border-radius:0 0 0 4px; }
.form-element .main-cta .common-btn:disabled{ background:#ccc !important; opacity:.7; color:#7a7a7a !important; }

.form-element .form-box{ margin:0; padding:0 0 24px 0; }
.form-element .form-box .intl-tel-input{ width:100%; }

.form-element .form-box .selected-flag{ display:flex !important; padding-left:5px; }
.form-element .form-box .selected-dial-code{ font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue, proxima-nova ,sans-serif; font-size:14px; font-weight:400; color: #495057; padding-left:12px !important; }

.form-element .content{ margin:8px 0 0 0; padding:0; }
.form-element .content p{ font-size:15px; line-height:1.3; color:#000; font-weight:400; margin:0; padding:0 0 12px 0; }
.form-element .content h5{ font-size:15px; line-height:1.3; color:#000; font-weight:500; margin:0; padding:0 0 12px 0; }

.scr-modal .form-box{ padding-bottom:55px; }

/* .profile-section .form-box p{ font-size:16px; line-height:1.3; color:#333; font-weight:500; margin:0; padding:0 0 10px 0; } */

.select-group{ display:flex; margin:0; padding:0; }
.select-group .common-btn{ width:85px; height:42px; margin-left:12px; background:none !important; border:1px solid #3235F5; color:#3235F5 !important; transition:all 0ms ease-in-out; }
.select-group .common-btn:hover{ background:#3235F5 !important; border:1px solid #3235F5; color:#fff !important; }


/*=========== Account-Profile End =============*/

/*=========== Fillter Modal Start =============*/
.fillter_form .block{ border:none; margin:0; padding:0; }
.fillter_form .block .gutter-box .ant-calendar-picker{ width:100%; }
.fillter_form .block .gutter-box .ant-calendar-picker .ant-input{ height:37px; border:1px solid #D1D1D0; }
.fillter_form .block .gutter-box .ant-calendar-picker .ant-input:hover{ border: 1px solid #3235F5; }
.fillter_form .block .gutter-box .ant-calendar-picker .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.fillter_form .block .ant-select-selection--multiple .ant-select-selection__choice{ height:auto; display:flex; align-items:center; }

.filter-condition .gutter-box{ padding-bottom:0; }
.filter-condition .gutter-box.button-add{ padding-bottom:25px; }
.filter-condition .conditions-field{ padding-left:0 !important; }

.fillter_form .block  label{ display:block; font-size:14px; line-height:1; font-weight:400; color:#000; margin:0; padding:0 0 8px 0; }

/*=========== Fillter Modal End =============*/

/*=========== Choose-WorkSpace Page Start =============*/
.workspace-logo-h{ position: absolute; width: auto; top: 30px; left: 30px; margin: 0; padding: 0; z-index: 9; }

.base-layout{ margin:0; padding:0 30px; height:100vh; display:flex; align-items:center; }
.base-layout .block{ max-width:500px; margin:0 auto; padding:0; text-align:center; }
.base-layout .up-block{ margin:0; padding:0; }
.base-layout .up-block h2{ font-size:28px; line-height:1.4; color:#080705; font-weight:700; margin:0; padding:0 0 10px 0; }
.base-layout .up-block p{ font-size:14px; line-height:1.4; color:#999; font-weight:400; margin:0; padding:0 30px 35px; }

.base-layout .block .logo{ margin-bottom:45px; }
.base-layout .block .logo a{ display:block; }
.base-layout .block .logo img{ width:130px; height:auto; }

.form-wrapper{ margin:0; padding:0; }
.form-wrapper .ant-input{ height:55px; border:1px solid rgba(0,0,0,.1); border-radius:10px 0 0 10px; color:#333; outline:none; }
.form-wrapper .ant-input:focus{ border: 1px solid #0093ff; box-shadow: 0 0 1px 1px #b9d4e8; }

.form-wrapper .ant-input-group-addon{ border:1px solid rgba(0,0,0,.1); border-left:none; border-radius:0 10px 10px 0; font-size:15px; font-weight:600; color:#080705; padding: 0 25px; }
.form-wrapper .submit_box{ margin:0; padding:12px 0 8px 0; }
.form-wrapper .btn{ font-size:15px; line-height:17px; color:#fff; font-weight:700; margin:0; padding:16px 55px; height:auto; border-radius:30px; }

.form-wrapper .ant-form-item a{ display:inline-block; font-size:14px; line-height:16px; color:#3235F5; font-weight:600; margin:0 0 35px 0; padding:0; transition:all 200ms ease-in-out; }
.form-wrapper .ant-form-item a:hover{ color:#080705; }

.base-layout .lower-block{ margin:0; padding:0; }
.base-layout .lower-block p{ color:#999999; }
.base-layout .lower-block a{ color:#3235F5; transition:all 200ms ease-in-out; }
.base-layout .lower-block a:hover{ color:#080705; }
.diagram-holder{ height:100%; display:flex; align-items: center; justify-content: center; background: #F9F9F9; position:relative; }
.sec-heading {  font-size:1.5rem; line-height:1.4; font-weight:600; cursor: text; }
.sec-heading:hover { text-decoration:underline}

.diagram-holder .up_content{ position: absolute; width:100%; left:0; top:0; margin:0; padding:15px 15px 0; text-align:right; display:flex; flex-flow:row-reverse; align-items:center; justify-content:center; }
.diagram-holder .up_content h2{ font-size: 16px; line-height: 1; color:#555; font-weight: 500; margin:0; padding:0 0 13px 0; }

.diagram-holder .up_content .legend-box{ display:inline-block; margin:0; padding:0; position: absolute; right:15px; }
.diagram-holder .up_content .ant-dropdown-link{ display:flex; align-items:center; justify-content:center; width:32px; height:32px; border:1px solid rgba(0,0,0,.1); margin:0 0 0 auto; padding:0; font-size:15px; color:#333; border-radius:3px; box-shadow:0 3px 8px rgba(0,0,0,.1); }

.stage-menu-dropdown{ position:absolute; top:0; width:150px; right:0; background:#fff; border:1px solid rgba(0,0,0,.1); visibility:hidden; opacity:0; }
.diagram-holder .up_content .legend-box:hover .stage-menu-dropdown{ visibility:visible; opacity:1; }

.diagram-holder .up_content .ant-tabs-bar{ margin-bottom:0; border:1px solid rgba(0,0,0,.2); border-radius:30px; overflow:hidden; }

.diagram-holder .up_content .ant-tabs-ink-bar{ display:none !important; }
.diagram-holder .up_content .ant-tabs-bar .ant-tabs-tab{ margin:0 !important; background:#fff; min-width:95px; text-align:center; border-right:1px solid rgba(0,0,0,.2); padding:9px 5px; line-height:1.2; transition:all 0ms ease-in-out; }

.diagram-holder .up_content .ant-tabs-nav > div  .ant-tabs-tab:last-child{ border-right:none; }


.stage-menu-dropdown .menu-item{ display:flex; align-items:center; font-size: 14px; font-weight:500; color: rgba(0, 0, 0, 0.85); text-align:left; margin:0; padding:6px 10px; }
.stage-menu-dropdown .menu-item .color_fill{ position:relative; top:0; transform:translateY(0); left:0; display: inline-block; width: 15px; height: 3px; margin: 0 10px 0 0; padding: 0; border-bottom: 2px solid rgba(0,0,0,.5); border-radius: 0; }
.stage-menu-dropdown .menu-item .color_fill.dotted-fill{ border-bottom: 2px dotted rgba(0,0,0,.5); }

.diagram-holder .stages-text{ display:flex; justify-content:center; margin:0; padding:0; }
.diagram-holder .stages-text .box{ margin:0; padding:0 12px; display:flex; align-items:center; }
.diagram-holder .stages-text .color_fill{ position:relative; right:0; top:0; transform:translateY(0); display:inline-block; width:12px; height:12px; margin:0 12px 0 0; padding:0; border:1px solid rgba(0,0,0,.5); border-radius:0; }
.diagram-holder .stages-text h5{ font-size:14px; line-height:1; font-weight:600; margin:0; padding:0; }

@media(max-height:550px){
    .diagram-holder{ display:block; text-align:center; padding-top:110px; padding-bottom:65px; overflow:auto; }

}

.ant-drawer-title{  font-size:15px; line-height:1.4; }

/*=========== Choose-WorkSpace Page End =============*/

.field-block { position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: rgba(255,255,255,0.6); display: flex; align-items: flex-end; padding-bottom: 5px; padding-left: 100px; }
.field-block button { background: none; border: none; color: blue; font-weight:600; cursor: pointer; }
.field-block { font-size:12px; }

.error-boundary{ height:100vh; margin:0; padding:0; position: relative; }
.error-boundary .base-error{ position: absolute; width:420px; left:0; right:0; top:50%; margin:0 auto; transform:translateY(-50%); text-align:center; }
.error-boundary .base-error .img-block{ margin:0; padding:0; }
.error-boundary .base-error .img-block img{ width:100%; height:auto; }

.error-boundary .base-error h2{ font-size:22px; line-height:1; font-weight:700; color:#1F2464; margin:0; padding:0 0 15px 0; }
.error-boundary .base-error p{ font-size:14px; line-height:1.3; font-weight:400; color:#333; margin:0; padding:0; }

.accept-base{ height:100vh; margin:0; padding:0 85px; display:flex; align-items:center; justify-content:center; position: relative; }
.accept-base .block{ width:100%; margin:0; padding:0; display:flex; align-items:center; justify-content:space-between; flex-flow:row-reverse; }
.accept-base .block .img-block{ width:50%; margin:0 auto; padding:0; }
.accept-base .block .img-block img{ width:100%; height:auto; }

.accept-base .block .text-block{ width:50%; margin:0; padding:0; }
.accept-base .block .text-block .logo-block{ position: absolute; top:30px; left:30px; margin:0 0 45px 0; padding:0; }
.accept-base .block .text-block h1{ font-size:36px; line-height:1.4; font-weight:700; color:green; margin:0; padding:0 0 15px 0; }
.accept-base .block .text-block p{ font-size:18px; line-height:1.4; font-weight:400; color:#333; margin:0; padding:0 0 30px 0; max-width:450px; }
.accept-base .block .text-block .btn{ display:inline-block; width:170px; height:42px; box-shadow:none; border:none; outline:none; font-size:15px; line-height:1; font-weight:700; color:#fff; margin:0; padding:0 12px; background:#3235F5; border-radius:3px; }
.accept-base .block .text-block .btn:hover{ background:#1404D6; }


/*================== Move Issue Css Start ===============*/
.perform-task-holder{ padding-bottom:0 !important; overflow:inherit; box-shadow:none; }

.perform-task-holder > ul{ padding-bottom:56px; }
.stage-move-block{ margin:0; padding:0; z-index:9; background: #f9f9f9; }
.stage-move-block .btn-stage{ display:block; width:100%; height:55px; font-size: 15px; line-height:1; font-weight:700; border-radius:0 0 10px 10px; margin:0; padding:0; }

.stage-move-block .list-field{  margin:0; padding:0; border-radius:0; }

.stage-move-block .list-field .heading-fill{ margin:0; padding:10px; border-bottom:1px solid rgba(0,0,0,.1); position: relative; background-color: #FFF; }
.stage-move-block .list-field .heading-fill span.sub_heading{ display: block; font-size: 12px; color:#c7c7c7; font-weight:600; text-transform: uppercase; margin:0; padding:0; }
.stage-move-block .list-field .heading-fill h3{ font-size:16px; line-height:1; font-weight:700; color:#000; margin:0; padding:0; text-align:left; margin-top: 5px; }


.stage-move-block .list-field .ant-dropdown-link{ display:block; font-size:16px; line-height:1; color:#000; font-weight:600; margin:0; padding:15px; background:#f4f4f4; border:1px solid rgba(0,0,0,.1); border-radius:3px; position:relative; width:100%; padding:0px 15px !important; }
.stage-move-block .list-field .ant-dropdown-link .anticon{ float:right; font-size:15px; }
.stage-move-block .list-field .ant-dropdown-link:hover{ background:#ededed; }


.color_fill{ position: absolute; width:15px; height:15px; right:15px; top:50%; transform:translateY(-50%); border-radius:50%; }

.item_fill{ position: absolute; width:100%; left:0; top:100%; background:#fff; margin:0; padding:10px 5px; border:1px solid rgba(0,0,0,.1); z-index:99; box-shadow:0 2px 15px rgba(0,0,0,.1); transition: all 100ms ease-in-out; }
.stage-move-block .list-field .ant-dropdown-link:hover .item_fill{ visibility:visible; opacity:1; }

.item_fill span.sub_heading{ display: block; font-size: 12px; color:#c7c7c7; font-weight:600; text-transform: uppercase; margin:0; padding:0 15px 5px; }
.item_fill .block{ margin:0; padding:0; position: relative; }
.item_fill .block .btn{ display:block; width:100%; height:auto; background:none; box-shadow:none; outline:none; border:none; border-radius:5px; font-size:16px; line-height:1; font-weight:700; color:#000; margin:0; padding:15px 15px; text-align:left; transition:all 250ms ease-in-out; }
.item_fill .block .btn:hover{ background:#F9F9F9; }

.btn_move{ margin:0; padding:0; }
.btn_move .btn_undo{ display:block; width:100%; height:auto; background:none; box-shadow:none; outline:none; border:none; border-radius:0; font-size:13px; line-height:1; font-weight:700; color:#EE1111; margin:0; padding:15px 15px; }
.stage-move-block:hover .list-field{ visibility:visible; opacity:1; }
.stage-deadline { opacity:0.4; margin-right: 10px; }
.stage-info-h{
    float:right;
}
.stage-color {
    float: left;
    background: rgb(163, 249, 255);
    width: 10px;
    width: 1;
    height: 10px;
    margin-left: -10px;
    margin-top: 4px;
    margin-right: 5px;
    border-radius: 50%;
}

.align-center {
    text-align: center;
}
.no-bottom-margin{
    margin-bottom: 0px;
}

/*================== Perform-Journey Start ==================*/
.perform-journey{ padding:35px; }
.perform-journey .gutter-box{ margin:0; padding:0; position:relative; z-index:1; }
.perform-journey .gutter-box:after{ content:''; position: absolute; width:1px; top:0; bottom:0; left:0; right:0; margin:0 auto; background:#666; z-index:-1; }

.perform-journey .block_journey{ margin:0; padding:0; }
.perform-journey .block_journey .box{ margin:0; padding:25px; border:1px solid rgba(0,0,0,.5); background:#fff; box-shadow: 0 2px 6px rgba(0,0,0,.2); }
.perform-journey .block_journey .top_heading{ margin:0 0 20px 0; padding:0; position:relative; }
.perform-journey .block_journey .top_heading h2{ font-size:18px; line-height:1; font-weight:700; color:#000; margin:0; padding:0; }
.perform-journey .block_journey .top_heading .color_fill{ right:0; }
.perform-journey .block_journey .value_text{ margin:0; padding:0; }
.perform-journey .block_journey .value_text p{ font-size:16px; line-height:1.3; font-weight:600; color:#707070; margin:0; padding:0 0 3px 0; }
.perform-journey .block_journey .value_text p:last-child{ padding-bottom:0; }

.perform-journey .block_journey .button_field{ margin:0; padding:60px 0; text-align:center; }
.perform-journey .block_journey .button_field .ant-btn{ display:inline-block; font-size:14px; line-height:1.3; font-weight:700; color:#BFBCBC; margin:0; padding:5px 15px; border:1px solid rgba(0,0,0,.2); background:#fff; border-radius:8px; }
.perform-journey .block_journey:last-child .button_field{ padding-bottom:0; }

.perform-journey .block_journey.active .box{ border:3px solid #707070; }
.perform-journey .block_journey.active .button_field .ant-btn{ color:#000; border:1px solid #000; }
.perform-journey .block_journey.active .button_field{ position:relative; z-index:1; }
.perform-journey .block_journey.active .button_field:after{ content:''; position: absolute; width:100%; height:1px; background:#444; left:50%; top:50%; transform:translateY(-50%); z-index:-1; }


.perform-journey .activities-block{ position:absolute; right:0; top:18%; padding-left:0 !important; background:#fff; z-index:1; }
.perform-journey .activities_box{ margin:0; padding:0; border:1px solid rgb(0,0,0,.2); border-radius:12px; overflow:hidden; position:relative; }
.perform-journey .activities_box .heading_box{ margin:0; padding:14px 18px; background:#080808; position: relative; }
.perform-journey .activities_box .heading_box h2{ font-size:20px; line-height:1; font-weight:700; color:#fff; margin:0; padding:0; }

.perform-journey .activities_box .heading_box .close_button{ position:absolute; right:0; top:50%; transform:translateY(-50%); }
.perform-journey .activities_box .heading_box .close_button .ant-btn{ background:none; box-shadow:none; outline:none; border:none; border-radius:0; }
.perform-journey .activities_box .heading_box .close_button .ant-btn .anticon-close{ font-size:19px; color:#fff; opacity:.7; }
.perform-journey .activities_box .heading_box .close_button .ant-btn:hover .anticon-close{ color:#fff; opacity:1; }

.perform-journey .activities_box .body_content{ margin:0; padding:15px; height:450px; overflow:auto; }


.ant-select-dropdown { width: auto !important; max-width: 300px;}

/*================== Perform-Journey End ==================*/

/*================== Welcome-Modal Css Start ==================*/
.welcome-modal{ margin:0; padding:15px 30px; text-align:center; }
.welcome-modal .logo-icon{ width:145px; margin:0 auto; padding:0; }
.welcome-modal .logo-icon a{ display:block; margin:0; padding:0; }
.welcome-modal .logo-icon img{ width:100%; height:auto; }

.welcome-modal .img-block{ margin:0; padding:30px 0; }
.welcome-modal .img-block img{ width:100%; height:auto; }

.welcome-modal .content-block{ margin:0; padding:0; }
.welcome-modal .content-block h4{ font-size:24px; line-height:1; color:#000; font-weight:700; margin:0; padding:0 0 10px 0; }
.welcome-modal .content-block p{ font-size:15px; line-height:1.3; color:#000; font-weight:400; margin:0; padding:0 0 40px 0; }
.welcome-modal .content-block .ant-btn{ font-size:14px; line-height:1; color:#fff; font-weight:700; text-transform:uppercase; margin:0; padding:13px 35px; background:#001DFF; height:auto; }
.welcome-modal .content-block .ant-btn:hover{ background:#161BB6; }
 


/*================== Welcome-Modal Css End ==================*/


/*================== Onboarding-Modal Video Css Start ==================*/
.onboarding-video-modal{ width:932px !important; }
.onboarding-video-modal .ant-modal-body{ padding:0; }

.onboarding-modal{ margin:0; padding:0; }
.onboarding-modal .up-sec{ margin:0; padding:30px; text-align:center; display:none; }
.onboarding-modal .logo-icon{ width:125px; margin:0 auto 15px; padding:0; }
.onboarding-modal .logo-icon a{ display:block; margin:0; padding:0; }
.onboarding-modal .logo-icon img{ width:100%; height:auto; }

.onboarding-modal .bottom-sec{ margin:0; padding:0; display:flex; background:#F5F5F5; }
.onboarding-modal .bottom-sec .list-block{ width:275px; height:500px; margin:0; padding:15px; overflow:auto; cursor:pointer; }
.onboarding-modal .list-block .block{ margin:0 0 15px 0; padding:15px; display:flex; align-items:flex-start; background:#fff; box-shadow:0 1px 2px rgba(0,0,0,.2); border-radius:3px; transition:all 200ms ease-in-out; }
.onboarding-modal .list-block .block:last-child{ margin-bottom:0; }
.onboarding-modal .block .number{ width:25px; margin:0 3px 0 0; padding:0;  }
.onboarding-modal .list-block .block .number span{ display:inline-block; font-size:14px; line-height:1; color:#DEDEDE; font-weight:700; margin:0; padding:0; vertical-align: middle; }

.onboarding-modal .block .text-block{ flex:1; margin:0; padding:0; }
.onboarding-modal .block .text-block h5{  font-size:14px; line-height:1.4; color:#404040; font-weight:600; margin:0; padding:0 0 5px 0; }
.onboarding-modal .block .text-block p{ font-size:12px; line-height:1.4; color:#404040; font-weight:400; margin:0; padding:0; }


.onboarding-modal .block:hover{ background:#f9f9f9; }
.onboarding-modal .block.active{ background:#FC7753; }
.onboarding-modal .block.active .text-block h5{ color:#fff; }
.onboarding-modal .block.active .text-block p{ color:#fff; }


.onboarding-modal .bottom-sec .video-block{ flex:1; margin:0; padding:0; }
.onboarding-modal .bottom-sec .video-block iframe{ width:100%; height:100%; }

/*================== Onboarding-Modal Video Css End ==================*/
/*================== Hierarchy-Section Start ==================*/
/* .branch-section.hierarchy-sec{ padding:0 ; } */
.hierarchy{ margin:0; padding:35px 0 0 35px; position:relative; height:100%; }

.hierarchy .add_branch{ position: absolute; left:0; top:0; font-size: 15px; font-weight:600; color:#000; background:#fff; border: 1px solid #ccc; z-index:99; padding:10px 18px; height:auto; cursor:pointer; }


.hierarchy h3{ font-size:25px; line-height:1; color:#000; font-weight:600; text-align:center; margin:0; padding:0 0 45px 0; }

.hierarchy ul.dfhv{ padding:0 35px; }
.hierarchy ul { display:inline-block; }

.hierarchy ul li{ padding-bottom:15px; position: relative;  }
.hierarchy ul li:last-child{ padding-bottom:0;}

.hierarchy ul ul { margin-top: 35px;}


.hierarchy ul li .value-text{ display:inline-block; margin:0 0 5px 0; padding:0 0 0 65px; position:relative; vertical-align: top; transition:all 150ms ease-in-out; }

.hierarchy ul li .value-text:before{ content:''; position: absolute; width:110%; height:1px; left:-9%; top:50%; transform:translateY(-50%); background:#ccc; }
.hierarchy ul li .value-text .add_btn{ position: absolute; right:5px; top:50%; transform:translateY(-50%); width:22px; height:22px; border:1px solid #ccc; border-radius:3px; z-index:99; padding:0; visibility: hidden; opacity:0; }
.hierarchy ul li .value-text:hover .add_btn{ visibility:visible; opacity:1; }
.hierarchy ul li .value-text .add_btn .anticon{ font-size:12px; color:#5a5a5a; line-height:0; }

.hierarchy ul li .value-text .delete-btn{ position:absolute; top:-8px; right:-8px; z-index:999; background: none; box-shadow: none; outline: none; margin: 0; padding: 0; height: auto; font-size:16px; line-height:0; color:#FF5253; visibility:hidden; opacity:0; transition:all 150ms ease-in-out; }
.hierarchy ul li .value-text:hover .delete-btn{ visibility:visible; opacity:.8; }

.hierarchy ul li:last-child .value-text:after{ content:''; position: absolute; width:1px; top:0; height:50%; left:-20px; background:#ccc; }

.hierarchy ul li .value-text p input { background: none; border:solid 1px transparent; padding: 5px; width: auto; max-width: 125px; }
.hierarchy ul li .value-text p input:hover { border:solid 1px #CCC; }
.hierarchy ul li .value-text p input:focus, .hierarchy ul li .value-text p input:active { border:solid 1px #000; outline: none; }

.hierarchy ul li:after {content:''; position: absolute; width:1px; top:0; bottom:0; left:-20px; background:#ccc;}

.hierarchy ul li:last-child:after{ display:none; }

/* .hierarchy ul.dfhv li:first-child:after{ top:16px; } */

.hierarchy ul li p{ width:160px; font-size:16px; line-height:1; color:#000; font-weight:400;  margin:0; padding:3px 15px; padding-left: 4px; border:1px solid rgba(0,0,0,.2); position:relative; z-index:9; background:#fff; }

.hierarchy ul li.highlighted p { background:#6BDED2;}
.hierarchy ul li .hovered p { background:#FDFF7C;}


/*================== Hierarchy-Section End ==================*/

/*================== Trial Css Start ==================*/
.trial-layout{ margin:0; padding:0; background:#f9f9f9; position: fixed; top:0; bottom:0; width:100%; overflow:auto; }
.trial-field{ margin:62px; padding:60px 0; }

.trial-field .field-sec{ margin:0; padding:0; }
.trial-field .top-block{ margin:0; padding:0 0 25px 0; }

.trial-field h1{ font-size:35px; line-height:1.2; color:#000; font-weight:700; margin:0; padding:0 0 15px 0; }
.trial-field p{ font-size:16px; line-height:1.3; color:#1D1C1D; font-weight:400; margin:0; padding:0 0 45px 0; max-width:545px; }

.trial-field .radio_field{ margin:0; padding:0; }
.trial-field .radio_field .label-text{ font-size:18px; line-height:1; color:#000; font-weight:600; margin:0; padding:0 15px 0 0; }
.trial-field .radio_field .ant-radio-wrapper{ font-weight:600; }


.plan-sec{ margin:0; padding:0; }
.trial-field h4{ font-size:18px; line-height:1; color:#000; font-weight:600; margin:0; padding:0 0 15px 0; }
.trial-field .plans-items{ margin:0; padding:0; }
.trial-field .plans-items ul { margin:0 -8px; padding:0; }
.trial-field .plans-items ul li{ display:inline-block; width:20%; margin:0; padding:0 8px; }
.trial-field .plans-items ul li .box{  font-size:14px; line-height:1.3; color:#1D1C1D; font-weight:600; margin:0; padding:20px 0; border:1px solid rgba(0,0,0,.1); border-radius:6px; cursor:pointer; background:#fff; box-shadow: 0 2px 2px 0 rgba(0,0,0,.1); position:relative; text-align:center; }
.trial-field .plans-items ul li:hover .box{ background:#F5FFFB; }
.trial-field .plans-items ul li:first-child{ margin-left:0; }
.trial-field .plans-items ul li:last-child{ margin-right:0; }
.trial-field .plans-items ul li.active .box{ border:1px solid #00B466; background:#F5FFFB; color:#00B466; }

.trial-field .plans-items ul li span{ display:block; font-size:30px; font-weight:700; }

p.price_label{ font-size: 15px; line-height: 1; color: #000; font-weight:600; margin:0; padding:0 0 15px 0; }

.price-box{ margin:25px 0 0 0; padding:0; max-width:100%; border:1px solid rgba(0,0,0,.2); border-radius:6px; background:#fff; box-shadow: 0 1px 5px 0 rgba(0,0,0,.1); }
.price-box .up-sec{ margin:0; padding:0; border-bottom:1px solid rgba(0,0,0,.2); }
.price-box .up-sec .btn{ display:inline-block; width:50%; font-size:15px; line-height:1; color:#5a5a5a; font-weight:600; margin:0; padding:13px 0; height:auto; border:none; border-right:1px solid rgba(0,0,0,.2); border-radius:6px 0 0 0; box-shadow:none; position:relative; cursor:pointer; text-align:center; }
.price-box .up-sec .btn:hover{ color:#1890FF; }
.price-box .up-sec .btn.active{ background:#E6F7FF; color:#1890FF; }
.price-box .up-sec .btn .badge{ display:flex; align-items:center; justify-content:center; position: absolute; width:40px; height:40px; background:#ff6d35; right:-15px; top:-16px; border-radius:50%; z-index:9; font-size:13px; line-height:1; color:#fff; font-weight:400; margin:0; padding:0; }


.trial-field .lt-sec{ margin:0; padding:0; }
.trial-field .lt-sec h1{ margin:0; padding:0 0 45px 0; }
.trial-field .lt-sec .form-field{ margin:0; padding:0; }

.trial-field .form-field .input-box{ margin:0 0 25px 0; padding:0; }
.trial-field .form-field .input-box label{ display:block; font-size: 16px; line-height: 1; font-weight: 600; color: #080705; letter-spacing: 0; margin: 0; padding: 0 0 8px 0; -webkit-transition: all 600ms ease-in-out; transition: all 600ms ease-in-out; } 

.trial-field .form-field .input-box .ant-select{ width:100%; }
.trial-field .form-field .input-box .ant-select-selection{ width:100%; font-size: 17px; line-height: 1; font-weight: 300; color: #080705; margin: 0; background: #FFF; height: 45px; border: 1px solid #D1D1D0; border-radius: 5px; box-shadow: none; position: relative; padding: 0; display: flex; align-items: center; outline: none !important; box-shadow: none !important; }
.trial-field .form-field .input-box .ant-select-selection:hover{ border: 1px solid #3235F5; }
.trial-field .form-field .input-box .ant-select-selection:focus{ border: 1px solid #3235F5;
    box-shadow: 0px 0px 2px 2px #75dddd !important; }
.trial-field .form-field .common-btn{ width:150px; height:42px; }

.plan-input-box .ant-form-item{ margin-bottom:25px !important; }
.plan-input-box .ant-form-item .ant-form-item-required:before{ display:none; }
.plan-input-box .ant-form-item .ant-input{ font-size: 17px; line-height: 1; font-weight: 300; color: #080705; margin: 0; background: #FFF; height: 45px; border: 1px solid #D1D1D0; border-radius: 5px; box-shadow: none; position: relative; }
.plan-input-box .ant-form-item .ant-input:hover{ border: 1px solid #3235F5; }
.plan-input-box .ant-form-item .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75dddd; }
    
.trial-field .rt-sec{ position:fixed; right:0; width:350px; top:62px; bottom:0; background:#E6F6FF; margin:0; padding:0; display:flex; flex-flow:column; }

.trial-field .rt-sec .info-field{ flex:1; margin:0; padding:30px 20px; overflow:auto; }
.trial-field .rt-sec .block{ margin:0 0 35px 0; padding:0; }

.trial-field .rt-sec .up-field{ margin:0 0 15px 0; padding:0; display:flex; align-items:center; justify-content:space-between; }
.trial-field .rt-sec .up-field h4{ font-size:16px; line-height:1; color:#000; font-weight:600; margin:0; padding:0; }
.trial-field .rt-sec .up-field .ant-btn{ display:inline-block; background:none; outline:none; box-shadow:none; border:1px solid #3235F5; font-size:13px; line-height:1; color:#3235F5; font-weight:400; margin:0; padding:2px 5px; border-radius:3px; }
.trial-field .rt-sec .up-field .ant-btn:hover{ background:#3235F5; border:1px solid transparent; color:#fff; }


.trial-field .rt-sec .block ul li{ margin:0 0 15px 0; padding:0; display:flex; align-items:flex-start; justify-content:space-between; }
.trial-field .rt-sec .block .label-text{ width:105px; font-size:14px; line-height:1; color:#5F676A; font-weight:400; margin:0 15px 0 0; padding:0; }
.trial-field .rt-sec .block .value-text{ flex:1; font-size:14px; line-height:1.2; color:#000; font-weight:500; margin:0; padding:0; text-align:right; }

.trial-field .rt-sec .btn-block{ margin:0; padding:15px 20px; background:#E6F6FF; }
.trial-field .rt-sec .btn-block .common-btn{ display:block; width:100%; height:42px; }

.ant-modal-confirm-error .ant-btn-primary{ 
    font-size: 14px !important;
    line-height: 1 !important;
    font-weight: 600 !important;
    color: #fff !important;
    background: #3235F5 !important;
    position: relative;
    z-index: 1; }


/*================== My-Tasks Css Start ==================*/
.my-tasks{ margin:0; padding:0 0 100px 0; }
.my-tasks .Collapsible{ margin:0 0 15px 0; padding:0; }
.my-tasks .Collapsible .Collapsible__trigger{ display:block;  font-size:16px; line-height:1.4; color:#000; font-weight:600; margin:0; padding:15px 15px 15px 20px; background:url(../src/assets/images/arrow-right-icon.svg) no-repeat left center; background-size:8px; cursor: pointer; border-bottom:1px solid rgba(0,0,0,.1); }
.my-tasks .Collapsible .Collapsible__trigger.is-open{ background:url(../src/assets/images/arrow-down-icon.svg) no-repeat left center; background-size:15px; }

.my-tasks .Collapsible__contentInner{ margin:0; padding-top:15px; }

.my-tasks .Collapsible__contentInner .emptyContainer img{ width:115px; height:auto; }

.table-tasks{ margin:0; padding:0; }
.table-tasks table{ border-collapse: collapse; margin:0; padding:0; width:100%; border: 1px solid #e8e8e8; }

.table-tasks table tr td{ border:none; border-right: 1px solid #e8e8e8; border-bottom: 1px solid #e8e8e8; margin:0; padding: 5px 16px; font-weight:600; color:#000; }
.table-tasks table tr td:first-child{ color:#fff; width:15%; }
.table-tasks table tr:hover td{ background: #e6f7ff; }


.table-tasks table tr td .stage_content{ display: block; width: 100%; padding: 0 6px; border-radius: 3px; border: solid 1px transparent; overflow: hidden; text-overflow: ellipsis; }
.table-tasks table tr td .stage_content:hover{ background: #FFF; border: solid 1px rgba(0,0,0,0.2); }


.table-tasks table tr td .select-fill{ display:inline-block; margin:0 10px 0 0; padding:0; width:20px; height:20px; background: rgba(0,0,0,.03); border-radius:3px; vertical-align:middle; }
.table-tasks table tr td .select-fill.active{ background: #9fd878; }
.table-tasks table tr td .task-name{ display:inline-block; margin:0; padding:0; vertical-align:middle; }


.task-filter{ margin:0; padding:0 0 45px 0; display:flex; align-items:center; justify-content:space-between; }
.task-filter .lt-block{ display:flex; align-items:center; margin:0; padding:0; }
.task-filter h2{ font-size:27px; line-height: 1; color: #000; font-weight:600; margin:0; padding:0; }

.task-filter .tab-btn{ margin:0 0 0 30px; padding:0; }
.task-filter .tab-btn ul li{ display:inline-block; font-size:15px; line-height:1; color:#939292; font-weight:500; margin:0 10px 0 0; padding:8px 12px; border-bottom:2px solid transparent; cursor: pointer; }
.task-filter .tab-btn ul li:hover{ color:#3235F5; }
.task-filter .tab-btn ul li.active{ color:#000; border-bottom:2px solid #000; }

.task-filter .rt-block{ margin:0; padding:0; display:flex; align-items:center; }
.task-filter .rt-block .form-group{ display:flex; align-items:center; margin:0 35px 0 0; padding:0; } 
.task-filter .rt-block .form-group:last-child{ margin-right:0; }
.task-filter .rt-block .form-group .heading-label{ font-size:14px; line-height: 1; color: #000; font-weight:600; margin:0; padding:0 18px 0 0; }
.task-filter .rt-block .form-group .select-box{ width:150px; margin:0; padding:0; }
.task-filter .rt-block .form-group .select-box .ant-select-selection{ height:38px; border-radius:0; border:1px solid #D1D1D0; }
.task-filter .rt-block .form-group .select-box .ant-select-selection:hover{ border: 1px solid #3235F5; }
.task-filter .rt-block .form-group .select-box .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }
.task-filter .rt-block .form-group .select-box .ant-select-selection__rendered{ line-height:36px; }

.task-base{ margin:0; padding:0; }
.task-base .block{ margin:0; padding:0; }
.task-base .block .title-block{ margin:0 0 25px 0; padding:0; text-align:center; position:relative; z-index:1; }
.task-base .block .title-block:after{ content:''; position: absolute; left:0; right:0; top:50%; transform:translateY(-50%); height:1px; background:rgba(0,0,0,.1); z-index:-1; }
.task-base .block .title-block h3{ display:inline-block; font-size:16px; line-height: 1; color:#939292; font-weight:600; margin:0; padding:6px 48px; background:#f0f2f5; }

.task-base .block .emptyContainer{ padding:0 0 30px 0; }


.task-base .data-base{ margin:0; padding:0; }
.task-base .data-base .lt-box{ margin:0; padding:0; }

.task-base .data-base ul{ margin:0 -10px; padding:0; }
.task-base .data-base ul li{ margin:0 0 10px 0; padding:10px; border-radius:7px; }
.task-base .data-base ul li:hover{  background:#f9f9f9; }
.task-base .data-base .lt-box{ margin:0;  padding:0; }

.task-base .data-base .top-field{ display:flex; align-items:center; margin:0 0 5px 0; padding:0; }

.task-base .data-base .check-box{ margin:0 12px 0 0; padding:0; }
.task-base .data-base .check-box .ant-checkbox-inner{ width:22px; height:22px; }

.task-base .data-base .task-request{ margin:0 12px 0 0; padding:0; }
.task-base .data-base .task-request span{ display:inline-block; font-size:12px; line-height: 1; color:#000; font-weight:600; margin:0; padding:7px 10px; border-radius:5px; background:#A3F9FF; border:none; }

.task-base .data-base .checklist-task{ margin:0; padding:0; }
.task-base .data-base .checklist-task .ant-btn{ display:inline-block; background:none; border:none; box-shadow:none; outline:none; font-size:14px; line-height: 1; color:#000; font-weight:500; margin:0; padding:0; height:auto; border-radius:0; }
.task-base .data-base .checklist-task .ant-btn.checked span { 
    text-decoration: line-through;
}

.task-base .data-base .checklist-task .ant-btn:hover{ color:#3235F5; }

.task-base .data-base .bottom-block{ margin:0; padding:0; }
.task-base .data-base .bottom-block .btn{ display:inline-block; background:none; border:none; box-shadow:none; outline:none; font-size:15px; line-height: 1; color:#6D6B6B; font-weight:500; margin:0; padding:0; border-bottom:1px solid #6D6B6B; height:auto; border-radius:0; }
.task-base .data-base .bottom-block .btn:hover{ color:#3235F5; border-bottom:1px solid #3235F5; }
.task-base .data-base .bottom-block .divider-line{ display:inline-block; font-size:16px; line-height: 1; color:#6D6B6B; font-weight:700; margin:0 5px; padding:0; }


.task-base .data-base .rt-box{ float:right; width:285px; margin:0; padding:0; }
.task-base .data-base p{ font-size:14px; line-height: 1; color:#000; font-weight:600; margin:0; padding:0; }

.task-base .data-base .assigned-field{ display:flex; align-items:center; margin:0 0 10px 0; padding:0; }
.task-base .data-base .assigned-field .profile-user{ margin:0 12px 0 0; padding:0; user-select: none; }

.task-base .data-base .date-field{ margin:0; padding:0; }
.task-base .data-base .date-field .icon-img{ display:inline-block; vertical-align: middle; width:26px; margin:0 12px 0 0; padding:0; text-align:center; }
.task-base .data-base .date-field .icon-img .anticon{ font-size:19px; line-height:1; color:#6e6e6e; }

.task-base .data-base .date-field .text-block{ display:inline-block; vertical-align: text-top; text-align:left; margin:0; padding:0; }
.task-base .data-base .date-field .text-block span.daygap{ display:block; font-size:11px; line-height: 1; color:#000; font-weight:400; margin:0; padding:4px 0 0 0; }

/*================== My-Tasks Css End ==================*/

/*================== APIs Css Start ==================*/
.apis-design-layout .up-field{ margin:0 0 30px 0; padding:0 0 30px 0; display:flex; align-items:center; justify-content:space-between; border-bottom:1px solid rgba(0,0,0,.1); }
.apis-design-layout .up-field h2{ margin:0; padding:0; }
.table-tasks table tr td:last-child{ text-align:right; padding:5px; width:74px; }

.apis-design-layout .up-field .api_btn{ font-size:15px; line-height:1; font-weight:600; text-transform:uppercase; height:auto; padding:13px 25px; }

.api_table_design .ant-table-thead > tr > th{ background:none; font-size:15px; font-weight:600; padding:15px 15px; border:none; }
.api_table_design .ant-table-tbody > tr > td{ border:none; padding:6px 15px; font-weight:600; }

.api_table_design .ant-table-tbody > tr > td .secret_btn{ border:1px solid rgba(0,0,0,.1); padding:0 20px; border-radius:15px; font-weight:600; }
.api_table_design .ant-table-tbody > tr > td .secret_btn:hover{ border-color:#3235F5; color:#3235F5; }

.api_table_design .ant-table-tbody .edit_btn{ color:#1890FF; }
.api_table_design .ant-table-tbody .edit_btn:hover{ opacity:.6; }
.api_table_design .ant-table-tbody .delete_btn{ color:#FF4D4F; }
.api_table_design .ant-table-tbody .delete_btn:hover{ opacity:.6; }

.edit_apis{ padding-bottom:25px; }
.edit_apis .block{ margin:0; padding:0 0 25px 0; }
.edit_apis .block label .anticon{ line-height:0; }
.edit_apis .ant-transfer label{ display:inline-block; padding-bottom:0; }


.edit_apis .select_tag{ padding:0 0 0 24px; }
.edit_apis .block .ant-radio-wrapper{ font-weight:400; }

.api_modal .btn-block{ position: absolute; width:100%; left:0; bottom:0; }
.api_modal .btn-block .create_btn{ display:block; width:100%; font-weight:600; border-radius:0; height:auto; padding:12px 0; text-transform:uppercase; }


/*================== APIs Css End ==================*/


/*================== Perform-Journey-Stage Start ==================*/
.perform-journey-stage{ margin:0; padding:15px 0; overflow:hidden; }
.perform-journey-stage .item{ width:auto; min-width:200px; margin:0; padding:0 12px 38px; position:relative; }
.perform-journey-stage .item .box:hover {background:#FFF}
/* .perform-journey-stage .item:before{ content:''; position: absolute; width:16px; height:14px; left:0; right:0; bottom:-21px; background:url(./assets/images/right-arrow-perform-j.svg) no-repeat center rgba(0,0,0,.1); background-size:14px; background-color:transparent; margin:0 auto; transform: rotate(90deg); } */
.perform-journey-stage .item.last-item:before{ display:none; }

.perform-journey-stage .item .box{ background:rgba(255,255,255,.2); border-radius:7px; margin:0; padding:15px 20px; padding-top:13px; min-height:46px; display:flex; align-items:center; cursor: pointer; position:relative; }
.perform-journey-stage .item .box:after{ content:''; position:absolute; right:-15px; width:30px; top:0; bottom:0; background:transparent; }
.perform-journey-stage .item.active .box:after{ content:''; position:absolute; right:-15px; width:30px; top:0; bottom:0; background:#fff; }

.perform-journey-stage .item.active .box{ background:#fff; }


.perform-journey-stage .item .box .task-pf{ flex:1; border-bottom:none; margin:0; padding:0; display:flex; align-items:center; justify-content:center; }
.perform-journey-stage .item .box h3{ color:#fff; font-weight:700; margin:0; padding:0; }

.perform-journey-stage .item .box .color_box{ width:9px; height:100%; border-top-left-radius:7px; border-bottom-left-radius:7px; position: absolute; left:0px; top:0px; }

.perform-journey-stage .item .box .text-block{ flex:1; margin:0; padding:0 3px; z-index:1; }
.perform-journey-stage .item .box .text-block .ant-tag{ position: absolute; top:-12px; left:50%; transform:translateX(-50%); visibility: hidden; opacity:0; }
.perform-journey-stage .item.current .box .text-block .ant-tag{ visibility:visible; opacity:1; letter-spacing:0.5px; }

.perform-journey-stage .item .box .text-block .flex_box{ display:flex; align-items:center; justify-content:space-between; margin:0; padding:0; }

.perform-journey-stage .item .box .text-block h4{ display:inline-block; font-size:22px; line-height:1.1; color:#fff; font-weight:700; margin:0; padding:0 0 4px 0; position:relative; }


.perform-journey-stage .item .box .text-block p{ font-size:13px; line-height:1.3; color:#919191; font-weight:400; margin:0; padding:0 0 8px 0; }
.perform-journey-stage .item .box .text-block p span{ font-weight:600; color:#7c7c7c; }

.perform-journey-stage .item .box .text-block .data-field .stage-info-h{ float: inherit; }
.perform-journey-stage .item .box .text-block .data-field .ant-tag{ margin-right:4px; padding:0 5px; }
.perform-journey-stage .item .box .text-block .data-field .ant-tag:last-child{ margin-right:0; }
.perform-journey-stage .item .box .text-block .data-field .stage-deadline{ color: #fff; opacity:0.8; font-size:12px; line-height:1.6; opacity:1; }
.perform-journey-stage .item .box .text-block .data-field .stage-deadline b{ font-weight:400; }
.perform-journey-stage .item .box:hover .text-block .data-field .stage-deadline{ color:#000; }
.perform-journey-stage .item .box:hover .text-block .data-field .stage-deadline .anticon{ color:#000; }
.perform-journey-stage .item.active .box .text-block .data-field .stage-deadline .anticon{ color:#000; }
.perform-journey-stage .item.active .box .text-block .data-field .stage-deadline{ color:#000; }
.perform-journey-stage .item.active .box .text-block .data-field .stage-deadline .anticon{ color:#000; }
.perform-journey-stage .item .box:hover .text-block .data-field .stage-deadline .anticon{ color:#000; }

.perform-journey-stage .item .box .text-block .data-field .anticon{ font-size:12px; color:#fff; }
.perform-journey-stage .item .box .stage-move-block{ flex:1; }
.perform-journey-stage .slick-slide:last-child .item:after{ display:none; }
.perform-journey-stage .slick-list{ line-height:0; padding-top:12px !important; padding-bottom:12px !important; }
.perform-journey-stage .slick-arrow{ position: absolute; width:30px; height:35px; top:50%; transform:translateY(-50%); border:none; box-shadow:none; outline:none; background:rgba(0,0,0,.1); z-index:99; text-indent: 30px; overflow: hidden; font-size:0; cursor:pointer; opacity:0.5; }
.perform-journey-stage .slick-arrow.slick-disabled{ display:none !important; }


.perform-journey-stage .slick-arrow.slick-next{ right:0; background-image: linear-gradient(to right,rgba(0,0,0,.0) 0, rgba(0,0,0,.6) 100%); border-radius: 0 7px 7px; }
.perform-journey-stage .slick-arrow.slick-next:after, .perform-journey-stage .slick-arrow.slick-prev:after{ content:''; position: absolute; width:10px; height:12px; left:50%; top:50%; transform:translate(-50%, -50%); background:url(./assets/images/next-arrow.svg) no-repeat center rgba(0,0,0,.1); background-size:7px; background-color:transparent; }


.perform-journey-stage .slick-arrow.slick-prev{ background-image: linear-gradient(to left,rgba(0,0,0,.0) 0, rgba(0,0,0,.6) 100%); border-radius: 7px 0 0 7px; }
.perform-journey-stage .slick-arrow.slick-prev:after{ background:url(./assets/images/previous-arrow.svg) no-repeat center rgba(0,0,0,.1); background-size:7px; }

.perform-journey-stage .slick-arrow.slick-next:hover:after, .perform-journey-stage .slick-arrow.slick-prev:hover:after{ opacity:.7; }

.perform-journey-stage .item.current .box .color_box{ animation:zoomOut 2s infinite; }

@keyframes zoomOut {
    0%{ opacity: 0.5; }
    50%{ opacity: 1; }
    100%{ opacity: 0.5; }

}

.item-bx{ margin:0; padding:0; position: relative; }
.item-bx .arw-box{ position:absolute; left:0; right:0; bottom:8px; margin:0; padding:0 0 0 15px; }
.item-bx .arw-box .icon img{ width:auto; height:auto; transform:rotate(45deg); }

.item-bx .arw-box span{ display:inline-block; position: absolute; top: 50%; transform: translateY(-50%); font-size:12px; line-height:1; font-weight:500; color:#fff; margin:0; padding:0 12px 0 20px; }



/*================== Perform-Journey-Stage End ==================*/

/*================== Dashboard Report Css Start ==================*/
.main-dashboard.report-base-layout { padding:15px 0px 15px 0px}
.report-base-layout .ant-row{ height:100%; }


.dashboard-report{ margin:0; padding:0; display:flex; flex-flow:column; height:100%; }
.dashboard-report .up-block{ display:flex; align-items:center; justify-content:space-between; margin:0 0 15px 0; padding:0; }
.dashboard-report .up-block h2{ display:inline-block; font-size:16px; line-height:1; color:#333; font-weight:700; margin:0; padding:0; cursor: pointer; }

.dashboard-report .form-box{ margin:0 0 18px 0; padding:0; }
.dashboard-report .form-box label{ display:block; font-weight:600; margin:0; padding:0 0 5px 0; }
.dashboard-report .form-box .ant-input{ border:1px solid #D1D1D0; }
.dashboard-report .form-box .ant-input:hover{ border: 1px solid #3235F5; }
.dashboard-report .form-box .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.dashboard-report .form-box .ant-select-selection{ border:1px solid #D1D1D0; }
.dashboard-report .form-box .ant-select-selection:hover{ border: 1px solid #3235F5; }
.dashboard-report .form-box .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

            

.dashboard-report .up-block .button-sec{ margin:0; padding:0; display:flex; }
.dashboard-report .up-block .button-sec .btn{ font-size:15px; line-height:1; font-weight:700; margin:0; padding:0 27px; }
.dashboard-report .up-block .button-sec .btn .icon{ display:inline-block; margin:0 10px 0 0; padding:0; vertical-align: text-bottom; }

.dashboard-report .up-block .button-sec .btn.filter-btn{ background:#F8F7FC; border:1px solid rgba(0,0,0,.1); }
.dashboard-report .up-block .button-sec .btn.filter-btn:hover{ border:1px solid #40A9FF; box-shadow:0 1px 12px rgba(0,0,0,.1); }

.dashboard-report .up-block .button-sec .filter-field{ position:relative; margin-right:15px; }
.filter-field .filter-form-box{ position: absolute; width:348px; top:100%; right:0; box-shadow:0 5px 10px rgba(0,0,0,.1); margin:0; padding:0; border-radius:3px; overflow:hidden; z-index:99; background:#fff; visibility:hidden; opacity:0; transition:all 150ms ease-in-out; margin-top:5px; }
.filter-field:hover .filter-form-box{ visibility:visible; opacity:1; }
.filter-field .filter-form-box .top_field{ margin:0; padding:15px 15px 0; }
.filter-field .filter-form-box .filter-btn .btn{ width:100%; border-radius:0 0 3px 3px; }

.report-base-Chart{ height:100%; border:1px solid rgba(0,0,0,.1); overflow:hidden; border-radius:5px; }

.report-drawer .ant-drawer-body{ height:100%; display:flex; flex-flow:column; padding:0; }
.settings-filter{ flex:1; margin:0; padding:24px; overflow:auto; }
.settings-filter .heading-block{ margin:0; padding:15px; display:flex; align-items:center; justify-content:space-between; border-bottom:1px solid rgba(0,0,0,0.1); }
.settings-filter .heading-block h3{ font-size:16px; line-height:1; color:#333; font-weight:700; margin:0; padding:0; }
.settings-filter .heading-block .close-btn{ box-shadow:none; font-size:16px; line-height:1; height:auto; padding:0; background:none; }

.settings-filter .form-field{ margin:0; padding:0; }
.settings-filter .form-field .divider-block{ border-bottom:1px solid rgba(0,0,0,.2); margin:0; padding:20px 0 10px; }
.settings-filter .form-field .divider-block h4{ font-size:16px; line-height:1; color:#333; font-weight:700; margin:0; padding:0 0 15px 0; }
.settings-filter .form-field .divider-block:first-child{ padding-top:0; }
.settings-filter .form-field .divider-block:last-child{ padding-bottom:0; border-bottom:none; }


.settings-form-group .attribute-block .form-box{ margin:0; padding:0 0 20px 0; }
.settings-form-group .attribute-block .form-box label{ display: block; font-size: 14px;line-height: 1; font-weight: 400; color: #000; margin: 0; padding: 0 0 8px 0; }
.settings-form-group .attribute-block .ant-select-selection{ height:37px; }
.settings-form-group .attribute-block .ant-select-selection .ant-select-selection-selected-value{ line-height:36px; }

.settings-form-group .attribute-block .form-box .btn-text{ font-size:14px; line-height:1; color:#000; font-weight:400; margin:0; padding:12px 0; box-shadow:none; outline:none; }
.settings-form-group .attribute-block .form-box .btn-text:after{ display:none; }

.loader-icon-img{ position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); }

.report-drawer .update-btn{ margin:0; padding:0; }
.report-drawer .update-btn .common-btn{ width:100%; height:42px; border-radius:4px 4px 0 0; }


.settings-filter .notify-fild{ padding-bottom:0; }

.report-base-Chart .update-btn{ position: absolute; width:100%; left:0; bottom:0; z-index:9; }
.report-base-Chart .update-btn .btn{ display:block; width:100%; font-size:16px; line-height:1; font-weight:700; border-radius: 0 0 5px 0px; height:auto; margin:0; padding:12px 0; }


.dashboard-report .form-box .btn-text{ height:auto; background:none; border:none; box-shadow:none; outline:none; font-size:13px; line-height:1; color:#3235F5; margin:0; padding:8px 0 0 0; }
.dashboard-report .form-box .btn-text:hover{ color:#1404D6; }

.base_modal_body{ margin:0; padding:0; }

.base_modal_body .form-box{ margin:0; padding:15px 0; border-bottom:1px solid rgba(0,0,0,.1); }
.base_modal_body .form-box .ant-checkbox-wrapper{ display:flex; align-items:center; padding:0; }
.base_modal_body .form-box .ant-checkbox-wrapper p{ margin-bottom:0; }
.base_modal_body .form-box .ant-checkbox-wrapper .profile_pic div{ line-height:0; }
.base_modal_body .form-box .ant-checkbox-wrapper .ant-checkbox{ margin-top:2px; }


/*================== Dashboard Report Css End ==================*/

.field_add .ant-modal-body .form-content .add-plus{ display:inline-block; height:37px; border-radius:0 !important; vertical-align:bottom; }
.field_add .ant-modal-body .form-content .ant-select{ vertical-align: bottom; }
.field_add .ant-modal-body .form-content .ant-select .ant-select-selection--single{ height:37px; border-radius: 2px; }
.field_add .ant-modal-body .form-content .ant-select .ant-select-selection__rendered{ line-height:37px; }
.field_add .ant-modal-body .form-content .ant-select .ant-select-selection__rendered .ant-tag{ margin-left:0; position:relative; top:3px; }

.field_add .ant-modal-body .form-content .op-box{ margin:0 0 8px 0; padding:0; }
.field_add .ant-modal-body .form-content .op-box .option_add_field{ width:182px; display:inline-block; vertical-align:top; }
.field_add .ant-modal-body .form-content .op-box .option_add_field .option-btn{ position: relative; height:auto; left:0; border:none; display:inline-block; width:auto; font-size:14px; line-height:1; color:#1890ff; font-weight:400; margin:7px 0; padding:0; cursor: pointer; }
.field_add .ant-modal-body .form-content .op-box .option_add_field a:hover{ opacity:0.8; }

.field_add .ant-modal-body .form-content .filecontent-box{ margin-bottom:60px; }
.field_add .ant-modal-body .form-content .filecontent-box .form-box{ padding-bottom:20px; } 
.field_add .ant-modal-body .form-content .filecontent-box .field-content{ padding-left:0; padding-right:0; }


.field_add .ant-modal-body .form-content .op-box{ margin:0 0 8px 0; padding:0; }
.field_add .ant-modal-body .form-content .op-box .option_add_field{ width:182px; display:inline-block; vertical-align:top; }
.field_add .ant-modal-body .form-content .op-box .option_add_field a{ display:inline-block; margin:5px 0 0 0; padding:0; }
.field_add .ant-modal-body .form-content .op-box .option_add_field a:hover{ opacity:0.8; }

.field_add .ant-layout-sider ul li .icon-add{ display: inline-block; margin: 0 8px 0 0; padding: 0; width: 16px; text-align: center; position: relative; top: 2px; }
.field_add .ant-layout-sider ul li .icon-add .normal{ display:block; }
.field_add .ant-layout-sider ul li.active .icon-add .normal{ display:none; }
.field_add .ant-layout-sider ul li .icon-add .active{ display:none; }
.field_add .ant-layout-sider ul li.active .icon-add .active{ display:block; }

.field_add .ant-layout-sider ul li .icon-add.rich_text{ top:-1px; }


.input_add_button{ display:flex; }
.input_add_button .ant-input{ flex:1; border-radius: 4px 0 0 4px; }

.input_add_button .add-plus{ margin-left:0 !important; border-radius: 0 0.4em 0.4em 0 !important; outline:none; border:none; box-shadow:none; cursor: pointer; }


.block_option_field{ margin-bottom:15px; }
.block_option_field .ant-btn{ box-shadow:none; margin:0; padding:0; height:37px; }


/*=========== Workflow Area Page Css Start ==========*/
.availabele-block{ display:none; }

.workflow-aria{ width:100%; margin:0; padding:30px; position:relative; }
.workflow-aria .workflows-h{ left:0; position: relative; top:0; margin:0; padding:0; }
.workflow-aria .workflows-h li{ display:flex; margin:0 0 15px 0; padding:8px; border:1px solid rgba(0,0,0,.1); background:#fff; }
.workflow-aria .workflows-h li:last-child{ margin-bottom:0; }
.workflow-aria .workflows-h li a{ height:100%; align-items:center; margin:0; border-radius:10px; padding:3px; }
.workflow-aria .workflows-h li a .workflow_dp{ position: relative; top:0; left:0; transform:translateY(0); line-height:0; }
.workflow-aria .workflows-h li:hover a .workflow_dp{ display:none; }
.workflow-aria .workflows-h li a .workflow_dp.start-icon{ display:none; cursor: pointer; }
.workflow-aria .workflows-h li a .workflow_name{ flex:1; padding-left:0; padding-right:150px; line-height:1.3; max-width:100%; }

.workflow-aria .workflows-h li:hover a .workflow_dp.start-icon{ display:inline-block; }


.workflow-create-btn{ text-align:center; margin:25px 0 0 0; padding:0; }
.workflow-create-btn .action-h{ display:inline-block; width:200px; margin:0 auto; }

.workflow-aria .availabele-block{ display:block; padding-bottom:45px; }
.workflow-aria .availabele-block .workflows-h li .box{ flex:1; display:flex; height:100%;  background:#fff; align-items:center; margin:0; border-radius:10px; padding:10px; position: relative; }
.workflow-aria .availabele-block .workflows-h li .box .add-fill{ position: absolute; top:50%; transform:translateY(-50%); right:5px; }
.workflow-aria .availabele-block .workflows-h .success-icon{ margin-right:3px; }
.workflow-aria .workflows-h li .add-btn{ width:30px; height:30px; border-radius:50%; font-size:22px; line-height:1; color:#fff; font-weight:700; margin:0; padding:0; background:#3235F5; }
.workflow-aria .workflows-h li .add-btn:hover{ background:#1404D6; }

.workflow-aria .availabele-block .workflows-h li a .workflow_name{ padding-right:7px; }
.workflow-aria .availabele-block h2{ margin-bottom:2px; }
.workflow-aria .availabele-block p{ max-width:500px; margin-bottom:18px; }
.workflow-aria .availabele-block .workflow_name{ padding-right:30px; }

.workflow-aria .availabele-block .workflows-h li .add-btn span{ position: relative; top:0; } 


/*=========== Workflow Area Page Css End ==========*/


.price-box .up-sec .btn:last-child{ border-right:none; border-radius:0 6px 0 0; }

.price-box .rupee-field{ margin:0; padding:25px 0; border-bottom:1px solid rgba(0,0,0,.2); text-align:center; }
.price-box .rupee-field .price-text{ display:inline-block; font-size:42px; line-height:1; color:#151515; font-weight:600; margin:0; padding:0; }

.price-box .text-box{ margin:0; padding:25px 0; text-align:center; }
.price-box .text-box p{ font-size:15px; line-height:1; color:#000; font-weight:600; margin:0; padding:0; max-width:100%; }
.price-box .text-box .icon{ display:inline-block; margin:0 2px 0 0; padding:0; position: relative; top:-1px; }
.price-box .text-box .icon img{ width:14px; height:auto; }

.price-box .button-sec{ margin:0; padding:0; }
.price-box .button-sec .btn-start{ display:block; width:100%; height:auto; font-size:15px; line-height:1; font-weight:600; text-transform:uppercase; margin:0; padding:21px 0; border:none; border-radius: 0 0 6px 6px; box-shadow:none; background: #1919d2; }
.price-box .button-sec .btn-start:hover{ background:#1890FF; }

.trial-maodal .ant-modal-body{ text-align:center; position:relative; padding-bottom:65px; }
.trial-maodal .ant-modal-body .profile{ padding-bottom:15px; }
.trial-maodal .ant-modal-body .profile_pic{ margin:0 auto; }
.trial-maodal .ant-modal-body h5{ font-size:20px; line-height:1.3; color:#000; font-weight:600; margin:0; padding:0 0 15px 0; }
.trial-maodal .ant-modal-body p{ font-size:12px; line-height:1; color:#000; font-weight:700; margin:0; padding:0; }
.trial-maodal .ant-modal-body .btn{ position: absolute; width: 100%; left: 0; right: 0; bottom: 0; border-radius:0 0 3px 3px; font-weight:600; text-transform:uppercase; height:40px; }

.trial-modal-content{ margin:0; padding:0; text-align:center; }
.trial-modal-content .img-block{ margin:0 0 45px 0; padding:0; }
.trial-modal-content .img-block img{ width:100%; height:auto; }

.trial-modal-content .text-block{ margin:0; padding:0; }
.trial-modal-content .text-block h3{ font-size:25px; line-height:1; color:#333; font-weight:600; margin:0; padding:0 0 15px 0; }
.trial-modal-content .text-block p{ font-size:14px; line-height:1.3; color:#333; font-weight:400; margin:0; padding:0 0 30px 0; }

.trial-modal-content .text-block .btn-field{ display:flex; flex-flow:column; margin:0; padding:0; }
.trial-modal-content .text-block .btn-field .ant-btn{ display:inline-block; width:70%; height:42px;  font-size:14px; line-height:1.3; color:#fff; font-weight:500; margin:0 auto 10px; padding:0; border:none; }
.trial-modal-content .text-block .btn-field .ant-btn:last-child{ margin-bottom:0; }
.trial-modal-content .text-block .btn-field .ant-btn:hover{ color:#fff; }

.trial-modal-content .text-block .btn-field .ant-btn.primary-btn{ background:#FC7753; color:#fff; font-weight:600; }
.trial-modal-content .text-block .btn-field .ant-btn.primary-btn:hover{ background:#ef7959; }

/*================== Trial Css End ==================*/

/*=========== Invite Page Start ==========*/
.base-invite-sec{ margin:0; padding:0; }

/* .base-invite-sec .lt-field{ margin:0; padding:0; }
.base-invite-sec .lt-field h4{ font-size:25px; line-height:1.2; color:#000; font-weight:600; margin:0; padding:0 0 35px 0; } */

.base-invite-sec .add-field{ margin:0; padding:15px 0 0 0; }
.base-invite-sec .add-field ul{ margin:0; padding:0; }
.base-invite-sec .add-field ul li{ display:flex; align-items:center; margin:0 0 15px 0; padding:0; }
.base-invite-sec .add-field ul li .number-text{ width:25px; font-size:18px; color:#000; font-weight:600; margin:0; padding:0; }
.base-invite-sec .add-field ul li .field-input{ flex:1; margin:0; padding:0; }
.base-invite-sec .add-field ul li .field-input .ant-input{ font-size: 17px; line-height: 19px; font-weight: 300; color: #444; margin: 0; background: #FFF; height: 50px; border: none; border: 1px solid rgba(0,0,0,0.08); border-radius: 5px; box-shadow: none; position: relative; padding:0 15px; }
.base-invite-sec .add-field ul li .field-input .ant-input:focus{ border: 1px solid #0093FF; box-shadow: 0px 0px 1px 1px #b9d4e8; }

.base-invite-sec .add-field .add-btn{ margin:0; padding:15px 0 0 0; display:flex; align-items:center; }
.base-invite-sec .add-field .add-btn p{ font-size: 14px; line-height:1; font-weight:600; color: #333; margin: 0 10px 0 0; padding:0; } 
.base-invite-sec .add-field .add-btn .input-box{ width:80px; margin:0 10px 0 0; padding:0; }
.base-invite-sec .add-field .add-btn .input-box .ant-input{ font-weight:600; }
.base-invite-sec .add-field .ant-btn{ background:none; border:1px solid rgba(0,0,0,.2); font-size:14px; font-weight:600; color:#333; box-shadow:none; outline:none; }
.base-invite-sec .add-field .ant-btn:hover{ border: 1px solid #0093FF; color: #0093FF; }

.base-invite-sec .bottom-block{ margin:0; padding:40px 0 0 0; text-align:center; }
.base-invite-sec .bottom-block .invite-btn{ display:block; width:100%; height:48px; font-size:18px; font-weight:700; background:#1775EE; margin:0; padding:0; }
.base-invite-sec .bottom-block .invite-btn:hover{ background:#1890FF; }
.base-invite-sec .bottom-block .end-field{ margin:0; padding:15px 0 0 0; }
.base-invite-sec .bottom-block .end-field .ant-btn{ display:block; width:100%; background:none; border:none; outline:none; box-shadow:none; font-size:14px; font-weight:600; margin:0; padding:0; }

.base-invite-sec .invite-img{ margin:45px 0 0 0; padding:0; }
.base-invite-sec .invite-img img{ width:100%; height:auto; }



/*=========== Invite Page End ==========*/

/*================== Payment Details Start ==================*/
.payment-layout{ height:100%; }

.payment-details{ margin:0; padding:30px; height:100%; }
.payment-details h2{  font-size: 25px; line-height:1.4; color:#080705; font-weight:600; margin: 0; padding: 0 0 35px 0; }
.payment-details .menu-list{ margin:0; padding:0; }
.payment-details .menu-list ul li{ font-size: 14px; line-height:1; color: #555; font-weight: 700; margin:5px 0 0 0; padding:13px; border-radius: 5px; cursor:pointer; }
.payment-details .menu-list ul li.active{ background: rgba(0, 0, 0, 0.03); }
.payment-details .menu-list ul li:hover{ background: rgba(0, 0, 0, 0.03); }

.payment-details .details-sec{ margin:0; padding:12px 0 0 15px; }
.payment-details .details-sec h3{ font-size:27px; line-height:1; color:#000; font-weight:400; margin:0; padding:0 0 30px 0; }

.payment-details .plan-details{ margin:0; padding:0 0 30px 0; }
.payment-details .plan-details .top-heading{ margin:0 0 22px 0; padding:5px 0; border-bottom:1px solid #E1E1E1; display:flex; align-items:center; justify-content:space-between; }
.payment-details .plan-details .top-heading p{ font-size:12px; line-height:1; color:#A2A2A2; font-weight:600; margin:0; padding:0; }
.payment-details .plan-details .top-heading .simbol-logo{ margin:0; padding:0; }
.payment-details .plan-details .top-heading .simbol-logo img{ width:42px; height:auto; }


.payment-details .plan-details h4{ font-size:16px; line-height:1; color:#4B4B4B; font-weight:700; margin:0; padding:0; }
.payment-details .plan-details table.plan-table{ width:100%; margin:0; padding:0; }
.payment-details .plan-details table.plan-table tr td{ border:none; margin:0; padding:10px 0px; }
.payment-details .plan-details table.plan-table tr td .pdf-btn{ border:none; box-shadow:none; outline:none; background:none; font-size:16px; color:#000; }
.payment-details .plan-details table.plan-table tr td .pdf-btn:hover{ color:#000; }
.payment-details .plan-details table.plan-table tr td.label-text{ width:35%; font-size:12px; line-height:1; color:#707070; font-weight:400; text-transform:uppercase;}
.payment-details .plan-details table.plan-table tr td.value-text{ display:inline-block; font-size:13px; line-height:1; color:#6E6E6E; font-weight:600; }

.payment-details .plan-details table.plan-table tr td.value-text .manage_btn{ box-shadow:none; outline:none; border:none; height:auto; background:none; font-weight:600; margin:0; padding:0; cursor:pointer; }

.method-modal .ant-modal-body{ position:relative; padding-bottom:40px; }
.payment-moadl .ant-radio-wrapper{ display:flex !important; align-items:flex-start; padding-bottom:25px !important; }
.payment-moadl .ant-radio-wrapper p{ margin:0; padding:0 0 2px 0; }
.payment-moadl .ant-radio-wrapper .sub-text{ font-weight:400; color:#5d5d5d; margin:0; padding:0; white-space: normal; }

.method-modal .btn{ position: absolute; width: 100%; left: 0; right: 0; bottom: 0; border-radius:0 0 3px 3px; font-weight:600; text-transform:uppercase; height:40px; }

.payment-moadl .form-box{ padding-bottom:25px; }
.payment-moadl .form-box label{ padding-bottom:5px !important; }


.ElementsApp.is-empty span{ display:block !important; }

.pay-from{ padding-bottom:35px; }
.pay-from p{ max-width: 350px; margin: 0 auto; text-align: center; padding-top: 30px; font-size: 11px; letter-spacing: 0.5px; color: #999; }
.pay-btn{ position: absolute; width: 100%; left: 0; right: 0; bottom: 0; border-radius:0 0 3px 3px; font-weight:600; text-transform:uppercase; height:40px; border:none; background:#1890FF; color:#fff; }

.pay-from p.s_text{ font-size:10px; line-height: 1; color: #A2A2A2; font-weight: 500; margin: 0; padding:25px 0 0 0; max-width:100%; }
.pay-from p.s_text .simbol-logo{ display:block; margin:0; padding:7px 0 0 0; }
.pay-from p.s_text .simbol-logo img{ width:68px; height:auto; }

.pay-btn:hover{ background:#40A9FF; color:#fff; }

.refund-modal .ant-modal-body{ position:relative; padding-bottom:65px; }
.refund-modal .ant-modal-body p{ padding-bottom:5px; }

.refund-modal .ant-modal-body .btn{ position: absolute; width: 100%; left: 0; right: 0; bottom: 0; border-radius:0 0 3px 3px; font-weight:600; text-transform:uppercase; height:40px; }


.base-sec{ margin:0; padding:0 15px; height:100vh; }
.base-sec .ant-row{ width:100%; margin-top:85px; }
.base-sec .gutter-box{ display:inline-block; text-align:left; }
.base-sec h1{ font-size:30px; line-height:1; color: #000; font-weight:600; margin: 0; padding: 0 0 15px 0; }
.base-sec p{ font-size:16px; line-height:1.3; color:#333; font-weight:400; margin:0; padding:0 0 25px 0; }
.base-sec a{ font-size:16px; line-height:1.3; color:#1468D1; font-weight:600; margin:0; padding:0; }
.base-sec a:hover{ color:#093b79; }

.card-group{ margin:0; padding:0; }
.card-group table{ margin:0; padding:0; }
.card-group table tr{ display:block; margin-bottom:25px; }
.card-group table tr td{ width:100px; font-size: 13px; line-height: 1; color: #6E6E6E; font-weight: 500; margin:0; padding:5px; border:none; }
.card-group table tr td img{ width:auto; height:35px; }
.card-group table .btn{ border:none; box-shadow:none; padding:0 8px; }
.card-group table .btn.delete_btn{ border:none; color:#f17375; box-shadow:none; }
.card-group table .btn.delete_btn:hover{ border:none; color:#FF4D4F; }

.plan-details .add_btn{ border:1px solid rgba(0,0,0,.1); font-weight:600; margin-top:5px; }
.plan-details .add_btn:hover{ background:#3235F5; color:#fff; border:1px solid transparent; }

.payment-email-notification{ margin:0; padding:0; }
.payment-email-notification .lt-block{ margin:0; padding:0; }
.payment-email-notification .lt-block h5{ font-size:12px; line-height:1.4; color:#707070; font-weight: 400; text-transform:uppercase; margin: 0; padding:10px 0 0 0; }

.payment-email-notification .rt-block{ margin:0; padding:0; }
.payment-email-notification .block{ width:468px; margin:0; padding:12px 12px; transition:all 200ms ease-in-out; cursor:pointer; }
.payment-email-notification .block p{ display: inline-block; font-size: 13px; line-height: 1; color: #6E6E6E; font-weight: 500; margin:0; padding:0; }
.payment-email-notification .block .email-add{ margin:0; padding:0; }
.payment-email-notification .block .email-add p{ display:flex; align-items:center; width:100%; }
.payment-email-notification .block:first-child .email-add .email-text{ display: inline-block; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 140px; vertical-align:middle; }
.payment-email-notification .block .email-add .ant-tag{ margin-left:7px; }
.payment-email-notification .block .button-sec{ margin:0; padding:0; text-align:right; visibility:hidden; opacity:0; }
.payment-email-notification .block:hover .button-sec{ margin:0; padding:0; visibility:visible; opacity:1; transition:all 200ms ease-in-out; }
.payment-email-notification .block .button-sec .btn{ border:none; box-shadow:none; padding:0 7px; height:auto; background:none; }

.payment-email-notification .block .button-sec .btn.remove-btn{ color:#FF4D4F; }
.payment-email-notification .block:hover{ background:#f9f9f9; }
 
.payment-email-notification .input-email{ max-width:320px; margin:15px 0 0 0; padding:0; }
.payment-email-notification .input-email .ant-input{ border:1px solid #D1D1D0; }
.payment-email-notification .input-email .ant-input:hover{ border: 1px solid #3235F5; }
.payment-email-notification .input-email .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }


.extend-trial{ margin:0; padding:0 0 25px 0; text-align:center; min-height: 200px; display: flex; flex-flow: column; justify-content: center; }
.extend-trial h2{ font-size:30px; line-height:1.2; color:#000; font-weight:600; margin:0; padding:0 0 10px 0; }
.extend-trial p{ font-size:18px; line-height:1.3; color:#828282; font-weight:600; margin:0; padding:0; }


.billing-form-edit{ margin:0; padding:0; }
.billing-form-edit .ant-form-item{ width:100%; margin:0 0 15px 0; }
.billing-form-edit .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper, .ant-form-inline .ant-form-item > .ant-form-item-label{ display:block; font-size: 13px; line-height: 15px; font-weight:600; color: #1A0D0D; margin: 0; padding:0; text-align:left; }

.billing-form-edit .ant-form-item-label > label:after { display:none; }

.billing-form-edit .ant-select-selection--single{ height:37px; }
.billing-form-edit .ant-select-selection__rendered{ line-height:37px; }

/*================== Payment Details End ==================*/

/*============= Task Editor Help Css Start =============*/
.editor_help{ margin:0; padding:15px 0; }
.editor_help .heading-text{ margin:0 15px 5px; padding:0 0 12px 0; border-bottom:1px solid rgba(255,255,255,.7); }
.editor_help h3{ font-size:14px; line-height:1.2; color:#fff; font-weight:600; margin:0; padding:0; }

.editor_help ul{ margin:0; padding:0; }
.editor_help ul li{ display:flex; margin:0; padding:15px 15px 0; cursor:pointer; }
.editor_help ul li:hover{ background: rgba(0,0,0,0.3); }
.editor_help ul li .round_shape{ width:18px; height:18px; border:1px solid #fff; border-radius:50%; margin:0 12px 0 0; padding:0; position:relative; top:-2px; }
.editor_help ul li .text-block{ flex:1; margin:0; padding:0 0 15px 0; border-bottom:1px solid rgba(255,255,255,.1); }
.editor_help ul li .text-block h5{ font-size:16px; line-height:1.2; color:#fff; font-weight:400; margin:0; padding:0; }
.editor_help ul li .text-block p{ font-size:14px; line-height:1.3; color:#FFF; font-weight:600; margin:0; padding:0; display: none; }

.editor_help ul li:hover .text-block{ border-color:transparent; }

.editor_help ul li.active{ align-items:flex-start; }
.editor_help ul li.active .text-block h5{ padding-bottom:5px; font-weight: 700; }
.editor_help ul li.active .text-block p { display: block;}
.editor_help ul li.done .text-block h5{ text-decoration:line-through; }

.editor_help ul li.done .round_shape{ background: #fff; display:flex; align-items:center; justify-content:center; }
.editor_help ul li.done .round_shape .anticon{ font-size:10px; color:#15507C; }


.app-container .ant-modal-body { width:100%; padding:0px }

/*============= Task Editor Help Css End =============*/

/*============= Perform-Workflow Start =============*/
.content-overflow.perform-overflow .rt-sec{ padding:15px; }
.perform-collapse{ margin:0; padding:0 0 10px 0; }
.perform-collapse .ant-collapse{ border:none; background:none; }
.perform-collapse .ant-collapse-item{ margin:0 0 10px 0; padding:0; border:1px solid rgba(0,0,0,.1); }
.perform-collapse .ant-collapse-item .ant-collapse-header{ background:#F7F7F7; padding-top:7px; padding-bottom:7px; }

.perform-collapse .clps-head{ display:flex; align-items:center; margin:0; padding:0; }
.clps-head .lt-bx{ display:flex; align-items:center; margin:0; padding:0; }
.clps-head .lt-bx .check-box{ display:inline-block; width:22px; height:22px; border:1px solid #000; text-align:center; line-height:22px; margin:0 12px 0 0; padding:0; border-radius:3px; color:#fff; }
.clps-head .lt-bx .check-box .anticon-check{ display:none; }
.perform-collapse .ant-collapse-item.checked .clps-head .lt-bx .check-box .anticon-check{ display:inline-block; }


.perform-collapse .clps-head h2{ flex:1; font-size:14px; line-height:1.3; color:#000; font-weight:700; margin:0; padding:0 25px 0 0; }

.perform-collapse .clps-head .rt-btn{ margin:0 0 0 auto; padding:0; display:flex; align-items:center; }
.perform-collapse .clps-head .rt-btn .btn{ width:26px; height:26px; border:1px dashed rgba(0,0,0,.2); border-radius:50%; margin:0 0 0 12px; padding:0; box-shadow:none; outline:none; background:#fff; }
.perform-collapse .clps-head .rt-btn .btn .anticon{ font-size:13px; opacity:.5; }

.perform-collapse .clps-head .rt-btn p{ margin-bottom:0; margin-left:12px; }

.perform-collapse .ant-collapse-content-box{ padding:0; }

.perform-collapse .ant-collapse-item.ant-collapse-item-active{ border-radius:0; }

.perform-setting{ margin:0; padding:0; }
.perform-setting .up-field{ margin:0; padding:0 0 30px 0; }
.perform-setting h5{ display:block; font-size:14px; line-height:1; color:#000; font-weight:400; margin:0; padding:0 0 9px 0; }

.perform-setting .up-field .group-box{ display:flex; margin:0; padding:0; }
.perform-setting .up-field .input-field{ flex:1; margin:0 12px 0 0; padding:10px 10px; padding-right:5px; display:flex; align-items:center; border:1px solid rgba(0,0,0,.1); background:#fff; }
.perform-setting .up-field .input-field p{ flex:1; font-size:14px; line-height:1; color:#000; font-weight:400; margin:0; padding:0 25px 0 0; }
.perform-setting .up-field .edit-btn{ display:inline-block; font-size:13px; line-height:1; color:#fff; font-weight:500; margin:0 0 0 auto; padding:0 15px; box-shadow:none; outline:none; border:none; border-radius:4px; cursor: pointer; }

.perform-about{ margin:0; padding:0; }
.perform-about h1{ display: block; font-size:20px; line-height: 1; color: #000; font-weight:700; margin: 0; padding: 0 0 25px 0; }
.perform-about table{ width:100%; margin:0; padding:0; }
.perform-about table tr td{ padding:5px; padding-left:0; }


.mbm-block{ margin:0; padding:35px 0 0 0; }
.mbm-block .delete_btn{ background:none !important; width:auto; height:auto; color:#000 !important; box-shadow:none; padding:0; }
.mbm-block .delete_btn .anticon{ color:red; }
.mbm-block .delete_btn:hover{ color:red !important; }

.perform-users .down-block .performers-add-btn{ background:none !important; box-shadow:none; color:#333 !important; margin-top:12px; }
.perform-users .down-block .performers-add-btn:hover{ color:#fff !important; }


.task-attr-holder .right-task .user-tab .ant-tabs{ height:100%; display:flex; flex-flow:column; }
.task-attr-holder .right-task .user-tab .ant-tabs-content{ flex:1; height: calc(100% - 38px); }


.task-attr-holder .right-task .user-tab .ant-tabs-tabpane{ height:100%; overflow:auto; display:flex; flex-flow:column; }
.task-attr-holder .right-task .ant-tabs .ant-tabs-tabpane.comment-tab{ padding:0 !important; }

.editflow-tabs{ margin:0; padding:0; }
.main-tab.ant-tabs{ overflow:inherit; }
.main-tab > .ant-tabs-bar{ position: absolute; top:-45px; right:50%; transform:translateX(50%); z-index:9; margin:0; padding:0; border-bottom:none; }
.main-tab > .ant-tabs-tab{ font-size:14px; line-height:1; color:#6a6a6a; font-weight:500; padding-top:20px; padding-bottom:20px; }
.main-tab > .ant-tabs-tab:hover{ color:#3235F5; }
.main-tab > .ant-tabs-tab.ant-tabs-tab-active{ color:#000; }

.main-tab > .ant-tabs-ink-bar{ background:#000; }

.main-tab > .ant-tabs-content{ height: calc(100vh - 62px); }
.main-tab > .ant-tabs-content .ant-tabs-tabpane{ height:100%; }
.main-tab > .ant-tabs-content .ant-tabs-tabpane.ant-tabs-tabpane-inactive{ display:none; }
.main-tab > .ant-tabs-content .ant-tabs-tabpane.ant-tabs-tabpane-active{ display:block; }

.main-tab.ant-tabs .ant-tabs-tab{ padding-bottom:14px; }

.task-attr-holder.task-attr-holder-stage .gutter-box{ padding-bottom:55px; }
.overflow-col.task-attr-holder-stage .gutter-box .right-side{ padding-bottom:0; }

.task-automation{ margin:0; padding:0; }
.trigger-element ul{ margin:0; padding:0; }
.trigger-element ul li{ display:block; margin:0; padding:15px; padding-left:25px; border-bottom:1px solid rgba(255,255,255,.2); cursor: pointer; color:#fff; font-weight: 500; }
.trigger-element h2{ font-size:18px; line-height:1.3; color:#fff; font-weight:700; margin:0; padding:0 0 5px 0; }

.trigger-element ul li .icon-box{ display:inline-block; margin-right:12px; }
.trigger-element h3{ font-size:18px; line-height:1.2; color:#fff; font-weight:600; margin:0; padding:0 0 9px 0; }
.trigger-element p{ font-size:14px; line-height:1.4; color:#fff; font-weight:400; margin:0; padding:0; }

.trigger-element p .dotted{ display:inline-block; width:6px; height:6px; background:#c3c3c3; border-radius:50%; margin:0 3px; padding:0; }
.trigger-element ul li:hover{ background: rgba(0,0,0,0.1); }
.trigger-element ul li.active{ background: rgba(0,0,0,0.3); }
.trigger-element ul li .icon-box .anticon{ color:#fff !important; }


.automation-row{ height:100%; }
.automation-condition-field{ height:100%; margin:0; padding:30px 15px; }
.automation-condition-field .field-box{ margin:0; padding:0 0 10px 0; }
.automation-condition-field .field-box .and-block{ display:flex; float: inherit; }
.automation-condition-field .first-box{ padding-bottom:20px; }
.automation-condition-field .gutter-box .heading-label{ display:block; font-size:14px; line-height:1; color:#474747; font-weight:500; margin:0; padding:0 0 9px 0; }

.automation-condition-field .b_tag{ margin-top:0; }

.automation-condition-field .ant-input{ border:1px solid #D1D1D0; }
.automation-condition-field .ant-input:hover{ border: 1px solid #3235F5; }
.automation-condition-field .ant-input:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.automation-condition-field .ant-select-selection{ border:1px solid #D1D1D0; }
.automation-condition-field .ant-select-selection:hover{ border: 1px solid #3235F5; }
.automation-condition-field .ant-select-selection:focus{ border: 1px solid #3235F5; box-shadow: 0px 0px 2px 2px #75DDDD; }

.automation-condition-field .add-automation{ margin:0 0 60px 0; padding:0; }
.automation-condition-field .add-automation .btn{ display:block; width:100%; height:40px; font-size:15px; font-weight:700; }

.editflow-tabs .no-data.task-impment-error{ padding:25px; }
.editflow-tabs .no-data.task-impment-error .img-block{ margin:0 0 30px 0; padding:0; }
.editflow-tabs .no-data.task-impment-error .img-block img{ width:100%; height:auto; }

.task-editor-overflow{ background:#fafafa; }
.task-editor-atmn{ padding:15px 30px 45px; }
.task-editor-atmn .trigger-drawer{ margin-bottom:25px; padding:0; }
.task-editor-atmn .trigger-drawer .add_fixed{ position: relative; text-align:center; }
.task-editor-atmn .trigger-drawer .add_fixed .trigger-add-btn{ width:100%; margin:0; border-radius:5px; }

.task-editor-atmn .trigger-drawer .add_fixed .delete-btn{ display:inline-block; border:none; box-shadow:none; outline:none; font-size:14px; font-weight:500; color:#333; margin:10px 0 0 0; padding:15px; height:auto; }
.task-editor-atmn .trigger-drawer .add_fixed .delete-btn:after{ display:none; }
.task-editor-atmn .trigger-drawer .add_fixed .delete-btn:hover{ color:red; }
.task-editor-atmn .trigger-drawer .add_fixed .delete-btn .anticon{ color:red; position: relative; top:-1px; }

.variable-field{ height:100%; margin:0; padding:0 0 47px 0; display:flex; flex-flow:column; border-left:1px solid rgba(0,0,0,.1); }
.variable-field .title-text{ margin:0; padding:15px; border-bottom:1px solid rgba(0,0,0,.1); background:#fff; }
.variable-field .title-text h4{ font-size: 16px; line-height: 1; color: #555; font-weight: 500; margin: 0; padding:0; }

.variable-field .vrl-list{ flex:1; margin:0; padding:0; overflow:auto; }
.variable-field .vrl-list ul li{ margin:0; padding:10px; padding-right:16px; border-bottom:1px solid rgba(0,0,0,.1); display:flex; align-items:center; }
.variable-field .vrl-list ul li:hover{ background:#f9f9f9; }

.variable-field .vrl-list .text{ flex:1; margin:0 15px 0 0; padding:0; }
.variable-field .vrl-list .text h3{ font-size: 13px; line-height:1.3; color: #000; font-weight: 600; margin:0 0 5px 0; padding:0; }
.variable-field .vrl-list .text .id-text{ display:inline-block; background:rgba(0,0,0,.1); font-size:12px; color: #555; line-height:1; font-weight:500; margin:0 0 0 3px; padding:4px 5px; border-radius:3px; }
.variable-field .vrl-list .text p{ font-size: 14px; line-height: 1.4; color:#6a6a6a; font-weight: 400; margin: 0; padding:0; }

.variable-field .vrl-list .text .dotted-box{ display:inline-block; width:8px; height:8px; background:#09B1A5; border-radius:50%; margin:0 4px 0 0; padding:0; position: relative; top:-1px; }

.variable-field .vrl-list .btn-box{ margin:0; padding:0; }
.variable-field .vrl-list .btn-box .add-btn{ width:30px; height:30px; background:#3235F5; border-radius:50%; color:#fff; margin:0; padding:0; display: flex; align-items: center; justify-content: center; }
.variable-field .vrl-list .btn-box .add-btn:hover{ background:#1404D6; }
.variable-field .vrl-list .btn-box .add-btn .anticon{ line-height:0; }

.atmn-box{ margin:0; padding:0; }

.trigger-element .header{ margin:0; padding:15px 25px; padding-right:15px; display:flex; align-items:center; justify-content:space-between; }
.trigger-element .header h1{ font-size:22px; line-height:1; color:#fff; font-weight:700; margin:0; padding:0; }

.trigger-element .switch-field{ margin:0; padding:0; }
.trigger-element .switch-field .label-text{ display:inline-block; vertical-align:middle; font-size:15px; line-height:1; color:#fff; font-weight:500; margin:0 10px 0 0; padding:0; }
.trigger-element .switch-field .ant-switch-checked{ background:#000; }

.trigger-element .block{ margin:0; padding:15px 0 20px 25px; }
.trigger-element .block h6{ font-size:16px; line-height:1; color:#fff; font-weight:400; margin:0; padding:0 0 7px 0; }
.trigger-element .block h6 .icon-box{ display:inline-block; min-width:15px; margin:0 6px 0 0; padding:0; }
.trigger-element .block h6 .icon-box .anticon{ color:#fff !important; }

.trigger-element .block .tasklist-group{ margin:0; padding:0 0 0 27px; }
.trigger-element .block .tasklist-group li{ font-size:14px; line-height:1; color:#fff; font-weight:400; margin:0; padding:15px 5px; }

.crt-atm-modal{ margin:0; padding:0; }
.crt-atm-modal .top-field{ margin:0; padding:0 0 25px 0; }
.crt-atm-modal .radio-field{ margin:0; padding:0; }
.crt-atm-modal .radio-field .d-flex{ display:flex; align-items:flex-start; }
.crt-atm-modal .radio-field .ant-radio-group{ flex:1; padding-right:20px; border-right:1px solid rgba(0,0,0,.1); margin-top:12px; }
.crt-atm-modal .radio-field .ant-radio-wrapper{ display:flex; align-items:flex-start; margin:0 0 25px 0; padding:0; white-space: inherit; }
.crt-atm-modal .radio-field .ant-radio-wrapper span.ant-radio + *{ width:100%; }
 span.ant-radio{ flex:inherit; }

.crt-atm-modal .radio-field .ant-radio-wrapper .ant-select{ width:200px; }

.crt-atm-modal .radio-text{ flex:1; margin:0 20px 0 0; padding:0; }
.crt-atm-modal .radio-text h2{ font-size:16px; line-height:1; color:#000; font-weight:600; margin:0; padding:0 0 7px 0; }
.crt-atm-modal .radio-text p{ font-size:15px; line-height:1.4; font-weight:400; }

.crt-atm-modal .radio-field .rt-field{ width:220px; margin:0; padding:0 0 0 20px; }
.crt-atm-modal .radio-field .rt-field p{ font-size:14px; line-height:1.4; color:#1A0D0D; font-weight:400; margin:0; padding:0 0 15px 0; }
.crt-atm-modal .radio-field .rt-field ul{ margin:0 0 20px 0; padding:0 0 10px 0; border-bottom:1px solid rgba(0,0,0,.1); }
.crt-atm-modal .radio-field .rt-field ul li{ margin:0 0 12px 0; padding:0; display:flex; align-items:flex-start; }
.crt-atm-modal .radio-field .rt-field ul li .anticon{ font-size:13px; color:green; position: relative; top:4px; }
.crt-atm-modal .radio-field .rt-field ul li .anticon.anticon-close{ display:none; }
.crt-atm-modal .radio-field .rt-field ul li span{ flex:1; font-size:15px; line-height:1.4; color:#1A0D0D; font-weight:400; margin:0 0 0 8px; padding:0; }

.crt-atm-modal .radio-field .rt-field ul li.line-through{ text-decoration:line-through; }
.crt-atm-modal .radio-field .rt-field ul li.line-through .anticon-check{ display:none; }
.crt-atm-modal .radio-field .rt-field ul li.line-through .anticon-close{ color:red; display:inline-block; }

.trigger-moadal .trigger-element .header{ padding:10px 0 15px 0; }
.trigger-moadal .trigger-element .header h1{ color:#000; }
.trigger-moadal .trigger-element .switch-field .label-text{ color:#000; }

.trigger-moadal .trigger-element h2{ color:#000; }
.trigger-moadal ul.trigger-list li{ color:#000; border-bottom:1px solid rgba(0,0,0,.1); padding-left:0; padding-right:0; }
.trigger-moadal .trigger-element ul li .icon-box .anticon{ color:#000 !important; }

.trigger-moadal .trigger-element .block{ padding-left:0; padding-right:0; }
.trigger-moadal .trigger-element .block h6{ color:#000; }
.trigger-moadal .trigger-element .block h6 .icon-box .anticon{ color:#000 !important; }
.trigger-moadal .trigger-element .block .tasklist-group li{ color:#000; border-bottom: 1px solid rgba(0,0,0,.1); }

.atmn-base-modal{ max-width:1250px; top:30px; }
.atmn-base-modal .top-field{ display:flex; align-items:center; justify-content:space-between; }
.atmn-base-modal .top-field .lt-box{ width:400px; }

.atmn-base-modal .top-field .rt-box .main-cta{ position: relative; border-radius:4px; min-width:180px; height:45px; }
.task-editor.atmn-base-modal .ant-modal-body{ padding-bottom:24px; }


.erwn-modal .ant-modal-body{ padding-top:5px; }
.erwn-modal-element{ margin:0; padding:0 0 40px 0; }
.erwn-modal-element .ant-tabs-tab{ font-size:15px; font-weight:500; color:#000; }
.erwn-modal-element .ant-tabs-tab span{ display:inline-block; min-width:20px; height:20px; font-size:12px; line-height:20px; font-weight:400; margin:0 0 0 7px; padding:0 6px; background:#000; color:#fff; border-radius:50%;  }
.erwn-modal-element .ant-tabs-tab:hover{ color:#000; opacity:.8; }

.erwn-modal-element table{ width:100%; margin:0; padding:0; }
.erwn-modal-element table tr td{ font-size:14px; line-height:1.4; color:#000; font-weight:400; margin:0; padding:8px 7px; }
.erwn-modal-element table tr td .btn{ display:inline-block; width:150px; height:38px; border-radius:4px; box-shadow:none; outline:none; font-size:14px; line-height:20px; font-weight:500; color:#818181; margin:0; padding:0; cursor: pointer; border:1px solid rgba(0,0,0,.2); background:#fff; }
.erwn-modal-element table tr td .btn:hover{ opacity:.8; }

.erwn-modal-element .ant-tabs-ink-bar{ background:rgba(0,0,0,.2); }

.erwn-modal-element table tr td .btn.resolve-btn:hover{ background:#d63333; opacity:1; color:#fff; border:1px solid transparent; }
.erwn-modal-element table tr td .btn.resolve-btn .unrsl-text{ display:none; }
.erwn-modal-element table tr td .btn.resolve-btn:hover .unrsl-text{ display:block; }
.erwn-modal-element table tr td .btn.resolve-btn:hover .rsl-text{ display:none; }

.radio-conditions{ margin:0; padding:20px 0; }


/*============= Task Editor Automation Css End =============*/

/*============= Perform task tab Start =============*/
.perform-tab-field{ margin:0; padding:0; }
.perform-tab-field ul li{ margin:0; padding:0 0 25px 0; position: relative; }
.perform-tab-field ul li:last-child{ padding-bottom:0; }

.perform-tab-field .inf-btn{ position:absolute; top:0; right:0; border:none; box-shadow:none; outline:none; margin:0; padding:0; cursor: pointer; line-height:0; background:none; }
.perform-tab-field .inf-btn .anticon{ font-size:18px; color:red; }

.perform-tab-field .label-text{ display:block; font-size: 14px; line-height: 1; font-weight: 600; color:#838383; margin:0; padding:0 0 8px 0; }

.perform-tab-field .value-text{ display:block; font-size:16px; line-height: 1; font-weight: 500; color:#000; margin:0; padding:0; }

/* .perform-tab-field .table-field{ display:block; overflow:auto; white-space:nowrap; margin:0; padding:0; }
.perform-tab-field table{ display:block; width:100%; margin:0; padding:0; }
.perform-tab-field table thead tr th{ max-width:500px; padding: 5px 10px; border: 1px solid #333; }

.perform-tab-field table tbody tr td{ max-width:500px; padding: 5px 10px; border: 1px solid #333; }
.perform-tab-field table tbody tr td p{ overflow: hidden; text-overflow: ellipsis; } */

.perform-inf-modal{ margin:0; padding:0; }
.perform-inf-modal table tr td{ font-size:16px; line-height:1.3; color:#000; font-weight:500; margin:0; padding:15px 10px; }

.perform-inf-modal table tr td .task-pf{ border-bottom:none; display:flex; align-items:center; }

.perform-inf-modal table tr td .task-pf .task-progress{ position:relative; left:0; top:0; transform:translateY(0); margin-right:12px; }
.perform-inf-modal table tr td .task-pf .task-output-box{ padding:0; }
.perform-inf-modal table tr td .task-pf .task-name{ padding:0; }
.perform-inf-modal table tr td .task-pf .list-text{ padding:0; }



/*============= Perform task tab End =============*/


/*____________________________________________
                Media Quaries 
______________________________________________*/

@media only screen and (orientation:landscape) {
body { -webkit-text-size-adjust:none; }
}
@media only screen and (orientation:portrait) {
body { -webkit-text-size-adjust:none; }
}
@media screen and ( min-width: 1800px ){
    .add-task .add-field a span{ position: relative; top:0; }
}

@media screen and ( max-width: 1600px ){

    .element-dsg-wrapper .text-flow{ width:42%; }
    .element-dsg-wrapper .text-flow .box{ width:33.3%; }

}

@media screen and ( max-width: 1550px ){
    .admin-section .thumb-nail .ant-col-8{ width:50%; }
    .ntf_all .ant-col-10{ width:75%; }

    /* .content-overflow .preform-mark-btn{ width: 83%; } */

    .tab-field .tab-content .amount-pyable table tr td{ padding: 15px 25px; }
    .tab-field .tab-content .team-list{ padding:0; }

}

@media screen and ( max-width: 1500px ){
    .signup-block .feature-sec{ width:20%; }
    
}

@media screen and ( max-width: 1400px ){
    .trigger-element .header{ display:block; }
    .trigger-element .header h1{ padding-bottom:15px; }
    .element-dsg-wrapper .text-flow{ width:40%; }
}

@media screen and ( max-width: 1360px ){
    .element-dsg-wrapper{ flex-flow: column; justify-content:center; }
    .element-dsg-wrapper .text-flow{ width:100%; border-left:none; margin-bottom:45px; padding:45px 0; border-bottom:1px solid rgba(0,0,0,.1); }
    .element-dsg-wrapper .flow-diagram{ width:100%; }
}

@media screen and ( max-width: 1300px ){
    .payment-block .tab-field .ant-tabs-tabpane{ padding: 0 60px; }
    .payment-block .tab-field .order-block .block{ padding: 0 30px; }


}

@media screen and ( max-width: 1280px ){ 
    .signup-block .signup-form{ padding: 0 0 0 50px; }

}

@media screen and ( max-width: 1199px ){
.form-h .lt-block{ width:420px; padding:30px; }
.form-h.login-page{ align-items:inherit; }
.payment-block .heading-block { padding: 30px 35px 15px; }  
.payment-block .tab-field .ant-tabs-top-bar{ padding: 0 35px; }
.payment-block .tab-field .ant-tabs-tabpane{ padding: 0 35px; }

.payment-block .tab-field .tab-content .top-block .pricing-side{ padding: 40px 60px 65px 0; }
.payment-block .tab-field .tab-content .top-block .form-block{ padding: 0 0 0 60px; }

.workspace-block{ width:100%; padding:0 30px; }
.workspace-block .left-block .lt-field{ width: 350px; }
.workspace-block .left-block .right-field{ width:525px; }
.workspace-block.login-workspace{ width:100%; padding:0; }
.login-block .login-sec{ padding:0; }

.signup-block .signup-form{ padding:0; }
.signup-block .content-desc{ width: 340px; }
.form-h .rt-block .desc{ padding:30px; }

.members-field{ padding: 60px 30px; }

.branch-section{ padding: 60px 30px; }

.payment-block .tab-field .ant-tabs-tabpane{ padding: 0 30px; }
.payment-block .tab-field .order-block .block{ padding:0 15px; }
.payment-block .tab-field .order-block .block .heading{ width:30%; }
.payment-block .tab-field .order-block .block .status-text{ width:70%; }
.payment-block .tab-field .order-block .block .status-text .desc{ padding:0 15px; }
.payment-block .tab-field .order-block .block .heading h3{ font-size:16px; line-height:18px; }

.faq-section .accordion-box{ width:100%; }

.main-dashboard .field-section{ padding: 0 30px; }

.settings-section{ padding: 0 15px; }
.tasks-block{ padding:0; }

.admin-section .thumb-nail .box .email-address h3{ text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 146px; }
.settings-section .block{ width:100%; }

.admin-section .thumb-nail .ant-col-8{ width:100%; }

.perform-body-tasks .all-lt-task{ width:100%; padding-right: 0 !important; }
.content-right-overflow{ display:none; }

.task-holder{ width:100%; }
.content-overflow.perform-overflow{ position: absolute; left: 0; width: 100%; transform: translateX(100%); z-index: 9; background: #fff; transition:all 500ms ease-in-out; }
.content-overflow.perform-overflow.m-perform-task-active{ transform: translateX(0%); }
.content-overflow.perform-overflow.m-perform-task-active .ant-form{ padding-top:30px; }
.back-task-btn{ display:block; position: fixed; width: 100%; background: #fff; z-index: 9; }

.profile-section .form-section{ width:85%; }

.body-content .ant-tabs-content{ padding:0; }
.body-content .ant-tabs .ant-tabs-content .tasks-block{ padding:0 15px; }
.ntf_all .item_block{ width:100%; }

.payment-block .tab-field .tab-content{ padding-bottom:45px; }
.payment-block .tab-field .tab-content .ant-row{ display:block; }
.payment-block .tab-field .tab-content .ant-col-12{ width:100%; }
.tab-field .tab-content .team-list{ padding:0 0 45px 0; }

.tab-field .tab-content .amount-sec{ padding:0; }
.tab-field .tab-content .amount-pyable table tr td{ padding: 15px 15px; }

}

@media screen and ( max-width: 991px ){ 
.form-h{ display:block; height:auto; }
.form-h .lt-block{ width:auto; max-width:500px; margin:0 auto; overflow:inherit; padding:30px; }
.login-block .login-sec{ padding:0; }
.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button{ height: 45px;  font-size: 13px; line-height: 15px; }

.form-h .rt-block .desc{ position: relative; top:0; transform:translateY(0); }
.form-h.login-page .logo-img{ position: relative; top:0; left:0; }

.workspace-block .left-block .lt-field{ float: inherit; width:100%; }
.workspace-block .left-block .right-field{ float: inherit; width:100%; }
.workspace-block .left-block .text-content{ position: relative; width:100%; padding: 0 0 42px 0; }
.workspace-block .left-block .text-content p{ max-width:650px; }

.workspace-block .left-block .ws-button{ padding: 18px 0 35px 0; text-align:center; }

.signup-block .feature-sec .content-desc{ width: 325px; padding:25px 20px; }
.signup-block .signup-form{ padding:0; }
.signup-block .signup-form .login-form{ padding:25px 0 0 0; }

.login-block .login-sec .lg_form .login-form{ padding:0; }
.logo-img{ display:block; text-align:center; }

.signup-block{ display:block; }
.signup-block .signup-form{ display:block; width:100%; }
.signup-block .signup-form .login-form{ width:100%; margin:0; max-width:100%; padding-top:0; }
.signup-block .content-desc{ width:100%; padding:65px 0 0; }
.signup-block .content-desc p{ font-size: 16px; line-height: 1.4; padding-bottom:25px; }
.signup-block .signup-form .singup-man{ position: relative; width:500px; left:0; right:0; margin:0 auto; }
.signup-block .feature-sec{ display:none; }
.signup-block .signup-form .logo-h{ display: block; opacity: 1; text-align:center; margin:0 0 20px 0; } 

.profile-section .form-section{ width: 100%; }

.members-field .upper-block{ display:block;  }
.members-field .upper-block .search-block{ width:100%; }
.members-field .upper-block .search-block .ant-input{ height: 47px; }
.members-field .upper-block .member-btn{ justify-content:space-between; padding-bottom:25px; }
.members-field .ant-table-body{ white-space:nowrap; }



.branch-section .up-block .lt-block .ant-checkbox-wrapper{ font-size: 14px; }
.branch-section .ant-table-body{ white-space:nowrap; }

.payment-block .tab-field .order-block .block{ display:block; height:auto; padding:20px 15px; }
.payment-block .tab-field .order-block .block .heading{ width:100%; padding-bottom:18px; }
.payment-block .tab-field .order-block .block .status-text{ width:100%; }
.payment-block .tab-field .order-block .block .status-text .desc:first-child{ border-left:none; padding-left:0; }

.payment-block .tab-field .ant-tabs-tabpane{ padding: 0 15px; }
.payment-block .tab-field .tab-content .top-block{ padding: 35px 0 0; }
.payment-block .tab-field .tab-content .top-block .pricing-side{ padding: 40px 15px 65px 0; }
.payment-block .tab-field .tab-content .top-block .form-block{ padding: 0 0 0 15px; }
.payment-block .tab-field .tab-content .top-block .pricing-side .user-features .content{ font-size: 20px; line-height: 27px; }
.payment-block .tab-field .tab-content .top-block .pricing-side .user-features .price-part{ font-size: 95px; line-height: 78px; margin: 25px 0 10px; }
.payment-block .tab-field .tab-content .top-block .pricing-side .user-features .price-part .dolar{ font-size: 35px; line-height: 52px; }

.faq-section .heading-text h2{ font-size: 45px; line-height: 47px; padding: 0 0 15px 0; }

.field-section .block .item-all .perfm_box{ width: 33.3%; }

.accept-base{ padding:0 35px; }

.ant-layout-sider.content-navigation{ width:inherit !important; min-width:inherit !important; max-width:inherit !important; flex:100% !important; }


.ant-modal .ant-modal-close{ right:10px; top:10px !important; }
.ant-modal .ant-modal-close-x{ width:30px; height:30px; }
.ant-modal .ant-modal-close-x:hover{ background:rgba(0,0,0,.4); }
.ant-modal .ant-modal-close-x .anticon{ font-size: 15px; color:#000; transition:all 350ms ease-in-out; } 
.ant-modal .ant-modal-close-x:hover .anticon{ font-size: 15px; color:#fff; }

.workflow-content{ position: fixed; top:0; bottom:0; left:100%; transition:all 450ms ease-in-out; }
.workflow-content.m-workflow-active{ left:79px; right:0; z-index: 99999; }

.checklist-header .headind-side .expand-btn .ant-btn.d_btn{ display:none; }
.checklist-header .headind-side .expand-btn .ant-btn.m_btn{ display:inline-block; }
.checklist-header .headind-side .expand-btn .ant-btn.m_btn span{ position:relative; top:-2px; }
.checklist-header .headind-side .expand-btn .ant-btn.m_btn img{ width:20px; height:auto; }

.settings-section .perform_custom .switch-block{ padding:0 0 0 65px; }



.ant-layout.main-body.perform-body{ padding: 10px 15px 35px; }

.field_look{ width:100%; margin:0; padding:5px 0px !important; }

.perform-report{ width:100%; }
.perform-report .head-block{ padding:15px; }
.perform-report .report-field{ padding: 20px 15px; }
.top-head .up-sec h2{ font-size:20px; line-height:22px; }
.top-head .up-sec p{ font-size:13px; line-height:15px; }
.top-head .complete-sec h4{ font-size: 35px; line-height: 37px; }

.perform-users .upper-block{ padding: 0px 0px 15px; }
.perform-users .down-block{ padding: 30px 0px 40px; }
.perform-users .down-block .ant-col-12{ width:100%; }

.workspace-setting-profile{ position: fixed; top:0; bottom:0; left:100%; transition:all 450ms ease-in-out; }
.workspace-setting-profile.m-settingpage-active{ left:79px; right:0; z-index:99999; }

.workspace-setting-profile .back-setting-btn{ display:block; position:fixed; width:100%; background:#fff; z-index:99; }
.profile-section{ padding:65px 25px 35px; }

.members-field{ padding:65px 25px 30px; }

.branch-section{ padding:65px 25px 30px; }

.workflows-field{ padding:65px 25px 30px; }
.workflows-field h2{ font-size:22px; padding:0 0 30px 0; }
.workflows-field .ant-table-body{ white-space:nowrap; }

.workflows-field table{ background:#fff; }

.payment-layout{ padding-top:40px; }
.payment-block .tab-field .order-block{ padding: 15px 0 30px; }
.payment-block .tab-field .tab-content .top-block{ padding:18px 0 0 0; }

.workspace-block{ margin:42px 0 0; padding:0 25px; }

.ac_profile{ width:100%; padding:0 25px; }
.account-sec .top-block{ padding:10px 0 30px 0; }
.account-sec .bottom-block{ padding:30px 0 0 0; }


.login-block .tip-side .text-block{ width:100%; }

.form-h .login-form{ width:100%; }
.workspace-block.login-workspace{ width:100%; padding:0; }

.onboarding-video-modal{ width:85% !important; }
.onboarding-modal .bottom-sec{ display:block; }
.onboarding-modal .bottom-sec .list-block{ width:100%; height:auto; overflow: auto; white-space: nowrap; display:flex; }
.onboarding-modal .list-block .block{ margin:0 15px 0 0; }
.onboarding-modal .block .text-block{ width:200px; white-space: normal }
.onboarding-modal .list-block .block:last-child{ margin-right:0; }

.onboarding-modal .bottom-sec .video-block{ height:315px; }

.base-choose{ display:block; padding:30px 15px; }
.workspace-logo-h{ position: relative; top:0; left:0; margin-bottom:45px; }

}

@media screen and ( max-width: 767px ){
.form-h{ padding-top:0; }

.signup-block{ display:block; }
.signup-block .feature-sec{ width:100%; padding:45px 30px; }
.signup-block .feature-sec .logo-h{ display:none; opacity:0; }
.signup-block .feature-sec .content-desc{ position: relative; width:400px; top:0; bottom:inherit; right:0; transform:translateY(0); margin:0 auto; }

.login-block .tip-side .text-block h2{ margin:0; padding:0 0 10px 0; }
.login-block .login-sec{ display:block; width:100%; padding:0; }

.signup-block .content-desc{ padding:38px 0 22px 0; }

.checklist-header{ padding: 20px; display:block; }
.checklist-header .headind-side{ padding-bottom:20px; }
.body-part .previous-block .up-secion{ padding: 5px 15px 10px 10px; }
.previous-block .bottom-section .report_table .ant-table-wrapper{ margin:0 !important; }

.body-header .btn-block{ flex-flow: row-reverse; justify-content: space-between; }
.checklist-header .btn-block .perform-btn{ font-size:12px; }

.transparent_overlay{ background:rgba(0,0,0,.2); }
.prf_notification{ max-height:445px; }

.body-header .btn-block .notification-btn{ padding: 0 18px 0 0; }

.check_listtab{ padding:0 55px; }

.ant-layout.main-body.perform-body .heading-field .drop-down .dropdown-content{ font-size: 20px; background: url(/static/media/arrow-down.b75cc781.svg) no-repeat right 12px; background-size: 14px; padding: 0 25px 0 0; }


.top-head .complete-sec h4{ font-size:30px; line-height:32px; }

.profile-upload{ width:100%; margin:0 0 25px; padding:0 25px; }

.settings-section .field-setting{ margin:0 0 25px; }

.onboarding-video-modal{ width:500px !important; }


}

@media screen and ( max-width: 700px ){
.task-perfirm { padding: 40px 25px 20px; }   
.task-perfirm .task-comment{ width:100%; }
.task-perfirm .task-comment .desc-box{ padding: 0 15px 0 50px; }

.login-block .tip-side{ display:none; opacity:0; width:0; }
.login-block .login-sec{ width:100%; }
.form-h .login-form{ margin:0 auto; }
.form-h .login-form .ant-form-item .ant-form-item-children .login-form-button{ margin-top: 5px; }
.login-block .login-sec .lg_form .login-form{ padding:0; }

.updates-content .product-listing{ width:100%; padding: 0 30px; }

.accept-base{ padding:0 30px; }
.accept-base .block{ display:block; }
.accept-base .block .img-block{ display:none; }
.accept-base .block .text-block{ width:100%; text-align:center; }
.accept-base .block .text-block p{ margin:0 auto; }
.accept-base .block .text-block .logo-block{ margin-bottom:35px; }

.ant-layout.main-body.perform-body .heading-field .up-block{ padding:0; }
.ant-layout.main-body.perform-body .heading-field .perform-back{ top:14px; left:37px; }

.ant-layout.main-body.perform-body .heading-field .flow_button{ position:relative; top:0; text-align:center; padding:15px 0 0 0; }

.top-head .name-block{ width:100%; display:flex; align-items:flex-start; justify-content:space-between; }
.top-head .details-sec{ width:100%; }

.payment-block .tab-field .ant-tabs-top-bar{ padding:0 15px; }
.payment-block .tab-field .order-block .block .heading{ padding-bottom: 5px; }
.payment-block .tab-field .order-block .block .status-text{ display:block; }
.payment-block .tab-field .order-block .block .status-text .desc{ width:100%; padding:0; border-left:none; }
.payment-block .tab-field .tab-content .top-block .ant-row{ display:block; }
.payment-block .tab-field .tab-content .top-block .ant-col-12{ width:100%; }
.payment-block .tab-field .tab-content .top-block .pricing-side{ border-right:none; padding:10px 0 35px 0; }
.payment-block .tab-field .tab-content .top-block .form-block{ padding:0; }
.payment-block .tab-field .tab-content .bottom-block{ padding:25px 0 35px 0; }

.welcome-box h4{ font-size:32px; margin-bottom:15px; }
.form-h .login-form{ width:100%; max-width:100%; padding:45px 25px 25px; }

.dropdown-perform{ top:67px; }

.app-signup .form-h .login-form{ padding:0; }

}
    
@media screen and ( max-width: 575px ){
.form-h .lt-block{ width:100%; padding: 30px 15px; }
.form-h .login-form .ant-form-item .ant-form-item-children .ant-input, .form-h .login-form .ant-form-item .ant-select{ height:42px; }
.signup-page .logo-img{ left:15px; }

.ant-layout-header .logout-menu a{ white-space: nowrap; }

.main-dashboard{ padding: 18px 0 0 0; }
.main-dashboard .field-section{ padding: 0 15px; }
.field-section .block .item-all .perfm_box{ width:50%; }

.product-listing .top-head{ padding: 0 0 30px 0; }
.updates-content .product-listing{ padding: 0 15px; }

.content-navigation h2{ padding:0px 15px 0 45px; }

.base-layout .up-block p{ padding: 0 0 35px; }

.accept-base{ padding:0 15px; }

.ant-layout-sider.main-navigation{ margin-left:-80px; }
.ant-layout-sider.main-navigation.active{ margin-left:0; box-shadow: 0 1px 5px rgba(0,0,0,.1); }
.main-navigation .humberger-menu{ display:flex; top:15px; cursor:pointer; }
.workflow-content.m-workflow-active{ z-index:9; left:0; }

.checklist-header{ padding: 20px; }
.checklist-header h5{ font-size:20px; line-height:1.3; }
.checklist-header .headind-side .expand-btn{ width: 25px; left: -37px; }
.checklist-header .headind-side .expand-btn .ant-btn.m_btn img{ width:17px; }
.checklist-header .headind-side .expand-btn{ display:none; }
.notification-modal{ position:fixed; width:100%; padding:0 15px; background:none; box-shadow:none; border-radius:0; }
.prf_notification{ background:#fff; border-radius: 5px; box-shadow: 0 2px 100px 0 rgba(0,0,0,.1); }
.check_listtab{ padding:0 15px 0 50px; }

.notification-modal::after{ display:none; }
.notification-close{ right:25px; display:block; }
.notification-close .n_btn .anticon-close{ position:relative; top:1px; }

.ant-layout.main-body.perform-body{ position:absolute; padding: 10px 15px 15px; }

.capacity-block .progress-bar .percent-progress{ width:85%; }
.capacity-block .progress-bar .percent-content{ right:-45px; }
.capacity-block .progress-bar .percent-content{ font-size:16px; line-height:18px; }

.head-block .switch-box{ padding: 0 18px 0 0; }
.head-block .switch-box:last-child{ padding-right:0; }
.head-block .switch-box .text-side{ padding: 0 0 0 5px; }

.workspace-setting-profile.m-settingpage-active{ left:0; z-index:9; }
.profile-section{ padding:65px 15px 30px; }

.members-field{ padding:65px 15px 30px; }

.branch-section{ padding:65px 15px 30px; }
.branch-section .up-block{ padding:0 0 25px 0; }

.workflows-field{ padding:65px 15px 30px; }

.ant-layout-header{ padding:0 15px; }
.workspace-block{ padding:0 15px; }
.workspace-block .left-block .text-content{ padding:0 0 25px 0; }
.workspace-block .left-block .text-content h3{ font-size:25px; line-height:1; }


.updates-content{ padding: 18px 0 25px; }
.product-listing .top-head .title-block{ padding-left:30px; }

.profile-upload{ padding:0 15px; }
.ac_profile{ padding:0 15px; }

.onboarding-modal .bottom-sec .video-block{ height:275px; }

.form-h .rt-block .desc h2{ font-size:30px; }

.app-signup{ display:block; padding:30px 15px; }
.app-signup .signup-page{ width:100%; }

.form-h.forgot-form .login-form{ padding: 10px 15px 30px; }
.form-h.forgot-form .forgot-box{ width:100%; }
.form-h .login-form .email-info p{ padding-right:0; }
.form-h .login-form .email-info .wrn-btn{ position: relative; margin-top:15px; }


}

@media screen and ( max-width: 550px ){
.task-perfirm .top-block{ display:block; text-align:center;  }
.task-perfirm .top-block .left-text{ padding: 0 0 22px 0; }
.task-perfirm .top-block .rt-side{ display: flex; justify-content: space-between; }


}
@media screen and ( max-width: 525px ){
.notification-block .block .content-text{ width: 375px; }

}

@media screen and ( max-width: 500px ){
.task-perfirm{ padding: 40px 0px 20px; }   
.task-perfirm .top-block{ padding: 25px 15px 15px; }  
.task-perfirm .top-block { margin: 0 0 25px 0; }  
.task-perfirm .top-block .left-text p{ white-space: nowrap; text-overflow: ellipsis; overflow: hidden; } 
.task-perfirm .task-comment .desc-box .text-block h2{ font-size: 16px; line-height: 22px; }
.task-perfirm .task-comment .desc-box{ padding: 0 15px 0 42px; }
.task-perfirm .task-comment .desc-box::after { width: 28px; height: 28px; }
.task-perfirm .task-comment .desc-box.active-box::before { width: 28px; height: 28px; background-size: 16px; }
.task-perfirm .task-comment .desc-box .bottom-field .form-field .ant-input{ margin-bottom:15px; }

.task-perfirm .task-comment .desc-box .bottom-field .task-controlar .ant-btn{ margin: 0 25px 0 0; }    
.task-perfirm .task-comment .desc-box .bottom-field .task-controlar .ant-btn:last-child{ margin:0; } 

.form-h .login-form{ width:100%; padding: 0 15px; }

.workspace-block{ padding:0 15px; }
.workspace-block .left-block .text-content{ padding: 0 0 30px 0; }

.signup-block .signup-form .login-form{ width:100%; max-width:100%; }
.signup-block .feature-sec .content-desc{ width:100%; }
.signup-block .signup-form .singup-man{ width:100%; }

.verify-field p{ padding: 0 0 30px 0; }
.verify-field .mid-block .box .ant-input{ height: 70px; font-size: 28px; }
.verify-field .mid-block p{ padding: 35px 0; }

.field-section .block .ant-collapse-header{ font-size: 14px; line-height: 16px; padding: 10px 0 12px 20px; }
.field-section .block .item-all .perfm_box{ width:100%; }
.field-section .block .item-all .item{ width:100%; }

.notification-block .block .content-text{ width:278px; }
.prf_notification .block h3{ font-size:13px; }

.updates-content{ padding: 22px 0; }
.product-listing .top-head{ padding: 0 0 20px 0; }
.updates-content .product-listing{ position: relative; padding-bottom: 45px; margin-bottom:25px; }
.product-listing .top-head .button-side{ position: absolute; bottom: 0; }

.form-h .welcome-box{ padding-top: 45px; }
.welcome-box h4{ font-size: 25px; margin-bottom: 15px; margin-top: 0; }


.base-layout .up-block h2{ font-size: 25px; line-height: 1.3; }

.ant-layout.main-body.perform-body .heading-field .up-block{ padding:0; }

.report-field .top-head{ padding: 0 0 15px 0; }
table.table-text .lt{ padding-right:10px; }
.branch-section .up-block .lt-block .tooltip-btn{ padding:0; }

.settings-section .field-setting .ant-input{ width:100% !important; }
.settings-section .field-setting .color-list ul li.active{ background-size:15px; }

.tasks-block .up-block{ justify-content:center; }
.tasks-block .up-block h1{ font-size:15px; margin:0; }
.tasks-block .down-block .block .desc-box a{ padding:10px 15px 10px; }
.tasks-block .up-block .edit-btn a{ padding:10px 8px; }

.dropdown-perform{ width:100%; left:0; transform: translateX(0); }

.onboarding-modal .bottom-sec .video-block{ height:220px; }
.tab-field .tab-content .payment-filter{ display:block; padding:0 0 35px 0; }
.tab-field .tab-content .label-text{ margin:0 0 15px 0; }

.app-signup .logo-img img{ width:120px; height:auto; }
.app-signup .element-block .company-logo img{ width:115px; height:auto; }
.app-signup .element-block .plus-icon:before{ width:2px; }
.app-signup .element-block .plus-icon:after{ height:2px; }

.app-signup .element-block .top-block{ padding: 0 0 35px; }

.form-h.forgot-form .login-form{ padding: 10px 15px 30px; }

}


@media screen and ( max-width: 450px ){
.signup-block { margin-top:40px; }
.signup-block .content-desc { display:none }

.task-perfirm .top-block .rt-side .ant-btn{ width:48%; }
.all-task{ padding:0 15px; }

.task-perfirm .task-comment .desc-box{ padding:0; }
.task-perfirm .task-comment .desc-box .text-block{ padding: 0 0 25px 35px; }
.task-perfirm .task-comment .desc-box .bottom-field .form-field .ant-btn{ position: relative; font-size: 14px;
    line-height: 16px; padding: 0 30px; }
.task-perfirm .task-comment .desc-box .bottom-field .task-controlar{ display: flex; justify-content: space-around; }
.task-perfirm .task-comment .desc-box .bottom-field .form-field{ display:flex;  }

.workspace-block .box .list-block .lt-block .text-block{ line-height: 1; margin: 0 0 0 15px; padding: 0; }

.form-wrapper .ant-input-group-addon{ padding:0 15px; }

.branch-section .up-block{ display:block; }
.branch-section .up-block .lt-block{ padding-bottom:15px; }

.head-block .switch-box{ padding:0 10px 0 0; } 
.head-block .switch-box .sw-side .ant-switch{ min-width:40px; }

.tab-field .tab-content .amount-pyable table tr td{ padding:15px 0; }
.tab-field .tab-content .team-list .block{ padding: 15px 30px 15px 15px; }
.team-list .block .text-field h4{ font-size: 20px; }
.team-list .block .price-content h4{ font-size: 35px; line-height: 25px; }
.team-list .block .check-icon img{ width:30px; }

.app-signup .signup-page{ padding: 20px 15px 0; }
.app-signup .signup-page .signup-block{ margin:0; }


}

@media screen and ( max-width: 400px ){ 
.form-h .login-form .signup-sec{ float: inherit; justify-content:center; padding-bottom:10px; }
.form-h .login-form .forgot-sec{ float: inherit; text-align:center; }
.form-h .login-form .login-form-forgot{ float: inherit; }

.task-perfirm .task-comment{ padding: 28px 15px 35px; }
.task-perfirm .task-comment .desc-box .bottom-field .form-field{ padding: 0 0 20px 0; }

.workspace-block .box .bottom-block a{ padding: 10px 0 10px 0; }
.workspace-block .box .list-block .lt-block .fetures{ width: 45px; height: 48px; overflow: hidden; }
.workspace-block .box .list-block .lt-block .text-block{ padding: 0 0 0 12px; }
.workspace-block .box .list-block .btn-block img{ width: 12px; }

.signup-block .feature-sec{ padding: 45px 15px; }
.form-h .signup-block .login-form .ant-form-item .ant-form-item-children .ant-input{ height:41px; }
.form-h .signup-block .login-form .ant-form-item{ margin-bottom:10px; }

.verify-field .mid-block .gutter-row{ padding-left: 5px !important; padding-right: 5px !important; }
.verify-field .mid-block .box .ant-input{ height: 60px; font-size: 25px; border-radius:5px; }

.product-listing .top-head h2{ font-size: 22px; }
.product-listing .all-list .item-list{ padding:20px 15px; }

.notification-block .block .content-text{ width:225px; }

.workspace-block .box .list-block .lt-block .text-block{ padding: 0; width: 168px; }
.workspace-block .box .list-block .lt-block .text-block h3{ font-size: 13px; line-height: 17px; }
.workspace-block .box .list-block .btn-block img{ width: 10px; }

.task-pf .task-name{ padding-right:70px; }

.perform-report .head-block .rt-block{ padding: 15px 0 0 0; width: 100%; text-align: center; }
.perform-users .box .user-name{ width: 160px; }
.perform-users .box .user-name .email-text{ display: block; overflow: hidden; text-overflow: ellipsis; }

.perform-report .head-block .lt-block{ width:100%; display:flex; justify-content:space-around; }

}
@media screen and ( max-width: 319px ){ 
.task-perfirm .task-comment .desc-box .bottom-field .form-field .ant-btn{ font-size: 12px; padding: 0 10px; }

}

.stage-move-dropdown.ant-dropdown {
    z-index: 1;
}
